import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './CreateCIFS.css';
import {useDispatch, useSelector} from "react-redux";
import {contentAction, popupAction} from "../../../actions";
import {contentService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import TextInput from "../../input/TextInput";
import Select from "../../selectbox/Select";
import Checkbox from "../../checkbox/Checkbox";
import {getMixString} from "../../../language/languageUtils";
import {validateSpecialChar} from "../../../helper/contentValidator";

const CreateCIFS = (props) => {
    const {t} = useTranslation();
    const myContentGroupId = useSelector(({content}) => content.myContentGroupId);
    const {id, content, editMode = false} = props;
    const dispatch = useDispatch();

    const pollingIntervalOption = [
        {title:1, value:1},
        {title:2, value:2},
        {title:3, value:3},
        {title:4, value:4},
        {title:5, value:5},
        {title:6, value:6},
        {title:7, value:7},
        {title:8, value:8},
        {title:9, value:9},
        {title:10, value:10},
        {title:15, value:15},
        {title:20, value:20},
        {title:25, value:25},
        {title:30, value:30}
    ];

    const remoteLoginRetryOption = [
        {title:1, value:1},
        {title:2, value:2},
        {title:3, value:3},
        {title:4, value:4},
        {title:5, value:5},
        {title:6, value:6}
    ];

    const [pollingInterval, setPollingInterval] = useState({
        interval : editMode ? content.cifsRefreshInterval : 1,
        disableInterval: editMode ? content.canRefresh === "N" : false
    });
    const [remoteLoginRetry, setRemoteLoginRetry] = useState({
        retryCount :  editMode ? content.loginRetryMaxCount : 1,
        disableRetry: editMode ? content.canLoginRetry === "N" : false
    });

    const [cifsContent, setCifsContent] = useState({
        userId: localStorage.getItem('user').id,
        cifsContentName: editMode ? content.cifsContentName : '',
        cifsIP:editMode ? content.cifsIP : '',
        cifsLoginId:editMode ? content.cifsLoginId : '',
        cifsPassword:editMode ? content.cifsPassword : '',
        cifsDirectory:editMode ? content.cifsDirectory : '',
        cifsRefreshInterval:editMode ? content.cifsRefreshInterval : 1,
        canRefresh: editMode ? content.canRefresh : 'Y',
        loginRetryMaxCount:editMode ? content.loginRetryMaxCount : 1,
        canLoginRetry: editMode ? content.canLoginRetry : 'Y',
    });

    const {interval, disableInterval} = pollingInterval;
    const {retryCount, disableRetry} = remoteLoginRetry;
    const {cifsContentName, cifsIP, cifsLoginId, cifsPassword, cifsRefreshInterval, cifsDirectory, loginRetryMaxCount} = cifsContent;

    const pollingEnable = useRef();
    const limitAttempts = useRef();

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const togglePollingInterval = () => {
        if(pollingEnable.current.checked){
            limitAttempts.current.checked = true;
            setPollingInterval({...pollingInterval, disableInterval: false});
            setRemoteLoginRetry({...remoteLoginRetry, disableRetry: false});
            setCifsContent({...cifsContent, canRefresh: 'Y', canLoginRetry: 'Y'});
        }else{
            setPollingInterval({...pollingInterval, disableInterval: true});
            setRemoteLoginRetry({...remoteLoginRetry, disableRetry: true});
            limitAttempts.current.checked = false;
            setCifsContent({...cifsContent, canRefresh: 'N', canLoginRetry: 'N'});
        }
    };

    const onChangePollingInterval =(value) => {
        setPollingInterval({...pollingInterval, interval: value});
        setCifsContent({...cifsContent, cifsRefreshInterval: value});
    };

    const toggleLimitAttempts = () => {
        if(limitAttempts.current.checked){
            setRemoteLoginRetry({...remoteLoginRetry, disableRetry: false});
            setCifsContent({...cifsContent, canLoginRetry: 'Y'});
        }else {
            setRemoteLoginRetry({...remoteLoginRetry, disableRetry: true});
            setCifsContent({...cifsContent, canLoginRetry: 'N'});
        }
    };

    const onChangeLimitAttempts =(value) => {
        setRemoteLoginRetry({...remoteLoginRetry, retryCount:value})
        setCifsContent({...cifsContent, loginRetryMaxCount: value});
    };

    const saveCifsContent = () => {
        if(cifsContentName.trim() === '' || cifsIP.trim() === '' || cifsLoginId.trim() === '' || cifsPassword.trim() === '' || cifsDirectory.trim() === ''){
            toastr.error(t('MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P'));
        }else if(!validateSpecialChar(cifsContentName)) {
            toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
        }else{
            let promise;

            if(editMode){
                const param = {
                    ...cifsContent,
                    groupId : content.groupId
                };
                promise = contentService.editCifsSetting(content.contentId, param);
            }else {
                const param = {
                    ...cifsContent,
                    groupId : myContentGroupId === "" ? 0 : myContentGroupId
                };
                promise = contentService.createCifsContent(param);
            }

            if(promise){
                promise.then(res => {
                    if(res.status === 'Success'){
                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                        dispatch(contentAction.reloadContent(true));
                        closePopup(id);
                    }
                }).catch(error => {
                    let errorMessage = error.errorMessage;
                    switch(error.errorCode){
                        case '400010':
                            errorMessage = t('MIS_SID_NO_CHANGES_HAVE_BEEN_MADE');
                            break;
                        case '403001':
                            errorMessage = t('ERROR_NO_ROLE');
                            break;
                        case '400308':
                            errorMessage = t('MIS_TEXT_CIFS_SERVER_ACCESS_FAILURE_P');
                            break;
                        case '400309':
                            errorMessage = t('MIS_TEXT_FTP_INVALID_FILES_P');
                            break;
                        case '400310':
                            errorMessage = t('MIS_TEXT_FTP_PARAM_INVALID_P');
                            break;
                        case '400311':
                            errorMessage = t('MIS_TEXT_CIFS_SERVER_LOGIN_FAILURE_P');
                            break;
                        case '400312':
                            errorMessage = t('MIS_TEXT_FTP_SAME_CONTENT_P');
                            break;
                        case '400313':
                            errorMessage = t('MIS_TEXT_FTP_INVALID_PATH_P');
                            break;
                        case '400314':
                            errorMessage = t('MESSAGE_SCHEDULE_ACCESS_DENIED_P');
                            break;
                    }
                    toastr.error(errorMessage);
                    closePopup(id);
                });
            }
        }
    };

    useEffect(() => {
        if(editMode){
            pollingEnable.current.checked = (content.canRefresh === 'Y');
            limitAttempts.current.checked = (content.canLoginRetry === 'Y');
        }else {
            pollingEnable.current.checked = true;
            limitAttempts.current.checked = true;
        }
    }, []);

    return (
        <div className='upload_cifs_content_popup_wrap'>
            <MISDialog dialog={{
                title: editMode ? t('MIS_TEXT_CIFS_EDIT_CIFS_P_KR_EDIT') : t('MIS_TEXT_CIFS_ADD_CIFS_P'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 525,
                height: 344,
                position: {x: -150, y: -200},
                onClose: () => closePopup(),
            }}
                       buttons={{
                           rightButtons: [
                               {
                                   id: 'SAVE',
                                   title: t('COM_BUTTON_SAVE'),
                                   onClick: () => saveCifsContent(),
                               },
                               {
                                   id: 'CANCEL',
                                   title: t('BUTTON_CANCEL_P'),
                                   onClick: () => closePopup(),
                               }
                           ],
                       }}>
                <div className='upload_cifs_content_popup'>
                    <table>
                        <colgroup>
                            <col width="139px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t('TEXT_CONTENT_NAME_P')}</th>
                            <td>
                                <TextInput width={243} maxLength={100} placeholder={t('COM_IDS_ERR_CONTENTS_NAME')} value={cifsContentName}
                                           onChange={(e)=> setCifsContent({...cifsContent, cifsContentName:e.target.value})}/>
                            </td>
                        </tr>
                        <tr className="va_t">
                            <th>{t('MIS_TEXT_CIFS_IP_ADDRESS_P')}</th>
                            <td>
                                <div>
                                    <TextInput width={243} placeholder={t('MIS_SID_ENTER_AN_IP_ADDRESS')} value={cifsIP}
                                               onChange={(e)=> setCifsContent({...cifsContent, cifsIP:e.target.value})}/>
                                </div>
                            </td>
                        </tr>
                        <tr></tr>
                        <tr>
                            <th className='pt17'>{t('COM_TEXT_LOGIN_ID_P')}</th>
                            <td className='pt17'>
                                <TextInput width={243} placeholder={t('MESSAGE_USER_ENTER_USER_ID_P')} value={cifsLoginId}
                                           onChange={(e)=> setCifsContent({...cifsContent, cifsLoginId:e.target.value})}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_PASSWORD_P')}</th>
                            <td>
                                <TextInput width={243} type={"password"} placeholder={t('COM_MESSAGE_USER_ENTER_PASS_P')} value={cifsPassword}
                                           onChange={(e)=> setCifsContent({...cifsContent, cifsPassword:e.target.value})}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('MIS_TEXT_FTP_REMOTE_DIR_P')}</th>
                            <td>
                                <TextInput width={243} placeholder={t('MIS_SID_20_EXAMPLE_SHARED_FOLDER_NAME_OR')} value={cifsDirectory}
                                           onChange={(e)=> setCifsContent({...cifsContent, cifsDirectory:e.target.value})}/>
                                <br/>
                                <span>{t('MIS_SID_20_EXAMPLE_SHARED_FOLDER_NAME_OR')}</span>
                            </td>
                        </tr>
                        <tr>
                            <th className='pt25'>{t('MIS_TEXT_FTP_POLLING_INTERVAL_P')}</th>
                            <td className='pt25'>
                                <div className="select_box float_l mr8" id="remotePollingBox">
                                    <Select selects={pollingIntervalOption} width={100} value={interval}
                                            onChange={(e,value) => onChangePollingInterval(value)} disabled={disableInterval}/>
                                </div>
                                <span className="space float_l mr8">{t('COM_SID_MIN')}</span>
                                <span className="space">
                                    <Checkbox id={"canRefresh"} name={t('TEXT_ENABLE2_P')} ref={pollingEnable} onChange={togglePollingInterval}/>
                                </span>

                            </td>
                        </tr>
                        <tr>
                            <th className='pt6'>{t('MIS_SID_RETRY_LOGIN')}</th>
                            <td className='pt6'>
                                <div className="select_box float_l mr8" id="remoteLoginRetryBox">
                                    <Select selects={remoteLoginRetryOption} width={100} value={retryCount}
                                            onChange={(e,value) => onChangeLimitAttempts(value)} disabled={disableRetry}/>
                                </div>
                                <span className="space float_l mr8">{t('MIS_SID_TIMS_S')}</span>
                                <span className="space">
                                    <Checkbox id={"canLoginRetry"} name={t('MIS_SID_LIMIT_ATTEMPTS')} ref={limitAttempts} onChange={toggleLimitAttempts}/>
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <div className='warring_text'>
                        {getMixString(['MIS_SID_MIX_CAFEB_IF_SPCIAL_CHAR_EXCLUD_DOWN', '\'<\', \'>\', \'&\''])}
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};

export default CreateCIFS;