import React from 'react';
import {useDispatch} from "react-redux";
import {menuAction, playlistAction, popupAction} from "../../actions";
import {commonConstants} from "../../constants";
import {systemService} from "../../services/system.service";
import {useTranslation} from "react-i18next";
import ClassNames from 'classnames';


const Tab = ({index, close, id, active, title, width}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const removeTab = e => {
        e.stopPropagation();

        switch(id) {
            case 'NEW_RULESET':
            case 'EDIT_RULESET':
                confirmTabClose(t('COM_BUTTON_CONFIRM'), t('MESSAGE_UPLOADER_REALLY_CANCEL_P'));
                break;
            case 'EDIT_PLAYLIST':
            case 'NEW_PLAYLIST':
                confirmTabClose(t('COM_BUTTON_CONFIRM'), t('MESSAGE_UPLOADER_REALLY_CANCEL_P'));
                break;
            case 'NEW_SCHEDULE':
            case 'EDIT_SCHEDULE':
            case 'NEW_MESSAGE':
            case 'EDIT_MESSAGE':
            case 'NEW_EVENT':
            case 'EDIT_EVENT':
                confirmTabClose(t('COM_BUTTON_CONFIRM'), t('MESSAGE_UPLOADER_REALLY_CANCEL_P'));
                break;
            default:
                dispatch(menuAction.removeTab(id));
                break;
        }
    }

    const confirmTabClose = (title, message) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: title,
            message: message,
            onClickYes: () => {
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)); dispatch(menuAction.removeTab(id));
                if (id === 'NEW_PLAYLIST' || id === 'EDIT_PLAYLIST') {
                    if (id === 'NEW_PLAYLIST'){
                        dispatch(playlistAction.destroyPlaylist('NEW'))
                    } else {
                        dispatch(playlistAction.destroyPlaylist('EDIT'))
                    }
                }
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    const activeTab = id => {
        dispatch(menuAction.activeTab(id))
    }

    return (
        <li className={ClassNames({'on': active}, id)} onClick={()=>activeTab(id)} style={{width: width}}>
            { index > 0 &&
            <div className="divider" id="divider"></div>
            }
            <div className="tabName" style={{width: width-40, display: width < 21 ? 'none':''}}>{title}</div>
            {
                close ?
                    <a href="#" id="contentTab_close" onClick={(e)=>removeTab(e)}><span id=""></span></a> :
                    ''
            }

        </li>
    )
}
export default Tab;