import React from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {toInteger} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from "../../../../actions";
import {commonConstants} from "../../../../constants";
import NoDataRow from './NoDataRow';
import {deviceService} from '../../../../services';

const convertTags = tagInfos => {
    if(!tagInfos) {
        return tagInfos;
    }

    return tagInfos.map(tag => ({
        ...tag, 
        tagId: toInteger(tag.tagId),
        tagConditionId: toInteger(tag.tagConditionId),
        tagType: toInteger(tag.tagType),
        tagCondition: tag.tagConditionName,
        tagDesc: tag.tagDescription,
    }));
};

const TagDetail = ({ device, tags, onApply }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    
    const {mediaTags = [], variableTags = []} = tags || {};
    const filteredMediaTags = mediaTags.filter(tag => !!tag.tagId);
    const filteredVariableTags = variableTags.filter(tag => !!tag.tagId);

    const getTagConditionList = (selectedTagList) => {
        const tagConditionIdList = [];

        selectedTagList.map(tag => {
            if (tag.contentTagConditions) {
                tag.contentTagConditions.map(condition => {
                    if (condition.checkCondition) {
                        tagConditionIdList.push(condition.tagConditionId);
                    }
                });
            }
        });

        return tagConditionIdList;
    };

    const onClickMediaTagSetting = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.TAG_SETTING,
            type: commonConstants.TAG_SETTING,
            tagListType: 'media',
            showTagValue: false,
            showDevice: false,
            selectedTags: filteredMediaTags,
            onApply: selectedTags => {
                deviceService.updateDeviceTags({
                    deviceIds: [device.deviceId],
                    tagIds: selectedTags.map(tag => tag.tagId),
                    tagConditionIds: getTagConditionList(selectedTags),
                    tagType: 'MEDIA'
                }).then(res => {
                    toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                    onApply('MEDIA', convertTags(res.items.successList));
                    dispatch(popupAction.closePopup(commonConstants.TAG_SETTING));
                }).catch(error => console.log(error));
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.TAG_SETTING))
        }));
    };

    const onClickVariableTagSetting = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.TAG_SETTING,
            type: commonConstants.TAG_SETTING,
            tagListType: 'variable',
            showDevice: false,
            showTagValue: true,
            selectedTags: filteredVariableTags,
            onApply: selectedTags => {
                if(selectedTags && selectedTags.length > 0 && getTagConditionList(selectedTags).length === 0) {
                    toastr.error(t('MESSAGE_COMMON_INPUT_CONTENT_P'));
                } else {
                    deviceService.updateDeviceTags({
                        deviceIds: [device.deviceId],
                        tagIds: selectedTags.map(tag => tag.tagId),
                        tagConditionIds: getTagConditionList(selectedTags),
                        tagType: 'VARIABLE'
                    }).then(res => {
                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                        onApply('VARIABLE', convertTags(res.items.successList));
                        dispatch(popupAction.closePopup(commonConstants.TAG_SETTING));
                    }).catch(error => console.log(error));
                }
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.TAG_SETTING))
        }));
    };

    return (
        <div className="detail_view mini_7 mt15">
            <span className="detail_table_title mt10">{t('MIS_SID_MEDIA_TAG')}</span>
            <button className="base float_r" onClick={onClickMediaTagSetting}>{t('MIS_SID_TAG_SETTING')}</button>
            <div className="detail_table pop_list mt9" style={{borderTopWidth:0}}>
                <table style={{width:"99%"}}>
                    <colgroup>
                        <col width="40px;"/>
                        <col width="60%"/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('MIS_TEXT_TAG_NAME_P_KR_MIS20')}</th>
                            <th>{t('TEXT_DESCRIPTION_P')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <NoDataRow show={!filteredMediaTags || filteredMediaTags.length === 0} colSpan={2} />
                        {
                            !!filteredMediaTags && filteredMediaTags.length > 0 &&
                            filteredMediaTags.map((tag, index) => {
                                return (
                                    <tr key={`${tag.tagId}_${index}`}>
                                        <td className="full_text">{tag.tagName}</td>
                                        <td className="full_text">{tag.tagDesc}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div style={{height:20}}></div>
            <span className="detail_table_title mt10">{t('MIS_SID_VARIABLE_TAG')}</span>
            <button className="base float_r" onClick={onClickVariableTagSetting}>{t('MIS_SID_TAG_SETTING')}</button>
            <div className="detail_table pop_list mt9" style={{borderTopWidth:0,overflowX:'inherit',overflowY:'inherit'}}>
                <table style={{width:"99%"}}>
                    <colgroup>
                        <col width="40px;"/>
                        <col width="60%"/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{t('MIS_TEXT_TAG_NAME_P_KR_MIS20')}</th>
                            <th>{t('MIS_TEXT_TAG_VALUE_P')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <NoDataRow show={!filteredVariableTags || filteredVariableTags.length === 0} colSpan={2} />
                        {
                            !!filteredVariableTags && filteredVariableTags.length > 0 && 
                            filteredVariableTags.map((tag, index) => {
                                return (
                                    <tr key={`${tag.tagId}_${index}`}>
                                        <td className="full_text">{tag.tagName}</td>
                                        <td className="full_text">
                                            {tag.tagCondition || tag.tagConditionName}
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

TagDetail.defaultProps = {
    tags: {
        mediaTags: [],
        variableTags: [],
    }
};

export default TagDetail;