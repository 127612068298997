import {isArray, isNil, isObject,transform, isEqual} from 'lodash';
import React from "react";
import i18n from '../language/i18n';

export function isAllNil(...args) {
    return !args.some(arg => !isNil(arg));
}

export function dateToString(date) {
    if(!date) {
        return;
    }
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function toFahrenheit(celsius) {
    return celsius * 1.8 + 32;
}

export function findWithDefault(arr, callback, def = {}) {
    return arr ? (arr.find(callback) || def) : def;
}

export function messageLine (str) {
    if( typeof str !== 'string' ) return "";
    const s = str
        .replace(/<br>/gi,'\n')
        .split('\n')
        .map( (item,i) =>{ return  <p style={{margin:0}} key={i}>{item}</p>; });
    return s;
}

export function getTabWidth (tabs, oriWidth, submenuWidth) {
    let exceptArea = submenuWidth || 254;
    if (tabs !== undefined && tabs.length > 1) {
        const maxWidth = window.innerWidth - exceptArea - 400;
        const tabNumber =  tabs.length;
        let resizeWidth, width = (oriWidth + 35);
        if ((tabNumber * width) > maxWidth) {
            resizeWidth = Math.ceil(((tabNumber * width) - maxWidth) / tabNumber);
            width = ((width - resizeWidth) - 40);
        } else {
            resizeWidth = Math.ceil((maxWidth - (tabNumber * width)) / tabNumber);
            width = width + resizeWidth - 40;
        }

        if (width > 169) {
            width = 169;
        } else if (width < 20) {
            width = 20;
        }
        return width;
    }
    return 169;

}

const ENGLISH = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_";
export function stringIsEnglish(str) {
    for (let index = str.length - 1; index >= 0; --index) {
        if (ENGLISH.indexOf(str.substring(index, index + 1)) < 0) {
            return false;
        }
    }
    return true;
}

const ipFilter = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
// const ipFilter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;

export function isRightIpv4Andv6(address) {
	return ipFilter.test(address);
}

export function checkPhoneNum(number){
    const filter=/^(\+[0-9]|[0-9]){1}([0-9])*((-[0-9]+)*)$/i;
    return filter.test(number);
}

export function checkemail(email){
    ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const filter=/^[\w-']+(\.[\w-']+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
    //var filter=/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    return filter.test(email);
}

export function convertJsonToObjectForTemplateFrame (json) {
    const frameList = [];
    if (json !== undefined && json.length > 0) {
        for (const frameFromJson of json) {
            const frames = {};
            frames.frames = [];
            const resolution = frameFromJson.resolution.width +'*' + frameFromJson.resolution.height;
            if (frameFromJson.frameInfos !== undefined) {
                for (const frameInfo of frameFromJson.frameInfos) {
                    const frame = {};
                    frame.id = frameInfo.index;
                    frame.frameName = frameInfo.frameName;
                    //frame.templateType = frameInfo.frameType.toLowerCase();
                    frame.frameType = frameInfo.frameType;
                    frame.x = frameInfo.x;
                    frame.y = frameInfo.y;
                    frame.width = frameInfo.width;
                    frame.height = frameInfo.height;
                    frame.resolution = resolution;

                    frame.templateId = frameFromJson.templateId !== undefined ? frameFromJson.templateId : undefined;

                    frames.frames.push(frame);
                }
                frameList.push(frames);
            }
        }
    }
    return frameList;
}


export function getNextYearDate() {
    const nextYear = new Date();
    const current = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);
    return nextYear;
}

export function cloneWithoutNil(obj) {
    let res = {...obj};
    Object.keys(res).forEach(key => isNil(res[key]) && delete res[key]);
    return res;
};

export const checkNumberValidation = (value, min, max) =>{
    let filter = /[^0-9]/gi;

    if (filter.test(value) == true) {
        value = min;
    }

    if(value != null && value != ''){
        value = parseInt(value);

        if(min !=null && value < min){
            value = min;
        }
        if(max != null && value > max){
            value = max;
        }
    }

    return value;
}


export const time24hhTo12hh = ( hh24, mm ) => {
    let HH = Number(hh24);
    const MM = Number(mm);
    let ampm = 1 ; // 1:am 0:pm
    if(HH < 1){
        ampm = 1;
        HH = 12;
    } else if ( HH < 12 ) {
        ampm = 1;
    } else if ( HH < 13 ) {
        ampm = 0;
        HH = 12 ;
    } else if ( HH < 24 ) {
        ampm = 0;
        HH = HH - 12;
    }
    return {hh: HH, mm: MM, ampm: ampm}
}

export const time12hhTo24hh = ( hh12, mm, ampm ) =>{
    let HH = Number(hh12);
    const MM = Number(mm);
    if ( ampm === 1 || ampm === '1'){
        if(HH === 12 ){
            HH = 0;
        }
    } else {
        if( HH === 12){
            HH = 12
        } else {
            HH = HH + 12;
        }
    }
    return {hh:HH, mm:MM}
}

export function toMomentDateTimeFormat(dateFormat, timeFormat) {
    return `${dateFormat.toUpperCase()} ${timeFormat === 'ampm' ? 'hh:mm A' : 'HH:mm'}`;
}

export function pickByName(source, name) {
    let result = isArray(source) ? [] : {};
    for(const prop in source) {
        if(prop === name) {
            result[prop] = source[prop];
        } else if(isObject(source[prop])) {
            result[prop] = pickByName(source[prop], name);
        }
    }
    return result;
}

export function getLocale() {
    const localeFromLocalStorage = localStorage.getItem('MagicInfoPremiumLanguage');
    return localeFromLocalStorage || 'en';
}

export const setLocale = (lang) => {
    localStorage.setItem('MagicInfoPremiumLanguage', (lang||"en"));
    i18n.changeLanguage(lang, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
    })
}

export function differenceObject(object, base) {
    function changes(object, base) {
        return transform(object, function(result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}