import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isNil, set} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../../MISDialog';
import Checkbox from '../../../checkbox/Checkbox';
import NumberInput from '../../../input/NumberInput';
import TextInput from '../../../input/TextInput';
import {deviceValidator} from '../../../../helper';
import './EditConnectionPopup.css';

const EditConnectionPopup = ({device, onScan, onClose}) => {
    const {t} = useTranslation();

    const initialScanInfo = {
        childNumber: 0,
        cabinetIPAddress : (device.ipAddress && device.ipAddress.substr(0, device.ipAddress.lastIndexOf('.')) + '.0') || '0.0.0.0',
        autoSetId : true
    };

    const cabinetGroupSetting = (device.cabinetGroupSetting && new Array(4).fill().map((e, i) => {
        const res = device.cabinetGroupSetting.filter(t=>(t.index == i+1));
        return res.length >= 1 ? {...initialScanInfo, ...res[0]} : {index:i+1, ...initialScanInfo} 
    })) || new Array(4).fill().map((e, i) => ({index:i+1, ...initialScanInfo}));

    const [scanInfos, setScanInfos] = useState(cabinetGroupSetting);
    const [errors, setErrors] = useState(new Array(4).fill().map(e => ({})));

    const onClickScan = () => {
        if(device.supportUhd) {
            if(scanInfos.some(info => isNil(info.childNumber))) {
                toastr.error(t('COM_SID_SIGNAGE_ENTER_NUMBER_OF_CABINETS'));
                return;
            }
            onScan(scanInfos.map(info => ({
                ...info,
                autoSetId: info.autoSetId ? 1 : 0,
                deviceType: device.deviceType
            })));
        } else {
            const info = scanInfos[0];
            if(isNil(info.childNumber)) {
                toastr.error(t('COM_SID_SIGNAGE_ENTER_NUMBER_OF_CABINETS'));
                return;
            }
            onScan([{
                ...info,
                autoSetId: info.autoSetId ? 1 : 0,
                deviceType: device.deviceType
            }]);
        }
    };

    const onChange = (e, value) => {
        const dataName = e.target.getAttribute('data-name');
        const newScanInfos = [...scanInfos];

        if(e.target.type !== 'checkbox') {
            const dataValue = isNil(value) ? e.target.value : value;
            const newErrors = [...errors];
            const namePath = dataName.split('.');
            const result = deviceValidator(namePath[namePath.length - 1], dataValue);

            set(newScanInfos, dataName, dataValue);
            if(result) {
                if(result.isValid) {
                    set(newErrors, dataName, undefined);
                } else {
                    set(newErrors, dataName, t(result.messageId));
                }
                setErrors(newErrors);
            }
        } else {
            set(newScanInfos, dataName, e.target.checked);
        }
        
        setScanInfos(newScanInfos);
    };

    return (
        <MISDialog 
            dialog={{
                title: t('COM_LFD_EDIT_CONNECTION'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 485,
                height: device.supportUhd ? 580 : 230,
                onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_TV_SID_SCAN',
                        title: t('COM_TV_SID_SCAN'),
                        onClick: onClickScan,
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose
                    }
                ]
            }}>
            <div className="edit_connection_wrap detail_view mb15">
                <span style={{display: 'block', lineHeight: '18px'}}>
                    {t('MIS_SID_EDIT_THE_CABINET_CONNECTION_SETTINGS')}
                </span>
                <br/>
                <table>
                    <colgroup>
                        <col width="140px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            device.supportCabinetSetting &&
                            <>
                                <tr>
                                    <th style={{fontWeight:'bold'}}>{t('MIS_SID_S_BOX_INFORMATION')}</th>
                                </tr>
                                <tr>
                                    <th>{t('COM_SID_IP_ADDRESS')}</th>
                                    <td>{device.ipAddress}</td>
                                </tr>
                            </>
                        }
                        {
                            device.supportUhd &&
                            <>
                                <tr><th colSpan="2" style={{height:10}}></th></tr>
                                <tr>
                                    <th style={{fontWeight:'bold'}}>{t('COM_SID_SIGNAGE_MIX_CABINET_GROUP').replace("<<A>>", "1")}</th>
                                    <td className="background_bar"></td>
                                </tr>
                                <tr>
                                    <th>{t('COM_MIS_SID_SERVER_NUMBER_OF_CABINETS')}</th>
                                    <td>
                                        <NumberInput width={100} propertyName='[0].childNumber' value={scanInfos[0].childNumber} min={0} max={255} onChange={onChange} forceInt />
                                        {/* <input id="slaveNumber1" type="text" class="" style="width:50px" value="0"><button class="number_up" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber1\').val(),10)+1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber1\').val(newVal>255?255:newVal); checkScanBtnStatus();}"></button><button class="number_down" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber1\').val(),10)-1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber1\').val(newVal<0?0:newVal); checkScanBtnStatus();}"></button> */}
                                        {
                                            device.supportCabinetSetting &&
                                            <Checkbox id='autoSetID1' name={t('MIS_SID_ID_AUTO_SET')} propertyName='[0].autoSetId' checked={scanInfos[0].autoSetId} onChange={onChange} />
                                        }
                                    </td>
                                </tr>
                                {
                                    device.supportCabinetSetting &&
                                    // <input type="checkbox" id="autoSetID1" checked ><label for="autoSetID1">' + $.i18n.prop('MIS_SID_ID_AUTO_SET') + '</label></td></tr>
                                    <tr>
                                        <th>{t('COM_SID_IP_ADDRESS')}</th>
                                        <td>
                                            <TextInput width='40%' propertyName='[0].cabinetIPAddress' value={scanInfos[0].cabinetIPAddress} onChange={onChange} error={errors[0].cabinetIPAddress} />
                                            {/* <input id="cabinetIP1" type="text" style="width:40%" value="'+ selDeivceIPAddrSubnet + '" onchange="checkUserInputValidation(\'cabinetIP1\', \'ipaddress\');"></input> */}
                                            {/* <div id="cabinetIP1ErrorDiv" style="display:none;">	<span class="error_txt2" id="cabinetIP1ErrorMsg"></span></div> */}
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <th style={{fontWeight:'bold'}}>{t('COM_SID_SIGNAGE_MIX_CABINET_GROUP').replace("<<A>>", "2")}</th>
                                    <td className="background_bar"></td>
                                </tr>
                                <tr>
                                    <th>{t('COM_MIS_SID_SERVER_NUMBER_OF_CABINETS')}</th>
                                    <td>
                                        <NumberInput width={100} propertyName='[1].childNumber' value={scanInfos[1].childNumber} min={0} max={255} onChange={onChange} forceInt />
                                        {/* <div class="input_number float_l mr8"><input id="slaveNumber2" type="text" class="" style="width:50px" value="0"><button class="number_up" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber2\').val(),10)+1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber2\').val(newVal>255?255:newVal); checkScanBtnStatus();}"></button><button class="number_down" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber2\').val(),10)-1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber2\').val(newVal<0?0:newVal); checkScanBtnStatus();}"></button></div> */}
                                        {
                                            device.supportCabinetSetting &&
                                            <Checkbox id='autoSetID2' name={t('MIS_SID_ID_AUTO_SET')} propertyName='[1].autoSetId' checked={scanInfos[1].autoSetId} onChange={onChange} />
                                            // <input type="checkbox" id="autoSetID2" checked><label for="autoSetID2">' + $.i18n.prop('MIS_SID_ID_AUTO_SET') + '</label>
                                        }
                                    </td>
                                </tr>
                                {
                                    device.supportCabinetSetting &&
                                    <tr>
                                        <th>{t('COM_SID_IP_ADDRESS')}</th>
                                        <td>
                                            <TextInput width='40%' propertyName='[1].cabinetIPAddress' value={scanInfos[1].cabinetIPAddress} onChange={onChange} error={errors[1].cabinetIPAddress} />
                                            {/* <input id="cabinetIP2" type="text" style="width:40%" value="'+ selDeivceIPAddrSubnet + '" onchange="checkUserInputValidation(\'cabinetIP2\', \'ipaddress\');"></input> */}
                                            {/* <div id="cabinetIP2ErrorDiv" style="display:none;">	<span class="error_txt2" id="cabinetIP2ErrorMsg"></span></div> */}
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <th style={{fontWeight:'bold'}}>{t('COM_SID_SIGNAGE_MIX_CABINET_GROUP').replace("<<A>>", "3")}</th>
                                    <td className="background_bar"></td>
                                </tr>
                                <tr>
                                    <th>{t('COM_MIS_SID_SERVER_NUMBER_OF_CABINETS')}</th>
                                    <td>
                                        <NumberInput width={100} propertyName='[2].childNumber' value={scanInfos[2].childNumber} min={0} max={255} onChange={onChange} forceInt />
                                        {/* <div className="input_number float_l mr8"><input id="slaveNumber3" type="text" class="" style="width:50px" value="0"><button class="number_up" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber3\').val(),10)+1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber3\').val(newVal>255?255:newVal); checkScanBtnStatus();}"></button><button class="number_down" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber3\').val(),10)-1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber3\').val(newVal<0?0:newVal); checkScanBtnStatus();}"></button></div> */}
                                        {
                                            device.supportCabinetSetting &&
                                            <Checkbox id='autoSetID3' name={t('MIS_SID_ID_AUTO_SET')} propertyName='[2].autoSetId' checked={scanInfos[2].autoSetId} onChange={onChange} />
                                            // <input type="checkbox" id="autoSetID3" checked><label for="autoSetID3">' + $.i18n.prop('MIS_SID_ID_AUTO_SET') + '</label></td></tr>';
                                        }
                                    </td>
                                </tr>
                                    {
                                        device.supportCabinetSetting &&
                                        <tr>
                                            <th>{t('COM_SID_IP_ADDRESS')}</th>
                                            <td>
                                                <TextInput width='40%' propertyName='[2].cabinetIPAddress' value={scanInfos[2].cabinetIPAddress} onChange={onChange} error={errors[2].cabinetIPAddress} />
                                                {/* <input id="cabinetIP3" type="text" style="width:40%" value="'+ selDeivceIPAddrSubnet + '" onchange="checkUserInputValidation(\'cabinetIP3\', \'ipaddress\');">'; */}
                                                {/* <div id="cabinetIP3ErrorDiv" style="display:none;">	<span class="error_txt2" id="cabinetIP3ErrorMsg"></span></div> */}
                                            </td>
                                        </tr>
                                    }
                                <tr>
                                    <th style={{fontWeight:'bold'}}>{t('COM_SID_SIGNAGE_MIX_CABINET_GROUP').replace("<<A>>", "4")}</th>
                                    <td className="background_bar"></td>
                                </tr>
                                <tr>
                                    <th>{t('COM_MIS_SID_SERVER_NUMBER_OF_CABINETS')}</th>
                                    <td>
                                        <NumberInput width={100} propertyName='[3].childNumber' value={scanInfos[3].childNumber} min={0} max={255} onChange={onChange} forceInt />
                                        {/* <div class="input_number float_l mr8"><input id="slaveNumber4" type="text" class="" style="width:50px" value="0"><button class="number_up" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber4\').val(),10)+1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber4\').val(newVal>255?255:newVal); checkScanBtnStatus();}"></button><button class="number_down" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber4\').val(),10)-1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber4\').val(newVal<0?0:newVal); checkScanBtnStatus();}"></button></div>'; */}
                                        {
                                            device.supportCabinetSetting &&
                                            <Checkbox id='autoSetID4' name={t('MIS_SID_ID_AUTO_SET')} propertyName='[3].autoSetId' checked={scanInfos[3].autoSetId} onChange={onChange} />
                                            // <input type="checkbox" id="autoSetID4" checked><label for="autoSetID4">' + $.i18n.prop('MIS_SID_ID_AUTO_SET') + '</label></td></tr>';
                                        }
                                    </td>
                                </tr>
                                {
                                    device.supportCabinetSetting &&
                                    <tr>
                                        <th>{t('COM_SID_IP_ADDRESS')}</th>
                                        <td>
                                            <TextInput width='40%' propertyName='[3].cabinetIPAddress' value={scanInfos[3].cabinetIPAddress} onChange={onChange} error={errors[3].cabinetIPAddress} />
                                            {/* <input id="cabinetIP4" type="text" style="width:40%" value="'+ selDeivceIPAddrSubnet + '" onchange="checkUserInputValidation(\'cabinetIP4\', \'ipaddress\');">';
                                            <div id="cabinetIP4ErrorDiv" style="display:none;">	<span class="error_txt2" id="cabinetIP4ErrorMsg"></span></div> */}
                                        </td>
                                    </tr>
                                }
                            </>
                        }
                        {
                            !device.supportUhd &&
                            <>
                                <tr>
                                    <th colspan="2" style={{height: 10}}></th>
                                </tr>
                                <tr>
                                    <th style={{fontWeight:'bold'}}>{t('COM_SID_SIGNAGE_MIX_CABINET_GROUP').replace("<<A>>", "1")}</th>
                                    <td className="background_bar"></td>
                                </tr>
                                <tr>
                                    <th>{t('COM_MIS_SID_SERVER_NUMBER_OF_CABINETS')}</th>
                                    <td>
                                        <NumberInput width={100} propertyName='[0].childNumber' value={scanInfos[0].childNumber} min={0} max={255} onChange={onChange} forceInt />
                                        {/* <div class="input_number float_l mr8"><input id="slaveNumber1" type="text" class="" style="width:50px" value="0"><button class="number_up" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber1\').val(),10)+1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber1\').val(newVal>255?255:newVal); checkScanBtnStatus();}"></button><button class="number_down" style="left:72px" onclick="{var newVal = parseInt($(\'#slaveNumber1\').val(),10)-1; if(isNaN(newVal)){newVal = 0;} $(\'#slaveNumber1\').val(newVal<0?0:newVal); checkScanBtnStatus();}"></button></div> */}
                                        {
                                            device.supportCabinetSetting &&
                                            <Checkbox id='autoSetID1' name={t('MIS_SID_ID_AUTO_SET')} propertyName='[0].autoSetId' checked={scanInfos[0].autoSetId} onChange={onChange} />
                                            // <input type="checkbox" id="autoSetID1" checked ><label for="autoSetID1">' + $.i18n.prop('MIS_SID_ID_AUTO_SET') + '</label>
                                        }
                                    </td>
                                </tr>
                                {
                                    device.supportCabinetSetting &&
                                    <tr>
                                        <th>{t('COM_SID_IP_ADDRESS')}</th>
                                        <td>
                                            <TextInput width='40%' propertyName='[0].cabinetIPAddress' value={scanInfos[0].cabinetIPAddress} onChange={onChange} error={errors[0].cabinetIPAddress} />
                                            {/* <input id="cabinetIP1" type="text" style="width:40%" value="'+ selDeivceIPAddrSubnet + '" onchange="checkUserInputValidation(\'cabinetIP1\', \'ipaddress\');">
                                            <div id="cabinetIP1ErrorDiv" style="display:none;">	<span class="error_txt2" id="cabinetIP1ErrorMsg"></span></div> */}
                                        </td>
                                    </tr>
                                }
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </MISDialog>
    );
};

export default EditConnectionPopup;