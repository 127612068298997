import {authHeader, createParams, getLocale, handleResponse, history, objToQueryString} from '../helper';
import fileDownload from "js-file-download";
import {fetchForDownload, fetchWithToken as fetch} from '../helper/fetchIntercept.js';
import {setExclusive} from "../helper/fetchUtils";

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const scheduleService = {
    createContentSchedule,
    updateContentSchedule,
    fetchContentScheduleFilter,
    fetchContentScheduleById,
    fetchContentScheduleGroups,
    fetchContentScheduleGroupById,
    deleteContentScheduleByIds,
    moveContentSchedule,
    updateDeviceGroups,
    createContentScheduleNewFrame,
    updateContentScheduleNewFrame,
    fetchContentScheduleProgressById,
    createContentScheduleGroup,
    deleteContentScheduleGroupById,
    updateContentScheduleGroup,
    moveContentScheduleGroup,
    fetchContentScheduleTemplateFrames,
    copyContentSchedule,
    exportContentSchedule,
    restoreContentSchedule,
    emptyContentSchedule,
    updateContentScheduleNameGroup,
    fetchContentScheduleDefaultGroup,
    updateEventScheduleNameGroup,
    fetchMessageScheduleFilter,
    fetchMessageScheduleById,
    fetchMessageScheduleGroups,
    fetchMessageScheduleGroupById,
    deleteTemplateFrameById,
    createMessageSchedule,
    updateMessageSchedule,
    deleteMessageByIds,
    moveMessageSchedule,
    moveMessageScheduleGroup,
    copyMessageSchedule,
    restoreMessageSchedule,
    createMessageScheduleGroup,
    emptyMessageScheduleRecycleBin,
    exportMessageSchedule,
    updateMessageScheduleNameGroup,
    fetchMessageScheduleDefaultGroup,
    rePublishMessageSchedule,
    deleteMessageScheduleGroupById,
    updateMessageScheduleGroup,
    checkDiskSizeByDeviceGroups,
    fetchEventScheduleFilter,
    fetchEventScheduleById,
    fetchEventScheduleGroups,
    fetchEventScheduleGroupById,
    deleteEventScheduleByIds,
    moveEventScheduleGroup,
    fetchEventConditionById,

    fetchEventScheduleManagerFilter,
    moveEventSchedule,

    createEventCondition,
    updateEventCondition,
    deleteEventCondition,
    deleteEventConditionByIds,
    updateEventScheduleGroup,

    createEventSchedule,
    updateEventSchedule,
    createEventScheduleGroup,
    exportEventSchedule,
    sendEventScheduleCondition,
    emptyEventSchedule,
    restoreEventSchedule,
    fetchEventScheduleDefaultGroup,
    deleteEventScheduleGroupById,
    fetchDeviceTagByDeviceIds,
    searchContentScheduleGroupByGroupName,
    searchMessageScheduleGroupByGroupName,
    searchEventScheduleGroupByGroupName

};

function fetchContentScheduleFilter(values) {
    const requestOptions = setExclusive({
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values),
        exclusive: true
    })
    return fetch(backendHost+'/restapi/v2.0/dms/content-schedules/filter', requestOptions).then(handleResponse);
}

function fetchContentScheduleById(programId) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/${programId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function deleteContentScheduleByIds(ids, goToRecycle = true) {
    const values = {
        deleteMethod: goToRecycle ? "GO_TO_RECYCLEBIN" : "DELETE_FORCE",
        ids: ids
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/deleted-schedules`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }).then(handleResponse);
}



function createContentSchedule(values) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost+'/restapi/v2.0/dms/content-schedules', requestOptions).then(handleResponse);
}

function updateContentSchedule(programId, values) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/${programId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(values),
    }).then(handleResponse);

}

function fetchContentScheduleGroups() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/dms/content-schedules/groups', requestOptions).then(handleResponse);
}

function fetchContentScheduleGroupById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/dms/content-schedules/groups/'+id+'/sub-groups', requestOptions).then(handleResponse);
}

function fetchMessageScheduleFilter(values) {
    const requestOptions = setExclusive({
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values),
        exclusive: true
    })
    return fetch(backendHost+'/restapi/v2.0/dms/message-schedules/filter', requestOptions).then(handleResponse);
}

function fetchMessageScheduleGroups() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/dms/message-schedules/groups', requestOptions).then(handleResponse);
}

function fetchMessageScheduleGroupById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/dms/message-schedules/groups/'+id+'/sub-groups', requestOptions).then(handleResponse);
}

function fetchMessageScheduleById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/dms/message-schedules/'+id, requestOptions).then(handleResponse);
}

function fetchEventScheduleFilter(values) {
    const requestOptions = setExclusive({
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    })
    return fetch(backendHost+'/restapi/v2.0/dms/event-schedules/filter', requestOptions).then(handleResponse);
}

function fetchEventScheduleById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/dms/event-schedules/'+id, requestOptions).then(handleResponse);
}

function fetchEventScheduleGroups() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/dms/event-schedules/groups', requestOptions).then(handleResponse);
}

function fetchEventScheduleGroupById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(backendHost+'/restapi/v2.0/dms/event-schedules/groups/'+id+'/sub-groups', requestOptions).then(handleResponse);
}

function fetchEventScheduleManagerFilter(values) {
    const requestOptions = setExclusive({
        method: 'GET',
        headers: authHeader()
    })
    let obj ={
        pageSize : values.pageSize,
        startIndex : values.pages,
    }

    let params = objToQueryString(obj);
    return fetch(backendHost+'/restapi/v2.0/dms/event-schedules/events'+params, requestOptions).then(handleResponse);
}

function createMessageSchedule(values) {
    const body = createParams(values);
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: body
    }

    return fetch(backendHost+'/restapi/v2.0/dms/message-schedules', requestOptions).then(handleResponse);
}

function updateMessageSchedule(values, messageId) {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/${messageId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: JSON.stringify(values),
    }).then(handleResponse);
}

function deleteMessageByIds(params) {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/deleted-schedules`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(params)
    }).then(handleResponse);
}

function moveMessageSchedule(messageId, groupId) {
    const body = {
        ids: messageId
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/groups/${groupId}/moved-schedules`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(body)
    }).then(handleResponse);
}

function copyMessageSchedule(messageId, messageInfo) {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/${messageId}/copy`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(messageInfo)
    }).then(handleResponse);
}

function restoreMessageSchedule(ids, groupId) {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/restored-schedules?groupId=${groupId}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams({ids: ids})
    }).then(handleResponse);
}

function createEventCondition(eventCondition) {
    const body = createParams(eventCondition);
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/events`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: body
    }).then(handleResponse);
}

function deleteEventCondition(eventId) {
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/events/${eventId}?permanently=true`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}


function deleteEventConditionByIds(eventIds) {
    const body = {
        ids: eventIds
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/events/delete-events`, {
        method: 'POST',
        headers: authHeader(),
        body: createParams(body)
    }).then(handleResponse);

}

function createEventSchedule(values) {
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: body
    }).then(handleResponse)
}

function updateEventSchedule(values, programId) {
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/${programId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    }).then(handleResponse)
}

function moveContentSchedule(programIds, groupId) {
    const body = {
        ids: programIds
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/groups/${groupId}/moved-schedules`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(body)
    }).then(handleResponse);
}
function updateDeviceGroups(programId, groupIds) {
    const body = {
        ids: groupIds
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/${programId}/re-publish`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(body)
    }).then(handleResponse);
}

function createContentScheduleNewFrame(frames) {
    const body = createParams(frames);
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/frame-templates`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: body
    }).then(handleResponse);
}

function fetchContentScheduleProgressById(id) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/${id}/publishing-progress`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function createContentScheduleGroup(values) {
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/groups`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: body
    }).then(handleResponse);
}

function deleteContentScheduleGroupById(id) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/groups/${id}`, {
        method: 'DELETE',
        headers: authHeader('DELETE')
    }).then(handleResponse);
}

function deleteMessageScheduleGroupById(id) {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/groups/${id}`, {
        method: 'DELETE',
        headers: authHeader('DELETE')
    }).then(handleResponse);
}

function deleteEventScheduleGroupById(id) {
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/groups/${id}`, {
        method: 'DELETE',
        headers: authHeader('DELETE')
    }).then(handleResponse);
}

function updateContentScheduleGroup(id, groupName) {
    const values = {
        "newGroupName": groupName,
    }
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/groups/${id}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    }).then(handleResponse);
}

function moveContentScheduleGroup(groupId, destinationGroupId){
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/groups/${groupId}/destination-groups/${destinationGroupId}`, {
        method: 'PUT',
        headers: authHeader('PUT')
    }).then(handleResponse);
}

function createMessageScheduleGroup(values) {
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/groups`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: body
    }).then(handleResponse);
}

function moveMessageScheduleGroup(groupId, destinationGroupId){
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/groups/${groupId}/destination-groups/${destinationGroupId}`, {
        method: 'PUT',
        headers: authHeader('PUT')
    }).then(handleResponse);
}

function createEventScheduleGroup(values) {
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/groups`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: body
    }).then(handleResponse);
}


function moveEventScheduleGroup(groupId, destinationGroupId){
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/groups/${groupId}/destination-groups/${destinationGroupId}`, {
        method: 'PUT',
        headers: authHeader('PUT')
    }).then(handleResponse);
}


function fetchContentScheduleTemplateFrames(width, height) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/frame-templates?resolutionWidth=${width}&resolutionHeight=${height}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function copyContentSchedule(programId, body) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/${programId}/copy`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(body)
    }).then(handleResponse);
}

function emptyMessageScheduleRecycleBin() {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/recycle-bin`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

function exportContentSchedule(body, exportType) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(body),
    };
    let fileName = (exportType === 'EXCEL' ? "ContentScheduleList.xls" : "ContentScheduleList.pdf");
    const locale = getLocale();
    return fetchForDownload(`${backendHost}/restapi/v2.0/dms/content-schedules/filter/export?exportType=${exportType}&locale=${locale}` , requestOptions)
        .then(res=> res.blob())
        .then(res => {
            fileDownload(res, fileName);
        }).catch(response => {
            return Promise.reject(response.message);
        });
}

function exportMessageSchedule(body, exportType) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(body),
    };
    let fileName = (exportType === 'EXCEL' ? "MessageScheduleList.xls" : "MessageScheduleList.pdf");
    const locale = getLocale();
    return fetchForDownload(`${backendHost}/restapi/v2.0/dms/message-schedules/filter/export?exportType=${exportType}&locale=${locale}`, requestOptions)
        .then(res => res.blob())
        .then(res => {
            fileDownload(res, fileName);
        }).catch(response => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    history.push('/login');
                }
                return Promise.reject(response.message);
            }
        });
}

function restoreContentSchedule(groupId, ids) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/restored-schedules?groupId=${groupId}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams({ids: ids})
    }).then(handleResponse);
}

function emptyContentSchedule() {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/recycle-bin`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function exportEventSchedule(body, exportType) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(body),
    };
    let fileName = (exportType === 'EXCEL' ? "EventScheduleList.xls" : "EventScheduleList.pdf");
    const locale = getLocale();
    return fetchForDownload(`${backendHost}/restapi/v2.0/dms/event-schedules/filter/export?exportType=${exportType}&locale=${locale}`, requestOptions)
        .then(res => res.blob())
        .then(res => {
            fileDownload(res, fileName);
        }).catch(response => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    history.push('/login');
                }
                return Promise.reject(response.message);
            }
        });
}

function sendEventScheduleCondition(eventId, scheduleIds, condition, isStopCondition) {
    const values = {
        eventConditionName: isStopCondition ? 'STOP' : condition,
        eventId: eventId,
        permission: true,
        scheduleIds: scheduleIds
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/event-send?action=${isStopCondition ? 'STOP' : 'SEND'}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }).then(handleResponse);
}

function deleteEventScheduleByIds(ids, goToRecycle) {
    const values = {
        deleteMethod: goToRecycle ? "GO_TO_RECYCLEBIN" : "DELETE_FORCE",
        ids: ids
    }
    const requestOptions = {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(values)
    }
    return fetch(backendHost+'/restapi/v2.0/dms/event-schedules/deleted-schedules', requestOptions).then(handleResponse);
}

function emptyEventSchedule() {
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/recycle-bin`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function restoreEventSchedule(ids, groupId) {
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/event-schedules/restored-schedules?groupId=${groupId}`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams({ids: ids})
    }).then(handleResponse);
}

function updateContentScheduleNameGroup(programId, body) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/${programId}/simple`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(body)
    }).then(handleResponse);
}


function updateMessageScheduleNameGroup(messageId, body) {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/${messageId}/simple`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(body)
    }).then(handleResponse);
}

function fetchContentScheduleDefaultGroup() {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/groups/default-group`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse)
}

function fetchMessageScheduleDefaultGroup() {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/groups/default-group`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse)
}

function fetchEventScheduleDefaultGroup() {
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/groups/default-group`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse)
}

function rePublishMessageSchedule(messageId, ids) {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/${messageId}/device-groups/re-publish`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams({ids: ids})
    }).then(handleResponse);
}

function updateEventCondition(eventCondition) {
    const body = createParams(eventCondition);
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/events/${eventCondition.eventId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    }).then(handleResponse);
}

function updateEventScheduleGroup(id, groupName) {
    const values = {
        "newGroupName": groupName,
    }
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/groups/${id}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    }).then(handleResponse);
}

function updateMessageScheduleGroup(id, groupName) {
    const values = {
        "newGroupName": groupName,
    }
    const body = createParams(values);
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/groups/${id}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    }).then(handleResponse);
}

function moveEventSchedule(eventId, groupId) {
    const body = {
        ids: eventId
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/groups/${groupId}/moved-schedules`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(body)
    }).then(handleResponse);
}

function fetchDeviceTagByDeviceIds(deviceGroups) {
    const body = {
        ids: deviceGroups
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/device-tags`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(body)
    }).then(handleResponse);
}

function fetchEventConditionById(eventId) {
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/events/${eventId}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function updateEventScheduleNameGroup(scheduleId, event) {
    const values = {
        eventScheduleGroupId: event.eventGroupId,
        eventScheduleName: event.eventScheduleName
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/${scheduleId}/simple`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(values)
    }).then(handleResponse);
}

function deleteTemplateFrameById(templateId) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/frame-templates/${templateId}`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function updateContentScheduleNewFrame(templateId, frames) {
    const body = createParams(frames);
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/frame-templates/${templateId}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: body
    }).then(handleResponse)
}

function checkDiskSizeByDeviceGroups(contentsFileSize, deviceGroups) {
    const body = {
        deviceGroupIds: deviceGroups,
        didDiskSpaceCheck: true,
        hasEnoughDiskSpace: true,
        scheduleFileSize: contentsFileSize
    }
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/disk-check`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(body)
    }).then(handleResponse);
}

function searchContentScheduleGroupByGroupName(searchText) {
    return fetch(`${backendHost}/restapi/v2.0/dms/content-schedules/groups/searched-groups?searchText=${searchText}`, {
        method: 'POST',
        headers: authHeader('POST')
    }).then(handleResponse);
}
function searchMessageScheduleGroupByGroupName(searchText) {
    return fetch(`${backendHost}/restapi/v2.0/dms/message-schedules/groups/searched-groups?searchText=${searchText}`, {
        method: 'POST',
        headers: authHeader('POST')
    }).then(handleResponse);
}
function searchEventScheduleGroupByGroupName(searchText) {
    return fetch(`${backendHost}/restapi/v2.0/dms/event-schedules/groups/searched-groups?searchText=${searchText}`, {
        method: 'POST',
        headers: authHeader('POST')
    }).then(handleResponse);
}