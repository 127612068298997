import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {toastr} from 'helper/toastrIntercept';
import {menuAction, popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import {userService} from "../../../services";
import ApproveUserDetailedInformation from './ApproveUserDetailedInformation';
import {checkPreValidation, handleChange, updateDetailed} from './common/userCommons';
import {commonConstants, userConstants} from "../../../constants";

const useUserInfo = (initialInfo, t) => {
    const [userInfo, setUserInfo] = useState(initialInfo);

    const onChangeUserInfo = (e, value) => {
        const newInfo = handleChange(e, value, userInfo, t);
        setUserInfo(newInfo);
    };

    const updateUserInfoPartially = (params) => {
        const newInfo = updateDetailed(userInfo, params);
        setUserInfo(newInfo);
    };

    return [userInfo, setUserInfo, updateUserInfoPartially, onChangeUserInfo];
};

const createUpdateInfo = ({dataField}, t) => {
    const updateInfo = {ids: []};
    for (let fieldName in dataField) {
        switch (fieldName) {
            case 'userId':
                updateInfo.ids.push(dataField[fieldName]);
                break;
            case 'groupName':
            case 'organizationName':
                updateInfo[fieldName] = dataField[fieldName] === t('TEXT_ROOT_GROUP_NAME_P') ? userConstants.ORGAN_NAME_ADMINISTRATORS : dataField[fieldName];
                break;
            default:
                updateInfo[fieldName] = dataField[fieldName];
        }
    }
    return updateInfo;
};

const ApproveUserPopup = ({onClose, onClickYes, addPopup, closePopup, userItem, organizationId, updateSubMenu}) => {
    const {t} = useTranslation();
    const timer = useRef(null);
    const [userInfo, setUserInfo, updateUserInfoPartially, onChangeUserInfo] = useUserInfo({
        dataField: {
            approvalStatus: 'APPROVED',
            userId: userItem.userInfo.userId === undefined ? '' : userItem.userInfo.userId,
            userName: userItem.userInfo.userName === undefined ? '': userItem.userInfo.userName,
            email: userItem.userInfo.email === undefined ? '' : userItem.userInfo.email,
            team: userItem.userInfo.team === undefined ? '' : userItem.userInfo.team,
            jobPosition: userItem.userInfo.jobPosition === undefined ? '' : userItem.userInfo.jobPosition,
            phoneNum: userItem.userInfo.phoneNum === undefined ? '' : userItem.userInfo.phoneNum,
            mobileNum: userItem.userInfo.mobileNum === undefined ? '' : userItem.userInfo.mobileNum,
            organizationId: organizationId,
            organizationName: userItem.organizationName,
            groupId: organizationId === userConstants.ORGAN_ID_ADMINISTRATOR ? 0 : null,
            groupName: organizationId === userConstants.ORGAN_ID_ADMINISTRATOR ? t('TEXT_ROOT_GROUP_NAME_P') : null,
            roleId: null,
            rejectReason: null
        },
        errors: {},
    }, t);

    useEffect(() => {
        return () => {
            if(timer.current) {
                closePopup('EDIT_DEVICE_LOADING');
                clearInterval(timer.current);
            }
        };
    }, []);

    const onClickSave = () => {
        if (checkPreValidation(userInfo, setUserInfo, t, true)) {
            return;
        }

        addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP});
        userService.updateUnapprovedUsers(createUpdateInfo(userInfo, t)).then(res => {
            toastr.success(t('COM_TEXT_AD_STATUS_APPROVED_P'));
            const newInfo = {...userInfo, errors: {}};
            setUserInfo(newInfo);
        }).catch(error => {
            toastr.error(error);
        }).finally(() => {
            closePopup(commonConstants.LOADING_POPUP);
            onClickYes();
            onClose();
            updateSubMenu();
        });
    };

    return (
        <MISDialog
            dialog={{
                title: t(`TEXT_TITLE_DETAIL_VIEW_P`),
                closeOnEscape: true,
                modal: true,
                width: 730,
                height: 530,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('COM_BUTTON_APPROVAL'),
                        onClick: onClickSave,
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <ApproveUserDetailedInformation userInfo={userInfo} updateUserInfoPartially={updateUserInfoPartially} onChange={onChangeUserInfo} />

        </MISDialog>
    );
};

ApproveUserPopup.defaultProps = {
    onSave: () => {},
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id)),
        updateSubMenu : () => dispatch(menuAction.updateSubMenuCounter('USER'))
    })
)(ApproveUserPopup);