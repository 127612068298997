import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DragSource} from "react-dnd";
import isEmpty from 'lodash/isEmpty';

import {ItemTypes} from "./ItemTypes";
import TimeSettingWrap from "./TimeSettingWrap";
import ContentThumbnail from "../../components/image/ContentThumbnail";

const TagContent = React.forwardRef(({
                                         tag, index, updateItem, itemClick, deleteButtonClick, tagCheckList,
                                         connectDragSource, connectDragPreview, settingButtonClick, deviceType, playlistType
                                     }, ref) => {
    const {t} = useTranslation();

    const elementRef = useRef(null);

    useImperativeHandle(ref, () => ({
        getNode: () => elementRef.current,
    }));

    const thumbId = !isEmpty(tag.contents) ? tag.contents[0].thumbFileId : 'NOIMAGE_THUMBNAIL';

    const [hOverStatus, setHOverStatus] = useState(false);
    const [checkStatus, setCheckStatus] = useState(false);
    const [tagCheckListState, setTagCheckList] = useState(tagCheckList);

    const onOffCheckStatus = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setCheckStatus(checkStatus ? false : true);
        itemClick(tag, index);
    };

    useEffect(()=> {
        tagCheckListState.every(({item}) => !(item.tagId == tag.tagId)) ? setCheckStatus(false) : setCheckStatus(true)
    }, [tag, index]);

    useEffect(() => {
        setTagCheckList(tagCheckList)
    }, [tagCheckList]);

    return connectDragSource(
        <li className={"addedContent tagContent"} index={index} ref={elementRef}>
            <div className={"addedContent_" + index}
                 style={{position: 'relative'}}
                 onMouseEnter={() => {
                     setHOverStatus(true);
                 }}
                 onMouseLeave={() => {
                     setHOverStatus(false);
                 }}
                 onClick={(e) => onOffCheckStatus(e)}>
                <button className={hOverStatus ? "delete" : "none"}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            deleteButtonClick()
                        }}
                />
                <button className={hOverStatus ? "playlist_settings" : "none"} onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    settingButtonClick(tag)
                }}/>
                <p className="name"><span className="name text-overflow" style={{width: 80}}>{tag.name}</span></p>
                <div className={checkStatus ? "check_on" : ""}></div>
                <div className="thum">
                    <div className="thumb_wrap float_l playlist_tag_thumbnail">
                        <span style={{margin: '0px auto'}}>
                        <ContentThumbnail style={{verticalAlign: 'middle', margin:'0px 6px 10px 0px'}} id={thumbId} width={68}
                                          height={40}/>
                        </span>
                    </div>
                </div>
            </div>
            <TimeSettingWrap content={tag} playlistType={playlistType} updateItem={updateItem} index={index}
                             deviceType={deviceType}/>
        </li>
    )
});

export default DragSource(
    ItemTypes.TagItem,
    {
        beginDrag: (props) => {
            return {dragItem: props.tag, index: props.index};
        },
        isDragging(props, monitor) {
            return monitor.getItem().id === props.id
        },
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }),
)(TagContent)
