import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import './EventConditionManager.css'
import EventCondition from "./EventCondition";
import {menuAction, popupAction, scheduleAction} from "../../../actions";
import {scheduleService} from "../../../services/schedule.service";
import {toastr} from 'helper/toastrIntercept';
import Select from "../../../components/selectbox/Select";
import TextInput from "../../../components/input/TextInput";
import {getErrorMessage} from "../../../helper/responseHandler";
import Checkbox from "../../../components/checkbox/Checkbox";
import {commonConstants, scheduleConstants} from "../../../constants";
import WhiteButton from "../../../components/button/WhiteButton";

const EventConditionManager = ({mode,currContent}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {eventCondition, currentContentId} = useSelector(state => ({
        eventCondition : state.schedules.events.condition[mode],
        currentContentId: state.menu.currentContentId
    }));

    const [useDataLink, setUseDataLink] = useState(eventCondition.isDatalinkEnabled);

    const updateEventConditionDes = e => {
        const value = e.target.value;
        const updateEventCondition = {...eventCondition, eventDescription: value};
        updateCondition(updateEventCondition);
    }

    const updateEventConditionName = e => {
        const value = e.target.value;
        const updateEventCondition = {...eventCondition, eventName: value};
        updateCondition(updateEventCondition);
    }

    const updateCondition = (updateEventCondition) => {
        dispatch(scheduleAction.updateEventCondition(mode, updateEventCondition));
    }

    const saveEventCondition = () => {
        const eventId = eventCondition.eventId;
        
        const doValid = () => {
            if (eventCondition.eventName === '') {
                toastr.error(t("MESSAGE_COMMON_ENTER_NAME_P"));
                return false;
            }
            if(eventCondition.eventName.trim() === ''){                                  
                toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P")); 
                return false;
            } 
            if (eventCondition.eventConditions.length < 1) {
                toastr.error(t("MIS_MESSAGE_EVENT_CHK_CONDITION_STRING_EMPTY_P"));
                return false;
            }
            if(eventCondition.isDatalinkEnabled && (eventCondition.datalinkInput === '' || eventCondition.datalinkServerAddress === '')) {
                toastr.error(t("MIS_MESSAGE_EVENT_CHK_CONDITION_STRING_EMPTY_P"));
                return false;
            }

            for (const condition of eventCondition.eventConditions) {

                if (eventCondition.eventType === "TEXT" || eventCondition.eventType === "NUMBER"){
                    if(condition.compString === ''){
                        toastr.error(t("MIS_MESSAGE_EVENT_CHK_CONDITION_STRING_EMPTY_P"));
                        return false;
                    }
                }

                if(!condition.content && !condition.channel && !condition.message){
                    toastr.error(t("MIS_MESSAGE_EVENT_CHK_CONDITION_STRING_EMPTY_P"));
                    return false;
                }

                if(condition.content && condition.contentId === ''){
                    toastr.error(t("MESSAGE_CONTENT_SELECT_A_CONTENT_P"));
                    return false;
                }

                if(condition.message && condition.messageText === ''){
                    toastr.error(t("MIS_MESSAGE_EVENT_CHK_CONDITION_STRING_EMPTY_P"));
                    return false;
                }

                if(condition.channel && condition.channelNo === -1){
                    toastr.error(t("MIS_MESSAGE_EVENT_CHK_CONDITION_STRING_EMPTY_P"));
                    return false;
                }

            }

            return true;
        }
        if (doValid()) {
            eventCondition.eventConditions.map(
                (eventCondition, conditionIndex) => {
                    !eventCondition.message && (eventCondition.messageText = '');

                    eventCondition.eventId = eventId;
                    eventCondition.conditionIndex = conditionIndex + 1;
                    if (!eventCondition.content) {
                        eventCondition.contentId = '';
                    }
                    if (!eventCondition.channel || eventCondition.channel === -1) {
                        eventCondition.channelNo = -1;
                    }
                }
            )

            if(!eventCondition.isDatalinkEnabled) {
                eventCondition.datalinkInput = '';
                eventCondition.datalinkServerAddress = '';
            }

            if (mode === 'new') {
                scheduleService.createEventCondition(eventCondition).then(
                    res => {
                        if (res) {
                            toastr.success(t("COM_TEXT_SUCCESS_P"));
                            dispatch(menuAction.removeTab('NEW_EVENT_CONDITION'));
                        }
                    }
                ).catch(
                    e => toastr.error(getErrorMessage(e))
                )
            } else if (mode === 'edit') {
                scheduleService.updateEventCondition(eventCondition).then(
                    res => {
                        if (res) {
                            toastr.success(t("COM_TEXT_SUCCESS_P"));
                            dispatch(menuAction.removeTab('EDIT_EVENT_CONDITION'));
                        }
                    }
                ).catch(
                    e=> toastr.error(getErrorMessage(e))
                )
            }
        }

    }

    const updateEventType = (e, value) => {
        const updateEventCondition = {...eventCondition, eventType: value};
        updateCondition(updateEventCondition);
    }

    const closeEventCondition = () => {
        dispatch(popupAction.addPopup({
            id:'EVENT_CONDITION_CLOSE_CONFIRM',
            type: 'COMMON_CONFIRM_POPUP',
            title: t("COM_BUTTON_CONFIRM"),
            message: t("MESSAGE_UPLOADER_REALLY_CANCEL_P"),
            onClickYes : ()=>{
                new Promise(() => {
                    if (mode === 'new') {
                        dispatch(menuAction.removeTab('NEW_EVENT_CONDITION'))
                    } else {
                        dispatch(menuAction.removeTab('EDIT_EVENT_CONDITION'))
                    }
                }).then(
                    dispatch(popupAction.closePopup('EVENT_CONDITION_CLOSE_CONFIRM'))
                )
            },
            onClose: ()=> dispatch(popupAction.closePopup('EVENT_CONDITION_CLOSE_CONFIRM'))
        }))
    }

    const onDatalinkTableApply = (selected, selectedUrl, serverAddress) => {
        dispatch(popupAction.closePopup(commonConstants.RULESET_SELECT_DATALINK_TABLE_POPUP));

        const name = serverAddress.substring((serverAddress.lastIndexOf("/") + 1));
        const updateEventCondition = {...eventCondition, datalinkInput: selected, datalinkServerAddress: name, isDatalinkEnabled: true};
        updateCondition(updateEventCondition);
    };

    const onSelectDatalinkTable = () =>{
        dispatch(
            popupAction.addPopup({
                id: commonConstants.RULESET_SELECT_DATALINK_TABLE_POPUP,
                type: commonConstants.RULESET_SELECT_DATALINK_TABLE_POPUP,               
                onApply: (onDatalinkTableApply),
                onClose: () => dispatch(popupAction.closePopup(commonConstants.RULESET_SELECT_DATALINK_TABLE_POPUP))
            })
        );
    }

    useEffect(() => {
        const updateEventCondition = {...eventCondition, isDatalinkEnabled: useDataLink};
        updateCondition(updateEventCondition);
    }, [useDataLink]);

    const [eventConditionWrapHeight, setEventConditionWrapHeight] = useState();

    useEffect(() => {
        const handleWindowResize = () => {
            const height = window.innerHeight
                            || document.documentElement.clientHeight
                            || document.body.clientHeight;
            const y = document.querySelector('#eventConditionWrap').getBoundingClientRect().y;
            setEventConditionWrapHeight(height - y);
        }

        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return (
        <div className={"event_condition_wrap"}
             style ={{display: (currentContentId === 'NEW_EVENT_CONDITION' && mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) || (currentContentId === 'EDIT_EVENT_CONDITION' && mode === scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE) ? 'block':'none'}}>
            <div className="contents_buttonwrap mt20">
                <div className="leftButton" id="leftButtonList">
                    <WhiteButton onClick={saveEventCondition} id="messageSaveBtn" name={t("COM_BUTTON_SAVE")}/>
                    <WhiteButton onClick={closeEventCondition} id="messageCancelBtn" name={t("BUTTON_CANCEL_P")}/>
                </div>
            </div>
            <div className="contentsbox clearfix" id="eventWrap" style={{marginTop:20}}>
                <div className="height100p" style={{background:'#f9fafb'}}>
                    <div className="schedule_message">
                        <h2>
                            <span style={{paddingLeft: 20}}>
                                {t("COM_IDS_EVENT_MAMAIN")}
                            </span>
                        </h2>
                        <div style={{borderBottom:'1px solid #eeeff0',paddingBottom:20}}>
                            <div className="event_wrap">
                                <div className="message_detail_setting">
                                    <table>
                                        <colgroup>
                                            <col width="100"/>
                                            <col width=""/>
                                        </colgroup>
                                        <tbody>
                                        <tr>
                                            <th>{t("COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME")}</th>
                                            <td>
                                                <TextInput maxLength={45} onChange={updateEventConditionName} value={eventCondition.eventName}/>
                                            </td>
                                            <th>{t("TEXT_DESCRIPTION_P")}</th>
                                            <td>
                                                <TextInput maxLength={390} width={300} onChange={updateEventConditionDes} value={eventCondition.eventDescription}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("COM_TEXT_EVENT_TYPE_P")}</th>
                                            <td>

                                                <Select
                                                    value={eventCondition.eventType !== undefined && eventCondition.eventType !== '' ? eventCondition.eventType : 'Text'}
                                                    selects={[
                                                        {title: "DID_LFD_TEXT", value: 'TEXT'},
                                                        {title: "COM_ADMIN_CONTENT_TYPEINFO_CONTENTS_LIST_JSP_NUMBER", value: 'NUMBER'},
                                                        {title: "MIS_TEXT_TRUE_OR_FALSE_P", value: 'BOOL'}
                                                    ]}
                                                    width={150}
                                                    onChange={updateEventType}
                                                    disabled={eventCondition.eventConditions != undefined && eventCondition.eventConditions.length > 0}
                                                />

                                            </td>
                                        </tr>
                                        {
                                            <tr>
                                            <th>
                                                <Checkbox
                                                    id={"chkDataLink"}
                                                    name={t("MIS_TEXT_USE_DATALINK_P")}
                                                    onChange={e => {
                                                        setUseDataLink(e.target.checked);
                                                    }}
                                                    checked={useDataLink}
                                                />
                                            </th>
                                            <td>
                                                <input
                                                    type="text"
                                                    id="inputDatalink"
                                                    style={{width:220}}
                                                    disabled={!useDataLink}
                                                    value={eventCondition.datalinkInput} />
                                                <span style={{marginLeft: 20}}/>
                                                <button
                                                    className="base"
                                                    id="openDataLinkBtn"
                                                    disabled={!useDataLink}
                                                    onClick={onSelectDatalinkTable} >
                                                    {t("COM_MIS_TEXT_OPEN_P")}
                                                </button>
                                            </td>
                                        </tr>}
                                        </tbody>
                                    </table>

                                </div>
                                <div><h2 className="mt20">{t("MIS_TEXT_EVENT_CONDITION_P")}</h2></div>
                                <div className="condition_wrap mt10" id="eventConditionWrap" style={{overflowY: 'auto',height: eventConditionWrapHeight}} rel="0">
                                    {
                                        eventCondition.eventConditions != undefined && eventCondition.eventConditions.length > 0 &&
                                        eventCondition.eventConditions.map(
                                            (condition, index) => <EventCondition key={condition.eventConditionId} index = {index} mode={mode} conditionType={eventCondition.eventType} condition={condition} id={condition.eventConditionId}/>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default EventConditionManager;