import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers/';

const loggerMiddleware = createLogger();

let middleware = [thunkMiddleware];

const configure = () => {
    let devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') {
        devTools = a => a;
    } else {
        middleware.push(loggerMiddleware);
    }

    const store = createStore(
        rootReducer,
        undefined,
        compose(
            applyMiddleware(
                ...middleware
            ),
            devTools
        )

    );

    return store;
}

export default configure;
