import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { deviceService } from '../../services';
import { useFilter } from "../../helper";
import ReactTable from "react-table";
import Pagination from "../../components/table/Pagination";
import { snakeCase } from "lodash";
import { SETTING_PAGE_SIZE_OPTIONS, settingConstants } from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import { isNil } from 'lodash';
import { connect } from "react-redux";
import { popupAction } from "../../actions";

const ServiceHistory = (props) => {
        const {t} = useTranslation();
        const { addPopup, closePopup } = props;
        const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
            page: 0,
            pageSize: 30,
            keyword: '',
            searchText: '',
            organId: props.groupId !== undefined ? props.groupId : 'ALL',
            sorted: [{id: 'device_id', desc: true}],
            reload: false
        });

        const [data, setData] = useState({
            items: [],
            loading: false,
            totalCount: 0,
            pages: 0,
        });
        const {items = [], loading = false, totalCount = 0, pages = 0} = data;
        const {page, pageSize} = filter;
        const [style, setStyle] = useState({
            height: '500px'
        });
    
        useEffect(() => {
            fetchData();
        }, [filter]);

        const onSearch = (value) => {
            setFilter({...filter, searchText: value, page: 0});
        }
    
        const fetchData = () => {
            const {page, pageSize, sorted: [{id, desc}], organId, searchText} = filter;
            setData({...data, loading: true});
            deviceService.fetchDeviceFilter({ 
                startIndex: page*pageSize + 1,
                pageSize,
                groupType: 'ALL',
                organId: organId,
                sortColumn: snakeCase(id),
                sortOrder: desc === true ? 'desc' : 'asc',
                searchText: searchText,
            }).then(res => {
                setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
            });
        }
    
        useEffect(() => {
            setStyle({height: window.innerHeight - 202});
        }, []);
    
        const openPopup = (deviceId) => {
            addPopup({
                id: settingConstants.SERVICE_HISTORY_POPUP,
                type: settingConstants.SERVICE_HISTORY_POPUP,
                deviceId: deviceId,
                onClose: () => closePopup(settingConstants.SERVICE_HISTORY_POPUP)
            });
        }
    
        const columns = [
            {
                accessor: "deviceId",
                show: false
            },
            {
                Header: t("TABLE_MAC_ADDR_P"),
                accessor: "deviceId",
                width: 200
            },
            {
                Header: t("TABLE_DEVICE_NAME_P"),
                accessor: "deviceName",
                width: 200
            },
            {
                Header: t("TABLE_IP_ADDR_P"),
                accessor: "ipAddress",
                width: 200,
                sortable: false
            },
            {
                Header: t("TABLE_DEVICE_MODEL_NAME_P"),
                accessor: "deviceModelName",
                width: 200,
                sortable: false
            },   
            {
                Header: t("TABLE_FIRMWARE_VERSION_P"),
                accessor: "firmwareVersion",
                Cell: ({value}) =>  {
                    if(isNil(value)){
                        return '';
                    }
                    let arr = value.split(";");
                    return arr.map(val => <div>{val}</div>)
                },
                width: 200,
                sortable: false
            },
            {
                Header: t("BUTTON_VIEW_P"),
                accessor: "detailView",
                Cell: (props) =>  <WhiteButton id={props.original.deviceId} width={'40px'} name={t("BUTTON_VIEW_P")} onClick={()=>openPopup(props.original.deviceId)} />,
                width: 200,
                sortable: false
            }
        ];
        return (
            <div style={{width: '100%',display: props.currContent === 'SERVICE_HISTORY' ? 'block':'none'}} className={'service_history'}>
                <div className="contents_buttonWrap">
                    <div className="rightButton">
                        <SearchBar id="deviceSearch" placeholder={t("TABLE_MAC_ADDR_P") + "," + t("TABLE_DEVICE_NAME_P") + "," + t("TABLE_IP_ADDR_P") + "," + t("TABLE_DEVICE_MODEL_NAME_P")} onClickSearch={onSearch} />
                    </div>
                </div>
                <ReactTable
                    minRows={0}
                    data={items}
                    page={page}
                    pages={pages}
                    loading={loading}
                    style={style}
                    columns={columns}
                    onSortedChange={onSortedChange}
                    defaultPageSize={30}
                    className="-striped -highlight"
                    manual
                    showPagination={false}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={SETTING_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} />
            </div>
        );
}
export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(ServiceHistory);
