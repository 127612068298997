import React from "react";
import PropTypes from "prop-types";
import {popupAction} from "../../actions";
import {connect} from "react-redux";

class DialogBody extends React.Component {

    constructor(props) {
        super(props);
    }

    setActive(e,activePoup,popupId,modal) {
        if (modal ==undefined || modal == false) {
            const currentDialog = e.target.closest('.ui-dialog'); // check clicked element is detail popup

            let currentDialogZIndex;
            if (currentDialog != null && currentDialog != undefined)
                currentDialogZIndex = currentDialog.style.zIndex;
            else
                return;

            const dialogs = document.getElementsByClassName('ui-dialog');
            const dialogsArr = Array.from(dialogs);
            const zIndexes = dialogsArr.map((dialog) => {
                return dialog.style.zIndex;
            })
            const maxZIndex = Math.max(...zIndexes);

            if (maxZIndex > Number(currentDialogZIndex)) {
                currentDialog.style.zIndex = maxZIndex + 1;
            }
            activePoup(maxZIndex + 1, popupId);
        }
    }

    render() {
        return (
            <div className="ui-dialog-content" style={{ "overflowY": "auto" }} onClick={(e) => {this.setActive(e, this.props.activePoup, this.props.popupId, this.props.modal)}}>
                {this.props.children}
            </div>
        );
    }
}

DialogBody.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default connect(
    null,
    dispatch => ({
        activePoup : (maxZIndex,popupId,modal)=> dispatch(popupAction.activePoup(maxZIndex,popupId,modal))
    })
)(DialogBody);