import {isNil} from "lodash";
import {userService} from "../services";
import {useTranslation} from 'react-i18next';
import { getMixString } from "../language/languageUtils";

const validatorMap = {
    'userId': validateId,
    'password': validatePassword,
    'confirmPassword': validateConfirmPassword,
    'userName': validateName,
    'email': validateEmail,
    'groupName': validateGroupName,
    'roleName': validateName,
    'organizationName': validateOrganizationName,
    'nickname': validateNickName
};
const validResult = {isValid: true};

export const userValidator = (name, ...args) => {
    const {[name]: validators} = validatorMap;
    if (validators) {
        if (Array.isArray(validators)) {
            for (const validator of validators) {
                const result = validator(...args);
                if (!result.isValid) {
                    return result;
                }
            }
            return validResult;
        }
        return validators(...args);
    } else {
        return validResult;
    }
};

function validateId(id) {
    let message = undefined;
    let isValid = true;
    let messageId = undefined;
    let duplicated = false;

    if (duplicated) {
        messageId = 'MESSAGE_USER_ID_NOT_AVAILABLE_P';
        isValid = false;
    } else if (!id || id.length < 5 || id.length > 20) {
        messageId = 'MESSAGE_USER_ID_WRONG_LENGTH_P';
        isValid = false;
    } else {
        if (!/^[a-zA-Z0-9.]+$/.test(id)) {
            messageId = 'MIS_SID_USE_ID_CONTAIN_ALPHA_NUM_PERIODS'
            isValid = false;
        }
    }

    return {isValid, messageId, message};
}

function validateNickName(isEnabled, nickName) {
    let message = undefined;
    let isValid = true;
    let messageId = undefined;
    let duplicated = false;

    if(isEnabled){
        if (duplicated) {
            messageId = 'MESSAGE_USER_ID_NOT_AVAILABLE_P';
            isValid = false;
        } else if (!nickName || nickName.length < 5 || nickName.length > 20) {
            messageId = 'MESSAGE_USER_ID_WRONG_LENGTH_P';
            isValid = false;
        } else {
            if (!/^[a-zA-Z0-9.]+$/.test(nickName)) {
                messageId = 'MIS_SID_USE_ID_CONTAIN_ALPHA_NUM_PERIODS'
                isValid = false;
            }
        }
    }

    return {isValid, messageId, message};
}


function validateConfirmPassword(password, confirmPassword) {
    let message = undefined;
    let isValid = true;
    let messageId = undefined;

    if (password !== confirmPassword) {
        messageId = 'MESSAGE_USER_DIFF_PASS_REPASS_P';
        isValid = false;
    }

    return {isValid, messageId, message};
}


function validatePassword(newPassword) {
    let message = undefined;
    let isValid = true;
    let messageId = undefined;

    let isAlphabet = false;
    let isNumber = false;
    let isThreeChar = false;
    let isChar = false;

    let pAlphabet = /[a-zA-Z]/g; // 영문자
    let pNumber = /[0-9]/g; // 숫자
    let pThreeChar = new RegExp("([0-9a-zA-Z])\\1{2,}"); // 같은 문자 또는 숫자 3번이상
    let pChar = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;

    if (!newPassword || newPassword.length < 8 || newPassword.length > 50) {
        messageId = 'MIS_SID_CHOOSE_PW_10_TO_50_ALPHANUMBRRIC_MSG';
        isValid = false;
    } else if (newPassword.indexOf(' ') > -1) {
        messageId = 'MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG';
        isValid = false;
    } else {
        pAlphabet.lastIndex = 0;
        pNumber.lastIndex = 0;
        pThreeChar.lastIndex = 0;
        pChar.lastIndex = 0;

        isAlphabet = pAlphabet.test(newPassword);
        isNumber = pNumber.test(newPassword);
        isChar = pChar.test(newPassword);
        isThreeChar = pThreeChar.test(newPassword);

        // 3가지 이상 조합인가?
        if (isAlphabet && isNumber && isChar) {
            if (newPassword.length < 8) {
                return {isValid: false, messageId: 'MIS_SID_CHOOSE_PW_10_TO_50_ALPHANUMBRRIC_MSG', message: undefined}
            }
            // 2가지 이상 조합인가?
        } else if (isAlphabet && isNumber || isNumber && isChar || isAlphabet && isChar) {
            if (newPassword.length < 10) {
                return {isValid: false, messageId: 'MIS_SID_CHOOSE_PW_10_TO_50_ALPHANUMBRRIC_MSG', message: undefined}

            }
        } else {
            return {isValid: false, messageId: 'MIS_SID_CHOOSE_PW_10_TO_50_ALPHANUMBRRIC_MSG', message: undefined}
        }

        if (isThreeChar) {
            return {isValid: false, messageId: 'MESSAGE_USER_NOTICE_PASSWORD4_P', message: undefined}
        }

        var seqThreeNum = [];
        seqThreeNum.push("012");
        seqThreeNum.push("123");
        seqThreeNum.push("234");
        seqThreeNum.push("345");
        seqThreeNum.push("456");
        seqThreeNum.push("567");
        seqThreeNum.push("678");
        seqThreeNum.push("789");
        seqThreeNum.push("890");
        for (var str = 0; str < seqThreeNum.length; str++) {
            if (newPassword.indexOf(seqThreeNum[str]) !== -1) {
                return {isValid: false, messageId: 'MESSAGE_USER_NOTICE_PASSWORD4_P', message: undefined}
            }
        }
    }

    return {isValid, messageId, message};
}

function validateName(name) {
    let message = undefined;
    let isValid = true;
    let messageId = undefined;

    if (isNil(name) || name.trim() === '') {
        messageId = 'MESSAGE_COMMON_CANT_NAME_SPACE_P';
        isValid = false;
        return {isValid, messageId, message};
    }

    if(name.length > 60){
        messageId = 'MESSAGE_COMMON_MAX_60_P';
        isValid = false;
    }

    return {isValid, messageId, message};
}

function validateEmail(address) {
    let messageId;
    let isValid = true;

    if (!/^[\w-']+(\.[\w-']+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/.test(address)) {
        messageId = 'COM_MESSAGE_USER_EMAIL_INVALID_P'
        isValid = false;
    }

    return {isValid, messageId};
}

function validateGroupName(name) {
    let messageId;
    let isValid = true;

    if (isNil(name) || name === '') {
        messageId = 'MESSAGE_USER_SEL_GROUP_P';
        isValid = false;
    }

    return {isValid, messageId};
}

function validateOrganizationName(name) {
    let message = undefined;
    let isValid = true;
    let messageId = undefined;
    let specialChar = /[`&*+\';,/|\":<>?]/gi;

    if (isNil(name) || name === '') {
        messageId = 'TEXT_SEL_ORGANIZATION_P';
        isValid = false;
    }

    if(specialChar.test(name)){
        messageId = 'COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2';
        isValid = false;
    }

    return {isValid, messageId, message};
}