import React, {useEffect, useMemo, useRef, useState} from 'react';
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {contentService} from '../../services';
import SearchBar from "../../components/search/SearchBar";
import SwitchListNImage from "../../components/switch/SwitchListNImage";
import Filter from "../../components/filter/Filter";
import Checkbox from "../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import Size from "../../components/utils/Size";
import {connect, useSelector} from "react-redux";
import {contentAction, menuAction, popupAction} from "../../actions";
import {APPROVAL_STATUS_LABEL, commonConstants, contentConstants, EDIT_DISABLED_MEDIA_TYPE} from "../../constants";
import {useTranslation} from 'react-i18next';
import {isEqual, snakeCase} from 'lodash';
import './Content.css'
import CategoryButton from "../../components/category/CategoryButton";
import ContentThumbnail from "../../components/image/ContentThumbnail"
import {addDashToString, useCheckRefForContent, useFilter, usePrevious} from "../../helper";
import Pagination from "../../components/table/Pagination";
import ContentCard from "../../components/content/ContentCard";
import ImageTableHeader from "../../components/table/ImageTableHeader";
import {getMixString} from "../../language/languageUtils";
import {toastr} from 'helper/toastrIntercept';
import Moment from "moment";
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import {useMISOpt} from "../../components/misopt";
import EMPTY_IMAGE from "../../images/img/empty_img_1.png";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";
import {getCache, updateCache} from "../../helper/cache/tableCache";
import {getErrorMessage} from "../../helper/responseHandler";

const AllContent = (props) => {
    const {t} = useTranslation();
    const {getAuthority} = useMISOpt();
    const [authority, setAuthority] = useState({});

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [style, setStyle] = useState({
        height: '762px'
    });

    const [contentCard, setContentCard] = useState([]);

    const [size, setSize] = useState(100);

    const [view, setView] = useState(props.cache.view || 'LIST');

    const [showNoData, setNoData] = useState(false);

    const [currentContentId, setCurrentContentId] = useState({
        currentId: props.currContent,
        fetched: props.currContent
    });

    const [webAuthorInfo, setWebAuthorInfo] = useState({
        userId: '',
        token:'',
        webAuthorPath:'',
        language:'',
        contentId:[]
    });

    const reload = useSelector(({content}) => content.reloadContent);
    const downloading = useSelector(({content}) => content.downloading);
    const [imageSort, setImageSort] = useState("default");

    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted, groupId = undefined, keyword, customFilter, categoryIds} = filter;
    const {openDetailView, addPopup, closePopup, reloadContent, setMyContentGroupId, reloadGroup, updateSubMenuCounter, contentDownloading} = props;
    const {currentId, fetched} = currentContentId;
    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt, initData, toggleSelectAllForCardView, toggleCard, toggleRowWithIndex] = useCheckRefForContent(items, contentCard);

    const webAuthorForm = useRef();
    let filterComp = useRef();

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}], categoryIds, customFilter} = filter;
        const {creatorIds} = customFilter;
        if(currentId === 'BY_USER' && (creatorIds === undefined || creatorIds.length === 0 || groupId === undefined))  {
            return;
        }

        setContentCard([]);
        setData({...data, loading: true});

        if(view === 'IMAGE'){
            addPopup({id: 'FETCH_CONTENT', type: commonConstants.LOADING_POPUP});
        }

        let params = {};

        switch(currentId) {
            case 'SHARED_CONTENT':
                params = {
                    groupType: "GROUPED",
                    shareFolderId: groupId,
                    isMainPage: true
                };
                break;
            default:
                if(groupId !== '' && groupId !== undefined){
                    params = {
                        groupType: "GROUPED",
                        groupId: groupId,
                        isMainPage: true
                    };
                }
                break;
        }

        contentService.fetchContentFilter({
            ...customFilter,
            ...params,
            isThumbnail: true,
            isUsedContent: true,
            startIndex: (page * pageSize) + 1,
            pageSize: pageSize,
            searchText: keyword,
            categoryIds: categoryIds,
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC',
        }).then(res => {
            if(currentId === 'MY_CONTENT' || currentId === 'ALL_CONTENT'){
                setMyContentGroupId(groupId);
            }
            setCurrentContentId({...currentContentId, fetched:currentId});
            if(view === 'IMAGE'){
                closePopup('FETCH_CONTENT');
            }

            setData({
                        ...data,
                        loading: false,
                        items: res.items,
                        totalCount: res.totalCount,
            });

            setNoData(res.items.length === 0);

            updateCache('CONTENT', {items: res.items, filter: filter, totalCount: res.totalCount}, props.currContent)
        });
    };

    const openDetailViewPopup = (e, contentId) => {
        openDetailView({
            type: commonConstants.COMMON_DETAIL_VIEW,
            viewType: 'CONTENT',
            id: contentId,
            onSuccess: () => fetchData(),
            onClose: () => closePopup(contentId),
        });
    };

    const getCheckedContentId = () => {
        return selected.current.map(key => items[key].contentId);
    };

    const getCheckedContentItems = () => {
        const contentIds = getCheckedContentId();
        const checkedItems = [];
        contentIds.forEach(id => {
            checkedItems.push(items.find(item => item.contentId === id));
        });
        return checkedItems;
    };

    const deleteContent = (id) => {
        const requestBody = {
            contentIds: getCheckedContentId(),
            deleteMethod: contentConstants.GO_TO_RECYCLEBIN
        };

        contentService.deleteContentById(requestBody)
            .then(res => {
                if(res.status === 'Success'){
                    toastr.success(t('MIS_MESSAGE_CONTENT_DELETE_CONTENT_FROM_PLAYLIST_SUCCESS_P'));
                    fetchData();
                    updateSubMenuCounter('CONTENT');
                    reloadGroup('MY_CONTENT');
                    reloadGroup('BY_USER');
                }
            }).catch(error => {
                if(error.errorCode === '403001'){
                    toastr.error(t('MIS_MESSAGE_COMMON_NOT_PERMISSION_DELETE_P'));
                }else{
                    addPopup({
                        type: commonConstants.USED_CONTENT_DELETE,
                        d: commonConstants.USED_CONTENT_DELETE,
                        failList: error.items.failList,
                        onSuccess: fetchData
                    });
                }
            }).finally(() => {
                    closePopup(id);
            });
    };

    const editContent = () => {
        const params = {
            contentIds : getCheckedContentId()
        };
        contentService.fetchContentAvailability(params).then(res => {
            if(res.status === 'Success'){
                if(res.items.expiredContentList !== undefined && res.items.expiredContentList.length > 0){
                    openExpiredContentPopup(res.items.expiredContentList);
                }else{
                    const contentIds = getCheckedContentId();
                    const content = items.find(item => item.contentId === contentIds[0]);
                    let type, id;
                    switch(content.mediaType){
                        case 'LFD':
                        case 'DLK':
                        case 'VWL':
                        case 'LFT':
                            openWebAuthorWindow(contentIds);
                            break;
                        case 'FTP':
                            type = commonConstants.CREATE_FTP;
                            id = commonConstants.CREATE_FTP;
                            contentService.fetchFtpSetting(content.contentId).then(res => {
                                if(res.status === 'Success'){
                                    openEditContentPopup(res.items, type, id);
                                }
                            }).catch(error => {
                                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
                            });
                            break;
                        case 'CIFS':
                            type = commonConstants.CREATE_CIFS;
                            id = commonConstants.CREATE_CIFS;
                            contentService.fetchCifsSetting(content.contentId).then(res => {
                                if(res.status === 'Success'){
                                    openEditContentPopup(res.items, type, id);
                                }
                            }).catch(error => {
                                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
                            });
                            break;
                        case 'HTML':
                            type = commonConstants.UPLOAD_WEB_CONTENT;
                            id = commonConstants.UPLOAD_WEB_CONTENT;
                            contentService.fetchContentById(content.contentId).then(res => {
                                if(res.status === 'Success') {
                                    openEditContentPopup(res.items, type, id);
                                }
                            }).catch(error => {
                                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
                            });
                            break;
                        case 'URL':
                            type = commonConstants.CREATE_WEB_URL;
                            id = commonConstants.CREATE_WEB_URL;
                            contentService.fetchUrlSetting(content.contentId).then(res => {
                                if(res.status === 'Success'){
                                    openEditContentPopup(res.items, type, id);
                                }
                            }).catch(error => {
                                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
                            });
                            break;
                        case 'SAPP':
                            type = commonConstants.SSSP_WEB_APP;
                            id = commonConstants.SSSP_WEB_APP;
                            contentService.fetchContentById(content.contentId).then(res => {
                                if(res.status === 'Success') {
                                    openEditContentPopup(res.items, type, id);
                                }
                            }).catch(error => {
                                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
                            });
                            break;
                        default:
                            break;
                    }
                }
            }
        }).catch(error => {
            if(error.errorCode === '403001'){
                toastr.error(t('ERROR_NO_ROLE'));
            }
        });
    };

    const openWebAuthorWindow = (contentIds) => {
        contentService.fetchWebAuthorAuthorizedUser().then(res => {
            if(res.status === 'Success'){
                window.open('', 'WebAuthorWindow');
                setWebAuthorInfo({
                    userId: res.items.userId,
                    token:res.items.token,
                    webAuthorPath:res.items.webAuthorPath,
                    language:res.items.language,
                    contentId:contentIds
                });
            }
        }).catch(error => {
            let errorMessage = error.errorMessage;
            switch(error.errorCode) {
                case '403001':
                    errorMessage = t('ERROR_NO_ROLE');
                    break;
                default:
                    errorMessage = t('COM_IDS_MSG_UNEXPEXTED_ERROR');
                    break;
            }
            toastr.error(errorMessage);
        });
    };

    const openExpiredContentPopup = (expiredContents) => {
        addPopup({
            type: commonConstants.EXPIRED_CONTENT,
            id: commonConstants.EXPIRED_CONTENT,
            expiredContents: expiredContents
        });
    };

    const openEditContentPopup = (content, type, id) => {
        addPopup({
            type: type,
            id: id,
            content: content,
            editMode: true
        });
    };

    const deleteContentPopup = (id) => {
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('BUTTON_CANCEL_P'),
            onClickYes: () => deleteContent(id),
            onClose: () => closePopup(id)
        });
    };

    const downloadContent = () => {
        const contentIds = getCheckedContentId();
        if(contentIds.length > 5){
            toastr.error(getMixString(['MIS_SID_20_MIX_CAN_SELECT_UP_TO_ITEMS', 5]));
        }else {
            const params = {
                contentIds: contentIds
            };

            contentDownloading(true);
            addPopup({
                id: 'CONTENT_DOWNLOADING',
                type: commonConstants.LOADING_POPUP,
                title: t('MSG_DOWNLOAD'),
                modal: false,
                isDraggable: true
            });
            contentService.contentDownload(params).then(res => {
            }).catch(error => {
                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
            }).finally(() => {
                closePopup('CONTENT_DOWNLOADING');
                contentDownloading(false);
            });
        }
    };

    const addToPlaylistPopup = (id) => {
        const params = {
            contentIds : getCheckedContentId(),
            authority : "READ",
        };
        contentService.fetchContentAvailability(params).then(res => {
            if (res.status === 'Success') {
                if (res.items.result) {
                    const selectedItems = getCheckedContentItems();
                    addPopup({
                        type: commonConstants.ADD_TO_PLAYLIST,
                        id: id,
                        contentItems:selectedItems,
                        checkResult: res.items,
                        onClose: () => closePopup(id),
                    });
                } else {
                    if(res.items.expiredContentList && res.items.expiredContentList.length > 0){
                        openExpiredContentPopup(res.items.expiredContentList);
                    }else{
                        toastr.error(t('MIS_SID_20_SOUND_FILE_TYPES_NOT_ADD_TO_THE_PLAYLIST'));
                    }
                }
            }
        }).catch(error => {
            if(error.errorCode === '403001'){
                toastr.error(t('ERROR_NO_ROLE'));
            }
        });
    };

    const moveContentPopup = (id) => {
        const checkedContentIds = {
            contentIds: getCheckedContentId()
        };

        contentService.authorityCheck(checkedContentIds).then(res => {
            const params = {
                groupType : contentConstants.MY_CONTENT_GROUP
            };
            addPopup({
                type: commonConstants.COMMON_GROUP_POPUP,
                id: id,
                mode: 'content',
                title : t('MIS_SID_SELECT_FOLDER'),
                save: moveContent,
                params: params,
                close: () => closePopup(id)
            });
        }).catch(error => {
            if(error.errorCode === '403001'){
                toastr.error(t('ERROR_NO_ROLE'));
            }
            else {
                toastr.error(getErrorMessage(error));
            }
        });
    };

    const shareContentPopup = (id) => {
        const checkedContentIds = {
            contentIds: getCheckedContentId()
        };

        contentService.authorityCheck(checkedContentIds).then(res => {
            const selectedItems = getCheckedContentId();
            addPopup({
                type: commonConstants.CONTENT_SHARE,
                id: id,
                contentIds:selectedItems,
                close: () => closePopup(id)
            });
        }).catch(error => {
            if(error.errorCode === '403001'){
                toastr.error(t('ERROR_NO_ROLE'));
            }
        });
    };

    const editMultipleContentPopup = (id) => {
        const checkedContentIds = {
            contentIds: getCheckedContentId()
        };

        contentService.fetchContentAvailability(checkedContentIds).then(res => {
            const selectedItems = getCheckedContentId();
            addPopup({
                type: commonConstants.CONTENT_MULTI_EDIT,
                id: id,
                contentIds: selectedItems,
                showExpirationDateTab: !checkLFTTypeIsSelected(),
                onClose: () => closePopup(id)
            });
        }).catch(error => {
            if(error.errorCode === '403001'){
                toastr.error(t('ERROR_NO_ROLE'));
            }
            else {
                toastr.error(getErrorMessage(error));
            }
        });
    };

    const saveAsTemplatePopup = (id) => {
        const selectedItems = getCheckedContentId();

        contentService.checkConvertibility(selectedItems[0]).then(res => {
                if(res.status === 'Success' && res.items.convertible) {
                    addPopup({
                        type: commonConstants.SAVE_AS_TEMPLATE,
                        id: id,
                        contentId: selectedItems[0],
                        groupId: groupId,
                        onClose: () => closePopup(id)
                    });
                }
                else {
                    if(res.items && res.items.convertible === false) {
                        let errorMessage;
                        switch(res.items.reasonCode) {
                            case '400306':
                                errorMessage = t('MIS_SID_ONLY_SHARED_FILES_SAVED_TEMPLATES');
                                break;
                            case '400315':
                                errorMessage = t('MIS_SID_ONLY_APPROVED_FILES_SAVED_TEMPLATE');
                                break;
                            case '403001':
                                errorMessage = t('ERROR_NO_ROLE');
                                break;
                            default:
                                errorMessage = res.items.reason;
                                break;
                        }
                        toastr.error(errorMessage);
                    }
                }
            }).catch(error => {
                let errorMessage;
                switch(error.errorCode) {
                    case '400306':
                        errorMessage = t('MIS_SID_ONLY_SHARED_FILES_SAVED_TEMPLATES');
                        break;
                    case '400315':
                        errorMessage = t('MIS_SID_ONLY_APPROVED_FILES_SAVED_TEMPLATE');
                        break;
                    case '403001':
                        errorMessage = t('ERROR_NO_ROLE');
                        break;
                    default:
                        errorMessage = error.errorMessage;
                        break;
                }
                toastr.error(errorMessage);
            });
    };

    const moveContent = (groups) => {
        const checkedContentIds = {
            contentIds: getCheckedContentId()
        };

        contentService.movedContent(checkedContentIds, groups[0].groupId).then(res => {
            if(res.status === 'Success') {
                toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
                reloadGroup('MY_CONTENT', 0);
                reloadGroup('BY_USER');
                fetchData();
            }
        }).catch(error => {
            let errorMessage = error.errorMessage;
            switch(error.errorCode) {
                case '403001':
                    errorMessage = t('ERROR_NO_ROLE');
                    break;
                default :
                    errorMessage = t('COM_IDS_MSG_UNEXPEXTED_ERROR');
                    break;
            }
            toastr.error(errorMessage);
        }).finally(() => {
            closePopup("CONTENT_MOVE");
        });

    };

    const unshareContent = () => {
        const checkedContentIds = {
            contentIds: getCheckedContentId(),
            authority : "UNSHARED"
        };

        contentService.fetchContentAvailability(checkedContentIds).then(res => {
            contentService.releaseSharedContent(checkedContentIds).then(res => {
                if(res.status === 'Success') {
                    toastr.success(t("MIS_SID_UNSHARED"));
                }
                reloadGroup('SHARED_CONTENT');
            }).catch(error => {
                if(error.errorCode === '401000'){
                    toastr.error(t('ERROR_NO_ROLE'));
                }
            }).finally(() => {
                fetchData();
            });
        }).catch(error => {
            if(error.errorCode === '403001'){
                toastr.error(t('ERROR_NO_ROLE'));
            }
        });
    };

    const updateSwitchListNImage = () => {
        const updateView = view === 'LIST' ? 'IMAGE' : 'LIST';
        setView(updateView);
        initData();
        setContentCard([]);

        const data = getCache('CONTENT');
        updateCache('CONTENT', {...data, view: updateView}, props.currContent)
    };

    const checkEditButtonEnable = () => {
        const disableMediaType = EDIT_DISABLED_MEDIA_TYPE;
        return !(selected.current.length === 1 && items[selected.current[0]] !== undefined && !disableMediaType.includes(items[selected.current[0]].mediaType));
    };

    const checkSaveAsTemplateButtonEnable = () => {
        return !(selected.current.length === 1 && items[selected.current[0]] !== undefined && items[selected.current[0]].mediaType === 'LFD');
    };

    const checkAddToPlaylistButtonEnable = () => {
        return !(selected.current.length > 0 && selected.current.filter(key => items[key] !== undefined && items[key].approvalStatus !== 'APPROVED').length === 0);
    };

    const checkLFTTypeIsSelected = () => {
        let ret = false;
        const checkedItems = getCheckedContentItems();
        checkedItems.forEach(item => {
            if(item.mediaType === 'LFT') {
                ret = true;
            }
        });
        return ret;
    };

    const onChangeImageSorting = (orderBy) => {
        setFilter({...filter, sorted: [{id:'contentName', desc: orderBy === 'desc'}]});
        setImageSort(orderBy);
    };

    const onChangeSize = (size) => {
        setSize(size);
    };

    const onClickExport = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}], categoryIds, customFilter} = filter;

        let params = {};
        switch(currentId) {
            case 'MY_CONTENT':
                params = {
                    groupType: "GROUPED",
                    groupId: groupId,
                    isMainPage: true
                };
                break;
            case 'BY_USER':
                if(groupId !== ''){
                    params = {
                        groupType: "GROUPED",
                        groupId: groupId,
                        isMainPage: true
                    };
                }
                break;
            case 'SHARED_CONTENT':
                params = {
                    groupType: "GROUPED",
                    shareFolderId: groupId,
                    isMainPage: true
                };
                break;
        }
        addPopup({id: 'CONTENT_EXPORT', type: commonConstants.LOADING_POPUP});
        contentService.contentExport({
            ...customFilter,
            ...params,
            // isThumbnail: true,
            // isUsedContent: true,
            startIndex: 1,
            pageSize : data.totalCount,
            searchText: keyword,
            categoryIds: categoryIds,
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC',
        }, 'EXCEL').finally(() => {
            closePopup('CONTENT_EXPORT');
        });
    };

    const onCategoryCheck = (checkedIds) => {
        if(!isEqual(categoryIds, checkedIds)){
            setFilter({...filter, page: 0, categoryIds: checkedIds});
        }
    };

    const onSaveFilter = (result) => {
        setFilter({
            ...filter,
            page:0,
            customFilter: {
                deviceTypes: result.devices,
                contentTypes: result.contents,
                creatorIds: fetched === 'BY_USER' ? customFilter.creatorIds : result.users,
                tagIds: result.mediaTags,
                approvalStatus: result.approvalStatus,
                contentUsingStatus: result.contentUse,
                fileSizes: result.fileSizes,
                expirationStatus: result.expirationStatus
            }
        });
    };

    const onClickDetailSearch = (searchDetail) => {
        const {customFilter} = filter;
        setFilter({
            ...filter,
            keyword: searchDetail.keyword,
            customFilter:{
                ...customFilter,
                searchCreator: searchDetail.creator,
                startModifiedDate: searchDetail.date.startDate !== "" ? Moment(searchDetail.date.startDate).format('YYYY-MM-DD') : '',
                endModifiedDate: searchDetail.date.endDate !== "" ? Moment(searchDetail.date.endDate).format('YYYY-MM-DD') : ''
            }
        });
    };

    const renderModifiedDate = (lastModifiedDate) => {
        return <DeviceRelativeTimeCell value={lastModifiedDate} />
    };

    const getApprovalStatusLabel = (status) => {
        if(status !== undefined){
            return t(APPROVAL_STATUS_LABEL.find(item => item.status === status).label);
        }else {
            return "";
        }
    };

    const updateDimensions = () => {
        let height = window.innerHeight - 204;
        setStyle({
            ...style,
            height: height
        })
    }

    const renderContentDetail = (content) => {

        return (<div>
            <div><span>{content.mediaType}</span></div>
            <div><Size size={content.totalSize} /></div>
            <div><span>{content.playTimeInString}</span></div>
        </div>)
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'AllContent_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={items[row.index].contentId}
                        index={row.index}
                        classname="table"
                        name="check"
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            width : 46,
            sortable: false,
            resizable: false
        },
        {
            accessor: "contentId",
            show: false
        },
        {
            Header: t("DID_MAIN_CONTENT"),
            width: 127,
            accessor: 'thumbFileId',
            sortable: false,
            Cell: (props) => <div><ContentThumbnail id={props.original.thumbFileId} width={100} height={56} textAlign={true}/></div>
        },
        {
            Header: t("TEXT_CONTENT_NAME_P"),
            accessor: "contentName",
            Cell: (props) => <span className={"data_name"} title={props.original.contentName} onClick={e=>openDetailViewPopup(e, props.original.contentId)}>{props.original.contentName}</span>,
            width: 450
        },
        {
            Header: t("COM_TV_SID_DETAILS"),
            width: 195,
            sortable: false,
            Cell: props => renderContentDetail(props.original) 
        },
        {
            Header: t("COM_MAPP_SID_SUPPORTED_DEVICES"),
            accessor: "deviceType",
            width: 248,
            sortable: false,
            Cell: props => <SupportedDeviceIcon
                deviceType={props.original.deviceType}
                deviceTypeVersion={props.original.deviceTypeVersion}
                mediaType={props.original.mediaType}
                fileType={props.original.mainFileExtension}
            />
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "lastModifiedDate",
            width: 182,
            Cell: props => renderModifiedDate(props.original.lastModifiedDate)

        },
        {
            Header: t("MESSAGE_STATISTICS_SUMMARY_APPROVE_TITLE_P"),
            accessor: "approvalStatus",
            width: 104,
            show: items.length > 0 ? items[0].isApprovalEnabled : false,
            Cell: props => getApprovalStatusLabel(props.original.approvalStatus)
        },
        {
            Header: t("TEXT_CREATOR_P"),
            accessor: "creatorId",
            width: 110,
        },
        {
            Header: t("COM_EXPIRATION_DATE_KR_DATE"),
            accessor: "expirationDate",
            Cell: props => <div><span>{addDashToString(props.original.expirationDate)}</span></div>,
            minWidth: 200
        }
    ], [items]);

    useEffect(() => {
        setCurrentContentId({...currentContentId, currentId: props.currContent});
    }, [props.currContent]);

    const preGroupId = usePrevious(props.cache.filter.groupId);
    const preUserId = usePrevious(props.cache.filter.userId);
    const preCurrContent = usePrevious(props.currContent);

    useEffect(() => {
        if (
            (preGroupId !== undefined && preGroupId !== props.groupId) ||
            (preUserId !== undefined && preUserId !== props.userId) ||
            (props.currContent === 'ALL_CONTENT' && preCurrContent !== undefined) ||
            (props.currContent === 'BY_USER' && preCurrContent === undefined) ||
            (preCurrContent !== undefined && preCurrContent !== props.currContent)
        ) {
            if(props.currContent === 'SHARED_CONTENT'){
                if(props.groupId !== '0'){
                    setFilter({
                        force: false,
                        page: 0,
                        pageSize: pageSize,
                        keyword: '',
                        groupId:props.groupId,
                        sorted: [{id: 'lastModifiedDate', desc: true}],
                        reload: false,
                        customFilter:{
                            creatorIds: props.userId !== undefined && props.userId !== null ? [] : customFilter.creatorIds
                        },
                        categoryIds: []
                    });
                }
            }else if(props.currContent=== 'BY_USER'){
                if(props.groupId !== undefined && props.userId !== undefined){
                    setFilter({
                        force: false,
                        page: 0,
                        pageSize: pageSize,
                        keyword: '',
                        groupId:props.groupId,
                        sorted: [{id: 'lastModifiedDate', desc: true}],
                        reload: false,
                        customFilter:{
                            creatorIds: props.userId !== undefined && props.userId !== null ?[props.userId] : []
                        },
                        categoryIds: []
                    });
                }
            }else if(props.currContent === 'MY_CONTENT'){
                setFilter({
                    force: false,
                    page: 0,
                    pageSize: pageSize,
                    keyword: '',
                    groupId:props.groupId,
                    sorted: [{id: 'lastModifiedDate', desc: true}],
                    reload: false,
                    customFilter:{
                        creatorIds:[]
                    },
                    categoryIds: []
                });
            }else if(props.currContent === 'ALL_CONTENT'){
                setFilter({
                    force: false,
                    page: 0,
                    pageSize: pageSize,
                    keyword: '',
                    groupId: props.groupId !== undefined ? props.groupId : '',
                    sorted: [{id: 'lastModifiedDate', desc: true}],
                    reload: false,
                    customFilter: {},
                    categoryIds: []
                });
            }

            if(filterComp.current !== undefined){
                filterComp.current.resetCheckBox();
            }
        }
    }, [props.groupId, props.userId]);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched){
            fetchData();
        }
    }, [filter]);

    useEffect(() => {
        updateDimensions();
        setAuthority(getAuthority('CONTENT_PLAYLIST_RULESET'));
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(()=> {
        if(webAuthorInfo.webAuthorPath !== '') {
            webAuthorForm.current.submit();
        }

    },[webAuthorInfo]);


    useEffect(() => {
        if(reload){
            reloadContent(false);
            fetchData();
        }
    }, [reload]);


    const renderNodata = () => {
        return (
                <div className='no_data_wrap'>
                    <div>
                        <img src={EMPTY_IMAGE} style={{marginTop:260}}/>
                            <span className="empty_title">{t('DID_MAIN_CONTENT')}</span>
                            <span>{getMixString(["MIS_SID_MIX_ADD_YOUR", "DID_MAIN_CONTENT"])}</span>
                    </div>
                 </div>
               );
    };

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow,'content_tr_group');
    const renderListView = () => {
        return (
            <div className='content_list_view'>
                <MagicInfoTable
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    data={items}
                    page={page}
                    loading={loading}
                    minRows={0}
                    sorted={sorted}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    showPagination={false}
                    columns={columns}
                    className="-striped -highlight"
                    manual
                    usePagination
                    paginationOptions={{
                        totalCount: totalCount,
                        page: page,
                        defaultPageSize: pageSize,
                        pageSizeOptions: contentConstants.PAGE_SIZE_OPTIONS,
                        onPageChange: onPageChange,
                        onPageSizeChange: onPageSizeChange,
                        divide: props.divide
                    }}
                    style={style}
                />
            </div>
        )
    };

    const renderImageView = () => {
                
        return (
            <div className="image_view_wrap">
                <ImageTableHeader
                    size={size}
                    sorting={imageSort}
                    setCheckAllRef={checkAll}
                    onChangeCheckAll={toggleSelectAllForCardView}
                    onChangeSize={onChangeSize}
                    onChangeSorting={(orderBy) => onChangeImageSorting(orderBy)}
                    min={50}
                    max={150}/>
                <div className="list imageView" style={{height: style.height - 33}}>
                    <div className="thumbnailview_wrap">
                        <ul className="clearfix" id="contentList">
                            {!loading && items != null && items.length > 0 ?
                                items.map(
                                    (content, index) => {
                                        return <ContentCard
                                            content={content}
                                            onClick={toggleCard}
                                            index={index}
                                            onTitleClick={openDetailViewPopup}
                                            width={size}
                                            setRef={(ref, index) => {setContentCard([...contentCard, contentCard[index] = ref])}}/>
                                    }
                                ) : ''}

                        </ul>
                    </div>
                </div>
                <Pagination totalCount={totalCount} page={page} defaultPageSize={pageSize}
                            pageSizeOptions={contentConstants.PAGE_SIZE_OPTIONS} onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange} divide={props.divide}/>
            </div>
        )
    };

    return (
        <div style={{width: '100%', display: (props.currContent === 'ALL_CONTENT' || props.currContent === 'MY_CONTENT' || props.currContent === 'SHARED_CONTENT' || props.currContent === 'BY_USER') ? 'block':'none'}}>
            <div className="contents_buttonWrap overflow_hidden">
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_EDIT"} name={t("TEXT_EDIT_P")} onClick={()=>editContent()} disable={checkEditButtonEnable() || selectedCnt < 1} authority={authority.CREATE || authority.MANAGE || authority.LOCK} />
                    <WhiteButton id={"CONTENT_DELETE"} name={t("COM_BUTTON_DELETE")} onClick={()=>deleteContentPopup("CONTENT_DELETE")} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE || authority.LOCK} />
                    <WhiteButton id={"CONTENT_DOWNLOAD"} name={t("BUTTON_DOWNLOAD_P")} onClick={()=>downloadContent()} disable={selectedCnt < 1 || downloading} authority={authority.CREATE || authority.MANAGE || authority.LOCK} />
                    <WhiteButton id={"CONTENT_ADD_TO_PLAYLIST"} name={t("MIS_TEXT_TITLE_ADD_CONTENT_TO_PLAYLIST_P")} onClick={()=>addToPlaylistPopup('CONTENT_ADD_TO_PLAYLIST')} disable={selectedCnt < 1 || checkAddToPlaylistButtonEnable()} authority={authority.CREATE || authority.MANAGE || authority.LOCK} />
                    {fetched !== 'SHARED_CONTENT' &&
                        <WhiteButton id={"CONTENT_MOVE"} name={t("BUTTON_MOVE_P")} onClick={()=>moveContentPopup('CONTENT_MOVE')} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE || authority.LOCK} />
                    }
                    {fetched === 'MY_CONTENT' &&
                        <WhiteButton id={"CONTENT_SHARE"} name={t("COM_SID_SHARE")} onClick={()=>shareContentPopup('CONTENT_SHARE')} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE || authority.LOCK}/>
                    }
                    {fetched === 'SHARED_CONTENT' &&
                        <WhiteButton id={"CONTENT_UNSHARE"} name={t("MIS_SID_UNSHARE")} onClick={()=>unshareContent()} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE || authority.LOCK}/>
                    }
                    <WhiteButton id={"CONTENT_MULTIPLE"} name={t("MIS_SID_20_EDIT_MULTIPLE")} onClick={()=>editMultipleContentPopup('CONTENT_MULTIPLE')} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE || authority.LOCK}/>
                    <WhiteButton id={"CONTENT_SAVE_AS_TEMPLATE"} name={t("MIS_SID_SERVER_SAVE_AS_TEMPLATE")} onClick={()=>saveAsTemplatePopup('CONTENT_SAVE_AS_TEMPLATE')}disable={checkSaveAsTemplateButtonEnable() || selectedCnt < 1} authority={authority.CREATE || authority.MANAGE || authority.LOCK || authority.READ} />
                    <WhiteButton id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={()=>onClickExport()} authority={authority.CREATE || authority.MANAGE || authority.LOCK || authority.READ} />
                </div>
                <div className="rightButton">
                    <CategoryButton onCategoryCheck={onCategoryCheck} defaultCheckedKeys = {categoryIds}/>
                    <span style={{float: 'left', marginRight: '8px'}}>
                        <Filter type={"CONTENT"} onSaveFilter={onSaveFilter} onRef={ref => (filterComp.current = ref)} cachedFilter={filter.customFilter}/>
                    </span>
                    <span style={{float: 'left', marginRight: '8px'}}>
                        <SwitchListNImage view={view} onClick={()=>updateSwitchListNImage()}/>
                    </span>
                    <SearchBar
                        id="contentSearch"
                        placeholder={t("TEXT_CONTENT_NAME_P")}
                        enableDetail={true}
                        onClickSearch={onKeywordChange}
                        onClickDetailSearch={onClickDetailSearch}
                        searchDetails={[
                            {title: 'creator', type: 'input'},
                            {title: 'date', type: 'date'},
                        ]}
                        keyword={filter.keyword}
                    />
                </div>
            </div>
            {showNoData ? renderNodata() : view === 'LIST' ? renderListView() : renderImageView() }
            <form ref={webAuthorForm} action={webAuthorInfo.webAuthorPath} target='WebAuthorWindow' method='post'>
                <input type="hidden" name="username" value={webAuthorInfo.userId} />
                <input type="hidden" name="contentId" value={webAuthorInfo.contentId} />
                <input type="hidden" name="token" value={webAuthorInfo.token} />
                <input type="hidden" name="language" value={webAuthorInfo.language} />
            </form>
        </div>
    );
};

export default connect(
    state => ({

    }),
    dispatch => ({
        openDetailView : (type)=> dispatch(popupAction.openDetailView(type)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        reloadContent: (reload) => dispatch(contentAction.reloadContent(reload)),
        contentDownloading: (downloading) => dispatch(contentAction.contentDownloading(downloading)),
        setMyContentGroupId : (myContentGroupId) => dispatch(contentAction.setMyContentGroupId(myContentGroupId)),
        reloadGroup: (submenuId, groupId) => dispatch(menuAction.reloadGroup(submenuId, groupId)),
        updateSubMenuCounter: (submenuId) => dispatch(menuAction.updateSubMenuCounter(submenuId)),
    })
)(AllContent);
