import React from 'react';
import SupportedDeviceIcon from "../../icon/SupportedDeviceIcon";
import {connect} from "react-redux";
import {popupAction} from "../../../actions";
import {withTranslation} from "react-i18next";
import {commonConstants} from "../../../constants";
import {isNil} from "lodash";
import Moment from "react-moment";
import TextInput from '../../input/TextInput';
import RadioGroup from "../../radio/RadioGroup";
import {MISOptContext} from "../../../components/misopt";
import {toMomentDateTimeFormat} from '../../../helper';
import './PlaylistDetailTab.css';
import { playlistService } from 'services';
import { toastr } from 'helper/toastrIntercept';

const PrivateOtion = [
    {value:"0", title:'BUTTON_YES_P'},
    {value:"1", title:'BUTTON_NO_P'},
];

class PlaylistDetailTab extends React.Component {

    static contextType = MISOptContext;

    constructor(props) {
        super(props);

        this.state = {
            playlist: props.playlist,
            errorMessage : ""
        };
    }

    onChangeDetail = () => {
        if(this.props.onChangeDetail){
            this.props.onChangeDetail(this.state.playlist)
        }
    }

    handleChange = (e) => {
        const {playlist} = this.state;
        const {name, value} = e.target;

        this.setState({playlist: {...playlist, [name]: value}}, () => {
                this.onChangeDetail();
            }
        );
    }

    onPrivateClick = (value) => {
        this.handleChange({target: {name: 'shareFlag', value: value}});
    };

    onClickGroup = () =>{
        const {addPopup, closePopup, t} = this.props;
        const {playlist : {playlistId}} = this.state;
        const obj = {playlistIds: [playlistId]};
        
        playlistService.movePlaylistCheck(obj).then(res => {
            addPopup({
                type: commonConstants.COMMON_DETAIL_VIEW,
                viewType: 'PLAYLIST_GROUP_POPUP',
                id: 'PLAYLIST_GROUP_POPUP'+ playlistId,
                title: t("MIS_SID_SELECT_FOLDER"),
                onSave: this.handleGroup,
                onClose: () => closePopup('PLAYLIST_GROUP_POPUP'+ playlistId)
            });
    
        }).catch(error => {
            toastr.error(t('MIS_MESSAGE_CONTENT_CANNOT_EDIT_OTHERS_PLAYLIST_GROUP_P'))
        }).finally(() => {
        });
    };

    handleGroup = (param) => {
        if(!param) return null;
        const {closePopup} = this.props;
        const groupId = param[0].groupId;
        const groupName = param[0].groupName;

        const {playlist} = this.state;
        closePopup('PLAYLIST_GROUP_POPUP'+ playlist.playlistId);

        if(isNil(groupId)){
            return null;
        };

        this.setState({playlist: {...playlist,  groupId , groupName }}, this.onChangeDetail );
    };

    onChangeTextInput = (e) => {
        this.validateTextInput(e.target.value);
        this.handleChange({target: {name: 'playlistName', value: e.target.value}});
    }

    validateTextInput(str){
        const {t} = this.props;

        if(!str.trim()){
            this.setState({errorMessage : t("MESSAGE_CONTENT_ENTER_PLAYLIST_NAME_P")})
        }
        else if(str.length > 60){
            this.setState({errorMessage : t("MESSAGE_CONTENT_PLAYLIST_NAME_LENGTH_P")})
        } else {
            this.setState({errorMessage : ""})
        }
    }

    tagPlaylistDisplay1(){
        const {t} = this.props;
        const {playlist} = this.state;
        if(playlist.playlistType === "5"){
            const ignoreTag = playlist.ignoreTag === 1 ? t("TEXT_ON_P") : t("BUTTON_OFF") ;
            const evennessPlayback = playlist.evennessPlayback === 1 ? t("TEXT_ON_P") : t("BUTTON_OFF") ;
            return (<>
                    <tr className="bullet_line">
                    <th><span>{t("MIS_SID_SERVER_IGNORE_TAG")}</span></th>
                    <td>{ignoreTag}</td></tr>
                    <tr className="bullet_line">
                    <th><span>{t("MIS_SID_SERVER_PLAY_EVENTLY")}</span>
                    </th><td>{evennessPlayback}</td></tr></>)
        }
    }

    tagPlaylistDisplay2(){
        const {t} = this.props;
        const {playlist} = this.state;

        if(playlist.playlistType === "5"){

            const tags = playlist.tags;

            return (<>
                <tr className="bullet_line">
                    <th><span>{t("MIS_TEXT_TAG_P")}</span></th>
                    <td>
                        <div style={{height:'40px',border: '1px solid #e7e7e7', borderRadius: '3px', padding: '10px', overflowY:'auto'}}>
                        {
                            tags.map( el => {
                                return < div style={{height:'auto',width:'100%', overflow:'hidden', lineHeight: el.name.length > 46 ? undefined : '25px'}}><span style={{overflow:'hidden'}}>{el.name}</span></div>
                            })
                        }
                    </div>

                    </td></tr>
            </>)


        }
    }

    render() {
        const {t} = this.props;
        const {playlist, errorMessage} = this.state;

        const {user: {dateFormat, timeFormat}}  = this.context;
        const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

        return (
            <div className="detail_view mini_1">
                <div style={{overflowX: 'hidden'}}>
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr className="bullet_line">
                            <th>
                                <span>{t("TABLE_PLAYLIST_NAME_P")}</span>
                            </th>
                            <td>
                                <TextInput
                                    width={265}
                                    propertyName='playlistName'
                                    errorWidth={530}
                                    value={playlist.playlistName || ''}
                                    error={errorMessage}
                                    onChange={this.onChangeTextInput}
                                    maxLength={60} />
                            </td>
                        </tr>
                        <tr className="bullet_line">
                            <th>
                                <span>{t("COM_MAPP_SID_SUPPORTED_DEVICES")}</span>
                            </th>
                            <td>
                                <SupportedDeviceIcon deviceType={playlist.deviceType}
                                                     deviceTypeVersion={playlist.deviceTypeVersion}/>
                            </td>
                        </tr>
                        {
                            this.tagPlaylistDisplay1()
                        }
                        <tr className="bullet_line">
                            <th>
                                <span>{t("COM_TEXT_MODIFY_DATE_P")}</span>
                            </th>
                            <td>
                                <Moment format={dateTimeFormat}>{playlist.lastModifiedDate}</Moment>
                            </td>
                        </tr>
                        {
                            this.tagPlaylistDisplay2()
                        }
                        <tr className="bullet_line">
                            <th>
                                <span>{t("TEXT_FOLDER_P")}</span>
                            </th>
                            <td>
                                <div className="menu_select_box">
                                    <input type="hidden" id="playlistGroupId"/>
                                    <button className="base un_radius select" style={{width: 265}}  id="playlistGroupBtn" onClick={this.onClickGroup}>
                                        <span className="playlist_detail_title_nowrap" style={{width: 260}}>{playlist.groupName}</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr className="bullet_line">
                            <th>
                                <span>{t("COM_SID_PRIVATE")}</span>
                            </th>
                            <td className='radio_group_wrap'>
                                <RadioGroup
                                    propertyName="playEvenly"
                                    selects={PrivateOtion}
                                    value={playlist.shareFlag}
                                    onChange={(e) => this.onPrivateClick(e.target.getAttribute('data-value'))}
                                />
                            </td>

                        </tr>
                        <tr className="bullet_line">
                            <th>
                                <span>{t("TEXT_DESCRIPTION_P")}</span>
                            </th>
                            <td>
                                <textarea id="playlistDescription" name="metaData" value={playlist.metaData} onChange={this.handleChange}
                                          maxLength="200" style={{width:'92%',minHeight:100}} ></textarea>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };
}

export default connect(
    state => ({

    }),
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
    })
)(withTranslation()(PlaylistDetailTab));