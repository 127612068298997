import React, {useEffect, useState} from 'react';
import SubMenuContainer from "./SubMenuContainer";
import './ContentContainer.css';
import {useDispatch, useSelector} from "react-redux";
import {menuAction, popupAction} from "../actions";
import Container from './Container';
import WScroll from "../components/utils/WScroll";
import {usePrevious} from "../helper";
import {getCache, updateCache, isCacheTable} from "../helper/cache/tableCache";

const ContentsContainer = () => {

    const dispatch = useDispatch();

    const {menu, common} = useSelector(state=> ({
        menu : state.menu,
        common : state.common
    }))

    const [style, setStyle] = useState({
        height: 900,
        width: 1000,
        position: 'absolute',
        overflow: 'hidden'
    })

    const [reload, setReload] = useState(false);

    const [node, setNode] = useState({})

    const _updateDimensions = () => {
        let height = window.innerHeight - 75;
        let width = window.innerWidth - 95;
        setStyle({
            ...style,
            height: height,
            width: width
        })
    }

    const handleGlobalKeydown = (e) => {
        const {activePopupId} = common;
        if ( e.keyCode == 27) {
            dispatch(popupAction.closePopup(activePopupId))
        }
    }

    useEffect((e)=> {
        document.addEventListener('keydown', handleGlobalKeydown);
        return () => {
            document.removeEventListener('keydown', handleGlobalKeydown);
        }
    },[common.activePopupId])

    useEffect(()=> {
        _updateDimensions();
        window.addEventListener('resize', _updateDimensions);
        return () => {
            window.removeEventListener('resize', _updateDimensions);
        }
    }, [])

    const preGroupId = usePrevious(menu.groupId !== undefined && menu.groupId || undefined);
    const preUserId = usePrevious(menu.userId !== undefined && menu.userId || undefined);
    const preOrganizationId = usePrevious(menu.organizationId !== undefined && menu.organizationId || undefined);
    const preForceReload = usePrevious(menu.forceReload);

    useEffect(()=> {
        const updateNode = (groupId, userId, organizationId) => {
            const nodeArray = [];
            const loop = (nodes) => {
                return nodes.find((node) => {
                    if (node.children != null && node.children.length > 0) {
                        return loop(node.children);
                    }
                    if (node.id === currentContentId) {
                        if( (node.hasGroup == true && groupId == undefined && userId == undefined && organizationId == undefined) ) {
                            //No need to load container just load each left group(ex. MyContent, SharedConent)
                        }else {
                            nodeArray.push(node);
                            return node;
                        }
                    }
                });
            };
            if (submenu != null && submenu.nodes != null) {
                loop(submenu.nodes);
            }

            const updateNode = nodeArray.length > 0 && nodeArray[0];

            if ( typeof userId !== 'undefined' && updateNode !== null && updateNode !== false ) {
                updateNode.userId = userId;
            }
            if ( typeof organizationId !== 'undefined' && organizationId !== '' && updateNode !== null && updateNode !== false ) {
                updateNode.organizationId = organizationId;
            }
            if (updateNode !== null && updateNode !== false) {
                if (groupId === undefined) {
                    updateNode.groupId = '';
                } else {
                    updateNode.groupId = groupId;
                }
            }
            setNode(updateNode);
        }
        const {groupId, userId, organizationId} = menu;
        if (preGroupId !== groupId ||
            preUserId !== userId ||
            preOrganizationId !== organizationId) {
                updateNode(groupId, userId, organizationId);
        }

    }, [menu.groupId, menu.userId, menu.organizationId])

    useEffect(()=> {
        if (preForceReload !== menu.forceReload) {
            dispatch(menuAction.forceReloadContent())
        }
    }, [menu.forceReload])

    /*
    useEffect(()=> {
        const {id} = submenu;
        if(currentContentId !== "LED_CABINET"){
            updateCache(id, {}, currentContentId);
        } 
    }, [menu.currentContentId]);*/

    const {currentContentId, submenu, submenuWidth} = menu;
    const paddingLeft = submenu != null && submenu.hasSubmenu && submenu.showSubmenu ? submenuWidth : 0;

    const getTableCache = ()=> {
        const {id} = submenu;
        if (isCacheTable(id)) {
            const cacheData = getCache(id)
            return cacheData;
        }
        return undefined;
    }

    return(
        <div className="contentsBox" style={style}>

            <SubMenuContainer id={submenu.id} content={currentContentId} cache={()=>getTableCache()}/>
            {
                submenu.showSubmenu && submenu.hasSubmenu &&
                <WScroll left={paddingLeft}/>
            }
            <Container className={'noSelect'} content={currentContentId} style={{paddingLeft: paddingLeft}} {...node} groupId={menu.groupId} userId={menu.userId} reloadKey={menu.reloadKey} reload={reload} cache={()=>getTableCache()} submenuWidth={submenuWidth} submenu={submenu}/>

        </div>
    )

}
export default ContentsContainer;