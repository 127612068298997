import React, {useEffect, useState} from 'react';
import {scheduleService} from "../../../../services";
import {useDispatch} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../../actions";
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import ScheduleViewCalendar from "../ScheduleViewCalendar";
import MessageScheduleDetail from './MessageScheduleDetail';
import {getErrorMessage} from "../../../../helper/responseHandler";
import {commonConstants} from "../../../../constants";
import {toastr} from 'helper/toastrIntercept';
import {useMISOpt} from "../../../misopt";
import {getCache, updateCache} from "../../../../helper/cache/tableCache";

const MessageScheduleDetailView = ({id,zIndex}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(undefined);
    const [authority, setAuthority] = useState({});
    const {misOptionSchedule, getAuthority} = useMISOpt();


    useEffect(()=> {
        setAuthority(getAuthority('SCHEDULE'));
        scheduleService.fetchMessageScheduleById(id).then(
            res=> setMessage(res.items)
        ).catch(e=> toastr.error(getErrorMessage(e)))
    }, [])

    const closePopup = (id) => {
        dispatch(popupAction.closePopup(id));
    }

    const updateMessage = (updatedMessage) => {
        setMessage(updatedMessage);
    }

    const editMessageSchedule = () => {
        scheduleService.fetchMessageScheduleById(id).then(
            res=> {
                if (res.items) {
                    const tab = {id: 'EDIT_MESSAGE', title: t("MIS_SID_20_EDIT_MESSAGE"), active: true, close: true};
                    new Promise(()=> {
                        dispatch(scheduleAction.initEditMessage(res.items));
                    }).then(
                        dispatch(menuAction.addTab(tab))
                    ).then(
                        dispatch(menuAction.loadContent('EDIT_MESSAGE'))
                    )
                }
            }
        ).catch(
            e=> getErrorMessage(e)
        ).finally(()=> closePopup(id))
    }

    const showDeviceGroup = () => {
        const {deviceType, deviceTypeVersion} = message;
        const priority = misOptionSchedule(deviceType, Math.floor(deviceTypeVersion)).priority;
        const deviceGroupIds = message.deviceGroups && message.deviceGroups.map(group=>group.groupId);
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_GROUP_POPUP,
            id: commonConstants.COMMON_GROUP_POPUP,
            mode: 'device',
            checkbox: true,
            priority: priority,
            checkedKeys: deviceGroupIds,
            allowEmptySave: true,
            save: (groups)=>handleDevicesGroup(groups),
            close: ()=>closePopup(commonConstants.COMMON_GROUP_POPUP)
        }));
    }

    const handleDevicesGroup = (groups) => {
        if (groups === undefined) {
            groups = [];
        }

        const deviceGroups = [];
        groups.map(
            group => deviceGroups.push({groupId: group.groupId, groupName: group.groupNameText !== undefined ? group.groupNameText : group.groupName})
        )
        const updatedMessage = Object.assign({}, message);
        updatedMessage.deviceGroups = deviceGroups;
        scheduleService.updateMessageSchedule(updatedMessage, updatedMessage.messageId)
            .then(
                res=> {
                    setMessage(updatedMessage);
                    toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
                }
            ).catch(e=> getErrorMessage(e))
            .finally(()=> closePopup(commonConstants.COMMON_GROUP_POPUP))

    }

    const saveMessageSchedule = () => {
        const {messageId, messageName} = message;
        if(messageName == null || messageName == ''){
            toastr.error(t("MESSAGE_SCHEDULE_ENTER_SCH_NAME_P"));
            return;
        }
        if(messageName.trim() === ''){                                  
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return;
        }
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}))
        scheduleService.updateMessageScheduleNameGroup(messageId, message)
            .then(
                res=> {
                    if (res) {
                        toastr.success(t("TEXT_SUCCESS_P"));
                        closePopup(messageId);
                    }
                }
            )
            .catch(
                e=> toastr.error(getErrorMessage(e))
            )
            .finally(
                ()=> {
                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                    
                    const cache = getCache('SCHEDULE');
                    updateCache('SCHEDULE', {...cache, isLoaded: false});
                    dispatch(menuAction.forceReloadContent());
                }
            )
    }


    const dialogProps = {title: message !== undefined ? message.messageName : '',isDraggable: true, closeOnEscape : true, allowMinimize : true, width :800, height :500, position: {x: -300, y: -400}, zIndex: zIndex, popupId:id, onClose: () => closePopup(id)};

    const buttons = {
        rightButtons: [
            {id: 'MAPPING_DEVICES_MESSAGE_SCHEDULE', title: t("TEXT_DEVICE_MAPPING_P"), onClick: ()=> showDeviceGroup(), authority: authority.CREATE || authority.MANAGE},
            {id: 'EDIT_MESSAGE_SCHEDULE', title: t("MIS_SID_20_EDIT_MESSAGE"), onClick: ()=> editMessageSchedule(), authority: authority.CREATE || authority.MANAGE},
            {
                id: 'SAVE_MESSAGE_SCHEDULE',
                title: t("COM_BUTTON_SAVE"),
                onClick: () => saveMessageSchedule()
            },
            {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: () => closePopup(message.messageId)}
        ]
    }

    return (

        message !== undefined &&
            <MISDialog
                dialog={dialogProps}
                buttons={buttons}
                height={400}
            >
                <div className="popup_contents clearfix">
                    <div className="playlist_imgview mt25" style={{width:370}}>
                        <ScheduleViewCalendar type={"MESSAGE"} program={message}/>
                    </div>
                    <div className="playlist_detail ml25 mb5" style={{width:'45%'}}>
                        <MessageScheduleDetail message={message} updateMessage={updateMessage}/>
                    </div>
                </div>
            </MISDialog>
    )



}

export default MessageScheduleDetailView;