import {configureRefreshFetch, fetchJSON} from 'refresh-fetch'
import jwtDecode from "jwt-decode";
import {toastr} from 'helper/toastrIntercept';
import {isString, merge} from 'lodash';
import {history} from '../helper/history';
import i18n from "../language/i18n";
import {userActions} from "../actions";
import store from '../store'


const saveToken = user => {
    const jwt = jwtDecode(user.token);
    const updateUser = {
        ...user,
        id: user.userId,
        authority: JSON.parse(jwt.viewAuthority),
        refreshToken: user.refreshToken,
        expired: jwt.expired
    };
    localStorage.setItem('user', JSON.stringify(updateUser));
    return updateUser;
}

const isExpired = (token) => {
    const decode = jwtDecode(token);
    if (decode) {
        const {expired} = decode;
        const now = new Date();
        return expired < now.getTime()
    }
    return true;
}

const retrieveToken = () => {
    try {
        let user = JSON.parse(localStorage.getItem('user'));
        return user.token;
    } catch(e) {
        console.log('retrieveToken',e);
    }
    return null;
}

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

const exclusives = {}
let cancelFetchData;
const fetchJSONWithToken = (url, options = {}) => {
    const token = retrieveToken();
    let optionsWithToken = options;
    if (token !== null) {
        optionsWithToken = merge({}, options, {
            headers: {
                api_key: token
            }
        })
    }

    const handleEx = e => {
        if (e) {
            if (e.status === 401 && e.body && e.body.errorCode === '401009') {
                toastr.error(i18n.t("MESSAGE_COMMON_FINISH_SESSION_P"));
                logout();
                history.push('/login');
            }
            throw e.body
        }
    }
    const pr = fetchJSON(url, optionsWithToken);
    if(options.exclusive){
        if (options.currentTab) {
            exclusives[options.currentTab] && exclusives[options.currentTab]({body: {errorMessage: 'CANCELED', errorCode: 408900}})
            exclusives[options.currentTab] = undefined
        }
        let p = new Promise((resolve, reject) => exclusives[options.currentTab] = reject);
        return Promise.race([p, pr]).catch(handleEx);
    } else {
        return pr.catch(handleEx);
    }
}

const loginWithCode = (code) => {
    let data = JSON.stringify({
        code: code,
    })

    return fetchJSON(`${backendHost}/restapi/v2.0/auth/code`, {
        method: 'POST',
        body: data
    }).then(response => {
        saveToken(response.body)
    }).catch(
        (e)=> {
            // proxy error (not response from backend)
            if (e.status === 500 && e.body !== undefined && isString(e.body) && e.body.startsWith('Proxy error:')) {
                toastr.error('No response from server')
            }
            throw e;
        }
    )
}

const login = (username, password) => {
    let data = JSON.stringify({
        password: password,
        username: username
    })

    return fetchJSON(`${backendHost}/restapi/v2.0/auth`, {
        method: 'POST',
        body: data
    }).then(response => {
        saveToken(response.body)
    }).catch(
        (e)=> {
            // proxy error (not response from backend)
            if (e.status === 500 && e.body !== undefined && isString(e.body) && e.body.startsWith('Proxy error:')) {
                toastr.error('No response from server')
            }
            throw e;
        }
    )
}

const logout = () => {
    //userActions.logout();
    store.dispatch(userActions.logout());
    //localStorage.removeItem('user');
}

const shouldRefreshToken = response =>{
    if (response === undefined) {
        return false;
    }
    if (response.errorCode === '401003') {
        return true;
    }
    return false;
}


const refreshToken = () => {
    const user = localStorage.getItem('user');
    const parse = JSON.parse(user);

    const body = JSON.stringify({
        token: parse.token,
        refreshToken: parse.refreshToken,
        grantType: 'refresh_token'
    });
    return fetchJSONWithToken(`${backendHost}/restapi/v2.0/auth`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: body
    })
        .then(response => {
            saveToken(response.body);
            return response;
        })
        .catch(error => {
            logout();
            history.push('/login');
            //throw error;
        })
}

const fetchForDownload  = (url, options) => {
    const token = retrieveToken();
    let optionsWithToken = options;
    if (token !== null) {
        optionsWithToken = merge({}, options, {
            headers: {
                api_key: token
            }
        })
    }
    return fetch(url, optionsWithToken);
}

const fetchWithoutToken = (url, option) => {
    return fetch(url, option);
}

const fetchWithToken = configureRefreshFetch({
    fetch: fetchJSONWithToken,
    shouldRefreshToken,
    refreshToken
})

export {
    fetchWithoutToken,
    fetchWithToken,
    fetchForDownload,
    login,
    logout,
    refreshToken,
    loginWithCode
}