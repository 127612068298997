import React, {useEffect, useRef, useState} from 'react';
import ReactTable from "react-table";
import {Trans, useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import {userService} from "../../../services";
import './UserPopup.css';
import {userConstants} from "../../../constants";
import {toastr} from 'helper/toastrIntercept';

const ChangeRolePopup = ({organizationId, onClickYes, onClose, closePopup}) => {
    const {t} = useTranslation();
    const timer = useRef(null);

    useEffect(() => {
        return () => {
            if(timer.current) {
                closePopup('EDIT_DEVICE_LOADING');
                clearInterval(timer.current);
            }
        };
    }, []);

    const [data, setData] = useState({
        loading: false,
        orgList: []
    });

    const [filter, setFilter] = useState({
        selected: -1
    });

    const [style, setStyle] = useState({
        height: '325px'
    });

    useEffect(() => {
        userService.fetchUserAssignableRoles()
            .then(values => {
                const roleListForAll = values.items.map(org => {
                    return {value: org.roleId, title: org.roleName, organizationId: org.organizationId};
                });

                const roleList = roleListForAll.filter(role => {
                    return role.organizationId === userConstants.ORGAN_ID_ADMINISTRATOR || role.organizationId === organizationId;
                });

                setData({...data, items: roleList})
            })
    }, [filter]);

    const onClickOk = () => {
        if (filter.selected === -1) {
            toastr.error(t('MESSAGE_USER_SEL_ROLE_P'));
            return;
        }

        onClickYes(data.items[filter.selected].value);
    };

    return (
        <MISDialog
            dialog={{
                title: t('TABLE_CHANGE_ROLE_P'),
                closeOnEscape: true,
                modal: true,
                width: 260,
                height: 330,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'DEVICE_SAVE',
                        title: t('BUTTON_OK_P'),
                        onClick: onClickOk,
                        disable: false,
                    },
                    {
                        id: 'DEVICE_CANCEL',
                        title: t('COM_BUTTON_CLOSE_P'),
                        onClick: onClose,
                    }
                ]
            }}>

            <div style={{height: '325px', width: '250px', borderLeftStyle:'solid', borderLeftColor:'#e7e7e7', borderLeftWidth: '1px'}} className={'change_organization_wrap'}>
                <ReactTable
                    data={data.items}
                    minRows={0}
                    loading={data.loading}
                    columns={
                        [
                            {
                                Header: <Trans i18nKey={"TABLE_ROLE_NAME_P"}/>,
                                accessor: "title",
                                width: 248,
                                sortable: false,
                                Cell: row => <div><span title={row.value}>{row.value}</span></div>
                            }
                        ]
                    }
                    resizable={false}
                    className="-striped -highlight"
                    manual
                    showPagination={false}
                    style={style}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: (e) => {
                                    setFilter({
                                        selected: rowInfo.index
                                    })
                                },
                                style: {
                                    background: rowInfo.index === filter.selected ? '#e6f2ff' : 'white'
                                }
                            }
                        }else{
                            return {}
                        }
                    }
                    }
                />
            </div>

        </MISDialog>
    );
};

ChangeRolePopup.defaultProps = {
    onSave: () => {},
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(ChangeRolePopup);