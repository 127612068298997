import React, {useState} from 'react';
import MISDialog from "../MISDialog";
import {useTranslation} from "react-i18next";
import './ResetPopup.css';
import TextInput from "../../input/TextInput";
import WhiteButton from "../../button/WhiteButton";
import {checkemail} from "../../../helper";
import {userService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../../helper/responseHandler";
import {PasswordResetPopup} from '../user';
import {getMixString} from "../../../language/languageUtils";

const ResetPopup = ({closePopup}, props) => {

    const {t} = useTranslation();

    const dialogProps ={title: t("TEXT_RESET_PASSWORD_P"), closeOnEscape : true, modal:true, onClose:()=> closePopup()};

    const [data, setData] = useState({
        userId: '',
        userEmail: '',
        code: ''
    });
    const [error, setError] = useState({
        userId: '',
        userEmail: '',
        code: ''
    });

    const [code, setCode] = useState({
        send: false,
        expired: false,
        intervalId: null
    })

    const [countdown, setCountdown] = useState(180);

    const [step, setStep] = useState(1);

    const handelInput = (e) => {
        const name = e.target.getAttribute("data-name");
        const value = e.target.value;
        if (name === 'userEmail') {
            if (value === '') {
                setError({...error, [name]: 'MIS_SID_ENTER_DATA'})
            } else if (!checkemail(value)) {
                setError({...error, [name]: 'COM_MESSAGE_USER_EMAIL_INVALID_P'})
            } else {
                setError({...error, [name]: ''})
            }
        } else {
            if (value === '') {
                setError({...error, [name]: 'MIS_SID_ENTER_DATA'})
            } else {
                setError({...error, [name]: ''})
            }
        }

        setData({...data, [name]: value});
    }

    const nextStep = () => {

        const doValid = () => {
            if(data.userId == ''){
                setError({...error, userId: 'MIS_SID_ENTER_DATA'});
                return false;
            }
            if(data.userEmail === '') {
                setError({...error, userEmail: 'MIS_SID_ENTER_DATA'})
                return false;
            } else if (!checkemail(data.userEmail)) {
                setError({...error, userEmail: 'COM_MESSAGE_USER_EMAIL_INVALID_P'})
                return false;
            }
            if (data.code === '') {
                setError({...error, code: 'MIS_SID_SERVER_ENTER_AUTHENTICATION_CODE'});
                return false;
            } else {
                setError({...error, code : ''});
            }
            return true;
        }

        if (doValid()) {
            userService.checkResetPasswordValid(data.code, data.userId)
                .then(res =>{
                    setStep(2);
                }).catch(
                    e => {
                        if(e.errorCode == 404001){
                            toastr.error(t("MIS_SID_SERVER_THE_AUTHENTICATION_CODE_IS_INCORRECT_PLEASE_TRY_AGAIN"), true);
                            return;
                        }
                        toastr.error(getErrorMessage(e), true);
                    }
                )
        }
    }

    const sendEmail = () => {
        const doValid = () => {
            let valid = true;
            Object.keys(data).map(
                key => {
                    console.log('key',key);
                    if (key === 'userId') {
                        if (data.userId === '') {
                            setError({...error, userId: 'MIS_SID_ENTER_DATA'})
                            valid = false;
                        } else {
                            setError({...error, userId: ''})
                        }
                    } else if (key === 'userEmail') {
                        if (data.userEmail === '') {
                            setError({...error, userEmail: 'MIS_SID_ENTER_DATA'})
                            valid = false;
                        } else if (!checkemail(data.userEmail)) {
                            setError({...error, userEmail: 'COM_MESSAGE_USER_EMAIL_INVALID_P'})
                            valid = false;
                        } else {
                            setError({...error, userEmail: ''})
                        }
                    }
                }
            )
            return valid;
        }
        clearInterval(code.intervalId);
        setCode({send: false, expired: false, intervalId: null});
        if (doValid()) {
            setError({...error, code: 'MESSAGE_DEVICE_WAIT_FOR_LOADING_P'});
            const locale = localStorage.getItem("i18nextLng") || 'en';
            userService.resetPassword(data.userId, data.userEmail, locale)
                .then(
                    res => {
                        setError({...error, code: 'COM_MESSAGE_COMMON_SEND_MAIL_P'});
                        startCountdown();
                    }
                ).catch(
                    e=> {
                        if(e.errorCode == '404001'){
                            toastr.error(getMixString(["COM_TV_SID_MIX_INVALID_VALUE_CHECK_AGAIN", t("COM_DID_ADMIN_USER_USERID") + " / " + t("COM_TV_SID_EMAIL")]), true);
                        }else{
                            toastr.error(getErrorMessage(e), true);
                        }
                        setError({...error, code: ''});
                    }
                )
        }
    }

    const startCountdown = () => {
        setCountdown(180);
        function callback() {
            let tmpcountdown;
            setCountdown(countdown => tmpcountdown = countdown - 1);
            
            if(tmpcountdown <= 0){
                clearInterval(intervalId);

                setCode({...code, send: false, expired: true, intervalId: null});
            }
        };
        let intervalId = setInterval(callback, 1000);
        setCode({...code, send: true, expired: false, intervalId: intervalId});
    }
    const resetPassword = (password, passwordConfirm) => {
        userService.changePassword({
            password: password, 
            passwordConfirm: passwordConfirm, 
            userId: data.userId, 
            encryptionToken:data.code
        }).then(res => {
            toastr.success(t('TEXT_SUCCESS_P'), true);
            closePopup('PASSWORD_RESET');
        }).catch(e => {
            toastr.error(getErrorMessage(e), true);
        });
    }

    const closeResetPopup = () => {
        setStep(0);
    }

    return (
        <>
        {
            step == 2 &&
            <PasswordResetPopup userId={data.userId} onChangePassword={resetPassword} force={false} onClose={closeResetPopup}/>
        }
        {
            step == 1 &&
            <MISDialog
                dialog={dialogProps}
                width={480}
                height={180}
                buttons={{
                    rightButtons: [
                        {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: ()=> closePopup()},
                        {id: 'NEXT_STEP', title: t("COM_BUTTON_NEXT"), onClick: ()=>nextStep()}
                    ]
                }}
            >
                <div className={"reset_popup"}>
                    <table style={{width:'100%'}}>
                        <tbody>
                        <tr>
                            <th style={{paddingRight:24}}>{t("COM_DID_ADMIN_USER_USERID")}</th>
                            <td>
                                <div>
                                    <TextInput placeholder={t("COM_DID_ADMIN_USER_USERID")} propertyName={"userId"} onChange={handelInput} value={data.userId !== undefined ? data.userId : ''} error={error.userId !== undefined && error.userId !== '' ? true : false}/>
                                </div>
                                <div className={"reset_password_error"}>
                                    {t(error.userId)}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th style={{paddingRight:24}}>{t("COM_TEXT_EMAIL_P")}</th>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <TextInput placeholder={t("COM_TEXT_EMAIL_P")} propertyName={"userEmail"} onChange={handelInput} value={data.userEmail !== undefined ? data.userEmail : ''} error={error.userEmail !== undefined && error.userEmail !== '' ? true: false}/>
                                    <div className="divider"></div>
                                    <WhiteButton id={"requestEmailCodeBtn"} name={t("BUTTON_SEND")} onClick={sendEmail}/>
                                </div>
                                <div className={"reset_password_error"}>
                                    {t(error.userEmail).replace('<br>', '\n')}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th style={{paddingRight:24}}></th>
                            <td>
                                <div className={"reset_password_error"}>
                                    {t(error.code)}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{paddingRight:24}}>{t("MIS_SID_SERVER_AUTHENTICATION_CODE")}</td>
                            <td>
                                <div style={{float: 'left'}}>
                                    <TextInput placeholder={t("MIS_SID_SERVER_ENTER_AUTHENTICATION_CODE")} id="userEmail" propertyName={"code"} onChange={handelInput} value={data.code}/>
                                </div>
                                {
                                    code.send && 
                                    <div style={{float: 'left'}}><span id="resetCountdown" style={{color:'red', lineHeight: '25px', paddingLeft: '10px'}}>{countdown}{t("COM_SID_SEC")}</span></div>
                                }
                            </td>
                        </tr>
                        {
                            code.expired && 
                            <tr>
                                <td></td>
                                <td>
                                    <div id="verificationExpireDiv" className={"reset_password_error"}>
                                        {t("MIS_SID_SERVER_THE_AUTHENTICATION_CODE_HAS_EXPIRED_PLEASE_TRY_AGAIN")}
                                    </div>
                                </td>
                            </tr>
                        }   
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        }
        </>
    )
}
export default ResetPopup;
