import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import moment from 'moment';
import Moment from 'moment';
import MISDialog from '../../MISDialog';
import {deviceService} from '../../../../services';
import DateInput from '../../../input/DateInput';
import TimeSelect from '../../../selectbox/TimeSelect';
import {useMISOpt} from '../../../misopt';
import {dateToString, toMomentDateTimeFormat} from '../../../../helper';
import {isNil} from 'lodash';

const EditCustomizeAppliedStatusPopup = ({rsvId, onSave, onClose}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const [software, setSoftware] = useState({});
    const onClickSave = () => {
        const {rsvDate, rsvTime} = software;

        if(isNil(rsvDate) || rsvDate === null) {
            toastr.error(t('MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P'));
            return;
        }

        if(isNil(rsvTime) || rsvTime === null) {
            toastr.error(t('MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P'));
            return;
        }

        const rsvDateTime = moment({
            year: rsvDate.getFullYear(), 
            month: rsvDate.getMonth(), 
            day: rsvDate.getDate(), 
            hour: rsvTime.hour(), 
            minute: rsvTime.minute(),
        });

        deviceService.updateSoftwareForDeploy(software.softwareId, rsvDateTime.format(dateTimeFormat)).then(res => {
            toastr.success(t('COM_BUTTON_SAVE') + ' - ' + t('COM_TEXT_SUCCESS_P'));
            if(onSave) {
                onSave();
            }
        }).catch(error => {
            toastr.error(t('COM_BUTTON_SAVE') + ' - ' + t('TEXT_FAIL_P'));
        });
    };

    useEffect(() => {
        deviceService.fetchSoftwareForDeploy(rsvId).then(res => {
            const sw = res.items;
            let rsvDateTime, rsvDate, rsvTime;
            if(sw.reservationDate) {
                rsvDateTime = moment(sw.reservationDate);
                rsvDate = rsvDateTime.toDate();
                rsvTime = rsvDateTime;
            }
            setSoftware(Object.assign({}, sw, {rsvDate, rsvTime}));
        }).catch(error => console.log(error));
    }, [rsvId]);

    return (
        <MISDialog 
            dialog={{
                title: t('COM_BUTTON_EDIT'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 790,
                height: 'auto',
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onClickSave,
                        disable: !software.rsvDate || software.type !== 1
                        // disable: !isEmpty(error)
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop'>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="240px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('MIS_TEXT_DEVICE_TYPE_P')}</th>
                                <td>
                                    {software.deviceType}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_DEVICE_MODEL_NAME_P')}</th>
                                <td>
                                    {software.deviceModelName}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_TYPE_P')}</th>
                                <td>
                                    {
                                        software.softwareType === '03' ? t('COM_PJT_IDS_SETUP_14') : t('COM_TEXT_DEFAULT_CONTENT_P')
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th>{t('LIST_TITLE_NAME')}</th>
                                <td>
                                    {software.softwareName}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TEXT_AD_FILE_NAME_P')}</th>
                                <td>
                                    {software.fileName}
                                </td>
                            </tr>
                            {
                                software.reservationDate &&
                                <tr>
                                    <th>{t('TEXT_DEPLOYMENT_RESERVATION_P')}</th>
                                    {
                                        software.type === 1 &&
                                        <td>
                                            <DateInput width={120} date={dateToString(software.rsvDate)}
                                                       onChange={date => setSoftware({...software, rsvDate: date})}/>
                                            <TimeSelect width={80} value={software.rsvTime} style={{marginLeft: '8px'}} onChange={val => setSoftware({...software, rsvTime: val})} />
                                        </td>
                                    }
                                    {
                                        software.type !== 1 &&
                                        <td>
                                            <span>{Moment(software.reservationDate).format(dateTimeFormat)}</span>
                                        </td>
                                    }
                                </tr>
                            }
                            <tr>
                                <th>{t('TEXT_SELECT_APPLICABLE_DEVICE_P')}</th>
                                <td>
                                    {
                                        software.groupNameList && software.groupNameList.length > 0 ?
                                        t('TEXT_BY_DEVICE_GROUP_P') : t('TEXT_BY_DEVICE_MODEL_P')
                                    }
                                </td>
                            </tr>
                            {
                                software.groupNameList && software.groupNameList.length > 0 &&
                                software.groupNameList.map((groupName, i) => (
                                    <tr key={i}>
                                        <th></th>
                                        <td>{groupName}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default EditCustomizeAppliedStatusPopup;