import React, { useState, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import './UserPopup.css';
import classNames from "classnames";
import {handleChange, checkPreValidation, checkPostValidation} from './common/userCommons';
import {isNil} from "lodash";

const useUserInfo = (initialInfo, t) => {
    const [userInfo, setUserInfo] = useState(initialInfo);

    const onChangeUserInfo = (e, value) => {
        const newInfo = handleChange(e, value, userInfo, t);
        setUserInfo(newInfo);
    };

    return [userInfo, setUserInfo, onChangeUserInfo];
};

const PasswordResetPopup = ({userId, isExpiredPassword, onChangePassword, onClose, force=true}) => {
    const {t} = useTranslation();
    const [userInfo, setUserInfo, onChangeUserInfo] = useUserInfo({
        dataField: {
            userId: userId,
            password: '',
            confirmPassword: ''
        },
        errors: {}
    }, t);

    useEffect(() => {
    }, []);

    const onClickSave = () => {
        if (checkPreValidation(userInfo, setUserInfo, t, false, true)) {
            return;
        }

        if (checkPostValidation(userInfo, t)) {
            return;
        }
        onChangePassword(userInfo.dataField.password, userInfo.dataField.confirmPassword);
    };

    const getButtons = () => {
        if(force){
            return [
                {
                    id: 'DEVICE_SAVE',
                    title: t('COM_BUTTON_SAVE'),
                    onClick: onClickSave,
                    disable: false,
                }
            ];
        } else {
            return [
                {
                    id: 'CLOSE_POPUP', 
                    title: t("BUTTON_CANCEL_P"), 
                    onClick: onClosePopup
                },
                {
                    id: 'DEVICE_SAVE',
                    title: t('COM_BUTTON_SAVE'),
                    onClick: onClickSave,
                    disable: false,
                }
            ];
        }
    }

    const onClosePopup = () => {
        onClose();
    }

    return (
        <MISDialog
            dialog={{
                title: t('TEXT_RESET_PASSWORD_P'),
                closeOnEscape: true,
                modal: true,
                width: 520,
                height: 145,
                hasCloseIcon: false
            }}
            buttons={{
                rightButtons: getButtons()
            }}>

            <div className='passwordResetPopup' style={{paddingLeft: '24px'}}>
                <table style={{width: '100%'}}>
                    {
                        force &&
                        <caption style={{paddingBottom: '10px'}}>{isExpiredPassword ? t('MIS_SID_SERVER_PASSWORD_EXPIRED_CHANGE_PASSWORD') : t('MIS_MESSAGE_USER_PASSWORD_CHANGE_REQUEST_P')}</caption>
                    }
                    <tbody>
                    <tr>
                        <th width="146px" nowrap="">{t('TEXT_NEW_PASSWORD_P')}</th>
                        <td>
                            <span className="text_input_wrapper">
                                <div style={{position:'relative'}}>
                                <input className={classNames({'error': !!userInfo.errors['password']})}
                                       type="password"
                                       data-name="password"
                                       maxLength="50"
                                       placeholder={t('TEXT_PASSWORD_P')}
                                       style={{width: '197px'}}
                                       value={!isNil(userInfo.dataField.password) ? userInfo.dataField.password : ''}
                                       onChange={onChangeUserInfo}/>

                                </div>
                                {
                                    userInfo.errors['password'] &&
                                    <span className="error autoline" style={{width: '320px'}}>{userInfo.errors['password']}</span>
                                }
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <th width="146px" nowrap="">{t('COM_TEXT_RE_PASSWORD_P')}</th>
                        <td>
                            <span className="text_input_wrapper">
                                <div style={{position:'relative'}}>
                                <input className = {classNames({'error': !!userInfo.errors['confirmPassword']})}
                                       type="password"
                                       data-name="confirmPassword"
                                       maxLength="50"
                                       placeholder={t('SETUP_NEW_STRING5_P')}
                                       style={{width: '197px'}}
                                       value={!isNil(userInfo.dataField.confirmPassword) ? userInfo.dataField.confirmPassword : ''}
                                       onChange={onChangeUserInfo}/>

                                </div>
                                {
                                    userInfo.errors['confirmPassword'] &&
                                    <span className="error autoline" style={{width: '320px'}}>{userInfo.errors['confirmPassword']}</span>
                                }
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </MISDialog>
    );
};

PasswordResetPopup.defaultProps = {
    onSave: () => {},
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(PasswordResetPopup);
