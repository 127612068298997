import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {popupAction} from "../../../actions";
import {commonConstants, userConstants} from "../../../constants";
import {userService} from '../../../services';
import Select from "../../selectbox/Select";
import {useDispatch} from "react-redux";
import BULLET_2 from "../../../images/icon/bullet_2.png";
import {updateRoleListServerAdmin} from './common/userCommons';


const ApproveUserDetailedInformation = ({
    userInfo,
    updateUserInfoPartially
}) => {
    const TEXT_INPUT_WIDTH = 290;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {errors} = userInfo || {};
    const organizationId = userInfo.dataField.organizationId;

    const [filter, setFilter] = useState({
        groupId: null,
        groupName: null,
        roleId: userConstants.ROLE_ID_CONTENT_MANAGER,
        organizationName: userInfo.dataField.organizationName,
        isGroupSelectionDisabled: false,
        isErrorTextForAdminDisplay: false
    });

    const [data, setData] = useState({
        roleList: []
    });

    const onSelectRoleChange = (e, selectedValue) => {
        let selectedItem = data.roleList.filter(item => item.value === selectedValue);
        updateUserInfoPartially({roleName: selectedItem[0].title, roleId: selectedValue});

        setFilter({...filter,
            roleId: selectedValue,
            isOrgGroupSelectionDisabled: selectedValue === userConstants.ROLE_ID_SERVER_ADMINISTRATOR,
            isErrorTextForAdminDisplay: selectedValue === userConstants.ROLE_ID_SERVER_ADMINISTRATOR
        });
    };

    const handleGroupSelection = (param) => {
        if(!param || !param[0]) {
            return null;
        }

        if (param[0].groupId === param[0].organizationId) {
            return null;
        }

        updateUserInfoPartially({groupId: param[0].groupId, groupName: param[0].groupName});
        setFilter({...filter, groupId: param[0].groupId, groupName: param[0].groupName});
        dispatch(popupAction.closePopup('ADD_USER_GROUP_SELECTION'));
    };

    const onGroupSelectionPopup = (e) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.ADD_USER_GROUP_SELECTION,
            type: commonConstants.ADD_USER_GROUP_SELECTION,
            onSave: handleGroupSelection,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.ADD_USER_GROUP_SELECTION)),
            organizationId: organizationId
        }));
    };

    useEffect(() => {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        Promise.all([
            userService.fetchUserAssignableRoles()
        ]).then(res => {
            const roleListForAll = res[0].items.map(org => {
                return {value: org.roleId, title: org.roleName, organizationId: org.organizationId};
            });

            const roleList = roleListForAll.filter(role => {
                return role.organizationId === userConstants.ORGAN_ID_ADMINISTRATOR || role.organizationId === organizationId;
            });

            updateRoleListServerAdmin(roleList, organizationId);

            let currentRoleId = roleList.filter(role => role.value === filter.roleId)[0].value;
            updateUserInfoPartially({roleId: currentRoleId});

            setFilter({
                ...filter,
                isErrorTextForAdminDisplay: currentRoleId === userConstants.ROLE_ID_SERVER_ADMINISTRATOR,
                isGroupSelectionDisabled: organizationId === userConstants.ORGAN_ID_ADMINISTRATOR,
                roleId : currentRoleId
            });
            setData({...data, roleList});
        }).catch(
            error => console.log(error)
        ).finally(
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
        );
    }, []);

    const {roleList} = data;

    return (
        <div className='user_pop_wrap'>
            <div className="user_detail_view">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <h4>
                                    {t('TEXT_DEFAULT_INFO_P')}
                                </h4>
                            </th>
                        </tr>
                        <tr>
                            <th>{t('COM_DID_ADMIN_USER_USERID')}
                                <img src={BULLET_2} />
                            </th>
                            <td style={{paddingLeft:'5px'}}>{userInfo.dataField.userId}</td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_USER_NAME_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td style={{paddingLeft:'5px'}}>{userInfo.dataField.userName}</td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_EMAIL_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td style={{paddingLeft:'5px'}}>{userInfo.dataField.email}</td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_MOBILE_NUM_P')}</th>
                            <td style={{paddingLeft:'5px'}}>{userInfo.dataField.mobileNum}</td>
                        </tr>
                        <tr>
                            <th>{t('COM_MIS_TEXT_PHONE_NUMBER_P')}</th>
                            <td style={{paddingLeft:'5px'}}>{userInfo.dataField.phoneNum}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="user_detail_view mt15 pt24 user_info t_line">
                <table>
                    <colgroup>
                        <col width="203px" />
                        <col width="" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <h4>{t('TEXT_ORGANIZATION_INFO_P')}</h4>
                            </th>
                        </tr>
                        <tr>
                            <th>{t('TABLE_ORGANIZATION_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td style={{paddingLeft:'5px'}}>{userInfo.dataField.organizationName}</td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_GROUP_P')}
                                <img src={BULLET_2} />
                            </th>
                            {
                                userInfo.dataField.groupName === t('TEXT_ROOT_GROUP_NAME_P') ?
                                    <td style={{paddingLeft:'5px'}}>{t('TEXT_ROOT_GROUP_NAME_P')}</td>
                                    :
                                    <td>
                                        <div className="menu_select_box">
                                            <button propertyName='groupId'
                                                    className={classNames('base', 'un_radius', 'button', {"error": errors['groupName'] !== undefined})}
                                                    value={filter.groupId}
                                                    onClick={onGroupSelectionPopup}
                                                    style={{overflow: "hidden"}}
                                                    disabled={filter.isGroupSelectionDisabled}>{filter.groupName === null ? t('TEXT_SEL_GROUP_P') : filter.groupName}
                                                <span className="arrow"
                                                      style={{display: 'inline-block', float: 'right'}}></span>
                                            </button>
                                            {
                                                errors['groupName'] !== undefined &&
                                                <div id="userAddPopupUserIdErrorDiv">
                                                    <span className="error_txt2"
                                                          id="userAddPopupUserIdErrorMsg">{t('TEXT_SEL_GROUP_P')}</span>
                                                </div>
                                            }
                                        </div>
                                    </td>
                            }
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_ROLE_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td>
                               <span>
                                 <Select propertyName='roleId' width={TEXT_INPUT_WIDTH} selects={roleList} value={filter.roleId} error={errors['roleId']} onChange={onSelectRoleChange} useEllipsis={true}/>
                              </span>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('DID_ADMIN_USER_TEAM')}</th>
                            <td style={{paddingLeft:'5px'}}>{userInfo.dataField.team}</td>
                        </tr>
                        <tr>
                            <th>{t('DID_ADMIN_USER_POSITION')}</th>
                            <td style={{paddingLeft:'5px'}}>{userInfo.dataField.jobPosition}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="user_detail_view t_line mt20"/>
            </div>
        </div>
    );
};

ApproveUserDetailedInformation.defaultProps = {
    generalInfo: {
        data: {},
        errors: {},
    },
    onChange: () => {},
};

export default ApproveUserDetailedInformation;