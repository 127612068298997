import React from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import TextInput from '../../input/TextInput';
import "./UserPopup.css"
import BULLET_2 from "../../../images/icon/bullet_2.png";

const AddOrganizationDetailedInformation = ({
    userInfo,
    onChange,
}) => {
    const TEXT_INPUT_WIDTH = 290;
    const {t} = useTranslation();
    const {errors} = userInfo || {};

    return (
        <div className='user_pop_wrap'>
            <div className="user_detail_view">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <h4>
                                    {t('TEXT_ORGANIZATION_INFO_P')}
                                </h4>
                            </th>
                        </tr>
                        <tr>
                            <th>{t('TABLE_ORGANIZATION_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='organizationName'
                                    errorWidth={530}
                                    error={errors['organizationName']}
                                    value={userInfo.dataField.organizationName}
                                    onChange={onChange}
                                    placeholder={t('TEXT_ORGANIZATION_P')}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_GROUP_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td>
                                <button className={classNames('base', 'un_radius', 'button')} disabled='true'>{t('ADMIN_SOFTWARE_RESERVATION_CONTENTS_DEFAULT')}</button>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_ROLE_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td>
                                <button className={classNames('base', 'un_radius', 'button')} disabled='true'>{'Administrator'}</button>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('DID_ADMIN_USER_TEAM')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='team'
                                    value={userInfo.dataField.team}
                                    onChange={onChange}
                                    placeholder={t('DID_ADMIN_USER_TEAM')}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('DID_ADMIN_USER_POSITION')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='jobPosition'
                                    onChange={onChange}
                                    value={userInfo.dataField.jobPosition}
                                    placeholder={t('DID_ADMIN_USER_POSITION')}
                                    maxLength={50} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="user_detail_view mt15 pt24 mb15 user_info t_line">
                <table>
                    <colgroup>
                        <col width="203px" />
                        <col width="" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <h4>{t('TEXT_DEFAULT_INFO_P')}
                                    <span>{t('MESSAGE_COMMON_JOIN_NOTICE_P')}</span>
                                </h4>
                            </th>
                        </tr>
                        <tr>
                            <th>{t('COM_DID_ADMIN_USER_USERID')}<img src={BULLET_2} /></th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='userId'
                                    errorWidth={500}
                                    error={errors['userId']}
                                    value={userInfo.dataField.userId}
                                    onChange={onChange}
                                    placeholder={t('COM_DID_ADMIN_USER_USERID')}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_PASSWORD_P')}<img src={BULLET_2} /></th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    type={"password"}
                                    propertyName='password'
                                    value={userInfo.dataField.password}
                                    errorWidth={500}
                                    error={errors['password']}
                                    onChange={onChange}
                                    placeholder={t('TEXT_PASSWORD_P')}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_RE_PASSWORD_P')}<img src={BULLET_2} /></th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    type={'password'}
                                    propertyName='confirmPassword'
                                    value={userInfo.dataField.confirmPassword}
                                    errorWidth={500}
                                    error={errors['confirmPassword']}
                                    onChange={onChange}
                                    placeholder={t('COM_TEXT_RE_PASSWORD_P')}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_USER_NAME_P')}<img src={BULLET_2} /></th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='userName'
                                    value={userInfo.dataField.userName}
                                    errorWidth={500}
                                    error={errors['userName']}
                                    onChange={onChange}
                                    placeholder={t('COM_TEXT_USER_NAME_P')}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_EMAIL_P')}<img src={BULLET_2} /></th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='email'
                                    value={userInfo.dataField.email}
                                    errorWidth={500}
                                    error={errors['email']}
                                    onChange={onChange}
                                    placeholder={'magicinfo@samsung.com'}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_MOBILE_NUM_P')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='mobileNum'
                                    value={userInfo.dataField.mobileNum}
                                    onChange={onChange}
                                    placeholder={t('TEXT_MOBILE_NUM_P')}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_PHONE_NUM_P')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='phoneNum'
                                    value={userInfo.dataField.phoneNum}
                                    onChange={onChange}
                                    placeholder={t('TEXT_PHONE_NUM_P')}
                                    maxLength={50} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="user_detail_view t_line mt20"></div>
            </div>
        </div>
    );
};

AddOrganizationDetailedInformation.defaultProps = {
    generalInfo: {
        data: {},
        errors: {}
    },
    onChange: () => {},
};

export default AddOrganizationDetailedInformation;