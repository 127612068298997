import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import ReactTable from "../../components/react-table";
import "../../components/table/react-table.css";
import Pagination from "./Pagination";
import isNumber from 'lodash/isNumber';

const MagicInfoTable = ({data = [], loading = false, noDataText, noDataFunc, minRows = 0, sorted= [], multiSort = false, getTbodyProps, getTrGroupProps, getTrProps, getTdProps,
    onSortedChange, columns, className, style, widthStyle,
    usePagination, paginationOptions = {}, expanded = undefined, onExpandedChange = undefined, SubComponent = undefined}) => {

    const COLUMN_MIN_WIDTH = 50;
    const LAST_COLUMN_MIN_WIDTH = 170;
    const {t} = useTranslation();
    const tableRef = useRef();

    const moveTableHeader = () => {
        const thead = tableRef.current.getElementsByClassName("rt-thead")[0];
        const tbody = tableRef.current.getElementsByClassName("rt-tbody")[0];
        thead.style.transform = `translate(-${tbody.scrollLeft}px, 0)`;
    }

    useEffect(()=>{
        let tbody = tableRef.current.getElementsByClassName("rt-tbody")[0];
        tbody && tbody.addEventListener("scroll", moveTableHeader);
        return () => {
            tbody && tbody.removeEventListener('scroll', moveTableHeader);
        }
    }, [data]);

    noDataText = noDataText || t('MESSAGE_COMMON_NO_DATA_P');
    return (
        <>
            <div className='magicinfo_table_wrapper' ref={tableRef} style={widthStyle}>
                {
                    noDataFunc && !loading && (!data || data.length < 1) && noDataFunc()
                }
                {
                    (!noDataFunc || loading || (data && data.length > 0)) &&
                    <>
                        <ReactTable
                            data={data}
                            loading={loading}
                            noDataText={loading ? '' : noDataText}
                            minRows={minRows}
                            sorted={sorted}
                            multiSort={multiSort}
                            getTbodyProps={getTbodyProps}
                            getTrGroupProps={getTrGroupProps}
                            getTdProps={getTdProps}
                            getTrProps={getTrProps}
                            onSortedChange={onSortedChange}
                            columns={columns.map((column, i) => {
                                if(!isNumber(column.minWidth)){
                                    if(isNumber(column.width) && (column.width < COLUMN_MIN_WIDTH))
                                        column.minWidth = column.width;
                                    else 
                                        column.minWidth = COLUMN_MIN_WIDTH;
                                    (i === columns.length-1) && (column.minWidth = LAST_COLUMN_MIN_WIDTH);
                                }
                                return column;
                            })}
                            className={className}
                            style={style}
                            showPagination={false}
                            manual

                            expanded={expanded}
                            onExpandedChange={onExpandedChange}
                            SubComponent={SubComponent}
                        />                   
                        {
                            usePagination &&
                            <Pagination totalCount={paginationOptions.totalCount} page={paginationOptions.page} 
                                defaultPageSize={paginationOptions.defaultPageSize} pageSizeOptions={paginationOptions.pageSizeOptions} 
                                onPageChange={paginationOptions.onPageChange} onPageSizeChange={paginationOptions.onPageSizeChange} 
                                divide={paginationOptions.divide}/>
                        }
                    </>
                }
            </div>
        </>
    );
};

MagicInfoTable.defaultProps = {
    onSortedChange: () => {},
    getTrGroupProps: () => {},
    className: '-striped -highlight',
    usePagination: false,
    paginationOptions: {},
    expanded: undefined,
    onExpandedChange: undefined,
    SubComponent: undefined
}

export default MagicInfoTable;