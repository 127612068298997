import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {snakeCase} from 'lodash';
import Moment from "react-moment";
import {toastr} from 'helper/toastrIntercept';
import fileDownload from 'js-file-download';
import {DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService, userService} from '../../services';
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Pagination from '../../components/table/Pagination';
import {
    toMomentDateTimeFormat,
    useCheckRefWithSelectedCnt as useCheckRef,
    useDeviceDetail,
    useFilter, useResizeWindow
} from '../../helper';
import {useMISOpt} from '../../components/misopt';
import {useTrGroupProps} from '../../helper/tables';
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {updateCache} from "../../helper/cache/tableCache";

const heightOffset = 204;

const ContentDownloadIncomplete = (props) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
        deviceSecurity: props.cache.deviceSecurity ? props.cache.deviceSecurity : false
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        Promise.all([
            deviceService.fetchDeviceContentDownload({
                startIndex: page * pageSize + 1,
                pageSize,
                searchText: keyword,
                sortColumn: snakeCase(id),
                sortOrder: desc ? 'desc' : 'asc',
            }),
            userService.fetchUserLoginInformation()
        ]).then(res => {
            setData({...data, loading: false, items: res[0].items, totalCount: res[0].totalCount,
                deviceSecurity: res[1].items.hasDeviceSecurity
            });
            updateCache('DEVICE', {items: res[0].items, filter: filter, totalCount: res[0].totalCount, deviceSecurity : res[1].items.hasDeviceSecurity}, props.currContent);
        });
    };

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value, page: 0, sorted: [{id: 'deviceName', desc: false}]});
    };

    const [renderDeviceNameCell, renderMACCell] = useDeviceDetail(true, data.deviceSecurity);

    const onExport = exportType => {
        const {keyword, sorted: [{id, desc}]} = filter;
        exportType = 'EXCEL';
        deviceService.exportNofifications({
            type: 'CONTENT',
            exportType,
            searchText: keyword,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error));
    };

    const publishDisabled = () => {
        return selectedCnt !== 1 || !selected.current.map(s => items[s]).every(e => {
            if( typeof e.power !== 'undefined' && e.power !== null ){
                return e.power;
            } else {
                return false;
            }
        });
    };

    const onPublish = () => {
        const {deviceId} = items[selected.current[0]] || {};
        deviceService.deployDeviceSchedule(deviceId).then(res => {
            toastr.success(`${t('COM_TEXT_DEPLOY_P')} - ${t('COM_TEXT_SUCCESS_P')}`);
        }).catch(error => {
            toastr.error(`${t('COM_TEXT_DEPLOY_P')} - ${t('ALERT_FAIL')}`);
        });
    };

    const updateDimensions = () => setStyle({height: window.innerHeight - heightOffset});
    useResizeWindow(updateDimensions);
    useEffect(updateDimensions, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const searchPlaceHolder = `${t('TABLE_DEVICE_NAME_P')}, ${t('DID_ADMIN_LICENSE_MAC')}, ${t('TABLE_IP_ADDR_P')}, ${
        t('ADMIN_DEVICEEVENT_ALARMRULE_ASSIGNRULE_CONTENTS_MODEL')}, ${t('COM_TEXT_FIRMWARE_P')}`;

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllDevice_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: 'deviceName',
            Cell: renderDeviceNameCell,
            width: 230,
        },
        {
            Header: t('DID_ADMIN_LICENSE_MAC'),
            accessor: 'deviceId',
            width: 160,
            Cell: renderMACCell
        },
        {
            Header: t('COM_TABLE_GROUP_NAME_P'),
            accessor: 'groupName',
            width: 300,
        },
        {
            Header: t('TABLE_IP_ADDR_P'),
            accessor: 'ipAddress',
            sortable: false,
            width: 120,
        },
        {
            Header: t('TABLE_DISK_SPACE_AVAILABLE_P'),
            accessor: 'diskSpaceAvailable',
            sortable: false,
            width: 160,
        },
        {
            Header: t('TABLE_DEVICE_MODEL_NAME_P'),
            accessor: 'deviceModelName',
            width: 300,
        },
        {
            Header: t('TABLE_LOCATION_P'),
            accessor: 'location',
            sortable: false,
            width: 120,
        },
        {
            Header: t('MIS_TEXT_LAST_CHECK_TIME_P'),
            accessor: 'lastConnectionTime',
            Cell: data => <Moment format={dateTimeFormat}>{data.value}</Moment>
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%' ,display: props.currContent === 'CONTENT_DOWNLOAD_INCOMPLETE' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"DEVICE_EXPORT"} name={t('BUTTON_EXPORT_P')} onClick={onExport} style={{borderColor: '#5e5e5e'}} />
                    <WhiteButton id={"MIS_SID_PUBLISH"} name={t('MIS_SID_PUBLISH')} onClick={onPublish} disable={publishDisabled()} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword} />
                </div>
            </div>
            <div className='device_list_view_wrap'>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    showPagination={false}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={props.divide} />
            </div>
        </div>
    );
};

export default ContentDownloadIncomplete;