import {contentConstants, menuConstants, TREE_KEY_SEPARATOR} from '../constants/';
import store from '../store';
import {
    contentService,
    deviceService,
    playlistService,
    rulesetService,
    scheduleService,
    settingService,
    userService
} from "../services";
import {systemService} from '../services/system.service';
import {
    jsonToSubmenuTreeForDevice,
    jsonToTree,
    jsonToTreeForContentByUser,
    jsonToTreeForPlaylistByMe,
    jsonToTreeForUser,
    jsonToTreeForUserWithChildren,
    jsonToTreeWithChildren
} from '../helper';
import {immutableMenu} from "../constants/MenuConstants";
import {merge} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../helper/responseHandler";
import {getCache, initCacheTable, isCacheTable, removeGroups, updateCache} from "../helper/cache/tableCache";

export const menuAction = {
    initMenus,
    updateSubMenuCounter,
    addTab,
    activeTab,
    removeTab,
    activeNode,
    activeNodeGroup,
    activeGroupNode,
    activeChildGroupNode,
    loadContent,
    activeChildrenNode,
    updateTab,
    showSubmenu,
    loadGroup,
    updateSubmenuWidth,
    loadSimpleSchedule,
    reloadGroup,
    destroyReloadGroup,
    selectGroup,
    moveTab,
    forceReloadContent,
    loadGroupWithExpandedKeys,
    requestGroup
}

function reloadGroup(submenuId, groupId = 0) {
    return {
        type: menuConstants.RELOAD_GROUP,
        submenuId: submenuId,
        groupId: groupId
    }
}

function destroyReloadGroup() {
    return {
        type: menuConstants.DESTROY_RELOAD_GROUP
    }
}

function selectGroup(submenuId, groupIds) {
    return {
        type: menuConstants.SELECT_GROUP,
        submenuId,
        groupIds,
    }
}

function initMenus(menus) {
    return {
        type: menuConstants.INIT_MENUS,
        menus: menus
    }
}

function updateSubMenuCounter(submenuId) {
    return dispatch => {
        systemService.fetchMenuById(submenuId).then(res => {
            dispatch(fetchMenuByIdSuccess(res.items));
        }).catch(error => console.log(error));
    };

    function fetchMenuByIdSuccess(menu) {
        return {type: menuConstants.UPDATE_SUBMENU_COUNTER, menu};
    };
}

function addTab(tab) {
    const {id} = tab;

    menuConstants[id] = immutableMenu.get('menus').get(id).toJS(); // init from immutable file(init opened node)
    const constantMenu = menuConstants[id]; // if first tab load, set menu constants
    initCacheTable(id, undefined, true)
    const complete = (menu = undefined) => {
        let newMenu = [];
        if(menu!=undefined) {
            newMenu = merge(constantMenu, menu);
            if (id === 'STATISTICS' && newMenu.nodes[0].enable === false) {
                // for RM license in statistics menu
                newMenu.nodes.map(n => n.active = false);
                const firstNode = newMenu.nodes.find(n => n.enable === true);
                firstNode.open = true;
                firstNode.active = true;
                firstNode.children[0].active = true;
            }
        }
        return {
            type: menuConstants.ADD_TAB,
            tab: tab,
            submenu: newMenu.length !=0 ? newMenu : constantMenu
        }
    }
    return dispatch => {
        if (constantMenu.nodes !== undefined && constantMenu.nodes.length > 0) {
            systemService.fetchMenuById(id === 'LED_CABINET' ? 'DEVICE' : id)
                .then(
                    res=> {
                        if (res && res.items) {
                            const menu = res.items;
                            //const submenu = menu.find(menu=> menu.id === id) || undefined;
                            dispatch(complete(menu));
                        }
                    }
                ).catch(e=> toastr.error(getErrorMessage(e)))
        } else {
            dispatch(complete());
        }
    };

}

function activeTab(tabId) {
    /*if (isCacheTable(tabId)) {
        const cache = getCache(tabId);
        updateCache(tabId, {...cache, filter: {...cache.filter, isFetched: false}})
    }*/
    return {
        type: menuConstants.ACTIVE_TAB,
        tabId: tabId,
        submenu: menuConstants[tabId]
    }
}

function activeNode(id) {
    const {submenu, currentContentId, forceReload} = store.getState().menu;
    let loadContent = true;
    let pastNode = null, currentNode = null;

    const findAndActive = (nodes) => {
        nodes.map(node => {
            if (node.id === id) {
                currentNode = node;
                if (node.children != null && node.children.length > 0) {
                    const activeChild = node.children.filter(children => children.active == true);
                    node.open = node.active||(activeChild&&activeChild.length>0) ? true : !node.open;
                    loadContent = false;
                } else {
                    node.active = true;
                }
            } else {
                if(node.active){
                    pastNode = node;
                }
                node.active = false;
                if (node.children != null && node.children.length > 0) {
                    findAndActive(node.children);
                }
            }
        });
    };

    if (submenu !== null && submenu.nodes !== null) {
        findAndActive(submenu.nodes);
    }

    if(pastNode != null && currentNode != null && currentNode.id !== pastNode.id && currentNode.children != null && currentNode.children.length > 0){
        pastNode.active = true;
    }

    if (loadContent) {
        initCacheTable(submenu.id, id);
        return {
            type: menuConstants.LOAD_CONTENT,
            contentId : id,
            submenu: submenu,
            forceReload: id === currentContentId ? !forceReload : forceReload,
            /*selected: {tabId:submenu.id, currentContentId:currentContentId, organizationId : '',
                groupId: '',
                userId: ''}*/
        }
    } else {
        return {
            type: menuConstants.UPDATE_SUBMENU,
            submenu: submenu
        }
    }
}

function activeNodeGroup(submenuId, nodeId, newGroupId, newUserId, organizationId) {
    const {submenu, currentContentId, forceReload, groupId, userId} = store.getState().menu;


    const updateMenu = {
        ...submenu,
        nodes: submenu.nodes.map(node => {
            if (node.children !== undefined && node.children.length > 0) {
                return {...node, active: false, children: node.children.map(
                        child => {
                            return {...child, active: false}
                        })}
            } else {
                return {...node, active: false}
            }
        })
    }
    
    let checkForceReload = true;
    if(currentContentId === 'BY_USER'){
        if((groupId === undefined || groupId === "") && (userId === undefined || userId === "")){
           checkForceReload = false;
        }
    }else if(currentContentId === 'SHARED_CONTENT' && newGroupId === '0'){
        checkForceReload = false;
    } else if(currentContentId === 'PLAYLIST_BY_USER'){
        if((newGroupId === undefined || newGroupId === "") && (newUserId === undefined || newUserId === "")) {
            checkForceReload = false;
        }
    }
    if (isCacheTable(submenu.id)) {
        initCacheTable(submenu.id, nodeId);
        const cache = getCache(submenu.id);
        if (cache) {
            updateCache(submenu.id, {...cache,
                filter: {...cache.filter,
                    groupId : newGroupId !== undefined && newGroupId !== '' ? newGroupId : undefined,
                    userId : newUserId !== undefined && newUserId !== '' ? newUserId : undefined,
                    organizationId : organizationId !== undefined && organizationId !== '' ? organizationId : undefined}}, nodeId);
        }
    }

    menuConstants[submenu.id] = updateMenu;

    return {
        type: menuConstants.LOAD_GROUP_CONTENT,
        contentId : nodeId,
        submenu: updateMenu,
        groupId: newGroupId,
        userId: newUserId,
        organizationId : organizationId,
        forceReload: checkForceReload && nodeId === currentContentId && newGroupId === groupId ? !forceReload : forceReload
    }
}

function activeGroupNode(id, noContentUpdate = false) {
    const {submenu, currentContentId, forceReload} = store.getState().menu;
    if (submenu !== null && submenu.nodes !== null) {
        submenu.nodes.map(
            node => {
                if (node.id === id) {
                    node.open = !node.open
                }
            }
        )
    }
    return {
        type: menuConstants.ACTIVE_GROUP_NODE,
        contentId : id,
        submenu: submenu,
        noContentUpdate
    }
}

function activeChildGroupNode(nodeId, childId) {
    const {submenu} = store.getState().menu;
    if (submenu !== null && submenu.nodes !== null) {
        submenu.nodes.map(
            node => {
                if (node.id === nodeId) {
                    node.children.map(
                        child => {
                            if (child.id === childId) {
                                child.open = !child.open
                            }
                        }
                    )
                }
            }
        )
    }
    return {
        type: menuConstants.ACTIVE_CHILD_GROUP_NODE,
        contentId : nodeId,
        submenu: submenu
    }
}

function activeChildrenNode(nodeId, contentId) {
    const {submenu, currentContentId, forceReload} = store.getState().menu;
    if (submenu !== null && submenu.nodes !== null) {
        submenu.nodes.map(
            node => {
                if (node.id === nodeId) {
                    if (node.children != null && node.children.length > 0) {
                        node.children.map(
                            children => {
                                children.id === contentId ? children.active = true : children.active = false;
                                if(children.active){
                                    node.open = true;
                                }
                            }
                        )
                    }
                } else {
                    node.active = false;
                    if (node.children != null && node.children.length > 0) {
                        node.children.map(
                            children => children.id === contentId ? '': children.active = false
                        )
                    }
                }
            }
        )
    }
    initCacheTable(submenu.id, contentId);
    return {
        type: menuConstants.LOAD_CONTENT,
        contentId : contentId,
        submenu: submenu,
        forceReload: contentId === currentContentId ?  !forceReload : forceReload,
        /*selected: {tabId:submenu.id, currentContentId:contentId, organizationId : '',
            groupId: '',
            userId: ''}*/
    }
}

function loadContent(id, isShowSubmenu = undefined) {
    const {submenu, currentContentId} = store.getState().menu;
    initCacheTable(submenu.id, currentContentId);
    return {
        type: menuConstants.LOAD_CONTENT,
        contentId : id,
        submenu: submenu,
        isShowSubmenu: isShowSubmenu
    }
}

function removeTab(id) {
    removeGroups(id);

    return {
        type: menuConstants.REMOVE_TAB,
        tabId: id
    }
}

function requestGroup(nodeId, dom) {
    return {
        type: menuConstants.REQUEST_GROUP,
        id: nodeId,
        dom: dom
    }
}

function receiveGroup(nodeId) {
    return {
        type: menuConstants.RECEIVE_GROUP,
    }
}

function loadGroupWithExpandedKeys(submenuId, nodeId, noContentUpdate, expandedKeys){
    return dispatch => {
        let promises = [];
        let result = [];
        let params = {};
        switch (submenuId) {
            case 'RULESET':
                switch(nodeId) {
                    case 'RULESET_BY_GROUP':
                        promises.push(rulesetService.fetchRulesetGroups());
                        expandedKeys.map(expandedKey => {
                            const splitKey = expandedKey.split(TREE_KEY_SEPARATOR);
                            promises.push(
                                rulesetService.fetchRulesetGroupsById(splitKey[1])
                            )
                        });

                        return Promise.all(promises).then(values => {
                            values.map(value => result = result.concat(value.items));
                            dispatch(success(jsonToTreeWithChildren(result)));
                        });
                    default:
                        break;
                }
                break;
            case 'CONTENT':

                switch (nodeId) {
                    case 'MY_CONTENT' :
                        params = {
                            groupType : contentConstants.MY_CONTENT_GROUP
                        };
                        return contentService.fetchGroups(params).then(res => {
                            if(res.status == 'Success'){
                                dispatch(success(jsonToTreeForPlaylistByMe(res.items)));
                            }
                        });
                    case 'SHARED_CONTENT':
                        params = {
                            groupType : contentConstants.SHARED_GROUP
                        };
                        let shared = [{
                            index: -10,
                            parentGroupId: 0,
                            groupName: "shared",
                            groupId: 0,
                            groupDepth: -1,
                            organizationId: 0,
                            contentCount: null,
                            groupType: "SHARED_GROUP"
                        }];
                        result = result.concat(shared);
                        return contentService.fetchGroups(params).then(
                            res => {
                                result = result.concat(res.items);
                                dispatch(success(jsonToTreeWithChildren(result, -1, false)));
                            });
                    case 'BY_USER':
                        params = {
                            groupType : contentConstants.ORGANIZATION
                        };
                        promises.push(contentService.fetchGroups(params));
                        expandedKeys.map(expandedKey => {
                            const splitKey = expandedKey.split(TREE_KEY_SEPARATOR);
                            switch(splitKey[0]) {
                                case 'CONTENT_ORGANIZATION':
                                    promises.push(contentService.fetchUserIdByOrganizationId(splitKey[1]));
                                    break;
                                case 'CONTENT_USER':
                                    promises.push(contentService.fetchFolderListByUserId(splitKey[1]));
                                    break;
                                case 'CONTENT_GROUP':
                                    promises.push(contentService.fetchGroupById(splitKey[1],{groupType:contentConstants.MY_CONTENT_GROUP}));
                                    break;
                                default:
                                    break;
                            }
                        });
                        return Promise.all(promises).then(values => {
                            values.map(value => {
                                if(value.items && value.items.filter(item => item.responseDataType === 'CONTENT_GROUP' && item.groupDepth === 1).length !== value.items.length){
                                    result = result.concat(value.items);
                                }
                            });
                            const organizationJson = result.filter(item => item.responseDataType === 'CONTENT_ORGANIZATION');
                            const userJson = result.filter(item => item.responseDataType === 'CONTENT_USER');
                            const groupJson = result.filter(item => item.responseDataType === 'CONTENT_GROUP');
                            dispatch(success(jsonToTreeForUserWithChildren(organizationJson, userJson, groupJson)));
                        });
                    case 'CONTENT_TEMPLATE_BY_FOLDER':
                        params = {
                            groupType : contentConstants.TEMPLATE_GROUP
                        };
                        promises.push(contentService.fetchGroups(params));
                        expandedKeys.map(expandedKey => {
                            const splitKey = expandedKey.split(TREE_KEY_SEPARATOR);
                            promises.push(
                                contentService.fetchGroupById(splitKey[1], params)
                            )
                         });

                        return Promise.all(promises).then(values => {
                            values.map(value => result = result.concat(value.items));
                            dispatch(success(jsonToTreeWithChildren(result)));
                        });
                    default :
                        break;
                }
                break;
            case 'PLAYLIST':
                switch (nodeId) {
                    case 'MY_PLAYLIST' :
                        return playlistService.fetchPlaylistGroups({groupType : "MY_PLAYLIST_GROUP"})
                            .then(res => {
                                    if(res.status == 'Success') {
                                        dispatch(success(jsonToTreeForPlaylistByMe(res.items)));
                                    }
                                }
                            );
                    case 'PLAYLIST_BY_USER' :
                        params = {
                            groupType : "ORGANIZATION"
                        };
                        promises.push(playlistService.fetchPlaylistGroups(params));
                        expandedKeys.map(expandedKey => {
                            const splitKey = expandedKey.split(TREE_KEY_SEPARATOR);
                            switch(splitKey[0]) {
                                case 'PLAYLIST_ORGANIZATION':
                                    promises.push(playlistService.fetchPlaylistUsersByOrganizationId(splitKey[1]));
                                    break;
                                case 'PLAYLIST_USER':
                                    promises.push(playlistService.fetchPlaylistGroupsByUserId(splitKey[1]));
                                    break;
                                case 'PLAYLIST_GROUP':
                                    promises.push(playlistService.fetchPlaylistGroups({groupId: splitKey[1], groupType: 'SUB_GROUP'}));
                                    break;
                                default:
                                    break;
                            }
                        });
                        return Promise.all(promises).then(values => {
                            values.map(value => {
                                if(value.items && value.items.filter(item => item.responseDataType === 'PLAYLIST_GROUP' && item.groupDepth === 1).length !== value.items.length){
                                    result = result.concat(value.items);
                                }
                            });
                            const organizationJson = result.filter(item => item.responseDataType === 'PLAYLIST_ORGANIZATION');
                            const userJson = result.filter(item => item.responseDataType === 'PLAYLIST_USER');
                            const groupJson = result.filter(item => item.responseDataType === 'PLAYLIST_GROUP');
                            dispatch(success(jsonToTreeForUserWithChildren(organizationJson, userJson, groupJson)));
                        });
                    default :
                        break;
                }
                break;
            case 'SCHEDULE':
                switch (nodeId) {
                    case 'CONTENT_SCHEDULE_BY_GROUP' :
                        promises.push(scheduleService.fetchContentScheduleGroups());
                        expandedKeys.map(expandedKey => {
                            const splitKey = expandedKey.split(TREE_KEY_SEPARATOR);
                            promises.push(
                                scheduleService.fetchContentScheduleGroupById(splitKey[1])
                            );
                        });

                        return Promise.all(promises).then(values => {
                            values.map(value => result = result.concat(value.items));
                            dispatch(success(jsonToTreeWithChildren(result)));
                        });
                    case 'MESSAGE_SCHEDULE_BY_GROUP' :
                        promises.push(scheduleService.fetchMessageScheduleGroups());
                        expandedKeys.map(expandedKey => {
                            const splitKey = expandedKey.split(TREE_KEY_SEPARATOR);
                            promises.push(
                                scheduleService.fetchMessageScheduleGroupById(splitKey[1])
                            );
                        });

                        return Promise.all(promises).then(values => {
                            values.map(value => result = result.concat(value.items));
                            dispatch(success(jsonToTreeWithChildren(result)));
                        });
                    case 'EVENT_SCHEDULE_BY_GROUP' :
                        promises.push(scheduleService.fetchEventScheduleGroups());
                        expandedKeys.map(expandedKey => {
                            const splitKey = expandedKey.split(TREE_KEY_SEPARATOR);
                            promises.push(
                                scheduleService.fetchEventScheduleGroupById(splitKey[1])
                            );
                        });

                        return Promise.all(promises).then(values => {
                            values.map(value => result = result.concat(value.items));
                            dispatch(success(jsonToTreeWithChildren(result)));
                        });
                }
                break;
            case 'USER':
                switch (nodeId) {
                    case 'USER_BY_GROUP':
                        promises.push(userService.fetchOrganizationsGroupsCount(true));
                        return Promise.all(promises).then(values => {
                            values.map(value => result = result.concat(value.items));
                            dispatch(success(jsonToTreeForUser(result)));
                        });
                }
                break;
            default:
                break;
        }
    };

    function success(group, selectedGroup) {
        const {submenu} = store.getState().menu;
        if (submenu !== null && submenu.nodes !== null) {
            submenu.nodes.map(
                node => {
                    if (node.id === nodeId) {
                        node.group = group;
                        if(!noContentUpdate) {node.open = !node.open;}
                    }
                    if (node.children.length > 0) {
                        node.children.map(
                            child => {
                                if (child.id === nodeId) {
                                    child.group = group;
                                    if (selectedGroup) {
                                        node.open = true;
                                        child.open = true;
                                    }
                                }
                            }
                        )
                    }
                }
            )
        }
        return {
            type: contentConstants.GET_CONTENT_GROUP,
            contentId : nodeId,
            submenu: submenu,
            noContentUpdate: true

        }
    }
}

function loadGroup(submenuId, nodeId, noContentUpdate, selectedGroup) {
    return dispatch => {
        let params = {};
        let result = [];
        switch (submenuId) {
            case 'RULESET':
                switch(nodeId) {
                    case 'RULESET_BY_GROUP':
                        noContentUpdate = true;
                        return rulesetService.fetchRulesetGroups()
                            .then(data => {
                                dispatch(success(jsonToTree(data.items)));
                            });
                    default:
                        break;
                }
                break;
            case 'CONTENT':
                switch (nodeId) {
                    case 'MY_CONTENT' :
                        params = {
                            groupType : contentConstants.MY_CONTENT_GROUP
                        };
                        return contentService.fetchGroups(params).then(res => {
                            if(res.status == 'Success'){
                                dispatch(success(jsonToTreeForPlaylistByMe(res.items)));
                            }
                        });
                    case 'SHARED_CONTENT':
                        params = {
                            groupType : contentConstants.SHARED_GROUP
                        };
                        let shared = [{
                            index: -10,
                            parentGroupId: 0,
                            groupName: "shared",
                            groupId: 0,
                            groupDepth: -1,
                            organizationId: 0,
                            contentCount: null,
                            groupType: "SHARED_GROUP"
                        }];

                        result = result.concat(shared);
                        return contentService.fetchGroups(params).then(
                            res => {
                                result = result.concat(res.items);
                                dispatch(success(jsonToTreeWithChildren(result, -1, false)));
                            });
                    case 'BY_USER':
                        params = {
                            groupType : contentConstants.ORGANIZATION
                        };
                        return contentService.fetchGroups(params).then(res => {
                            if(res.status == 'Success'){
                                dispatch(success(jsonToTreeForContentByUser(res.items)));
                            }
                        });
                    case 'CONTENT_TEMPLATE_BY_FOLDER':
                        params = {
                            groupType : contentConstants.TEMPLATE_GROUP
                        };
                        noContentUpdate = true;
                        return contentService.fetchGroups(params).then(res => {
                            if(res.status == 'Success'){
                                dispatch(success(jsonToTree(res.items)));
                            }
                        });
                    default :
                        break;
                }
                break;
            case 'PLAYLIST':
                switch (nodeId) {
                    case 'MY_PLAYLIST' :
                        return playlistService.fetchPlaylistGroups({groupType : "MY_PLAYLIST_GROUP"})
                            .then(res => {
                                    if(res.status == 'Success') {
                                        dispatch(success(jsonToTreeForPlaylistByMe(res.items)));
                                    }
                                }
                            );
                    case 'PLAYLIST_BY_USER' :
                        return playlistService.fetchPlaylistGroups({groupType : "ORGANIZATION"})
                            .then(res => {
                                    if(res.status == 'Success') {
                                        dispatch(success(jsonToTreeForContentByUser(res.items)));
                                    }
                                }
                            );

                    default :
                        break;
                }
                break;
            case 'SCHEDULE':
                switch (nodeId) {
                    case 'CONTENT_SCHEDULE_BY_GROUP' :
                        return scheduleService.fetchContentScheduleGroups()
                            .then(data => {
                                dispatch(success(jsonToTree(data.items)));
                            })
                    case 'MESSAGE_SCHEDULE_BY_GROUP' :
                        return scheduleService.fetchMessageScheduleGroups()
                            .then(data => {
                                dispatch(success(jsonToTree(data.items)));
                            })
                    case 'EVENT_SCHEDULE_BY_GROUP' :
                        return scheduleService.fetchEventScheduleGroups()
                            .then(data => {
                                dispatch(success(jsonToTree(data.items)));
                            })
                }
                break;
            case 'DEVICE':
                switch (nodeId) {
                    case 'DEVICE_BY_GROUP':
                        return deviceService.fetchDeviceSearchedGroups({})
                            .then(data => {
                                data.items.sort((i1, i2) => i1.groupName.localeCompare(i2.groupName));
                                const groups = jsonToSubmenuTreeForDevice(data.items);
                                dispatch(success(groups, selectedGroup));
                            });
                }
                break;
            case 'USER':
                switch (nodeId) {
                    case 'USER_BY_GROUP':
                        return userService.fetchOrganizationsGroupsCount()
                            .then(data => {
                                dispatch(success(jsonToTreeForUser(data.items.map(item =>
                                    {
                                        return {...item, description : 'Organization'}
                                    }
                                ))));
                            });
                }
                break;
            case 'SETTING':
                switch (nodeId) {
                    case 'TAG_BY_GROUP':
                        return settingService.fetchTagOrgan()
                            .then(data => {
                                dispatch(success(jsonToTree(data.items)));
                            });
                }
                break;
            default:
                break;
        }
    };
    function success(group, selectedGroup) {
        const {submenu} = store.getState().menu;
        if (submenu !== null && submenu.nodes !== null) {
            submenu.nodes.map(
                node => {
                    if (node.id === nodeId) {
                        node.group = group;
                        if(!noContentUpdate || nodeId === "RULESET_BY_GROUP"){
                            node.open = !node.open;
                        }
                    }
                    if (node.children.length > 0) {
                        node.children.map(
                            child => {
                                if (child.id === nodeId) {
                                    child.group = group;
                                    if (selectedGroup) {
                                        node.open = true;
                                        child.open = true;
                                    }
                                }
                            }
                        )
                    }
                }
            )
        }
        return {
            type: contentConstants.GET_CONTENT_GROUP,
            contentId : nodeId,
            submenu: submenu,
            noContentUpdate: true
        }
    }
}

function updateTab() {
    return {
        type: menuConstants.UPDATE_TAB,
    }
}

function showSubmenu(id) {
    return {
        type: menuConstants.SHOW_SUBMENU,
        id: id
    }
}

function updateSubmenuWidth(width) {
    return {
        type: menuConstants.UPDATE_SUBMENU_WIDTH,
        width: width
    }
}

function loadSimpleSchedule() {
    return {
        type: menuConstants.LOAD_SIMPLE_SCHEDULE,
        submenu: menuConstants.SIMPLE_SCHEDULE
    }
}

function moveTab(tab, nodeId, subNodeId) {
    const {id} = tab;
    menuConstants[id] = immutableMenu.get('menus').get(tab.id).toJS(); // init from immutable file(init opened node)
    const constantMenu = menuConstants[id]; // if first tab load, set menu constants
    initCacheTable(id)
    const complete = (menu = undefined) => {
        let newMenu = [];
        if(menu!=undefined) {
            newMenu = merge(constantMenu, menu);
            if(subNodeId === 'DEVICE_BY_GROUP') {
                newMenu.nodes.find(node => node.id === nodeId).children.find(child => child.id === subNodeId).open = true;
            }
        }
        return {
            type: menuConstants.ADD_TAB,
            tab: tab,
            submenu: newMenu.length !=0 ? newMenu : constantMenu
        }
    }
    return dispatch => {
        if (constantMenu.nodes !== undefined && constantMenu.nodes.length > 0) {
            systemService.fetchMenuById(id).then(res => {
                if (res && res.items) {
                    const menu = res.items;
                    dispatch(complete(menu));
                }
                if (nodeId) {
                    dispatch(activeNode(nodeId));
                    if (subNodeId) {
                        dispatch(activeChildrenNode(nodeId, subNodeId));
                    }
                    if(subNodeId === 'DEVICE_BY_GROUP') {
                        dispatch(loadGroup(nodeId, subNodeId));
                    }
                }
            }).catch(e => toastr.error(getErrorMessage(e)))
        } else {
            dispatch(complete());
        }
    };
}

function forceReloadContent() {
    return {
        type: menuConstants.FORCE_RELOAD_CONTENT,
    }
}