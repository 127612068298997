import {
    CONTENT_FILTERS,
    DEVICE_FILTERS,
    PLAYLIST_FILTERS,
    RULESET_FILTERS,
    SCHEDULE_FILTERS,
    USER_FILTERS,
    DEVICE_PAGE_SIZE_OPTIONS,
    SETTING_FILTERS
} from "../../constants";

import {getPageSize} from "../pageSizeInfo";

const isCacheTable = (table) => {
    return table === 'CONTENT' || table === 'PLAYLIST' || table === 'RULESET' || table === 'SCHEDULE' || table === 'DEVICE' || table === 'USER' || table === 'DASHBOARD' || table === 'NEW_SCHEDULE' || table === 'EDIT_SCHEDULE' || table === 'NEW_PLAYLIST' || table === 'EDIT_PLAYLIST' || table === 'SETTING';
}
const getCache = (table) => {
    switch (table) {
        case 'CONTENT': return content;
        case 'PLAYLIST': return playlist;
        case 'RULESET': return ruleset;;
        case 'SCHEDULE':return schedule;
        case 'DEVICE': return device;
        case 'USER': return user;
        case 'DASHBOARD': return dashboard;
        case 'NEW_SCHEDULE': return newSchedule;
        case 'EDIT_SCHEDULE': return editSchedule;
        case 'ADD_SIMPLE_SCHEDULE': return simpleSchedule;
        case 'NEW_PLAYLIST': return newPlaylist;
        case 'EDIT_PLAYLIST': return editPlaylist;
        case 'SETTING' : return setting;
    }
}
const updateCache = (table, data, submenuId) => {
    switch (table) {
        case 'CONTENT':
            content = {...content, ...data, isLoaded: data.isLoaded !== undefined ? data.isLoaded : true, lastSubmenuId: submenuId !== undefined ? submenuId : undefined};
            break;
        case 'PLAYLIST':
            playlist = {...playlist, ...data, isLoaded: data.isLoaded !== undefined ? data.isLoaded : true, lastSubmenuId: submenuId !== undefined ? submenuId : undefined};
            break;
        case 'RULESET':
            ruleset = {...ruleset, ...data, isLoaded: data.isLoaded !== undefined ? data.isLoaded : true, lastSubmenuId: submenuId !== undefined ? submenuId : undefined};
            break;
        case 'SCHEDULE':
            schedule  = {...schedule, ...data, isLoaded: data.isLoaded !== undefined ? data.isLoaded : true, lastSubmenuId: submenuId !== undefined ? submenuId : undefined};
            break;
        case 'DEVICE':
            if(submenuId)
                device = {...device, ...data, isLoaded: data.isLoaded !== undefined ? data.isLoaded : true, lastSubmenuId: submenuId};
            else
                device = {...device, ...data, isLoaded: data.isLoaded !== undefined ? data.isLoaded : true};
            break;
        case 'USER':
            user = {...user, ...data, isLoaded: data.isLoaded !== undefined ? data.isLoaded : true, lastSubmenuId: submenuId !== undefined ? submenuId : undefined};
            break;
        case 'DASHBOARD':
            dashboard = {...dashboard, ...data, isLoaded: data.isLoaded !== undefined ? data.isLoaded : true, lastSubmenuId: submenuId !== undefined ? submenuId : undefined};
            break;
        case 'NEW_SCHEDULE':
            newSchedule = {...newSchedule, ...data,isLoaded: data.isLoaded !== undefined ? data.isLoaded : true};
            break;
        case 'EDIT_SCHEDULE':
            editSchedule = {...editSchedule, ...data,isLoaded: data.isLoaded !== undefined ? data.isLoaded : true,};
            break;
        case 'ADD_SIMPLE_SCHEDULE':
            simpleSchedule = {...simpleSchedule, ...data,isLoaded: data.isLoaded !== undefined ? data.isLoaded : true,};
            break;
        case 'NEW_PLAYLIST':
            newPlaylist = {...newPlaylist, ...data,isLoaded: data.isLoaded !== undefined ? data.isLoaded : true};
            break;
        case 'EDIT_PLAYLIST':
            editPlaylist = {...editPlaylist, ...data,isLoaded: data.isLoaded !== undefined ? data.isLoaded : true,};
            break;
        case 'SETTING':
            setting = {...setting, ...data,isLoaded: data.isLoaded !== undefined ? data.isLoaded : true, lastSubmenuId: submenuId !== undefined ? submenuId : undefined};
            break;
        case '':
            dashboard = {...dashboard, ...data, isLoaded: data.isLoaded !== undefined ? data.isLoaded : true, lastSubmenuId: submenuId !== undefined ? submenuId : undefined};
            break;
    }
}

const removeGroups = (table) => {
    switch (table) {
        case 'CONTENT':
            content.groups = undefined;
            break;
        case 'PLAYLIST':
            playlist.groups = undefined;
            break;
        case 'RULESET':
            ruleset.groups = undefined;
            break;
        case 'SCHEDULE':
            schedule.groups = undefined;
            break;
        case 'DEVICE':
            device.groups = undefined;
            break;
        case 'USER':
            user.groups = undefined;
        case 'DASHBOARD':
            dashboard.dashboards = [];
    }
}

const initCacheTable = (table, submenuId, isReset = false) => {
    switch (table) {
        case 'CONTENT':
            if (isReset) {
                content = {};
            }
            content.isLoaded = false;
            content.filter= defaultContentFilters(submenuId);
            content.items = [];

            break;
        case 'PLAYLIST':
            if (isReset) {
                playlist = {};
            }
            playlist.isLoaded = false;
            playlist.filter = defaultPlaylistFilters(submenuId);
            playlist.items = [];
            break;
        case 'RULESET':
            if (isReset) {
                ruleset = {};
            }
            ruleset.isLoaded = false;
            ruleset.filter = defaultRulesetFilters(submenuId);
            ruleset.items = [];
            break;
        case 'SCHEDULE':
            if (isReset) {
                schedule = {};
            }
            schedule.isLoaded = false;
            schedule.filter = defaultScheduleFilters(submenuId);
            schedule.items = [];
            break;
        case 'DEVICE':
            if (isReset) {
                device = {};
            }
            device.isLoaded = false;
            device.filter = defaultDeviceFilters(submenuId);
            device.items = [];
            break;
        case 'USER':
            if (isReset) {
                user = {};
            }
            user.isLoaded = false;
            user.filter = defaultUserFilters(submenuId);
            user.items = [];
            break;
        case 'DASHBOARD':
            dashboard.dashboards = [];
            break;
        case 'NEW_SCHEDULE':
            newSchedule = {};
            newSchedule.isLoaded = false;
            newSchedule.filter = {mode:'CONTENT', view:'IMAGE'}
            break;
        case 'EDIT_SCHEDULE':
            editSchedule = {};
            editSchedule.isLoaded = false;
            editSchedule.filter = {mode:'CONTENT', view:'IMAGE'};
            break;
        case 'ADD_SIMPLE_SCHEDULE':
            editSchedule = {};
            editSchedule.isLoaded = false;
            editSchedule.filter = {mode:'CONTENT', view:'IMAGE'};
            break;
        case 'NEW_PLAYLIST':
            newPlaylist = {};
            newPlaylist.isLoaded = false;
            newPlaylist.filter = {mode:'CONTENT', view:'IMAGE'}
            break;
        case 'EDIT_PLAYLIST':
            editPlaylist = {};
            editPlaylist.isLoaded = false;
            editPlaylist.filter = {mode:'CONTENT', view:'IMAGE'}
            break;
        case 'SETTING':
            //setting = {};
            setting.isLoaded = false;
            setting.filter = defaultSettingFilters(submenuId);
            break;
    }
}

const defaultSettingFilters = (submenuId = 'ALL_TAG') => {
    const getFilterId = (id) => {
        switch (id) {
            case 'ALL_TAG':
            case 'TAG_BY_GROUP':
                return 'ALL_TAG';
            default :
                return id;
        }
    }
    const filterId = getFilterId(submenuId);
    const filter = SETTING_FILTERS[filterId];
    if (filter) {
        filter.pageSize =  getPageSize('SETTING');
    }
    return filter;
}

const defaultContentFilters = (submenuId = 'ALL_CONTENT') => {
    const getFilterId = (id) => {
        switch (id) {
            case 'BY_USER':
            case 'MY_CONTENT':
            case 'SHARED_CONTENT':
                return 'ALL_CONTENT';
            default :
                return id;
        }
    }
    const filterId = getFilterId(submenuId);
    const filter = CONTENT_FILTERS[filterId];
    filter.pageSize =  getPageSize('CONTENT');
    return filter;
}
const defaultPlaylistFilters = (submenuId = 'ALL_PLAYLIST') => {
    const getFilterId = (id) => {
        switch (id) {
            case 'MY_PLAYLIST':
            case 'PLAYLIST_BY_USER':
                return 'ALL_PLAYLIST';
            case 'MY_PLAYLIST':
                return 'ALL_PLAYLIST';
            default :
                return id;
        }
    }
    const filterId = getFilterId(submenuId);
    const filter = PLAYLIST_FILTERS[filterId];
    filter.pageSize =  getPageSize('PLAYLIST');
    return filter;
}

const defaultRulesetFilters =  (submenuId = 'ALL_RULESET') => {
    const getFilterId = (id) => {
        switch (id) {
            case 'RULESET_BY_GROUP':
                return 'ALL_RULESET';
            default :
                return id;
        }
    }
    const filterId = getFilterId(submenuId);
    const filter = RULESET_FILTERS[filterId];
    filter.pageSize =  getPageSize(submenuId);
    return filter;
}

const defaultScheduleFilters = (submenuId = 'ALL_CONTENT_SCHEDULE') => {
    const getFilterId = (id) => {
        switch (id) {
            case 'CONTENT_SCHEDULE_BY_GROUP':
                return 'ALL_CONTENT_SCHEDULE';
            case 'MESSAGE_SCHEDULE_BY_GROUP':
                return 'ALL_MESSAGE_SCHEDULE';
            case 'EVENT_SCHEDULE_BY_GROUP':
                return 'ALL_EVENT_SCHEDULE';
            default :
                return id;
        }
    }
    const filterId = getFilterId(submenuId);
    const filter = SCHEDULE_FILTERS[filterId];
    filter.pageSize = getPageSize('SCHEDULE');
    return filter;
}

const defaultDeviceFilters = (submenuId = 'ALL_DEVICE') => {
    const getFilterId = (id) => {
        switch (id) {
            case 'DEVICE_HEALTH_WARNING':
            case 'DEVICE_HEALTH_RESOLVED':
                return 'DEVICE_HEALTH_ERROR'
            default :
                return id;
        }
    }
    const filterId = getFilterId(submenuId);
    const filter = DEVICE_FILTERS[filterId];
    filter.pageSize = getPageSize('DEVICE', DEVICE_PAGE_SIZE_OPTIONS[0]);
    return filter;
}

const defaultUserFilters = (submenuId = 'ALL_USER') => {
    const getFilterId = (id) => {
        switch (id) {
            case 'USER_BY_GROUP':
                return 'ALL_USER'
            default :
                return id;
        }
    }
    const filterId = getFilterId(submenuId);
    const filter = USER_FILTERS[filterId];
    filter.pageSize = getPageSize('USER');
    return filter;
}


let content = {}
let playlist = {}
let ruleset = {}
let schedule = {}
let device = {}
let user = {}
let dashboard = {}
let newSchedule = {}
let editSchedule = {}
let simpleSchedule = {}
let newPlaylist = {}
let editPlaylist = {}
let setting = {}

export {
    isCacheTable,
    getCache,
    updateCache,
    initCacheTable,
    removeGroups
}


