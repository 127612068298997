import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {isNil} from 'lodash';
import MISDialog from '../../MISDialog';
import TextInput from '../../../input/TextInput';
import {deviceService} from '../../../../services';

const EditCustomizePopup = ({initSoftware, onSave, onClose}) => {
    const {t} = useTranslation();
    const [software, setSoftware] = useState(initSoftware);
    const [disableSave, setDisableSave] = useState(false);

    const onClickSave = () => {
        if(isNil(software.softwareName)) {
            return;
        }

        setDisableSave(true);
        deviceService.updateSoftware({
            softwareId: software.softwareId, softwareName: software.softwareName
        }).then(res => {
            toastr.success(t('COM_TEXT_SUCCESS_P'));
            if(onSave) {
                onSave();
            }
        }).catch(error => {
            console.log(error);
            setDisableSave(false);
        });
    };

    return (
        <MISDialog 
            dialog={{
                title: initSoftware.softwareType === 'Custom Logo' || initSoftware.softwareType === '03' ?
                    t('MIS_SID_PREMIUM_EDIT_CUSTOM_LOGO') : t('MIS_SID_20_EDIT_DEFAULT_CONTENT'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 420,
                height: 54,
                // position: {x: -230, y: -100},
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onClickSave,
                        disable: disableSave,
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop'>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{initSoftware.softwareType === 'Custom Logo' || initSoftware.softwareType === '03'
                                    ? t('MIS_SID_PREMIUM_LOGO_NAME') : t('TEXT_CONTENT_NAME_P')}
                                </th>
                                <td>
                                    <TextInput width={240} value={software.softwareName} onChange={e => setSoftware({...software, softwareName: e.target.value})} maxLength={50} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default EditCustomizePopup;