import React, {useEffect, useState} from 'react'
import {Trans, useTranslation, withTranslation} from "react-i18next";
import {settingService, userService} from '../../services';
import {systemService} from "../../services/system.service";
import {isEmpty, isNil, set, toInteger, toUpper} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import WhiteButton from "../../components/button/WhiteButton";
import Select from "../../components/selectbox/Select";
import Checkbox from "../../components/checkbox/Checkbox";
import TextInput from "../../components/input/TextInput";
import {Loading} from "../../components/loading/Loading";
import "./ServerSettings.css";
import {commonConstants, settingConstants} from "../../constants";
import {connect, useDispatch} from "react-redux";
import {menuAction, popupAction, settingsAction} from "../../actions";
import {deviceOptions} from '../../components/misopt';

import ServerSettingsAdmin from './ServerSettingsAdmin';
import ServerSettingsOrgan from './ServerSettingsOrgan';
import {getErrorMessage} from '../../helper/responseHandler';
import i18n from "../../language/i18n";


const ServerSettings = (props) => {

    const { addPopup, closePopup, loadSettings, updateSettings } = props;
    const { data, changed, me, organs } = props;

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [init, setInit] = useState({fetchComplete: false});
    const [myInfo, setMyInfo] = useState(me);
    const [settings, setSettings] = useState({
        data: data,
        changed: changed
    });
    const [organInfo, setOrganInfo] = useState(organs);

    const SERVER_ADMIN = "Server Administrator";

    useEffect(() => {
        setInit({...init, height : window.innerHeight});
        if(isEmpty(settings.data)){
            getUserInfo();
        }
    }, []);

    useEffect(() => {
        if(isNil(myInfo.role) || isNil(myInfo.organizationId)){
            return;
        }
        if(isEmpty(settings.data)){
            fetchSettingsData(myInfo.organizationId);
        }
    }, [myInfo]);

    useEffect(() => {
        if(isEmpty(settings.data)){
            return;
        }
        setInit({...init, fetchComplete: true});
    }, [settings]);

    const onSetData = (type, id, value) => {
        type = type.replace(settingConstants.NO_CHANGED, "");
        id = id.replace(settingConstants.NO_CHANGED, "");

        let data = {...settings.data};
        set(data[type], id, value);

        let changed = {...settings.changed};

        setSettings({data : data, changed: changed});
        updateSettings(data, changed, myInfo, organInfo);
    }

    const onChange = (type, id, value) => {

        if(id && id.lastIndexOf(settingConstants.NO_CHANGED)>-1){
            onSetData(type, id, value);
            return;
        }

        let data = {...settings.data};
        set(data[type], id, value);
        let changed = {...settings.changed};
        if(isNil(changed[type])){
            changed[type] = {};
        }
        if(type == 'general' && id == 'contentApprovers'){
            set(changed[type], "useContentApproval", true);
        }
        if(type == 'passwordPolicy' && id == 'customPasswordChangePeriod'){
            set(changed[type], "passwordChangePeriod", "custom");
        }
        if(type == 'alarmMailing' && id == 'customDeviceDisconnectDuration'){
            set(changed[type], "deviceDisconnectDuration", "custom");
        }
        set(changed[type], id, value);

        setSettings({data : data, changed: changed});
        updateSettings(data, changed, myInfo, organInfo);
    }

    const fetchSettingsData = (organizationId) => {
        setInit({...init, fetchComplete: false});
        let organ = {...organInfo, selected: organizationId};
        if(myInfo.role == SERVER_ADMIN && organizationId == 0){
            settingService.fetchServerSettings().then(res => {
                setSettings({
                    ...settings,
                    data: res.items,
                    changed: {}
                });
                setInit({...init, fetchComplete: true});
                loadSettings(res.items, myInfo, organ);
            });
        }else{
            settingService.fetchOrganSettings(organizationId).then(res => {
                setSettings({
                    ...settings,
                    data: res.items,
                    changed: {}
                });
                setInit({...init, fetchComplete: true});

                loadSettings(res.items, myInfo, organ);
            });
        }
    }

    const getUserInfo = () => {
        settingService.fetchMyAccount().then(res => {
            setMyInfo({organizationId: res.items.organizationId, role: res.items.role, userId: res.items.userId});
            setOrganInfo({...organInfo, selected: res.items.organizationId});
            if(res.items.role == SERVER_ADMIN){
                getOrganList();
            }
        });
    }

    const ldapValidationCheck = (data) => {
        if(isEmpty(data.ldapServerAddress)){
            toastr.error(t("MIS_MESSAGE_ENTER_LDAP_SERVER_ADDRESS_P"));
            return false;
        }
        if(isEmpty(data.ldapRootDN)){
            toastr.error(t("MIS_MESSAGE_ENTER_ROOT_DN_P"));
            return false;
        }
        if(isEmpty(data.ldapManagerDN)){
            toastr.error(t("MIS_MESSAGE_ENTER_MANAGER_DN_P"));
            return false;
        }
        if(isEmpty(data.ldapManagerPassword)){
            toastr.error(t("MIS_MESSAGE_ENTER_MANAGER_PASSWORD_P"));
            return false;
        }

        if(data.ldapServerAddress.trim() == "" || data.ldapRootDN.trim() == "" || data.ldapManagerDN.trim() == "" || data.ldapManagerPassword.trim() == ""){
            toastr.error(t("MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG"));
            return false;
        }

        return true;
    }

    const smtpValidationCheck = (data) => {
        if(isEmpty(data.smtpServerAddress)){
            toastr.error(t("MESSAGE_SETUP_INPUT_SMTP_ADDRESS_P"));
            return false;
        }
        if(data.smtpServerAddress.trim() == ""){
            toastr.error(t("MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG"));
            return false;
        }
        if(data.useSmtpAuth){
            if(isEmpty(data.smtpSingInId) || isEmpty(data.smtpPassword)){
                toastr.error(t("MIS_SID_20_NO_SMTP_INFORMATION_IN_THE_SERVER_SETTINGS"));
                return false;
            }

            if(data.smtpSingInId.trim() == "" || data.smtpPassword.trim() == ""){
                toastr.error(t("MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG"));
                return false;
            }

        }
        if(isEmpty(data.smtpServerPort + "")){
            toastr.error(t("TABLE_PORT_P") + " : " +t("MIS_SID_20_NO_SMTP_INFORMATION_IN_THE_SERVER_SETTINGS"));
            return false;
        }
        return true;
    }

    const onSave = () => {
        let changedData = {...settings.changed};
        if(isEmpty(changedData)){
            toastr.error(t("MIS_SID_NO_CHANGES_HAVE_BEEN_MADE"));
            return;
        }

        let originData = {...settings.data};
        if(organInfo.selected != 0 && !isNil(changedData["general"]) &&
                !isNil(changedData["general"].useContentApproval) && changedData["general"].useContentApproval){
            let selectApprovals = false;
            originData["general"].contentApprovers.map(approver => {
                if(approver.contentApprover=="Y"){
                    selectApprovals = true;
                }
            })
            if(!selectApprovals){
                toastr.error(t("MIS_SID_CONTENT_APPROVAL") + " : " + t("MESSAGE_COMMON_MODIFIED_LEAST_ONE_P"));
                return;
            }

        }
        if(!isNil(changedData["passwordPolicy"])
            && !isNil(changedData["passwordPolicy"].passwordChangePeriod) && changedData["passwordPolicy"].passwordChangePeriod == "custom"){
                if(isNil(changedData["passwordPolicy"].customPasswordChangePeriod)){
                    toastr.error(t("MESSAGE_DEVICE_ENTER_ONLY_NUM_P"));
                    return;
                }
                onChange("passwordPolicy", "passwordChangePeriod", changedData["passwordPolicy"].customPasswordChangePeriod);
        }
        if(!isNil(changedData["alarmMailing"])
            && !isNil(changedData["alarmMailing"].deviceDisconnectDuration) && changedData["alarmMailing"].deviceDisconnectDuration == "custom"){
                if(isNil(changedData["alarmMailing"].customDeviceDisconnectDuration)){
                    toastr.error(t("MESSAGE_DEVICE_ENTER_ONLY_NUM_P"));
                    return;
                }
                onChange("alarmMailing", "deviceDisconnectDuration", changedData["alarmMailing"].customDeviceDisconnectDuration);
        }

        if(!isNil(changedData["smtpServer"])){
            changedData["smtpServer"] = {...originData["smtpServer"]};
            if((organInfo.selected == 0 && changedData["smtpServer"].useSmtpServer) ||
                    organInfo.selected != 0 && changedData["smtpServer"].useSmtpServer &&  !changedData["smtpServer"].useSmtpServerSetting){
                const valid = smtpValidationCheck(changedData["smtpServer"]);
                if(!valid){
                    return;
                }
            }
        }


        if(!isNil(changedData["autoTimezone"])){
            set(changedData, "autoTimezone", originData["autoTimezone"]);
            if(changedData["autoTimezone"].useAutoTimezone && isNil(changedData["autoTimezone"].selectedTimeZone)){
                set(changedData["autoTimezone"], "selectedTimeZone", deviceOptions.timeZone[0].value);
            }

        }

        if(!isNil(changedData["general"]) && !isNil(changedData["general"].useContentApproval) && changedData["general"].useContentApproval){
            set(changedData["general"], "contentApprovers", originData["general"].contentApprovers);
        }
        if(isNil(changedData["ldapServer"])){
            saveChanges(changedData);
            return;
        }

        changedData["ldapServer"] = {...originData["ldapServer"]};
        if(!changedData["ldapServer"].useLdap){
            saveChanges(changedData);
            return;
        }

        if(organInfo.selected != 0 &&
            (!isNil(changedData["ldapServer"].useLdapServerSettings) && changedData["ldapServer"].useLdapServerSettings)){
            saveChanges(changedData);
            return;
        }
        //check LDAP
        const valid = ldapValidationCheck(changedData["ldapServer"]);
        if(!valid){
            return;
        }
        const promise = checkLdapConnection(changedData["ldapServer"]);
        if(promise){
            addPopup({id: 'SERVER_SETTING_LOADING', type: commonConstants.LOADING_POPUP});
            promise.then(res => {
                const result = res.items;
                if(!result){
                    toastr.error(t("MIS_MESSAGE_LDAP_SERVER_CONNECT_FAILED_P"));
                }else{
                    toastr.success("LDAP - " + t("MIS_MESSAGE_SUCCESS_LOGIN_P"));
                    saveChanges(changedData);
                }
            }).catch(e => {
                toastr.error(getErrorMessage(e));
            }).finally(()=>{
                closePopup('SERVER_SETTING_LOADING')
            });
        }
    }

    const saveChanges = (changedData) => {
        const selectedOrgId = organInfo.selected;
        addPopup({id: 'SERVER_SETTING_LOADING', type: commonConstants.LOADING_POPUP});
        if(myInfo.role == SERVER_ADMIN && selectedOrgId == 0){
            settingService.updateServerSettings(changedData).then(res => {
                setSettings({
                    ...settings,
                    data: res.items,
                    changed: {}
                });
                setMisOptForSetting();
                updateSettings(res.items, {}, myInfo, organInfo);
                toastr.success(t("MIS_MESSAGE_STATISTICS_SAVE_P"));
            }).catch(res => {
                toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
            }).finally(()=>{
                closePopup('SERVER_SETTING_LOADING')
            });
        }else{
            settingService.updateOrganSettings(selectedOrgId, changedData).then(res => {
                setSettings({
                    ...settings,
                    data: res.items,
                    changed: {}
                });
                setMisOptForSetting();
                updateSettings(res.items, {}, myInfo, organInfo);
                toastr.success(t("MIS_MESSAGE_STATISTICS_SAVE_P"));
            }).catch(res => {
                toastr.error(t("COM_IDS_MSG_UNEXPEXTED_ERROR"));
            }).finally(()=>{
                closePopup('SERVER_SETTING_LOADING')
            });
        }
    }

    const setMisOptForSetting = () => {
        systemService.fetchMenus().then(res => {
            const menus = res.items;
            dispatch(menuAction.initMenus(menus));
        }).catch((e) => {
            getErrorMessage(e);
        })
    }

    const checkLdapConnection = (ldapInfo) => {
        return settingService.checkLdapConnection(ldapInfo);
    }

    const renderServerSettingsView = (organizationId) => {
        switch(organizationId){
            case 0:
                return (
                    <ServerSettingsAdmin settings={settings} onChange={onChange} organInfo={organInfo} myInfo={myInfo} {...props}/>
                )
            default:
                return (
                    <ServerSettingsOrgan settings={settings} onChange={onChange} organInfo={organInfo} {...props}/>
                )
        }
    }

    const getOrganList = () => {
        userService.fetchOrganizations().then(res => {
            let options = [{value : 0, title: t("MIS_BUTTON_SERVER_MANAGEMENT_P")}];
            let organs = res.items;
            organs.forEach((organ) => {
                if(organ.organizationId != 0){
                    options.push({value : organ.organizationId, title: organ.groupName})
                }

            })
            setOrganInfo({...organInfo, list: options});
            let organ = {...organInfo, list: options};
            loadSettings(settings.data, myInfo, organ);
        })
    }

    const getWindowHeight = () => {
        return window.innerHeight - 180;
     }


    const onChangeOrgan = (e, value) => {
        setOrganInfo({...organInfo, selected: value});
        fetchSettingsData(value);
    }

    return(
        <>
        {
            <div style={{display: props.currContent === 'SERVER_SETTINGS' ? 'block':'none'}}>
                <div className="contents_buttonWrap" >
                    <div className="leftButton float_l">
                        <WhiteButton id={"EDIT_SERVER_SETTING"} name={t("COM_BUTTON_SAVE")} onClick={onSave}  />
                        <WhiteButton id={"CANCEL_SERVER_SETTING"} name={t("BUTTON_CANCEL_P")} onClick={()=>fetchSettingsData(organInfo.selected)} />
                    </div>
                </div>
                <div className="settings_list mt20" style={{borderTop: '1px solid#f2f2f2', overflowY: 'auto', overflowX: 'hidden', height: getWindowHeight()}}>
                    {
                        !isNil(myInfo.role) && myInfo.role == SERVER_ADMIN && !isNil(organInfo.list) && organInfo.list.length > 0 &&
                        <div style={{marginLeft : '24px', marginTop: '20px'}}>
                            <Select width={300} propertyName={"SELECT_ORGAN"} selects={organInfo.list} value={organInfo.selected} multiLang={false} onChange={onChangeOrgan} useEllipsis={true}/>
                        </div>

                    }
                    {
                        !init.fetchComplete &&
                        <div style={{height: getWindowHeight(), textAlign: 'center', paddingTop: getWindowHeight()/2-60}}><Loading /></div>
                    }
                    {
                        init.fetchComplete &&
                        renderServerSettingsView(organInfo.selected)
                    }
                </div>
            </div>
        }
        </>
    )
}

/* event handler */
export const onChangeCheckBox= (e, type, onChange) => {
    onChange(type, e.target.getAttribute('data-name'), e.target.checked);
}

export const onChangeNumberInput = (e, value, type, onChange) => {
    onChange(type, e.target.getAttribute('data-name'), value);
}

export const onChangeSelect = (e, value, type, onChange) => {
    onChange(type, e.target.getAttribute('data-name'), value);
}

export const onChangeRadioGroup = (e, value, type, onChange) => {
    onChange(type, e.target.getAttribute('data-name'), value);
}

export const onChangeTextInput = (e, type, onChange) => {
    onChange(type, e.target.getAttribute('data-name'), e.target.value);
}

export const onChangeTimeInput = (e, value, type, onChange) => {
    onChange(type, e.target.getAttribute('data-name'), value);
}

/* common view */
export const smtpServerSettingsCommon = (t, items, changed, onChange) => {
    const type = 'smtpServer';
    return (
        [
            {id: 'SERVER_SETTING_SMTP_ALARM_MAILING', name: <Trans i18nKey={"TABLE_IS_MAILING_P"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpServer),
            value: <Checkbox id={'useSmtpServer'} propertyName={'useSmtpServer'} name={t("MESSAGE_SETUP_SEND_MAIL_TO_ADMIN_WHEN_ALARM_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useSmtpServer}/>},
            {id: 'SERVER_SETTING_SMTP_SERVER_ADDRESS', name: <Trans i18nKey={"MIS_TEXT_SERVER_ADDRESS_P"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpServerAddress),
            value: <TextInput propertyName={'smtpServerAddress'} value={items.smtpServerAddress} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!items.useSmtpServer}/> },
            {id: 'SERVER_SETTING_SMTP_AUTHENTICATION', name: <Trans i18nKey={"MIS_TEXT_AUTHENTICATION_P"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpAuth),
            value: <Checkbox id={'useSmtpAuth'} propertyName={'useSmtpAuth'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useSmtpAuth} disabled={!items.useSmtpServer}/> },
            {id: 'SERVER_SETTING_SMTP_LOGIN_ID', name: <Trans i18nKey={"COM_TEXT_LOGIN_ID_P"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpSingInId),
            value: <TextInput propertyName={'smtpSingInId'} value={items.smtpSingInId} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!(items.useSmtpServer&&items.useSmtpAuth)}/> },
            {id: 'SERVER_SETTING_SMTP_PASSWORD', name: <Trans i18nKey={"TEXT_PASSWORD_P"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpPassword),
            value: <TextInput propertyName={'smtpPassword'} value={items.smtpPassword} onChange={(e)=>onChangeTextInput(e, type, onChange)} type={"password"} disabled={!(items.useSmtpServer&&items.useSmtpAuth)}/> },
            {id: 'SERVER_SETTING_SMTP_PORT', name: <Trans i18nKey={"TABLE_PORT_P"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].smtpServerPort),
            value: <TextInput propertyName={'smtpServerPort'} value={items.smtpServerPort} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!items.useSmtpServer} /> },
            {id: 'SERVER_SETTING_SMTP_SSL', name: "SSL",
            changed: !isNil(changed[type])&&!isNil(changed[type].useSmtpSSL),
            value: <Checkbox id={'useSmtpSSL'} propertyName={'useSmtpSSL'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useSmtpSSL} disabled={!items.useSmtpServer}/> }
        ]
    )
}

export const ldapServerSettingsCommon = (t, items, changed, onChange) => {
    const type = 'ldapServer';

    const getLdapAddress = () => {
        let protocol = 'ldap://';
        if(items.useLdapSSL) {
            protocol = 'ldaps://';
        }

        return (
            <>
                <div style={{float: 'left', marginRight: '2px'}}>{protocol}</div>
                <div style={{float: 'left'}}>
                    <TextInput propertyName={'ldapServerAddress'} value={items.ldapServerAddress} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!items.useLdap} />
                </div>
            </>
        )
    }
    return (
        [
            {id: 'SERVER_SETTING_LDAP_USE', name: <Trans i18nKey={"COM_SETUP_NEW_STRING16_P"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].useLdap),
            value: <Checkbox id={'useLdap'} propertyName={'useLdap'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useLdap}/>},
            {id: 'SERVER_SETTING_LDAP_SSL', name: 'SSL',
            changed: !isNil(changed[type])&&!isNil(changed[type].useLdapSSL),
            value: <Checkbox id={'useLdapSSL'} propertyName={'useLdapSSL'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useLdapSSL} disabled={!items.useLdap}/>},
            {id: 'SERVER_SETTING_LDAP_ADDRESS', name: <Trans i18nKey={"MIS_TEXT_SERVER_ADDRESS_P"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].ldapServerAddress),
            value: getLdapAddress()},
            {id: 'SERVER_SETTING_LDAP_ROOT_DN', name: <Trans i18nKey={"MIS_SID_20_ROOT_DN"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].ldapRootDN),
            value: <TextInput propertyName={'ldapRootDN'} value={items.ldapRootDN} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!items.useLdap} />},
            {id: 'SERVER_SETTING_LDAP_MANAGER_DN', name: <Trans i18nKey={"MIS_SID_20_MANAGER_DN"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].ldapManagerDN),
            value: <TextInput propertyName={'ldapManagerDN'} value={items.ldapManagerDN} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!items.useLdap} />},
            {id: 'SERVER_SETTING_LDAP_PASSWORD', name: <Trans i18nKey={"MIS_SID_20_MANAGER_PASSWORD"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].ldapManagerPassword),
            value: <TextInput propertyName={'ldapManagerPassword'} value={items.ldapManagerPassword} type={"password"} onChange={(e)=>onChangeTextInput(e, type, onChange)}  disabled={!items.useLdap} />},
            {id: 'SERVER_SETTING_LDAP_ORGANIZATION_SYNC', name: <Trans i18nKey={"MIS_SID_20_USE_ORGANIZATION_SYNC"} />,
            changed: !isNil(changed[type])&&!isNil(changed[type].useOrganizationSync),
            value: <Checkbox id={'useOrganizationSync'} propertyName={'useOrganizationSync'} name={t("TEXT_ENABLE2_P")} onChange={(e)=>onChangeCheckBox(e, type, onChange)} checked={items.useOrganizationSync} disabled={!items.useLdap}/>}
        ]
    )
}

export const saveNotificationsSetting = (userNotifications, orgId, callback) => {
    settingService.updateNotificationsForUsers(userNotifications, orgId).then(res => {
        callback();
    }).catch(res => {
        toastr.error(<Trans i18nKey={"COM_IDS_MSG_UNEXPEXTED_ERROR"} />);
    })
}

export const alarmMailingWeekly = (week, callback, disabled) => {
    let weekArray = isNil(week)?'0000000'.split(''):week.split('');
    const days = [
        {id: 'weeklySun', value: 0, name: <Trans i18nKey={'COM_TEXT_DAY_SUNDAY_P'} />},
        {id: 'weeklyMon', value: 1, name: <Trans i18nKey={'COM_TEXT_DAY_MONDAY_P'} />},
        {id: 'weeklyTue', value: 2, name: <Trans i18nKey={'COM_TEXT_DAY_TUESDAY_P'} />},
        {id: 'weeklyWed', value: 3, name: <Trans i18nKey={'COM_TEXT_DAY_WEDNESDAY_P'} />},
        {id: 'weeklyThu', value: 4, name: <Trans i18nKey={'COM_TEXT_DAY_THURSDAY_P'} />},
        {id: 'weeklyFri', value: 5, name: <Trans i18nKey={'COM_TEXT_DAY_FRIDAY_P'} />},
        {id: 'weeklySat', value: 6, name: <Trans i18nKey={'COM_TEXT_DAY_SATURDAY_P'}/>}
    ]

    const onClickCheck = (e) => {
        const index = toInteger(e.target.getAttribute("data-name"));
        if(e.target.checked){
            weekArray[index] = "1";
        } else {
            weekArray[index] = "0";
        }
        callback(weekArray.join(""));
    }

    return (
        <div >
            {
                days.map(
                    (day, index) => <Checkbox key={index} id={day.id} propertyName={day.value} name={day.name} checked={weekArray[index]==1?true:false} onClick={onClickCheck} disabled={disabled}/>
                )
            }
        </div>
    )
}



export const openPrivacyPolicySettingPopup = (props, onChange) => {

    const { addPopup, closePopup } = props;

    const privacyPolicyStorage = localStorage.getItem(settingConstants.PRIVACY_POLICY);
    if(isEmpty(privacyPolicyStorage)){
        toastr.error(i18n.t('COM_TV_SID_PRIVACY_POLICY_FOR_GER') + " - " + i18n.t('MIS_SID_FAILED_TO_DISPLAY_ALARM_MAIL_DETAILS'));
        return;
    }
    const privacyPolicyJson = JSON.parse(privacyPolicyStorage);

    Promise.all([
        settingService.fetchServerSettings()
    ]).then(res => {
        addPopup({
            id: settingConstants.PRIVACY_POLICY_POPUP,
            type: settingConstants.PRIVACY_POLICY_POPUP,
            title: <Trans i18nKey={'COM_TV_SID_PRIVACY_POLICY_FOR_GER'} /> ,
            privacyPolicySettings: res[0].items.privacyPolicy,
            privacyPolicyList: privacyPolicyJson,
            onSave: (privacyPolicySettings) => {
                let changedPrivacyPolicySettings = {};

                changedPrivacyPolicySettings.privacyPolicy = privacyPolicySettings;
                settingService.updateServerSettings(changedPrivacyPolicySettings).then(res => {
                    settingConstants.PRIVACY_POLICY_LOCATION_WITHOUT_AGREE !== toUpper(privacyPolicySettings.privacyPolicyLocation) && toastr.success(props.t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                }).catch(res => {
                    toastr.error(props.t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
                });

                if (onChange !== null && onChange !== undefined) {
                    onChange("privacyPolicy", "privacyPolicyLocation"+settingConstants.NO_CHANGED, privacyPolicySettings.privacyPolicyLocation);
                    onChange("privacyPolicy", "privacyPolicyEnable"+settingConstants.NO_CHANGED, privacyPolicySettings.privacyPolicyEnable);
                }

                closePopup(settingConstants.PRIVACY_POLICY_POPUP);
            },

            onClose: () => {
                closePopup(settingConstants.PRIVACY_POLICY_POPUP)
            },
        });
    });

}


export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        loadSettings: (data, myInfo, organInfo) => dispatch(settingsAction.loadSettings(data, myInfo, organInfo)),
        updateSettings: (data, updated, myInfo, organInfo) => dispatch(settingsAction.updateSettings(data, updated, myInfo, organInfo))
    })
)(withTranslation()(ServerSettings));

