import {useMISOpt} from "../../../misopt";
import {toMomentDateTimeFormat, useFilter} from "../../../../helper";
import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo, useState} from "react";
import {deviceService, settingService} from "../../../../services";
import Moment from "moment";
import MISDialog from "../../MISDialog";
import ReactTable from "react-table";
import Pagination from "../../../table/Pagination";
import E2ELicenseHistoryPopup from "./E2ELicenseHistoryPopup";
import {snakeCase} from "lodash";
import {updateCache} from "../../../../helper/cache/tableCache";
import {menuAction, popupAction} from "../../../../actions";
import {useDispatch} from "react-redux";
import Checkbox from "../../../checkbox/Checkbox";
import DeviceNameCell from "../../../device/DeviceNameCell";
import DeviceRelativeTimeCell from "../../../device/DeviceRelativeTimeCell";
import WhiteButton from "../../../button/WhiteButton";
import {commonConstants} from "../../../../constants";
import {toastr} from 'helper/toastrIntercept';

const E2ELicenseSwapPopup = ({title, onClose, licKey, oldDeviceId}) => {
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [filter, setFilter, onPageChange] = useFilter({
        pageSize : 10,
        page: 0,
        reload: false
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
    });
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    useEffect(() => {
        fetchData();
    }, [filter]);

    const fetchData = () => {
        const {page, pageSize} = filter;

        setData({...data, loading: true});

        deviceService.fetchDeviceFilter({
            startIndex: page * pageSize + 1,
            pageSize,
            groupType: 'unapproval',
        }).then(res => {
            setData({
                ...data,
                loading: false,
                items: res.items.map(item => {
                    item.deviceName = item.deviceName.replace(/"/g, '_');
                    return item;
                }),
                totalCount: res.totalCount
            });
        });
    }

    const swapPopup = (deviceId) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: 'Swap license',
            message: t("COM_SID_ARE_YOU_SURE_WANT_TO_CONTINUE"),
            onClickYes: ()=>handleSwap(deviceId),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    const handleSwap = (deviceId) =>{
        console.log(licKey[0], oldDeviceId[0], deviceId)
        settingService.swapE2ELicense(licKey, oldDeviceId[0], deviceId)
            .then(() => {
                toastr.success('success')
            })
            .catch(() => {
                toastr.error('error')
            })
    }


    const columns = [
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: 'deviceName',
            width: 150
        },
        {
            Header: t('DID_ADMIN_LICENSE_MAC'),
            accessor: 'deviceId',
            width: 150
        },
        {
            Header: t('TABLE_IP_ADDR_P'),
            accessor: 'ipAddress',
            Cell: data => <span style={{whiteSpace: 'normal',wordBreak:'break-all'}}>{data.value}</span>,
            width: 100
        },
        {
            Header: t('TABLE_DEVICE_MODEL_NAME_P'),
            accessor: 'deviceModelName',
            width: 100
        },
        {
            Header: t('TABLE_DEVICE_SERIAL_P'),
            accessor: 'serialDecimal',
            width: 130
        },
        {
            Header: t('ADMIN_DEVICEEVENT_ALARMRULE_ASSIGNRULE_CONTENTS_REGISTERED'),
            accessor: 'createDate',
            Cell: data => <DeviceRelativeTimeCell value={data.value} />,
        },
        {
            Header: 'Swap',
            accessor: 'deviceId',
            Cell: data =><WhiteButton width={86} name='Swap' onClick={()=>swapPopup(data.value)}/>
        }
    ]

    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    modal: true,
                    width: 920,
                    height: 600,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ACTIVATION_ONLINE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ]
                }}>
                <div>
                    <ReactTable
                        data={items}
                        loading={loading}
                        columns={columns}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        manual
                        style={{ height : 600 ,border: '0px'}}
                        showPagination={false}
                        columns={columns}
                        usePagination={true}
                    />
                    <Pagination totalCount={totalCount} page={filter.page} pageSize = {10} pages={pages} onPageChange={onPageChange}
                                style={{ width : 720, border: '0px', position: 'relative', margin: 0}}
                    />
                </div>

            </MISDialog>

        </div>
    )
}

export default E2ELicenseSwapPopup;