import React, {useEffect, useRef, useState} from 'react';
import Moment from 'moment';
import {useTranslation} from "react-i18next";
import {
    ADVERTISEMENT_SCHEDULE_VIEW_LIST,
    ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY,
    ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY,
    commonConstants
} from "../../../../constants";
import TimeHeader from "./TimeHeader";
import SelectADPlaylistPopup from "../../../../components/popup/schedule/content/SelectADPlaylistPopup";
import {useDispatch} from "react-redux";
import {popupAction, scheduleAction} from "../../../../actions";
import {createUUID} from "../../../../helper/schedule/scheduleUtils";
import {toastr} from 'helper/toastrIntercept';
import {extendMoment} from "moment-range";
import TimeLines from "./TimeLines";
import AdvertisementListView from "./AdvertisementListView";
import SlotSettingPopup from "../../../../components/popup/schedule/content/SlotSettingPopup";
import {playlistService} from "../../../../services/playlist.service";

const AdvertisementScheduler = ({mode, slots, deviceType, deviceTypeVersion}) => {

    const moment = extendMoment(Moment);

    const ref = useRef(null);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [adWrap, setAdWrap] = useState({});
    const [slotSetting, setSlotSetting] = useState(false);
    const [adSchedule, setAdSchedule] = useState(undefined);
    const [headerLeftPosition, setHeaderLeftPosition] = useState(0);

    const firstDay = 0;
    const lastDay = Number(firstDay) + Number(7);

    const [view, setView] = useState(ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY);

    const [tableTime, setTableTime] = useState({
        /*tableStartTime: view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? Moment().weekday(firstDay).startOf('day') : Moment().startOf('month'),
        tableEndTime: ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? Moment().weekday(lastDay).startOf('day') : Moment().endOf('month')*/
    })

    const titleFormat = 'YYYY-MM-DD';

    const sc_width = window.innerWidth - 300;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const sc_height = height-250;
    const sc_main_width = sc_width - 100;

    const closeADPlaylistPopup = () => {
        setAdSchedule({show: false})
    }

    const openADPlaylistPopup = (event) => {
        setAdSchedule({show: true, event: event})
    }

    const isOverlapping = (oldEvent, updateStart, updateEnd) => {
        const oldStart = Moment(oldEvent.startDate+' '+oldEvent.startTime);
        const oldEnd = Moment(oldEvent.stopDate+' '+oldEvent.stopTime);
        return oldStart.isBefore(updateEnd) && updateStart.isBefore(oldEnd);
    }

    const onUpdateSchedule = (slotId, event) => {
        const events = slots.find(slot => slot.slotId === slotId).events;
        const updateStart = Moment(event.startDate+' '+event.startTime);
        const updateEnd = Moment(event.stopDate+' '+event.stopTime);
        let updateSlots, valid = true;;
        for (const e of events) {
            if (e.scheduleId !== event.scheduleId) {
                if (isOverlapping(e, updateStart, updateEnd)) {
                    valid = false;
                }
            }
        }
        if (valid) {
            dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}))
            playlistService.fetchPlaylistDetailById(event.contentId)
                .then(
                    res=> {
                        if (res) {
                            const thumbnails = [];
                            res.items.contents.map(playlist=>playlist.thumbFileId);
                            for (const content of res.items.contents) {
                                thumbnails.push({thumbnailFileId: content.thumbFileId, thumbnailFileName: content.thumbFileName})
                            }
                            event.thumbnails = thumbnails;
                            if (res.items.totalSize) {
                                event.fileSize = res.items.totalSize;
                            }
                            if (event.scheduleId === '') {
                                event.scheduleId = createUUID();
                                const start = Moment(event.startDate+' '+event.startTime);
                                const end = Moment(event.startDate+' '+event.stopTime);
                                if (end > start) {
                                    event.durationInSeconds = Moment.duration(end - start).asSeconds();
                                } else {
                                    event.durationInSeconds = Moment.duration(start - end).asSeconds();
                                }
                                updateSlots = slots.map(slot => slot.slotId === slotId ? {...slot, events: slot.events.concat(event)} : slot)
                            } else {
                                const updateEvents = events.map(e => e.scheduleId === event.scheduleId ? {...e, ...event} : e);
                                updateSlots = slots.map(slot => slot.slotId === slotId ? {...slot, events: updateEvents} : slot)
                            }
                            dispatch(scheduleAction.updateContentADSchedule(mode, updateSlots));
                        }
                    }
                )
                .then(closeADPlaylistPopup())
                .finally(dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)))
        } else {
            toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
        }
        return valid;
    }

    const onRemoveSchedule = (slotId, scheduleId) => {
        const slot = slots.find(slot => slot.slotId === slotId);
        if (slot !== undefined) {
            const events = slot.events;
            const updateEvents = events.filter(e => e.scheduleId !== scheduleId);
            const updateSlots = slots.map(slot => slot.slotId === slotId ? {...slot, events: updateEvents} : slot);
            dispatch(scheduleAction.updateContentADSchedule(mode, updateSlots));
            setAdSchedule({show: false});
        }
    }

    const moveCalendar = (mode) => {
        if (mode === 'left'){
            if (view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY) {
                const time = tableTime.tableStartTime.clone().startOf('day');
                setTableTime({
                    tableStartTime: time.clone().weekday(-7),
                    tableEndTime: time.clone().weekday(0)
                })
            } else if (view === ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY) {
                const time = tableTime.tableStartTime.subtract(1, 'months');
                setTableTime({
                    tableStartTime: time.clone().startOf('month'),
                    tableEndTime: time.clone().endOf('month')
                })
            }
        } else if (mode === 'right') {
            if (view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY) {
                const time = tableTime.tableStartTime.clone().startOf('day');
                setTableTime({
                    tableStartTime: time.clone().weekday(7),
                    tableEndTime: time.clone().weekday(14)
                })
            } else if (view === ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY) {
                const time = tableTime.tableStartTime.add(1, 'months');
                setTableTime({
                    tableStartTime: time.clone().startOf('month'),
                    tableEndTime: time.clone().endOf('month')
                })
            }
        } else if (mode === 'today') {
            const now = view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? Moment().startOf('day') : Moment().startOf('month');
            if (view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY) {
                setTableTime({
                    tableStartTime: now.clone().weekday(0),
                    tableEndTime : now.clone().weekday(7)
                });
            } else if (view === ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY) {
                setTableTime({
                    tableStartTime: now.clone(),
                    tableEndTime : now.clone().endOf('month')
                });
            }
        }
    }

    const changeView = (mode) => {
        setView(mode);
    }

    const onScroll = (e) => {
        const left = e.target.scrollLeft;
        setHeaderLeftPosition(left*-1)
    }

    const openSlotSettingPopup = () => {
        setSlotSetting(!slotSetting);
    }

    useEffect(()=> {
        const width = ref.current ? ref.current.offsetWidth : 0;
        setAdWrap({
            width: width
        })
    }, [ref.current])

    useEffect(()=> {
        setTableTime({
            tableStartTime: view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? Moment().weekday(firstDay).startOf('day') : Moment().startOf('month'),
            tableEndTime: view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? Moment().weekday(lastDay).startOf('day') : Moment().endOf('month')
        })
    }, [view])


    return (
        <div className={'advertisement_schedule_wrap'} ref={ref} style={{padding: 20}}>
            {
                adSchedule !== undefined && adSchedule.show &&
                    <SelectADPlaylistPopup adEvent={adSchedule.event} closePopup={closeADPlaylistPopup} onUpdateSchedule={onUpdateSchedule} onRemoveSchedule={onRemoveSchedule} deviceType={deviceType} deviceTypeVersion={deviceTypeVersion} programType={'ADV'}/>
            }
            {
                slotSetting &&
                    <SlotSettingPopup mode={mode} slots={slots} closePopup={openSlotSettingPopup}/>
            }
            {
                adWrap !== undefined && adWrap.width !== undefined && adWrap.width > 0 &&
                    <>
                        <div className="adScheduleTitle">
                            <div className="select_date">
                                <div className="date float_l mr8">
                                    <button className="calendar_arrow prev calendarLeft" onClick={()=>moveCalendar('left')}><span></span></button>
                                    <a id="getCalendarOnAdSch" href="#">{tableTime.tableStartTime.format(titleFormat)} ~  {tableTime.tableEndTime.format(titleFormat)}</a>
                                    <button className="calendar_arrow next calendarRight" onClick={()=>moveCalendar('right')}><span></span></button>
                                </div>
                                <button className="todaybtn float_l calendarToday" onClick={()=>moveCalendar('today')}><span></span>{t('TEXT_TODAY_P')}</button>
                            </div>
                            <div className="schedule_tabwrap">
                                <ul>
                                    <li className={view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? 'on' : ''} onClick={()=>changeView(ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY)}><a href="#" id="viewModeWeekly">{t('TEXT_WEEKLY_P')}</a></li>
                                    <li className={view === ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY ? 'on' : ''} onClick={()=>changeView(ADVERTISEMENT_SCHEDULE_VIEW_MONTHLY)}><a href="#" id="viewModeMonthly">{t('TEXT_MONTHLY_P')}</a></li>
                                    <li className={view === ADVERTISEMENT_SCHEDULE_VIEW_LIST ? 'on' : ''} onClick={()=>changeView(ADVERTISEMENT_SCHEDULE_VIEW_LIST)}><a href="#" id="viewModeList">{t('BUTTON_LIST')}</a></li>
                                </ul>
                            </div>
                        </div>
                        {
                            view === ADVERTISEMENT_SCHEDULE_VIEW_LIST &&
                            <AdvertisementListView view={view} mode={mode} slots={slots} openADPlaylistPopup={openADPlaylistPopup}/>
                        }
                        {
                            view !== ADVERTISEMENT_SCHEDULE_VIEW_LIST &&
                            <div style={{marginTop:20}}>
                                <div className="sc_menu">
                                    <div className="sc_header_cell" style={{width: 100}}>
                                        <button className="adOption" onClick={openSlotSettingPopup}></button>
                                    </div>
                                    <div className="sc_header" style={{width : adWrap.width - 150}}>

                                        <TimeHeader view={view} startTime={tableTime.tableStartTime} endTime={tableTime.tableEndTime} maxWidth={adWrap.width - 150} left={headerLeftPosition}/>

                                    </div>
                                </div>
                                <div className="sc_wrapper" style={{height: sc_height}} onScroll={onScroll}>
                                    <div className="sc_data" style={{width: 100}}>
                                        <div className="sc_data_scroll">
                                            {
                                                slots && slots.length > 0 && slots.map(
                                                    slot => <div className={'timeLine'} style={{height: 150, lineHeight: '150px'}}><span title={slot.slotName}>{slot.slotName}</span></div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="sc_main_box" style={{width : adWrap.width-150}} >
                                        <TimeLines mode={mode} slots={slots} view={view} tableStartTime={tableTime.tableStartTime} tableEndTime={tableTime.tableEndTime} maxWidth={adWrap.width} height={150} openADPlaylistPopup={openADPlaylistPopup} onUpdateSchedule={onUpdateSchedule}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
            }




        </div>

    )
}
export default AdvertisementScheduler;