import React from 'react';
import {withTranslation} from "react-i18next";
import ReactDOM from "react-dom";
import {commonService} from "../../services/common.service";
import './Category.css';
import Tooltip from 'rc-tooltip';
import Tree, {TreeNode} from "rc-tree/es";
import {jsonToTreeForCategory} from "../../helper";
import {isNil} from "lodash";


function setLeaf(treeData, curKey, level) {
    const loopLeaf = (data, lev) => {
        const l = lev - 1;
        data.forEach((item) => {
            if (item.children.length > 0) {
                loopLeaf(item.children, l);
            } else if (l < 1) {
                item.isLeaf = true;
            }
        });
    };
    loopLeaf(treeData, level + 1);
}

function getNewTreeData(treeData, curKey, child, level) {
    const loop = (data) => {
        data.forEach((item) => {
            if (Number(curKey) === Number(item.key)) {
                item.children = child;
                return;
            }
            if (item.children.length > 0) {
                loop(item.children);
            }
        });
    };
    loop(treeData);
    setLeaf(treeData, curKey, level);
}

class Category extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            type: this.props.type || '',
            categories: [],
            selected : this.props.defaultCheckedKeys || [],
            checkedKeys : this.props.defaultCheckedKeys || [],
        }
    }

    componentDidMount() {
        this.openCategory();
    }

    getContainer() {
        if (!this.cmContainer) {
            this.cmContainer = document.createElement('div');
            document.body.appendChild(this.cmContainer);
        }
        return this.cmContainer;
    }
    componentWillUnmount() {
        if (this.cmContainer) {
            ReactDOM.unmountComponentAtNode(this.cmContainer);
            document.body.removeChild(this.cmContainer);
            this.cmContainer = null;
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!isNil(nextProps.defaultCheckedKeys) && this.props.defaultCheckedKeys !== nextProps.defaultCheckedKeys) {
            this.setState({
                selected: nextProps.defaultCheckedKeys
            })
        }
    }

    onLoadData = (treeNode) => {
        return new Promise((resolve) => {
            commonService.fetchCategoryById(treeNode.props.eventKey).then(
                res=>{
                    if( res.items !== undefined && res.items.length > 0 ) {
                        const treeData = [...this.state.categories];
                        getNewTreeData(treeData, treeNode.props.eventKey, jsonToTreeForCategory(res.items), 2);
                        this.setState({treeData});
                    }
                    resolve();
                }
            )
        });
    }

    openCategory = () => {
        const {show, categories} = this.state;
        if (!show && categories.length < 1) {

            // commonService.fetchCategoryById(0).then(
            //     res=> {
            //         res.items.sort(function(a, b){
            //             return a.groupName < b.groupName ? -1 : a.groupName > b.groupName ? 1 : 0;
            //         });
            //
            //         this.setState({
            //             categories: jsonToTreeForCategory(res.items),
            //             show: true
            //         })
            //     }
            // );

            commonService.fetchCategory().then(
                res=> {
                     res.items.sort(function(a, b){
                         return a.groupName < b.groupName ? -1 : a.groupName > b.groupName ? 1 : 0;
                     });

                    this.setState({
                        categories: jsonToTreeForCategory(res.items),
                        show: true
                    })
                }
            );

        } else {
            this.setState({
                show: !show
            })
        }
    }

    /*
    onCheck = ({checked}, treeNode) => {
        const {onCategoryCheck} = this.props;
        if(onCategoryCheck){
            onCategoryCheck(checked, treeNode);
        }
    }*/

    categoryCheck = (checked, treeNode) => {
        const {onCategoryCheck} = this.props;
        //Start reloading when click on "Deselect all" without any selected group before.
        //if(onCategoryCheck){
            onCategoryCheck(checked, treeNode);
        //}
    }

    //onCheck = ({checked}, treeNode) => {
    onCheck = (checkedKeys, e) => {
        if (this.props.checkStrictly) {
            const {checked} = checkedKeys;
            this.setState({selected : checked}, this.categoryCheck(checked, e));
        } else {
            this.setState({
                checkedKeys,
            }, ()=> {
                const {onCategoryCheck} = this.props;
                if (onCategoryCheck) {
                    onCategoryCheck(checkedKeys);
                }
            });


        }
    }


    onSelect = (e, treeNode) => {
        // TODO
        // 선택된 항목에 대해 다시 선택시 KEY 값이 없음
    }

    onDeSelectAll = () => {
        if (this.props.checkStrictly) {
            this.setState({selected: []}, this.categoryCheck([]));
        } else {
            this.setState({
                checkedKeys: []
            }, ()=> {
                const {onCategoryCheck} = this.props;
                if (onCategoryCheck) {
                    onCategoryCheck([]);
                }
            })
        }
    }

    onMouseEnter= (info ) =>{
        if (this.toolTip) {
            ReactDOM.unmountComponentAtNode(this.cmContainer);
            this.toolTip = null;
        }
        this.toolTip = (
            <Tooltip
                trigger="click"
                placement="bottomRight"
                prefixCls="rc-tree-contextmenu"
                defaultVisible
            >
                <span />
            </Tooltip>
        );

        const container = this.getContainer();
        Object.assign(this.cmContainer.style, {
            position: 'absolute',
            left: `${info.event.pageX}px`,
            top: `${info.event.pageY}px`,
        });

        ReactDOM.render(this.toolTip, container);
    }

    render(){
        const {t, optionStyle, innerStyle, checkStrictly, defaultExpandAll, reLoadedCategories = []} = this.props;
        const {categories, type, show} = this.state;

        const tempOptionStyle = {...optionStyle, 'display': show ? '' : 'none'};

        const loop = (data) => {
            return data.map((item) => {
                if (item.children) {
                    return <TreeNode checked={true} title={item.name} key={item.key}>{loop(item.children)}</TreeNode>;
                }
                return (
                    <TreeNode checked={true}  title={item.name} key={item.key} isLeaf={item.isLeaf} />
                );
            });
        };

        const treeNodes = ( reLoadedCategories === undefined || reLoadedCategories.length === 0) ?  loop(categories) : loop(reLoadedCategories);

        return(
            <div className={"option_list checklist categoryWrap" + " " + type} style={tempOptionStyle}>
                {
                    categories.length > 0 &&
                    <div className={"category_tree_div category" + " " + type} style={innerStyle}>
                        <Tree
                            style={{'display':'block'}}
                            showIcon={false}
                            onCheck={this.onCheck}
                            onSelect={this.onSelect}
                            onMouseEnter={this.onMouseEnter}
                            checkable
                            checkStrictly={checkStrictly}
                            checkedKeys={this.props.checkStrictly ? this.state.selected : this.state.checkedKeys}
                            defaultExpandAll={defaultExpandAll}
                            selectedKeys={this.props.checkStrictly ? this.state.selected : this.state.checkedKeys}
                            loadedKeys={this.props.checkStrictly ? this.state.selected : this.state.checkedKeys}
                        >
                            {treeNodes}
                        </Tree>
                    </div>
                }
                {type !== 'tab' && <div id="categoryTreeAllUncheck" onClick={this.onDeSelectAll}>{t("BUTTON_ALL_DESELECT_P")}</div>}
            </div>
        )
    }

}
export default withTranslation()(Category);