import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import TextInput from '../../input/TextInput';
import {popupAction} from "../../../actions";
import {commonConstants, userConstants} from "../../../constants";
import {userService} from '../../../services';
import Select from "../../selectbox/Select";
import {useDispatch} from "react-redux";
import Moment from "react-moment";
import BULLET_2 from "../../../images/icon/bullet_2.png";
import {getMixString} from '../../../language/languageUtils'
import {toastr} from 'helper/toastrIntercept';
import {updateRoleListWithCurrentUser} from './common/userCommons';

const EditUserDetailedInformation = ({
    userInfo,
    updateUserInfoPartially,
    onChange
}) => {
    const TEXT_INPUT_WIDTH = 290;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {errors} = userInfo || {};

    const organizationId = userInfo.dataField.organizationId;
    const organizationName = userInfo.dataField.organizationName;
    const organizationGroupName = userInfo.dataField.organizationGroupName;
    const groupId = userInfo.dataField.groupId;
    const roleName = userInfo.dataField.roleName;
    const jobPosition = userInfo.dataField.jobPosition;
    const signUpDate = userInfo.dataField.signUpDate;
    const lastLoginDate = userInfo.dataField.lastLoginDate;
    const team = userInfo.dataField.team;
    const userId = userInfo.dataField.userId;
    const userName = userInfo.dataField.userName;
    const email = userInfo.dataField.email;
    const mobileNum = userInfo.dataField.mobileNum;
    const phoneNum = userInfo.dataField.phoneNum;
    const passwordChangeDate = userInfo.dataField.passwordChangeDate;
    const ldapId = userInfo.dataField.ldapId;
    const isServerAdmin = localStorage.getItem('user') === null ? false : JSON.parse(localStorage.getItem('user')).serverAdmin;

    const [filter, setFilter] = useState({
        groupName: null,
        roleId: null,
        isGroupSelectionDisabled: false,
        isErrorTextForAdminDisplay: false
    });

    const [data, setData] = useState({
        roleList: [],
        orgGroupList: [],
    });

    const [displayNotification, setDisplay] = useState(false);

    const {orgGroupList, roleList} = data;

    const show = () => {setDisplay(!displayNotification);};

    const onSelectOrganGroupChange = (e, selectedValue) => {
        updateUserInfoPartially({organizationGroupName: selectedValue === t('TEXT_ALL_P') ? '' : selectedValue});
        setFilter({...filter, organizationGroupName: selectedValue});
    };

    const onSelectRoleChange = (e, selectedValue) => {
        let selectedItem = data.roleList.filter(item => item.value === selectedValue);
        updateUserInfoPartially({roleName: selectedItem[0].title, roleId: selectedValue});

        if (selectedValue === userConstants.ROLE_ID_SERVER_ADMINISTRATOR) {
            updateUserInfoPartially({organizationGroupName: ''});
        }

        setFilter({...filter,
            roleId: selectedValue,
            isOrgGroupSelectionDisabled: organizationId !== userConstants.ORGAN_ID_ADMINISTRATOR || selectedValue === userConstants.ROLE_ID_SERVER_ADMINISTRATOR,
            isErrorTextForAdminDisplay: selectedValue === userConstants.ROLE_ID_SERVER_ADMINISTRATOR
        });
    };

    const handleGroupSelection = (param) => {
        if(!param || !param[0]) {
            return null;
        }

        if (param[0].groupId === param[0].organizationId) {
            return null;
        }

        updateUserInfoPartially({groupId: param[0].groupId, groupName: param[0].groupName});
        setFilter({...filter, groupId: param[0].groupId, groupName: param[0].groupName});
        dispatch(popupAction.closePopup('ADD_USER_GROUP_SELECTION'));
    };

    const onGroupSelectionPopup = (e) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.ADD_USER_GROUP_SELECTION,
            type: commonConstants.ADD_USER_GROUP_SELECTION,
            onSave: handleGroupSelection,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.ADD_USER_GROUP_SELECTION)),
            organizationId: organizationId
        }));
    };

    const handleResetPassword = (resetPassword, id) => {
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("TEXT_RESET_PASSWORD_P"),
            useMessageLine: false,
            message: (
                <div style={{height: '90px', width: '330px', verticalAlign:'middle', display:'table-cell'}}>
                    <div style={{height: '15px', textAlign:"center", lineHeight: '15px'}}>{resetPassword}</div>
                </div>
            ),
            width: 330,
            height: 110,
            useNoButton: false,
            onClickYes: () => dispatch(popupAction.closePopup(id)),
            onClose: () => dispatch(popupAction.closePopup(id)),
        }));
    };

    const resetPasswordPopup = (e, id) => {
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("TEXT_TITLE_WARNING_P"),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('COM_BUTTON_CLOSE_P'),
            useMessageLine: false,
            message: (
                <div style={{height: '90px', textAlign:"center", lineHeight: '90px'}}>{t('MESSAGE_USER_ISSUE_PWD_P')}</div>
            ),
            width: 320,
            height: 110,
            onClickYes: () => {
                userService.updateTemporaryPassword(userId).then(res => {
                    handleResetPassword(res.items.resetPassword, 'DISPLAY_RESET_PASSWORD');
                    dispatch(popupAction.closePopup(id));
                }).catch(err => {
                    err && err.errorMessage ? toastr.error(err.errorMessage) : toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
                });
            },
            onClose: () => dispatch(popupAction.closePopup(id)),
        }));
    };

    useEffect(() => {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        Promise.all([
            userService.fetchOrganizationGroups(),
            userService.fetchUserAssignableRoles(),
            userService.fetchUserGroupsById(groupId),
            userService.fetchUserRoles({})
        ]).then(values => {
            const orgGroupList = values[0].items.map(org => {
                return {value: org.organizationGroupName, title: org.organizationGroupName};
            });
            orgGroupList.splice(0, 0, {title: t('TEXT_ALL_P'), value: t('TEXT_ALL_P')});

            const roleList = values[1].items.map(org => {
                return {value: org.roleId, title: org.roleName, organizationId: org.organizationId};
            });

            updateRoleListWithCurrentUser(roleList, roleName, values[3].items);

            let currentRoleObj = roleList.filter(role => role.title === roleName)[0];
            let currentRoleId = currentRoleObj.value;
            updateUserInfoPartially({roleName: currentRoleObj.title, roleId: currentRoleObj.value});

            setFilter({
                ...filter,
                isErrorTextForAdminDisplay: currentRoleId === userConstants.ROLE_ID_SERVER_ADMINISTRATOR,
                isGroupSelectionDisabled: organizationId === userConstants.ORGAN_ID_ADMINISTRATOR,
                isOrgGroupSelectionDisabled: organizationId !== userConstants.ORGAN_ID_ADMINISTRATOR || currentRoleId === userConstants.ROLE_ID_SERVER_ADMINISTRATOR,
                roleId : currentRoleId,
                groupName: values[2].items.groupName === 'ROOT' ? t('TEXT_ROOT_GROUP_NAME_P') : values[2].items.groupName
            });
            setData({...data, orgGroupList, roleList: roleList});
        }).catch(
            error => console.log(error)
        ).finally(
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
        );
    }, []);

    return (
        <div className='user_pop_wrap'>
            <div className="user_detail_view">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <h4>
                                    {t('TEXT_DEFAULT_INFO_P')}
                                    <span>{t('MESSAGE_COMMON_JOIN_NOTICE_P')}</span>
                                </h4>
                            </th>
                        </tr>
                        <tr>
                            <th>{t('COM_DID_ADMIN_USER_USERID')}
                                <img src={BULLET_2} />
                            </th>
                            <td style={{paddingLeft:'5px'}}>{userId}</td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_USER_NAME_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='userName'
                                    value={userName}
                                    errorWidth={500}
                                    error={errors['userName']}
                                    onChange={onChange}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_EMAIL_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='email'
                                    value={email}
                                    errorWidth={500}
                                    error={errors['email']}
                                    onChange={onChange}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_MOBILE_NUM_P')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='mobileNum'
                                    value={mobileNum}
                                    errorWidth={500}
                                    onChange={onChange}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_PHONE_NUM_P')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='phoneNum'
                                    value={phoneNum}
                                    onChange={onChange}
                                    maxLength={50} />
                            </td>
                        </tr>
                        {
                            ldapId === '' &&
                            <tr>
                                <th>{t('TEXT_RESET_PASSWORD_P')}</th>
                                <td>
                                    <button className={classNames('base', 'mr8')} style={{width: '100px'}}
                                            onClick={(e) => {
                                                resetPasswordPopup(e, 'RESET_PASSWORD')
                                            }}>{t('MIS_BUTTON_ISSUANCE_P')}</button>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t('MIS_SID_PW_MODIFIED_DATE')}
                                <img src={BULLET_2} />
                            </th>
                            <td style={{paddingLeft:'5px'}}><Moment format="YYYY-MM-DD HH:mm:ss">{passwordChangeDate}</Moment></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="user_detail_view mt15 pt24 user_info t_line">
                <table>
                    <colgroup>
                        <col width="203px" />
                        <col width="" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <h4>{t('TEXT_ORGANIZATION_INFO_P')}</h4>
                            </th>
                        </tr>
                        <tr>
                            <th>{t('TEXT_ORGANIZATION_P')}
                                <img src={BULLET_2} />
                            </th>
                            <td style={{paddingLeft:'5px'}}>{organizationName}</td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_GROUP_P')}
                                <img src={BULLET_2} />
                            </th>
                            {
                                organizationName === 'ROOT' ?
                                    <td style={{paddingLeft:'5px'}}>{t('TEXT_ROOT_GROUP_NAME_P')}</td>
                                    :
                                    <td>
                                    <button propertyName='groupId'
                                            className={classNames('base', 'un_radius', 'button')} value={groupId}
                                            style={{overflow: "hidden"}}
                                            onClick={onGroupSelectionPopup}
                                            disabled={filter.isGroupSelectionDisabled}>{filter.groupName}
                                    </button>
                                    </td>
                            }
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_ROLE_P')}<img src={BULLET_2} /></th>
                            <td>
                               <span>
                                 <Select propertyName='roleName' width={TEXT_INPUT_WIDTH} selects={roleList} value={filter.roleId} error={errors['roleName']} onChange={onSelectRoleChange} useEllipsis={true} direction={'up'}/>
                              </span>
                            </td>
                        </tr>
                        {
                            isServerAdmin &&
                            <tr>
                                <th>{t('MIS_SID_ORGANIZATION_GROUP')}</th>
                                <td>
                                    <div className={classNames('float_l', 'mr8')}>
                                <span>
                                    <Select propertyName='organizationGroupName' width={TEXT_INPUT_WIDTH}
                                            selects={orgGroupList} value={organizationGroupName}
                                            defaultTitle={organizationId === userConstants.ORGAN_ID_ADMINISTRATOR ? t('TEXT_ALL_P') : t('MIS_SID_SELECT_ORGANIZATION_GROUP')} onChange={onSelectOrganGroupChange}
                                            disabled={filter.isOrgGroupSelectionDisabled}
                                            useEllipsis={true}/>
                                </span>
                                    </div>
                                    <button type='button' id='mngGroupTutorialBtn'
                                            className={classNames('float_l', 'ml8')} onClick={() => show()}/>
                                    {
                                        displayNotification &&
                                        <div className="mngGrouptutorial_wrap" style={{display: 'block'}}>
                                            <div>{getMixString(['MIS_SID_MIX_SELELCT_OPTIONS_MANAGE_MENU', 'MIS_SID_ORGANIZATION_GROUP'])}</div>
                                        </div>
                                    }
                                </td>
                            </tr>
                        }
                        {
                            filter.isErrorTextForAdminDisplay && !displayNotification &&
                            <tr>
                                <th></th>
                                <td className='notification'>
                                    <span className='error_txt2' >{t('MIS_SID_NOT_SELELCT_ORGANIZATION_MSG')}</span>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t('DID_ADMIN_USER_TEAM')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='team'
                                    value={team}
                                    onChange={onChange}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('DID_ADMIN_USER_POSITION')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='jobPosition'
                                    value={jobPosition}
                                    onChange={onChange}
                                    maxLength={50} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="user_detail_view t_line mt20"/>
            </div>

            <div className="user_detail_view user_info">
                <table>
                    <colgroup>
                        <col width="203px" />
                        <col width="" />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>{t('TEXT_JOIN_DATE_P')}</th>
                        <td style={{paddingLeft:'5px'}}><Moment format="YYYY-MM-DD HH:mm:ss">{signUpDate}</Moment></td>
                    </tr>
                    <tr>
                        <th>{t('TEXT_LAST_LOGIN_DATE_P')}</th>
                        <td style={{paddingLeft:'5px'}}><Moment format="YYYY-MM-DD HH:mm:ss">{lastLoginDate}</Moment></td>
                    </tr>
                    </tbody>
                </table>
                <div className="user_detail_view t_line mt20"/>
            </div>

        </div>
    );
};

EditUserDetailedInformation.defaultProps = {
    userInfo: {
        data: {},
        errors: {},
    },
    onChange: () => {},
};

export default EditUserDetailedInformation;