import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './UsedContentDelete.css';
import {contentConstants} from "../../../constants";
import {useDispatch} from "react-redux";
import {menuAction, popupAction} from "../../../actions";
import {contentService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {messageLine} from "../../../helper/utils";
import MagicInfoTable from "../../table/MagicInfoTable";
import {useTrGroupProps} from '../../../helper/tables';

const UsedContentDelete = ({ failList, onSuccess, id}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
    });

    const [checkDeletable, setDeletable] = useState({
        isDelete: true,
        deletableMessage: ''
    });

    const [style, setStyle] = useState({
        height: '420px'
    });

    const [contentIds, setContentIds] = useState([]);

    const {items, loading} = data;

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const parseList = (list) => {
        let itemList= [];
        let deleteItemList= [];

        list.forEach(failItem => {
            let item = {};
            let isDelete = true;
            let deleteMessage = '';
            item = {
                contentName: failItem.contentName,
                playlistInfo: failItem.resource.refPlaylistList.map(array => array.map(playlist => {
                    if(playlist !== null) {
                        if(playlist.playlistType === '3') {
                            isDelete = false;
                            deleteMessage = t('MIS_SID_SYNC_PLAY');
                        }
                        return playlist.playlistName + '\n ';
                    }
                })),
                contentScheduleInfo: failItem.resource.refScheduleList.map(array => array.map(schedule => {
                    if(schedule !== null){
                        return schedule.scheduleName + '\n ';
                    }
                })),
                eventScheduleInfo: failItem.resource.refEventList.map(array => array.map(event => {
                    if(event !== null) {
                        return event.eventName + '\n ';
                    }
                })),
                ruleSetInfo: failItem.resource.refRulesetList.map(array => array.map(ruleset => {
                    if(ruleset !== null){
                        if(isDelete) {
                            isDelete = false;
                            deleteMessage = t('MIS_SID_RULESET');
                        }
                        return ruleset.rulesetName + '\n ';
                    }
                })),
                convertDataInfo: failItem.resource.refConvertDataList.map(array => array.map(convertData => {
                    if(convertData !== null){
                        if(isDelete) {
                            isDelete = false;
                            deleteMessage = 'DLK';
                        }
                        return convertData.convertDataName + '\n ';
                    }
                })),
                deletable: isDelete ? 'O' : 'X\n' + '(' + deleteMessage + ')'
            };
            if(isDelete){
                deleteItemList.push(failItem.id);
            }
            itemList.push(item);
        });

        setData({
            ...data,
            items:itemList
        });

        setContentIds(deleteItemList);
    };

    const deleteContent = () => {
        const requestBody = {
            contentIds: contentIds,
            deleteMethod: contentConstants.GO_TO_RECYCLEBIN_FORCE
        };
        contentService.deleteContentById(requestBody)
            .then(res => {
                if(res.status === 'Success'){
                    toastr.success(t('MIS_MESSAGE_CONTENT_DELETE_CONTENT_FROM_PLAYLIST_SUCCESS_P'));
                    dispatch(menuAction.reloadGroup("BY_USER"));
                    dispatch(menuAction.reloadGroup("MY_CONTENT"));
                }
            }).catch(error => {
                let errorMessage = error.errorMessage;
                switch(error.errorCode) {
                    case '403001':
                        errorMessage = t('ERROR_NO_ROLE');
                        break;
                }
                toastr.error(errorMessage);
            }).finally(() => {
                onSuccess();
                closePopup(id);
        });
   };

    useEffect(() => {
        parseList(failList);
    }, []);

    const [getTrGroupPropsType1] = useTrGroupProps(null, null, null, 'content_tr_group');

    return (
        <div className='used_content_delete_popup'>
            <MISDialog
                dialog={{
                    title: t('COM_BUTTON_DELETE'),
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 1163,
                    height: 503,
                    onClose: closePopup,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'USED_CONTENT_YES',
                            title: t('BUTTON_YES_P'),
                            disable: contentIds.length < 1,
                            onClick: () => deleteContent(),
                        },
                        {
                            id: 'USED_CONTENT_NO',
                            title: t('BUTTON_NO_P'),
                            onClick: closePopup,
                        }
                    ],
                }}>
                <div className="detail_view mini_p">
                    <p>{messageLine(t('MESSAGE_CONTENT_CANNOT_DELETE_CONTENT_P'))}</p>
                    <p>{t('MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P')}</p>
                </div>
                <MagicInfoTable
                    data={items}
                    minRows={0}
                    getTrGroupProps={getTrGroupPropsType1}
                    showPagination={false}
                    resizable={false}
                    loading={loading}
                    width={'auto'}
                    columns={
                        [
                            {
                                Header: t("TEXT_CONTENT_NAME_P"),
                                accessor: 'contentName',
                                sortable: false
                            },
                            {
                                Header: t("TABLE_PLAYLIST_NAME_P"),
                                accessor: 'playlistInfo',
                                style: {'whiteSpace':'pre-line'},
                                sortable: false,
                            },
                            {
                                Header: t("TEXT_CONTENT_SCHEDULE_P"),
                                accessor: 'contentScheduleInfo',
                                style: {'whiteSpace':'pre-line'},
                                sortable: false,
                            },
                            {
                                Header: t("MIS_TEXT_EVENT_SCHEDULE_P"),
                                accessor: 'eventScheduleInfo',
                                style: {'whiteSpace':'pre-line'},
                                sortable: false,
                            },
                            {
                                Header: t("MIS_SID_RULESET"),
                                accessor: 'ruleSetInfo',
                                style: {'whiteSpace':'pre-line'},
                                sortable: false,
                            },
                            {
                                Header: "DLK",
                                accessor: 'convertDataInfo',
                                style: {'whiteSpace':'pre-line'},
                                sortable: false,
                            },
                            {
                                Header: t("COM_BUTTON_DELETE"),
                                accessor: 'deletable',
                                style: {'whiteSpace':'pre', 'textAlign':'center'},
                                sortable: false,
                            },
                        ]
                    }
                    className="-striped -highlight"
                    manual
                    style={style}
                />
            </MISDialog>
        </div>
    );
};

export default UsedContentDelete;