import React, {useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../../components/button/WhiteButton";
import "../../../components/table/react-table.css";
import {scheduleService} from '../../../services';
import Checkbox from "../../../components/checkbox/Checkbox";
import {useDispatch} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../actions";
import {useTranslation} from 'react-i18next';
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../../helper';
import {toastr} from 'helper/toastrIntercept';
import {commonConstants, defaultEventCondition, SCHEDULE_PAGE_SIZE_OPTIONS} from "../../../constants";
import DateToStr from "../../../components/utils/DateToStr";
import {snakeCase} from "lodash";
import {useMISOpt} from "../../../components/misopt";
import {createUUID} from "../../../helper/schedule/scheduleUtils";
import MagicInfoTable from "../../../components/table/MagicInfoTable";
import {getErrorMessage} from "../../../helper/responseHandler";
import {useTrGroupProps} from "../../../helper/tables";
import {updateCache} from "../../../helper/cache/tableCache";

const AllEventScheduleManager = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {getAuthority} = useMISOpt();
    const [authority] = useState(getAuthority('SCHEDULE'));

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}], searchText} = filter;
        setData({...data, loading: true});
        scheduleService.fetchEventScheduleFilter({
            startIndex: (page * pageSize) + 1,
            pageSize,
            groupType: 'MANAGE',
            sortColumn: snakeCase(id),
            sortOrder: desc === true ? 'desc' : 'asc',
            searchText: searchText
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
            updateCache('SCHEDULE', {items: res.items, filter: filter, totalCount: res.totalCount});
        });
    }

    const openPopup = programId => {
        dispatch(popupAction.openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'SCHEDULE_EVENT_MANAGER', id: programId}))
    }

    const createEventCondition = () => {
        const menuId = 'NEW_EVENT_CONDITION';
        const tab = {id: menuId, title: t("MIS_TEXT_EVENT_MANAGEMENT_P"), active: true, close: true};
        const event = defaultEventCondition;
        new Promise(() => {
            dispatch(scheduleAction.initEventCondition('new', event));
        }).then(
            dispatch(menuAction.loadContent(menuId))
        ).then(
            dispatch(menuAction.addTab(tab))
        );
    }

    const editEventCondition = () => {
        const menuId = 'EDIT_EVENT_CONDITION';
        const eventConditionIds = getCheckedId();
        scheduleService.fetchEventConditionById(eventConditionIds[0]).then(
            res=> {
                const tab = {id: menuId, title: t("MIS_TEXT_EVENT_MANAGEMENT_P"), active: true, close: true};
                const editEvent = res.items;
                editEvent.eventConditionList = editEvent.events;
                if (editEvent.eventConditions !== undefined) {
                    editEvent.eventConditions.map(
                        e => e.eventConditionId = createUUID()
                    )
                }
                editEvent.events = undefined;
                new Promise(() => {
                    dispatch(scheduleAction.initEventCondition('edit', editEvent))
                }).then(
                    dispatch(menuAction.addTab(tab))
                ).then(
                    dispatch(menuAction.loadContent(menuId))
                );
            }
        ).catch(e=> toastr.error(getErrorMessage(e)))
    }

    const deleteEventCondition = () => {
        const eventIds = getCheckedId();
        scheduleService.deleteEventConditionByIds(eventIds).then(
            res=> {                
                res.items.forEach(element => {                    
                    if(element.delete === "fail"){
                        if(element.message === "Data not found"){
                            toastr.error(t("TEXT_FAIL_P")+" : "+element.message);                       
                        }else{
                            toastr.error(t('MIS_MESSAGE_EVENT_DELETE_USED_EVENT_P'));                       
                        }                        
                    }
                });
                setFilter({...filter, reload: !filter.reload});
            }
        ).catch(
            err=> {
                toastr.error(err.statusText);
            }
        ).finally(()=>{
           dispatch(popupAction.closePopup(eventIds[0]));
        })
    }

    const getCheckedId = () => {
        return selected.current.map(s => items[s].programId);
    }

    const ConditionTypeToStr = ({conditionType}) => {

        let type = '';
        switch (conditionType.toUpperCase()){
            case 'TEXT':
                type = t('DID_LFD_TEXT');
                break;
            case 'NUMBER':
                type = t('COM_ADMIN_CONTENT_TYPEINFO_CONTENTS_LIST_JSP_NUMBER');
                break;
            case 'BOOL':
                type = t('MIS_TEXT_TRUE_OR_FALSE_P');
                break;
        }
        return (
            <span>{type}</span>
        )
    }

    const columns = useMemo(()=>[
        {
            accessor: "programId",
            show: false
        },
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllEventScheduleManager_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].programId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                );
            },
        },
        {
            Header: t("COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME"),
            accessor: "eventName",
            Cell: (props) => <span className={"data_name"} onClick={()=>openPopup(props.original.programId)}>{props.original.eventName}</span>,
            width: 600
        },

        {
            Header: t("COM_TEXT_EVENT_TYPE_P"),
            accessor: "conditionType",
            Cell: (props) => <ConditionTypeToStr conditionType={props.original.conditionType} />,
            width: 200
        },
        {
            Header: t("TEXT_DESCRIPTION_P"),
            accessor: "description",
            Cell: (props) => <span title={props.original.description}>{props.original.description}</span>,
            width: 200,
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "modifyDate",
            Cell: ({value}) => <DateToStr date={value}/>

        }
    ], [items]);

    useEffect(() => {
        setStyle({height: window.innerHeight - 205});
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);
    return (
        <div className={'all_event_schedule_manager'} style={{width: '100%', display:(props.currContent === 'EVENT_SCHEDULE_MANAGER') ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"EVENT_SCHEDULE_MANAGER_CREATE"} name={t("COM_TEXT_CREATE_P")} onClick={createEventCondition} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"EVENT_SCHEDULE_EDIT_CREATE"} name={t("TEXT_EDIT_P")} disable={selectedCnt !== 1} onClick={editEventCondition} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"EVENT_SCHEDULE_DELETE_PERMANENTLY"} name={t("MIS_BUTTON_DELETE_PERM_P")} disable={selectedCnt < 1} onClick={deleteEventCondition} authority={authority.CREATE || authority.MANAGE}/>
                </div>
            </div>
            <MagicInfoTable
                data={items}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                columns={columns}
                style={style}
                getTrGroupProps={getTrGroupPropsType2}
                usePagination={true}
                paginationOptions={{
                    totalCount: totalCount,
                    page: page,
                    pageSize: pageSize,
                    pageSizeOptions: SCHEDULE_PAGE_SIZE_OPTIONS,
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    divide: props.divide
                }}
            />
        </div>
    )

}
export default AllEventScheduleManager;