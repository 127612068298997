import React from "react";
import PropTypes from "prop-types";
import {popupAction} from "../../actions";
import {useDispatch} from "react-redux";


const DialogTitle = ({ title, hasCloseIcon, onClose, allowMinimize, isMinimized, onMinimize, allowMaximize, isMaximized, onMaximize, onRestore, popupId,modal, useEllipsis = true }) => {
    const dispatch = useDispatch();

    function setActive(e) {
        if (modal ==undefined || modal == false) {

            const currentDialog = e.target.closest('.ui-dialog'); // check clicked element is detail popup

            let currentDialogZIndex;
            if (currentDialog != null && currentDialog != undefined)
                currentDialogZIndex = currentDialog.style.zIndex;
            else
                return;

            const dialogs = document.getElementsByClassName('ui-dialog');
            const dialogsArr = Array.from(dialogs);
            const zIndexes = dialogsArr.map((dialog) => {
                return dialog.style.zIndex;
            })
            const maxZIndex = Math.max(...zIndexes);

            if (maxZIndex > Number(currentDialogZIndex)) {
                currentDialog.style.zIndex = maxZIndex + 1;
            }
            dispatch(popupAction.activePoup(maxZIndex + 1, popupId));
        }
    }

    let closeIcon;
    if (hasCloseIcon !== false) {
        closeIcon = (
            <i className="icon icon-close" onClick={onClose}></i>
        );
    }

    let minimizeIcon;
    if (allowMinimize) {
        if (isMinimized) {
            minimizeIcon = (
                <i className="icon icon-restore" onClick={onRestore}></i>
            );
        } else {
            minimizeIcon = (
                <i className="icon icon-minimize" onClick={onMinimize}></i>
            );
        }
    }

    let maximizeIcon;
    if (allowMaximize) {
        if (isMaximized) {
            maximizeIcon = (
                <i className="icon icon-restore" onClick={onRestore}></i>
            );
        } else {
            maximizeIcon = (
                <i className="icon icon-maximize" onClick={onMaximize}></i>
            );
        }
    }

    return (
        <header className="ui-dialog-titlebar" onMouseDown = {(e) => {setActive(e)}}>
            <div className={useEllipsis ? "title" : "title_noellipsis"}>
                {title}
            </div>
            <div className="action-items">
                {minimizeIcon}
                {maximizeIcon}
                {closeIcon}
            </div>
        </header>
    );
};

DialogTitle.propTypes = {
    hasCloseIcon: PropTypes.bool,
    allowMinimize: PropTypes.bool,
    allowMaximize: PropTypes.bool,
    isMinimized: PropTypes.bool,
    isMaximized: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClose: PropTypes.func.isRequired,
    onMinimize: PropTypes.func,
    onMaximize: PropTypes.func,
    onRestore: PropTypes.func
};

export default DialogTitle;