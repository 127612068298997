import React, {Component, Fragment} from 'react';
import MISDialog from '../MISDialog';
import {withTranslation} from "react-i18next";
import {userService} from "../../../services";
import "./AddRolePopup.css"
import {connect} from "react-redux";
import {popupAction} from "../../../actions";
import TextInput from "../../input/TextInput";
import CircleButton from "../../button/CircleButton";
import Select from "../../selectbox/Select";
import "./AddLDAPPopup.css"
import Checkbox from "../../checkbox/Checkbox";
import {commonConstants} from "../../../constants";
import {toastr} from 'helper/toastrIntercept';
import {cloneDeep} from "lodash";
import {getNotificationSIDByType} from "../../../helper/user/roleUtils";
import {getErrorMessage} from "../../../helper/responseHandler";

class EditLDAPPopup extends Component {
    state = {
        original: {},
        roles: [],
        group: {},
        ldapOrganizationName: "",
        selectedOptionRoleIndex: -1,
        deviceHealthNotifications: [],
        normalNotifications: [],
        isAlarmMailingServiceEnable: false,
        isContentsApprovalEnable: false,
        isDevicePermissionsEnable: false,
        isAlarmMailingServiceDetailOpen: false,
        isContentsApprovalDetailOpen: false,
        isDevicePermissionsDetailOpen: false,
        isContentsApprovalChecked: false,
        permissionDeviceGroups: [],
    }

    componentDidMount() {
        this.fetchLDAPOrganizationDetail();
    }

    onClickSave = () => {
        this.fetchUpdateLDAPOrganization();
    };

    onChangeRole = (value) => {
        const {original, selectedOptionRoleIndex, roles} = this.state;
        if(selectedOptionRoleIndex !== value) {
            this.setState({
                selectedOptionRoleIndex: value,
                isContentsApprovalDetailOpen: false,
                isDevicePermissionsDetailOpen: false,
                isContentsApprovalChecked: roles[value].title === 'Content Manager' && original.isContentsApprovalChecked ? true : false,
                permissionDeviceGroups: roles[value].title === 'Device Manager' ? cloneDeep(original.permissionDeviceGroups) : [],
            });
        }
    };

    onClickSearchLdapOrganization = () => {
        const {addPopup, closePopup} = this.props;
        const {group, ldapOrganizationName} = this.state;
        addPopup({
            id: commonConstants.LDAP_ORGANIZATION_SELECTION,
            type: commonConstants.LDAP_ORGANIZATION_SELECTION,
            groupId: group.groupId,
            ldapOrganization: ldapOrganizationName,
            onSave: (organization) => {
                this.onSelectedLdapOrganization(organization);
            },
            onClose: () => closePopup(commonConstants.LDAP_ORGANIZATION_SELECTION)
        });
    };

    onSelectedLdapOrganization = (organization) => {
        const {closePopup} = this.props;
        const {original, ldapOrganizationName} = this.state;
        if(original.ldapOrganizationName === organization.data) {
            this.setState({
                ldapOrganizationName: organization.data,
            });
            closePopup(commonConstants.LDAP_ORGANIZATION_SELECTION);
        } else if (organization && organization.data && ldapOrganizationName !== organization.data) {
            this.fetchCheckExistSyncLDAPOrganization(organization.data);
        } else {
            closePopup(commonConstants.LDAP_ORGANIZATION_SELECTION);
        }
    };

    onClickDeviceHealthCheck = (checked, index) => {
        this.state.deviceHealthNotifications[index].isEnable = checked;
        this.setState({
            deviceHealthNotifications: this.state.deviceHealthNotifications,
        });
    };

    onClickNormalCheck = (checked, index) => {
        this.state.normalNotifications[index].isEnable = checked;
        this.setState({
            normalNotifications: this.state.normalNotifications,
        });
    };

    onChangeContentApproval = e => {
        this.setState({
            isContentsApprovalChecked: e.target.checked,
        })
    };

    onClickSearchDevicePermission = () => {
        const {addPopup, closePopup} = this.props;
        addPopup({
            id: commonConstants.LDAP_DEVICE_GROUP_SELECTION,
            type: commonConstants.LDAP_DEVICE_GROUP_SELECTION,
            groupId: this.state.group.groupId,
            defaultDevices: this.state.permissionDeviceGroups,
            onSave: (devices) => {
                this.onSelectedDeviceGroup(devices);
            },
            onClose: () => closePopup(commonConstants.LDAP_DEVICE_GROUP_SELECTION),
        });
    };

    onSelectedDeviceGroup = (devices) => {
        const {closePopup} = this.props;
        closePopup(commonConstants.LDAP_DEVICE_GROUP_SELECTION);
        this.setState({
            permissionDeviceGroups: devices.length > 0 ? devices.map((device) => {
                return {
                    deviceGroupId: device.groupId,
                    deviceGroupName: device.groupName,
                }
            }) : [],
        });
    };

    onClickDeleteDeviceGroup = (index) => {
        this.state.permissionDeviceGroups.splice(index, 1);
        this.setState({
            permissionDeviceGroups: this.state.permissionDeviceGroups,
        });
    };

    fetchLDAPOrganizationDetail = () => {
        const {t} = this.props;
        Promise.all([
            userService.fetchLDAPOrganizationDetail(this.props.ldapId),
            userService.fetchUserRoles(),
        ]).then(value => {
            const ldap = value[0].items;
            const roles = value[1].items;
            let newRoles = [];
            let selectedOptionRoleIndex = -1;
            roles.forEach((role, index) => {
                if (role.roleId === ldap.roleId) {
                    selectedOptionRoleIndex = index;
                }
                newRoles.push({value: index, id: role.roleId, title: role.roleName,});
            });
            let deviceHealthNotifications = [];
            ldap.notificationList.deviceStatus.forEach((notification) => {
                let findIndex = ldap.selectedNotificationList.findIndex(setting => setting === notification.type);
                let sid = getNotificationSIDByType(notification.type);
                deviceHealthNotifications.push({...notification, sid: sid, isEnable: findIndex >= 0 ? true : false,})
            });
            let normalNotifications = [];
            ldap.notificationList.etc.forEach((notification) => {
                let findIndex = ldap.selectedNotificationList.findIndex(setting => setting === notification.type);
                let sid = getNotificationSIDByType(notification.type);
                normalNotifications.push({...notification, sid: sid, isEnable: findIndex >= 0 ? true : false,})
            });
            this.setState({
                original: {
                    roleId: roles[selectedOptionRoleIndex].roleId,
                    ldapOrganizationName: ldap.ldapOrganizationName,
                    isContentsApprovalChecked: ldap.isContentApporver === 'Y' ? true : false,
                    permissionDeviceGroups: ldap.permittedDeviceGroupList.map((permittedDeviceGroup) => {
                        return {
                            deviceGroupId: permittedDeviceGroup.deviceGroupId,
                            deviceGroupName: permittedDeviceGroup.deviceGroupName,
                        }
                    })
                },
                roles: newRoles,
                selectedOptionRoleIndex: selectedOptionRoleIndex,
                group: {
                    groupId: ldap.groupId,
                    groupName: ldap.groupName
                },
                ldapOrganizationName: ldap.ldapOrganizationName,
                isAlarmMailingServiceEnable: ldap.alarmMailingServiceEnable,
                isContentsApprovalEnable: ldap.contentsApprovalEnable,
                isDevicePermissionsEnable: ldap.hasDeviceGroupPermission,
                deviceHealthNotifications: deviceHealthNotifications,
                normalNotifications: normalNotifications,
                isContentsApprovalChecked: ldap.isContentApporver === 'Y' ? true : false,
                permissionDeviceGroups: ldap.permittedDeviceGroupList.map(permittedDeviceGroup => {
                    return {
                        deviceGroupId: permittedDeviceGroup.deviceGroupId,
                        deviceGroupName: permittedDeviceGroup.deviceGroupName,
                    }
                })
            })
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    fetchUpdateLDAPOrganization = () => {
        const {t, ldapId, onSave} = this.props;
        const {ldapOrganizationName, roles, group, selectedOptionRoleIndex, deviceHealthNotifications, normalNotifications, isContentsApprovalChecked, permissionDeviceGroups} = this.state;
        let enableNotifications = [];
        deviceHealthNotifications.forEach((notification) => {
            if (notification.isEnable) {
                enableNotifications.push(notification.type);
            }
        });
        normalNotifications.forEach((notification) => {
            if (notification.isEnable) {
                enableNotifications.push(notification.type);
            }
        });
        userService.updateLDAPOrganizations(ldapId, {
            groupId: group.groupId,
            remoteLdapOrganization: ldapOrganizationName,
            roleName: roles[selectedOptionRoleIndex].title,
            notificationSetting: enableNotifications,
            isContentApporver: isContentsApprovalChecked ? 'Y' : 'N',
            deviceGroupIds: permissionDeviceGroups.map(permittedDeviceGroup => permittedDeviceGroup.deviceGroupId),
        }).then(res => {
            toastr.success(t('TEXT_SUCCESS_P'));
            onSave();
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    fetchCheckExistSyncLDAPOrganization = (ldapGroup) => {
        const {t, closePopup} = this.props;
        userService.fetchCheckExistSyncLDAPOrganization({ldapOrganizationName: ldapGroup}).then((res) => {
            if(res.items.result !== false) {
                toastr.error(t('MIS_SID_20_THIS_LDAP_ORGANIZATION_IS_ALREADY_SYNCHRONIZING'));
                return;
            }
            closePopup(commonConstants.LDAP_ORGANIZATION_SELECTION);
            this.setState({
                ldapOrganizationName: ldapGroup,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage));
            return;
        });
    };

    render () {
        const {t, onClose} = this.props;
        const {group, ldapOrganizationName, selectedOptionRoleIndex, roles,
            isAlarmMailingServiceEnable, isContentsApprovalEnable, isDevicePermissionsEnable,
            isAlarmMailingServiceDetailOpen, isContentsApprovalDetailOpen, isDevicePermissionsDetailOpen} = this.state;
        const isContentsApproval = isContentsApprovalEnable && roles && selectedOptionRoleIndex >= 0 && roles[selectedOptionRoleIndex].title === 'Content Manager';
        const isDevicePermissions = isDevicePermissionsEnable && roles && selectedOptionRoleIndex >= 0 && roles[selectedOptionRoleIndex].title === 'Device Manager';

        let height = 102;
        isAlarmMailingServiceEnable ? isAlarmMailingServiceDetailOpen ? height += 706 : height += 46 : height = height;
        isContentsApproval ? isContentsApprovalDetailOpen ? height += 106 : height += 46 : height = height;
        isDevicePermissions ? isDevicePermissionsDetailOpen ? height += 132 : height += 46 : height = height;
        return (
            <MISDialog
                classname='ldap_edit_dialog'
                dialog={{
                    title: t('MIS_SID_20_EDIT_LDAP_ORGANIZATION'),
                    closeOnEscape: true,
                    modal: true,
                    width: 407,
                    height: height,
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {id: "OK", title: t("BUTTON_OK_P"), onClick: this.onClickSave},
                        {id: "CANCEL", title: t("BUTTON_CANCEL_P"), onClick: onClose}
                    ]
                }}>
                <div className="ldap_contents">
                    <div className='ldap_contents_name'>
                        <span className='ldap_contents_title'>{t('TABLE_USER_GROUP_P')}</span>
                        <TextInput width={232} height={27} maxLength={25} value={group.groupName} readOnly={true} disabled={true}/>
                        <div style={{width: '8px'}}/>
                        <CircleButton id={"SEARCH_GROUP"} disable={true}>
                            <span className={'ldap_contents_search_btn'}/>
                        </CircleButton>
                    </div>
                    <div style={{height: '10px', minHeight: '10px'}}/>
                    <div className='ldap_contents_name'>
                        <span className='ldap_contents_title'>{t('MIS_TEXT_LDAP_ORGAN_P')}</span>
                        <TextInput width={232} height={27} maxLength={25} value={ldapOrganizationName} readOnly={true}/>
                        <div style={{width: '8px'}}/>
                        <CircleButton id={"SEARCH_ORGANIZATION"} onClick={this.onClickSearchLdapOrganization}>
                            <span className={'ldap_contents_search_btn'}/>
                        </CircleButton>
                    </div>
                    <div style={{height: '10px', minHeight: '10px'}}/>
                    <div className='ldap_contents_name'>
                        <span className='ldap_contents_title'>{t('COM_TEXT_ROLE_P')}</span>
                        <Select multiLang={false} width={270} value={selectedOptionRoleIndex} selects={roles} onChange={(e, value) => this.onChangeRole(value)}
                                optionStyle={{
                                    position: 'fixed',
                                    width: '268px',
                                    top: '180px',
                                    left: '154px',
                                }}/>
                    </div>
                    {this.renderContentsApproval()}
                    {this.renderDevicePermission()}
                    {this.renderMailingServer()}
                </div>
            </MISDialog>
        );
    }

    renderContentsApproval = () => {
        const {t} = this.props;
        const {selectedOptionRoleIndex, roles, isContentsApprovalEnable, isContentsApprovalDetailOpen, isContentsApprovalChecked} = this.state;
        const isContentsApproval = isContentsApprovalEnable && roles && selectedOptionRoleIndex >= 0 && roles[selectedOptionRoleIndex].title === 'Content Manager';
        if(!isContentsApproval)
            return (null);
        return (
            <Fragment>
                <div style={{height: '20px', minHeight: '20px'}}/>
                <div className='ldap_contents_detail'>
                    <button className="ldap_contents_detail_btn" onClick={() => {this.setState({isContentsApprovalDetailOpen: !isContentsApprovalDetailOpen,});}}>
                        <span className={isContentsApprovalDetailOpen ? "ldap_contents_detail_btn_open" : "ldap_contents_detail_btn_close"}/>
                        {t('MIS_SID_CONTENT_APPROVAL')}
                    </button>
                    {
                        isContentsApprovalDetailOpen &&
                        <div className='ldap_content_approval'>
                            <div>
                                <span className="mr30">{t('MIS_SID_CONTENT_APPROVAL')}</span>
                                <Checkbox id={'ldapCheckContentApproval'} name={t('TEXT_ENABLE2_P')} onChange={this.onChangeContentApproval} checked={isContentsApprovalChecked}/>
                            </div>
                            <div className='ldap_content_approval_error'>{t('MIS_SID_20_ENABLING_CONTENT_NUMBER_USER_GROUP')}</div>
                        </div>
                    }
                </div>
            </Fragment>
        );
    }

    renderDevicePermission = () => {
        const {t} = this.props;
        const {selectedOptionRoleIndex, roles, isDevicePermissionsEnable, isDevicePermissionsDetailOpen, permissionDeviceGroups} = this.state;
        const isDevicePermissions = isDevicePermissionsEnable && roles && selectedOptionRoleIndex >= 0 && roles[selectedOptionRoleIndex].title === 'Device Manager';
        if(!isDevicePermissions)
            return (null);
        return (
            <Fragment>
                <div style={{height: '20px', minHeight: '20px'}}/>
                <div className='ldap_contents_detail'>
                    <button className="ldap_contents_detail_btn" onClick={() => {this.setState({isDevicePermissionsDetailOpen: !isDevicePermissionsDetailOpen,});}}>
                        <span className={isDevicePermissionsDetailOpen ? "ldap_contents_detail_btn_open" : "ldap_contents_detail_btn_close"}/>
                        {t('MIS_TEXT_DEVICE_GROUP_PERMISSION_P')}
                    </button>
                    {
                        isDevicePermissionsDetailOpen &&
                        <table className='ldap_device_permission'>
                            <colgroup>
                                <col width="101px"/>
                                <col width=""/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th><span>{t('MIS_TEXT_DEVICE_PERMISSIONS_P')}</span></th>
                                <td>
                                    <div className='ldap_device_permission_device_permission'>
                                        <div className='ldap_device_permission_device_permission_list'>
                                            {permissionDeviceGroups.map((permissionDeviceGroup, index) =>
                                                <div key={index} style={{height: '25px', width: '100%'}}>
                                                    <div style={{float: 'left', paddingTop: '3px'}}>{permissionDeviceGroup.deviceGroupName}</div>
                                                    <div style={{float: 'right'}}>
                                                        <button className='ldap_device_permission_device_permission_row_del_button' onClick={() => {this.onClickDeleteDeviceGroup(index)}}></button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="float_l">
                                        <CircleButton id={"SEARCH_DEVICE_PERMISSION"} onClick={this.onClickSearchDevicePermission}>
                                            <span className={'ldap_contents_search_btn'}/>
                                        </CircleButton>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    }
                </div>
            </Fragment>
        );
    }

    renderMailingServer = () => {
        const {t} = this.props;
        const {deviceHealthNotifications, normalNotifications, isAlarmMailingServiceEnable, isAlarmMailingServiceDetailOpen} = this.state;
        if(!isAlarmMailingServiceEnable)
            return (null);
        return (
            <Fragment>
                <div style={{height: '20px', minHeight: '20px'}}/>
                <div className='ldap_contents_detail'>
                    <button className="ldap_contents_detail_btn" onClick={() => {this.setState({isAlarmMailingServiceDetailOpen: !isAlarmMailingServiceDetailOpen,});}}>
                        <span className={isAlarmMailingServiceDetailOpen ? "ldap_contents_detail_btn_open" : "ldap_contents_detail_btn_close"}/>
                        {t('TEXT_TITLE_ALARM_MAILING_SERVICE_P')}
                    </button>
                    {
                        isAlarmMailingServiceDetailOpen &&
                        <div className='ldap_contents_notification'>
                            <div className='ldap_contents_notification_subject'>{t('COM_SID_DEVICE_HEALTH')}</div>
                            {
                                deviceHealthNotifications.map((notification, index) =>
                                    <div key={index} className='ldap_contents_notification_row'>
                                        <div className='ldap_contents_notification_title' title={notification.sid ? t(notification.sid) : notification.description}>
                                            {notification.sid ? t(notification.sid) : notification.description}
                                        </div>
                                        <Checkbox id={"HealthCheck" + index} classname='ldap_contents_notification_checkbox' name={t('TEXT_ENABLE2_P')} checked={notification.isEnable}
                                                  onChange={() => this.onClickDeviceHealthCheck(!notification.isEnable, index)}/>
                                    </div>
                                )
                            }
                            <div className='ldap_contents_notification_subject'>{t('COM_TV_NOTIFICATIONS_KR_VOICE')}</div>
                            {
                                normalNotifications.map((notification, index) =>
                                    <div key={index} className='ldap_contents_notification_row'>
                                        <div className='ldap_contents_notification_title' title={notification.sid ? t(notification.sid) : notification.description}>
                                            {notification.sid ? t(notification.sid) : notification.description}
                                        </div>
                                        <Checkbox id={"NormalCheck" + index} classname='ldap_contents_notification_checkbox' name={t('TEXT_ENABLE2_P')} checked={notification.isEnable}
                                                  onChange={() => this.onClickNormalCheck(!notification.isEnable, index)}/>
                                    </div>
                                )
                            }
                        </div>
                    }
                </div>
            </Fragment>
        );
    }

};
export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(withTranslation()(EditLDAPPopup));