import React, {createRef} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {menuAction, popupAction} from "../../actions";
import {toastr} from 'helper/toastrIntercept';
import {chunk, isNil, snakeCase, toInteger, toUpper, isUndefined} from 'lodash';
import fileDownload from 'js-file-download';
import classNames from 'classnames';
import WhiteButton from "../../components/button/WhiteButton";
import {
    ALL_DEVICE_SUB_TABS,
    commonConstants,
    DEVICE_PAGE_SIZE_OPTIONS,
    FLIP,
    LPLAYER,
    SIGNAGE_CHILD,
} from "../../constants";
import "../../components/table/react-table.css";
import {deviceService, userService} from '../../services';
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Filter from "../../components/filter/Filter";
import SwitchListNImage from "../../components/switch/SwitchListNImage";
import './AllDevice.css';
import './Device.css';
import Device from "../../components/device/Device";
import SubTab from '../../components/tab/SubTab';
import DeviceNameCell from '../../components/device/DeviceNameCell';
import ImageTableHeader from '../../components/table/ImageTableHeader';
import Pagination from '../../components/table/Pagination';
import {
    DeviceDisplayTable,
    DeviceInfoTable,
    DeviceSecurityTable,
    DeviceSetupTable,
    DeviceTimeTable
} from './AllDeviceTables';
import SelectBox from '../../components/selectbox/SelectBox';
import DeviceInfoButton from '../../components/device/DeviceInfoButton';
import CircleButton from '../../components/button/CircleButton';
import DeviceListSettingPopper from '../../components/device/DeviceListSettingPopper';
import EmptyImg from '../../images/img/empty_img_9.png';
import SettingIcon from '../../images/icon/circle_setting.png';
import withMISOpt, {deviceOptions} from '../../components/misopt';
import ReactDOM from "react-dom";
import handleRemoteControl from '../../components/device/RemoteControlHandler';
import RMServerOpener from '../../components/device/RMServerOpener';
import {DeviceFadeInTransition, DeviceFadeOutTransition} from '../../components/device/DeviceTransition';
import {Loading} from '../../components/loading/Loading';
import {getErrorMessage} from "../../helper/responseHandler";
import {getCache, updateCache} from "../../helper/cache/tableCache";

const convFilter = (filter, groupId) => {
    const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;
    return {
        ...filter,
        startIndex: page * pageSize + 1,
        pageSize,
        searchText: keyword,
        groupId,
        sortColumn: snakeCase(id),
        sortOrder: desc ? 'desc' : 'asc',
    };
};

const tagOptions = [
    {value: 'media', title: 'MIS_SID_MEDIA_TAG'},
    {value: 'variable', title: 'MIS_SID_VARIABLE_TAG'},
];

const defaultSort = {id: 'deviceName', desc: false};
const defaultPollingInterval = 15;
const defaultPollingNumber = 15;
const imageViewOffset = 35;
const listViewOffset = 52;

const getTagConditionList = (selectedTagList) => {
    const tagConditionIdList = [];

    selectedTagList.map(tag => {
        if (tag.contentTagConditions) {
            tag.contentTagConditions.map(condition => {
                if (condition.checkCondition) {
                    tagConditionIdList.push(condition.tagConditionId);
                }
            });
        } else if(tag.tagConditionId) {
            tagConditionIdList.push(tag.tagConditionId);
        }
    });

    return tagConditionIdList;
};
const checkTagCondition = (selectedTagList) => {
    let isValidCondition = false;
    selectedTagList.map(tag => {
        if (tag.contentTagConditions) {
            isValidCondition = tag.contentTagConditions.filter(condition => condition.checkCondition === true).length !== 0;
        } else {
            isValidCondition = true;
        }
    });

    return isValidCondition;
};

class AllDevice extends React.Component {

    constructor(props) {
        super(props);

        this.userId = JSON.parse(localStorage.getItem('user')).id;
        this.state = {
            loading: false,
            data: props.cache.items !== undefined ? props.cache.items : [],
            totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
            view: localStorage.getItem(`devicePageView_${this.userId}`) || 'IMAGE',
            filter: {
                ...props.cache.filter
            },
            size: props.cache.size ? props.cache.size : 150,
            height: 500,
            subTab: props.cache.subTab ? props.cache.subTab : ALL_DEVICE_SUB_TABS[0],
            showSettingPopper: false,
            showScreenCapture: props.cache.showScreenCapture ? props.cache.showScreenCapture : false,
            showInformation: props.cache.showInformation ? props.cache.showInformation : false,
            showTagInformation: props.cache.showTagInformation ? props.cache.showTagInformation :  false,
            selectedCount: props.cache.selected ? props.cache.selected.length : 0,
            rmServer: {show: false},
            showChannelToolTip: false,
            availableFunctions: {},
            openSignageList: [],
        };

        this.checkAll = createRef();
        this.checkBox = [];
        this.deviceCard = [];
        this.selected = [];
        this.pollingHandle = null;
        this.pollingInterval = defaultPollingInterval;
        this.pollingNumber = defaultPollingNumber;
        this.pollingCount = 0;
        this.pollingDeviceIdArray = [];
        this.hasDeviceSecurity = false;
        this.rmServerPollingHandle = null;

        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleRemoteControl = handleRemoteControl.bind(this);
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);

        const {id: userId} = JSON.parse(localStorage.getItem('user'));
        this.userId = userId;

        this.pollingInterval = localStorage.getItem(`devicePollingInterval_${userId}`) || defaultPollingInterval;
        this.pollingNumber = localStorage.getItem(`devicePollingNumber_${userId}`) || defaultPollingNumber;

        if(!this.props.cache.isLoaded || this.state.filter.isFetched) {
            this.fetchData();
            this.checkSecurityAuthority();
            this.fetchAvailableFunctions();
        }
    }

    componentWillUnmount() {
        const {closePopup} = this.props;
        window.removeEventListener('resize', this.updateDimensions);
        this.stopPolling();
        //updateCache('DEVICE', {selected: this.selected}, this.props.currContent);
        // closePopup(commonConstants.QUICK_CONTROL_DEVICE);
    }

    componentDidUpdate(prevProps, prevState) {
        const {filter, subTab, view} = this.state;
        const {groupId, reloadGroup, refreshIds} = this.props;

        const {id: userId} = JSON.parse(localStorage.getItem('user'));
        this.userId = userId;
        this.hasDeviceSecurity = localStorage.getItem(`hasDeviceSecurity_${userId}`) || false;

        if(prevState.filter !== filter) {
            this.fetchData();
            this.checkSecurityAuthority();
        }

        if(prevProps.groupId !== groupId) {
            if(!isNil(groupId)) {
                this.selected = [];
                if(view === 'IMAGE') {
                    this.setState({filter: {...filter, page: 0, keyword: ''}, selectedCount: 0});
                } else {
                    this.setState({filter: {...filter, page: 0, keyword: '', sorted: [defaultSort]}, selectedCount: 0});
                }
                reloadGroup('DEVICE_BY_GROUP', -1);
                this.fetchAvailableFunctions();
            } else {
                this.setState({filter: {...filter, page: 0, keyword: '', sorted: [defaultSort]}, availableFunctions: {}});
            }
        }

        if(prevState.view !== view) {
            if(view === 'IMAGE') {
                const {filter} = this.state;
                this.restartPolling();
                this.setState({filter: {...filter, page: 0, sorted: [defaultSort]}});
            } else {
                this.stopPolling();
                this.setState({subTab: ALL_DEVICE_SUB_TABS[0], filter: {...filter, page: 0, sorted: [defaultSort]}});
            }
        }

        if(prevProps.refreshIds !== refreshIds) {
            if(view === 'IMAGE' || subTab === ALL_DEVICE_SUB_TABS[0]) {
                this.fetchDataByIds(refreshIds);
            }
        }
    }

    updateDimensions() {
        this.setState({height: window.innerHeight - 210});
    }

    startPolling = () => {
        this.pollingDeviceIdArray = chunk(this.state.data.map(d => d.deviceId), this.pollingNumber);
        clearInterval(this.pollingHandle);
        this.pollingHandle = setInterval(() => {
            this.pollingData();
        }, this.pollingInterval * 1000);
    };

    restartPolling = () => {
        this.stopPolling();
        this.startPolling();
    };

    stopPolling = () => {
        if(this.pollingHandle) {
            clearInterval(this.pollingHandle);
        }
    };

    fetchDataByIds = deviceIds => {
        if(!deviceIds || deviceIds.length === 0) {
            return;
        }

        const {data} = this.state;
        deviceService.fetchDeviceFilter({pollingDeviceIds: deviceIds, pageSize: deviceIds.length}).then(res => {
            const newData = data.map(d => {
                const found = res.items.find(item => item.deviceId === d.deviceId);
                if(found) {
                    return found;
                }
                return d;
            });

            this.setState({data: newData});
            updateCache('DEVICE', {...this.props.cache, items: newData});
        }).catch(error => {
            console.log(error);
        });
    };

    pollingData = () => {
        const {view} = this.state;
        if(view !== 'IMAGE') {
            return;
        }

        const pollingDeviceIds = this.pollingDeviceIdArray[this.pollingCount % this.pollingDeviceIdArray.length];
        this.pollingCount ++;
        if(this.pollingCount >= this.pollingDeviceIdArray.length) {
            this.pollingCount = 0;
        }

        if( typeof pollingDeviceIds === 'undefined' || pollingDeviceIds.length === 0){
            return;
        }

        this.fetchDataByIds(pollingDeviceIds);
    };

    checkSecurityAuthority = () => {
        userService.fetchUserLoginInformation().then(
            res => {
                if(res.status === 'Success') {
                    // this.setState({hasDeviceSecurity: res.items.hasDeviceSecurity});
                    localStorage.setItem(`hasDeviceSecurity_${this.userId}`, res.items.hasDeviceSecurity);
                }
            }
        ).catch(error => {
            console.log(error);
        });
    };

    fetchData = () => {
        const {view, loading, subTab, filter} = this.state;
        const {groupId} = this.props;

        if (!loading) {
            this.setState({loading: true});
        }

        if(view === 'IMAGE') {
            this.stopPolling();
        }

        let promise;
        if(view === 'IMAGE' || subTab === ALL_DEVICE_SUB_TABS[0]) {
            promise = deviceService.fetchDeviceFilter(convFilter(filter, groupId));
        } else if(subTab === ALL_DEVICE_SUB_TABS[1]) {
            promise = deviceService.fetchDeviceTimeFilter(convFilter(filter, groupId));
        } else if(subTab === ALL_DEVICE_SUB_TABS[2]) {
            promise = deviceService.fetchDeviceSetupFilter(convFilter(filter, groupId));
        } else if(subTab === ALL_DEVICE_SUB_TABS[3]) {
            promise = deviceService.fetchDeviceDisplayFilter(convFilter(filter, groupId));
        } else if(subTab === ALL_DEVICE_SUB_TABS[4]) {
            promise = deviceService.fetchDeviceSecurityFilter(convFilter(filter, groupId));
        }
        
        if(promise) {
            promise.then(data => {
                const items = view === 'LIST' && subTab === ALL_DEVICE_SUB_TABS[0] ? data.items : data.items.filter(dev => dev.deviceType !== SIGNAGE_CHILD);
                updateCache('DEVICE', {items: items, filter: filter, totalCount: data.totalCount, subTab: subTab}, this.props.currContent);
                this.selected = [];
                this.setState({
                    data: items,
                    loading: false,
                    totalCount: data.totalCount,
                }, () => {
                    if (view === 'IMAGE') {
                        this.startPolling();
                    }
                });

            }).catch(error => {
                if(error.errorCode === 408900)
                    return;
                toastr.error(getErrorMessage(error));
                this.setState({loading: false});
            });
        } else {
            this.setState({loading: false});
        }
    };

    fetchAvailableFunctions = () => {
        const {groupId} = this.props;
        if(!isNil(groupId)) {
            deviceService.fetchAvailableFunctions(groupId).then(res => {
                this.setState({availableFunctions: res.items});
            }).catch(error => console.log(error));
        }
    };

    setCheckAll(check) {
        if(this.checkAll && this.checkAll.current) {
            this.checkAll.current.checked = check;
        }
    };

    networkModeDisabled() {
        const {misopt} = this.props;
        const {data, selectedCount} = this.state;

        const supportNetworkMode = this.selected.map(s => data[s]).every(s => {
            const option = misopt.misOptionDevice(toUpper(s.deviceType), parseInt(s.deviceTypeVersion));
            return option.deviceNetworkMode;
        });

        return selectedCount === 0 || !supportNetworkMode;
    };

    toggleRow = index => {
        if(this.checkBox[index].checked) {
            this.selected.push(index);
            this.checkBox[index].closest('.rt-tr-group').classList.add('selected');
            if(this.selected.length === this.state.data.length) {
                this.setCheckAll(true);
            }
        } else {
            if(this.selected.length === this.state.data.length) {
                this.setCheckAll(false);
            }
            this.selected.splice(this.selected.findIndex(idx => idx === index), 1);
            this.checkBox[index].closest('.rt-tr-group').classList.remove('selected')
        }
        this.setState({selectedCount: this.selected.length});
    };

    toggleDevice = index => {
        const deviceCardRef = this.deviceCard[index];
        const classes = deviceCardRef.getAttribute('class');

        if(classes.indexOf('on') >= 0) {
            if(this.selected.length === this.state.data.length) {
                this.setCheckAll(false);
            }
            this.selected.splice(this.selected.findIndex(idx => idx === index), 1);
            deviceCardRef.setAttribute('class', classes.split(' ').filter(cls => cls !== 'on').join(' '));
        } else {
            this.selected.push(index);
            if(this.selected.length === this.state.data.length) {
                //select all
                this.setCheckAll(true);
            }
            deviceCardRef.setAttribute('class', classes + ' on');
        }

        this.setState({selectedCount: this.selected.length});
        // if(this.deviceCard[index].getSelect()) {
        //     if(this.selected.length === this.state.data.length) {
        //         this.setCheckAll(false);
        //     }
        //     this.selected.splice(this.selected.findIndex(idx => idx === index), 1);
        //     this.deviceCard[index].setSelect(false);
        // } else {
        //     this.selected.push(index);
        //     if(this.selected.length === this.state.data.length) {
        //         //select all
        //         this.setCheckAll(true);
        //     }
        //     this.deviceCard[index].setSelect(true);
        // }
    };

    toggleSelectAll = e => {
        const {data, view} = this.state;
        if(e.target.checked) {
            this.selected = [];
            for(let i = 0; i < data.length; i++) {
                this.selected.push(i);
                if(view === 'IMAGE') {
                    const deviceCardRef = this.deviceCard[i];
                    const classes = deviceCardRef.getAttribute('class');

                    if(classes.indexOf('on') < 0) {
                        deviceCardRef.setAttribute('class', classes + ' on');
                    }
                } else {
                    if(!this.checkBox[i].checked) {
                        this.checkBox[i].checked = true;
                        this.checkBox[i].closest('.rt-tr-group').classList.add('selected');
                    }
                }
            }
        } else {
            for(const i of this.selected) {
                if(view === 'IMAGE') {
                    const deviceCardRef = this.deviceCard[i];
                    const classes = deviceCardRef.getAttribute('class');

                    if(classes.indexOf('on') >= 0) {
                        deviceCardRef.setAttribute('class', classes.split(' ').filter(cls => cls !== 'on').join(' '));
                    }
                } else {
                    if(this.checkBox[i].checked) {
                        this.checkBox[i].checked = false;
                        this.checkBox[i].closest('.rt-tr-group').classList.remove('selected')
                    }
                }
            }
            this.selected = [];
        }
        this.setState({selectedCount: this.selected.length});
    };

    updateSwitchListNImage = (view) => {
        if(this.state.view !== view) {
            const data = {...this.props.cache, view: view};
            updateCache('DEVICE', data);
            this.selected = [];
            this.setState({view, selectedCount: this.selected.length});
            localStorage.setItem(`devicePageView_${this.userId}`, view );
        }
    };

    onKeywordChange = value => {
        const {filter} = this.state;
        this.setState({filter: {...filter, keyword: value, page: 0}});
    };

    onPageChange = page => {
        const {filter} = this.state;
        this.setState({filter: {...filter, page}});
    };

    onPageSizeChange = (pageSize, page) => {
        const {filter} = this.state;
        this.setState({filter: {...filter, page, pageSize}});
    };

    onSortedChange = (newSorted, column, additive) => {
        const [{id, desc}] = newSorted;
        const {filter} = this.state;
        if(id) {
            this.setState({filter: {...filter, sorted: [{id, desc}]}});
        }
    };

    onClickDevice = deviceId => {
        const {openDetailView} = this.props;
        openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'DEVICE', id: deviceId, hasDeviceSecurity: this.hasDeviceSecurity});
    };

    onEditDevice = () => {
        const {addPopup, closePopup, t} = this.props;
        const {data, selectedCount} = this.state;
        const deviceIds = this.selected.map(s => data[s].deviceId);
        const deviceTypes = this.selected.map(s => data[s].deviceType);

        let popupId = commonConstants.UPDATE_DEVICE;
        if(selectedCount === 1) {
            if(deviceTypes[0] === 'FLIP') {
                popupId = commonConstants.UPDATE_FLIP_DEVICE;
            }
            addPopup({
                id: popupId,
                type: popupId,
                deviceIds,
                hasDeviceSecurity: this.hasDeviceSecurity,
                onClose: () => closePopup(popupId),
            });
        } else if(selectedCount > 1) {
            if(!this.checkDeviceType()) {
                toastr.warning(t('MIS_SID_CHOOSE_THE_SAME_TYPE_OF_DEVICE'));
                return;
            }

            if(this.isAllFlipType()) {
                popupId = commonConstants.UPDATE_FLIP_DEVICE;
            }

            deviceService.fetchDeviceConnectionCheck(deviceIds).then(res => {
                const {items: {successList = []} = {}} = res;
                if(successList.length === deviceIds.length) {
                    addPopup({
                        id: popupId,
                        type: popupId,
                        deviceIds,
                        hasDeviceSecurity: this.hasDeviceSecurity,
                        onClose: () => closePopup(popupId),
                    });
                }
                else {
                    toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
                }
            }).catch(error => {
                toastr.warning(t('MESSAGE_DEVICE_NOT_CONNECTED_DEVICE_P'));
            });
        }
    };

    handleDelete = () => {
        const {addPopup, closePopup, t, reloadGroup,updateSubMenuCounter} = this.props;
        const {data} = this.state;
        const deviceIds = this.selected.map(s => data[s].deviceId);

        deviceService.deleteDevices(deviceIds).then(res => {
            if(res.status === 'Success') {
                closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                if(res.items.failList.length > 0) {
                    if(res.items.failList[0].reasonCode === '400627') {
                        addPopup({
                            id: commonConstants.COMMON_ALERT_POPUP,
                            type: commonConstants.COMMON_ALERT_POPUP,
                            title: t('TEXT_TITLE_DELETE_DEVICE_P'),
                            message: (
                                <>
                                    {t('MIS_SID_SERVER_NOT_DELETED_DEVICE_VIDEO_WALL_LAYOUT')}
                                    <br/>
                                    {t('MIS_SID_SERVER_REPLACE_DEVICE_ADDING_DELETE_GROUP')}
                                </>
                            ),
                            width: 350,
                            height: 130,
                            onClose: () => closePopup(commonConstants.COMMON_ALERT_POPUP)
                        })
                    } else if(res.items.failList[0].reasonCode === '400626') {
                        toastr.error(t('MIS_MESSAGE_BACKUPPLAYER_FAIL_DELETE_P'));
                    } else {
                        toastr.warning(t('TEXT_FAIL_P'));
                    }
                } else {
                    toastr.success(t('ALERT_SUCCESS_DELETE'));
                    this.selected = [];
                    this.setState({selectedCount: this.selected.length});
                }

                // force reload group tree
                reloadGroup('DEVICE_BY_GROUP', -1);
                this.fetchData();

            }
        }).catch(error => {
            // if(json.reason == 'isVwtDevice'){
            //     var msg = $.i18n.prop('MIS_SID_SERVER_NOT_DELETED_DEVICE_VIDEO_WALL_LAYOUT') + "\n"
            //             + $.i18n.prop('MIS_SID_SERVER_REPLACE_DEVICE_ADDING_DELETE_GROUP');
            //     $(this).onConfirmPopup({
            //         title : $.i18n.prop('TEXT_TITLE_DELETE_DEVICE_P'),
            //         message : msg
            //     });
            // }else if(json.reason == 'isRedundancy'){
            //     toastr.error($.i18n.prop('MIS_MESSAGE_BACKUPPLAYER_FAIL_DELETE_P'));
            // }else{
            //     toastr.warning($.i18n.prop('TEXT_FAIL_P'));
            // }
            if(error.items.failList && error.items.failList.length > 0) {
                if(error.items.failList[0].reasonCode === '400627') {
                    addPopup({
                        id: commonConstants.COMMON_ALERT_POPUP,
                        type: commonConstants.COMMON_ALERT_POPUP,
                        title: t('TEXT_TITLE_DELETE_DEVICE_P'),
                        message: (
                            <>
                                {t('MIS_SID_SERVER_NOT_DELETED_DEVICE_VIDEO_WALL_LAYOUT')}
                                <br/>
                                {t('MIS_SID_SERVER_REPLACE_DEVICE_ADDING_DELETE_GROUP')}
                            </>
                        ),
                        width: 350,
                        height: 130,
                        onClose: () => closePopup(commonConstants.COMMON_ALERT_POPUP)
                    })
                    return;
                } else if(error.items.failList[0].reasonCode === '400626') {
                    toastr.error(t('MIS_MESSAGE_BACKUPPLAYER_FAIL_DELETE_P'));
                    return;
                }
            }

            toastr.warning(t('TEXT_FAIL_P'));
        }).finally(()=>{
            updateSubMenuCounter('DEVICE');
        });
    };

    requestMove = (groupId, deviceIds) => {
        const {closePopup, reloadGroup, t} = this.props;
        deviceService.moveDevice(groupId, deviceIds).then(res => {
            toastr.success(t('COM_TEXT_SUCCESS_P'));
            closePopup(commonConstants.COMMON_GROUP_POPUP);
            reloadGroup('DEVICE_BY_GROUP', -1);
            this.fetchData();
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    handleMove = groups => {
        const {addPopup, closePopup, t} = this.props;
        const {data} = this.state;
        const deviceIds = this.selected.map(s => data[s].deviceId);

        if(!groups || groups.length === 0) {
            return;
        }
        
        deviceService.checkDeviceApproval(groups[0].groupId, deviceIds).then(res => {
            this.selected = [];
            this.setState({selectedCount: this.selected.length});

            if(res.status === 'Success') {
                if(res.items.unapproveList.length > 0) {
                    res.items.unapproveList.map(unapprove => {
                        if(unapprove.reasonCode === '400617'){
                            toastr.error(t('MIS_MESSAGE_BACKUPPLAYER_FAIL_MOVE_P'));
                        } else if(unapprove.reasonCode === '400620'){
                            toastr.error(t('MIS_MESSAGE_VWLLAYOUT_GROUP_MOVE_FAIL_P'));
                        } else if(unapprove.reasonCode === '400621'){
                            toastr.error(t('MIS_MESSAGE_VWLLAYOUT_GROUP_CANNOT_MOVE_P'));
                        } else {
                            toastr.error(t('COM_SID_NOT_AVAILABLE_KR_USE'));
                        }
                    });
                }

                if (res.items.approveList.length > 0) {
                    const approveDeviceIds = res.items.approveList.map(approve => approve.deviceId);
                    this.requestMove(groups[0].groupId, approveDeviceIds);
                }
            } else {
                if(res.items.reason === 'initSchedule') {
                    addPopup({
                        id: commonConstants.COMMON_CONFIRM_POPUP,
                        type: commonConstants.COMMON_CONFIRM_POPUP, 
                        title: t('COM_BUTTON_CONFIRM'), 
                        message: t('MIS_MESSAGE_DELETE_SCHEDULE_CHANGE_DEV_GROUP_P'),
                        width: 310,
                        height: 130,
                        onClickYes: () => {
                            closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                            this.requestMove(groups[0].groupId, deviceIds);
                        }, 
                        onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
                    })
                }
            }
        }).catch(error => {
            if(error.items){
                if(error.items.reasonCode === '400617'){
                    toastr.error(t('MIS_MESSAGE_BACKUPPLAYER_FAIL_MOVE_P'));
                } else if(error.items.reasonCode === '400620'){
                    toastr.error(t('MIS_MESSAGE_VWLLAYOUT_GROUP_MOVE_FAIL_P'));
                } else if(error.items.reasonCode === '400621'){
                    toastr.error(t('MIS_MESSAGE_VWLLAYOUT_GROUP_CANNOT_MOVE_P'));
                } else {
                    toastr.error(t('COM_SID_NOT_AVAILABLE_KR_USE'));
                }
            } else if(error.errorMessage){
                toastr.error(error.errorMessage);
            }
        });
    };

    checkDeviceType = () => {
        const {data} = this.state;
        return !this.selected.some(s => !(data[this.selected[0]].deviceType === data[s].deviceType 
            && data[this.selected[0]].deviceTypeVersion === data[s].deviceTypeVersion));
    };

    isAllFlipType = () => {
        const {data} = this.state;
        return !this.selected.some(s => !(data[this.selected[0]].deviceType === data[s].deviceType
            && data[this.selected[0]].deviceTypeVersion === data[s].deviceTypeVersion
            && data[this.selected[0]].deviceType === 'FLIP'
        ));
    };

    onMoveDevice = () => {
        const {addPopup, closePopup, t} = this.props;
        if(!this.checkDeviceType()) {
            toastr.warning(t('MIS_SID_CHOOSE_THE_SAME_TYPE_OF_DEVICE'));
            return;
        };

        addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'device',
            allEnabled: true,
            save: this.handleMove, 
            close: () => closePopup(commonConstants.COMMON_GROUP_POPUP),
        });
    };

    onDeleteDevice = () => {
        const {addPopup, closePopup, t} = this.props;
        addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            width: 310,
            height: 130,
            title: t('TEXT_TITLE_DELETE_DEVICE_P'), 
            message: t('MESSAGE_DEVICE_DELETE_DEVICE_P'),
            onClickYes: this.handleDelete, 
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        });
    };

    isSameDeviceType = () => {
        const {data} = this.state;
        const deviceType = data[this.selected[0]].deviceType;
        return [this.selected.every(s => data[s].deviceType === deviceType), deviceType];
    };

    onQuickControl = () => {
        const {addPopup, closePopup, t} = this.props;

        addPopup({
            id: commonConstants.QUICK_CONTROL_DEVICE,
            type: commonConstants.QUICK_CONTROL_DEVICE, 
            onControl: (menu, value, power, onResult) => {
                const {data} = this.state;

                if(this.selected.length === 0) {
                    toastr.warning(t('MESSAGE_DEVICE_SELECT_DEVICE_P'));
                    value === '20' && onResult('fail');
                    return;
                }

                // if(power && this.selected.every(s => data[s].power === true))
                // const deviceType = data[this.selected[0]].deviceType;
                // const sameDeviceType = this.selected.every(s => data[s].deviceType === deviceType);
                const [sameDeviceType, deviceType] = this.isSameDeviceType();

                if(!sameDeviceType) {
                    toastr.warning(t('MIS_SID_CHOOSE_THE_SAME_TYPE_OF_DEVICE'));
                    value === '20' && onResult('fail');
                    return;
                }
                
                if((deviceType === FLIP && menu !== 'power') || 
                    (deviceType === LPLAYER && menu === 'remote')) {
                    toastr.warning(t('MIS_TEXT_DOES_NOT_SUPPORT_THE_PRODUCT_P'));
                    value === '20' && onResult('fail');
                    return;
                }

                if(menu === 'remote') {
                    if(this.selected.length > 1) {
                        toastr.warning(t('MESSAGE_DEVICE_SELECT_ONE_DEVICE_P'));
                        return;
                    }

                    this.handleRemoteControl(data[this.selected[0]]);
                } else {
                    let deviceIds = [];
                    if(menu === 'power' && value === '1') {
                        deviceIds = this.selected.filter(s => !data[s].power).map(s => data[s].deviceId);
                    } else {
                        deviceIds = this.selected.filter(s => data[s].power).map(s => data[s].deviceId);
                    }

                    if(deviceIds.length > 0) {
                        deviceService.quickControlDevice({
                            deviceIds,
                            menu: menu,
                            // productType: '',
                            value: value
                        }).then(res => {
                            toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                            value === '20' && onResult('success');
                            // closePopup(commonConstants.QUICK_CONTROL_DEVICE);
                        }).catch(error => {
                            toastr.error(getErrorMessage(error))
                            value === '20' && onResult('fail');
                        });
                    }
                }
            },
            onClose: () => closePopup(commonConstants.QUICK_CONTROL_DEVICE),
        });
    };

    renderNameCell = ({original}) => {
        const {subTab} = this.state;
        return <DeviceNameCell device={original} enableChild={subTab === ALL_DEVICE_SUB_TABS[0]} disableLink={original.deviceType === SIGNAGE_CHILD} toggleChild={this.toggleChild} onClick={this.onClickDevice} onClickMemo={this.onClickMemo} />;
    };

    renderMACCell = data => {
        const notSignageChild = data.original.deviceType !== SIGNAGE_CHILD;
        return (
            <span className={classNames({'mac_address_cell': notSignageChild})} onClick={() => notSignageChild && this.onClickDevice(data.original.deviceId)}>{data.value}</span>
        );
    };

    renderCheckAllCell = () => {
        return (
            <Checkbox
                id='AllDevice_all'
                classname="table"
                name="check"
                ref={this.checkAll}
                onChange={this.toggleSelectAll}
                defaultChecked={this.selected.length > 0 ? this.selected.length === this.state.data.length : false}
            />
        )
    };

    renderCheckCell = (row) => {
        const checked = this.selected.length > 0 ? this.selected.includes(row.index) : false;
        if(this.checkBox[row.index]){
            if(checked)
                this.checkBox[row.index].closest('.rt-tr-group').classList.add('selected');
            else
                this.checkBox[row.index].closest('.rt-tr-group').classList.remove('selected')
        }

        return (
            <Checkbox id={'AllDevice_'+row.index} classname="table" name="check"
                ref={ref => this.checkBox[row.index] = ref} checked={checked}
                onChange={() => this.toggleRow(row.index)}/>
        );
    };

    toggleChild = (open, device) => {
        const {openSignageList} = this.state;
        if(open) {
            this.setState({openSignageList: openSignageList.concat(device.deviceId)});
        } else {
            this.setState({openSignageList: openSignageList.filter(el => el !== device.deviceId)});
        }
    };

    onClickSubTab = tab => {
        const {filter} = this.state;
        this.selected = [];
        this.setState({subTab: tab, filter: {...filter, page: 0, sorted: [defaultSort]}, selectedCount: this.selected.length});
    };

    onClickTag = tagType => {
        const {addPopup, closePopup, t} = this.props;
        const {data} = this.state;
        let selectedTags = [];
        
        if(this.selected.length === 1) {
            selectedTags = tagType === 'media' ? data[this.selected[0]].mediaTagValueList : data[this.selected[0]].varTagValueList;
        }
        if(isNil(selectedTags)) {
            selectedTags = [];
        }

        addPopup({
            id: commonConstants.TAG_SETTING,
            type: commonConstants.TAG_SETTING,
            showTagValue: tagType !== 'media',
            showDevice: true,
            selectedTags,
            tagListType: tagType,
            devices: this.selected.map(s => data[s]),
            onApply: (tags, tagDevices) => {
                if(tagType !== 'media' && tags && tags.length > 0 && getTagConditionList(tags).length === 0) {
                    toastr.error(t('MESSAGE_COMMON_INPUT_CONTENT_P'));
                    return;
                }

                if(tagType !== 'media' && tags && tags.length > 0 && !checkTagCondition(tags)) {
                    toastr.error(t('MESSAGE_COMMON_INPUT_CONTENT_P'));
                    return;
                }

                if(tagDevices.length <= 0) {
                    toastr.error(t('MESSAGE_DEVICE_SELECT_DEVICE_P'));
                    return;
                }

                deviceService.updateDeviceTags({
                    deviceIds: tagDevices.map(d => d.deviceId),
                    tagIds: tags.map(t => t.tagId),
                    tagConditionIds: getTagConditionList(tags),
                    tagType: tagType.toUpperCase()
                }).then(res => {
                    toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                    closePopup(commonConstants.TAG_SETTING);
                    this.fetchData();
                }).catch(error => toastr.error(getErrorMessage(error)));

                selectedTags = [];
            },
            onClose: () => {
                selectedTags = [];
                closePopup(commonConstants.TAG_SETTING);
            },
        });
    };

    onSaveFilter = selected => {
        const {filter} = this.state;

        let deviceType = [];
        if(!(selected.devices.length === 1 && selected.devices[0] === 'ALL')) {
            deviceType = selected.devices;
        }
        this.selected = [];
        this.setState({
            filter: {
                ...filter,
                page: 0, 
                connectionStatus: selected.connectionStatus,
                deviceType,
                alarmTypes: selected.notifications,
                functionTypes: selected.functions,
                tagIds: selected.mediaTags,
                inputSources: selected.sources.length === deviceOptions.inputSource.length ? [] : selected.sources.map(source => source.value),
            }, 
            selectedCount: this.selected.length
        });
    };

    onExport = exportType => {
        const {subTab, filter} = this.state;
        const {groupId} = this.props;
        let category;
        switch(subTab){
            case ALL_DEVICE_SUB_TABS[1]:
                category = 'DEVICE_TIME';
                break;
            case ALL_DEVICE_SUB_TABS[2]:
                category = 'DEVICE_SETUP';
                break;
            case ALL_DEVICE_SUB_TABS[3]:
                category = 'DEVICE_DISPLAY';
                break;
            case ALL_DEVICE_SUB_TABS[4]:
                category = 'DEVICE_SECURITY';
                break;
            default:
                category = 'DEVICE_INFORMATION';
        }
        exportType = 'EXCEL';
        deviceService.exportDeviceFilter(exportType, convFilter(filter, groupId), category).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    getVwlLayoutMenu = vwlLayoutMng => {
        let menuLIst = [];

        if(vwlLayoutMng.subMenuList) {
            menuLIst = vwlLayoutMng.subMenuList.map(sm => (
                    {
                        value: sm.subKey, title: sm.name,
                        sub: sm.subKey === 'vwlLayoutNew' ? [{value: 'SETTING_NEW', title: 'TEXT_NEW_P'}, {value: 'SETTING_OPEN', title: 'COM_MIS_TEXT_OPEN_P'}] : [],
                    }
                )
            );
        } else {
            menuLIst = [];
        }

        return menuLIst;
    };

    onVwlLayout = vwlType => {
        // const {t} = this.props;

        const {groupId} = this.props;

        if(groupId !== undefined && groupId !== '') {
            switch (vwlType) {
                case 'SETTING_NEW' :
                    deviceService.fetchDeviceConnectionCheckByGroupId(groupId).then(res => {
                        if(res.status === 'Success') {
                            window.open('', 'VwlLayoutEditor');
                            let form = ReactDOM.findDOMNode(this).querySelector('#vwl-layout-editor-form');
                            form.setAttribute('action', res.items.layoutAuthorPath);
                            ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-username').setAttribute("value", res.items.userId);
                            // ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-password').setAttribute("value", res.items.userPassword);
                            ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-token').setAttribute("value", res.items.token);
                            ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-language').setAttribute("value", res.items.language);
                            // ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-device-type').setAttribute("value", res.items.token);
                            ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-device-group').setAttribute("value", res.items.deviceGroupId);
                            ReactDOM.findDOMNode(form).querySelector('#vwl-layout-editor-type').setAttribute("value", 'NEW');
                            form.submit();
                        }
                    }).catch(error => {
                        toastr.error(getErrorMessage(error));
                    });
                    break;
                case 'SETTING_OPEN' :
                    deviceService.fetchDeviceConnectionCheckByGroupId(groupId).then(res => {
                        if(res.status === 'Success'){
                            this.openVWLLayout(groupId);
                        }
                    }).catch(error => {
                        toastr.error(getErrorMessage(error));
                    });

                    //this.openVWLLayout(groupId);
                    break;
                case 'vwlLayoutPreview' :
                    this.openVWLLayoutPreview(groupId);
                    break;
                case 'vwlLayoutCancel' :
                    this.cancelVWLLayoutPreview(groupId);
                    break;
                default:
                    break;
            }
        }
    };

    openVWLLayout = (groupId) => {
        const {addPopup, closePopup} = this.props;

        addPopup({
            id: commonConstants.VWL_LAYOUT_SETTING,
            type: commonConstants.VWL_LAYOUT_SETTING,
            groupId: groupId,
            vwlLayoutEditorForm: ReactDOM.findDOMNode(this).querySelector('#vwl-layout-editor-form'),
            onClose: () =>closePopup(commonConstants.VWL_LAYOUT_SETTING)
        });
    };

    openVWLLayoutPreview = (groupId) => {
        const {addPopup, closePopup} = this.props;

        addPopup({
            id: commonConstants.VWL_LAYOUT,
            type: commonConstants.VWL_LAYOUT,
            groupId: groupId,
            layoutType: 'VWL',
            onClose: () =>closePopup(commonConstants.VWL_LAYOUT)
        });
    };

    cancelVWLLayoutPreview = (groupId) => {
        const {addPopup, closePopup, t} = this.props;

        addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t('TEXT_TITLE_WARNING_P'),
            message: t('MIS_TEXT_DEVICE_CANCEL_VWL_LAYOUT_EFFECT_P'),
            width: 310,
            height: 130,
            onClickYes: () => {
                closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                this.requestVwlCancel(groupId);
            },
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        })
    };

    requestVwlCancel = (groupId) => {
        const {t} = this.props;

        deviceService.cancelVwlLayoutByGroupId(groupId).then(
            res => {
                if(res.status === 'Success') {
                    toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                    this.fetchData();
                    this.fetchAvailableFunctions();
                }
            }
        ).catch(error => console.log(error));
    };

    renderListView = () => {
        const {height, totalCount, data, loading, filter: {page = 0, pageSize = 30, sorted}, subTab, openSignageList} = this.state;
        const ht = height - listViewOffset;

        return (
            <div className='device_list_view_wrap'>
                <div style={{marginLeft: '25px', marginRight: '25px', marginBottom: '20px'}}>
                    <SubTab tabs={ALL_DEVICE_SUB_TABS} selected={subTab} style={{width: '650px'}} onClick={this.onClickSubTab} />
                </div>
                { 
                    subTab === ALL_DEVICE_SUB_TABS[0] && 
                    <DeviceInfoTable 
                        height={ht} 
                        data={data} 
                        sorted={sorted} 
                        loading={loading}
                        openSignageList={openSignageList}
                        renderCheckAllCell={this.renderCheckAllCell}
                        renderCheckCell={this.renderCheckCell} 
                        renderNameCell={this.renderNameCell}
                        renderMACCell={this.renderMACCell} 
                        onSortedChange={this.onSortedChange}
                        checkBoxRefs={this.checkBox}
                        toggleRow={this.toggleRow} /> 
                }
                { 
                    subTab === ALL_DEVICE_SUB_TABS[1] && 
                    <DeviceTimeTable 
                        height={ht} 
                        data={data} 
                        sorted={sorted} 
                        loading={loading}
                        renderCheckAllCell={this.renderCheckAllCell}
                        renderCheckCell={this.renderCheckCell} 
                        renderNameCell={this.renderNameCell}
                        renderMACCell={this.renderMACCell} 
                        onSortedChange={this.onSortedChange}
                        checkBoxRefs={this.checkBox}
                        toggleRow={this.toggleRow} /> 
                }
                { 
                    subTab === ALL_DEVICE_SUB_TABS[2] && 
                    <DeviceSetupTable
                        height={ht} 
                        data={data} 
                        sorted={sorted} 
                        loading={loading}
                        renderCheckAllCell={this.renderCheckAllCell}
                        renderCheckCell={this.renderCheckCell} 
                        renderNameCell={this.renderNameCell}
                        renderMACCell={this.renderMACCell} 
                        onSortedChange={this.onSortedChange}
                        checkBoxRefs={this.checkBox}
                        toggleRow={this.toggleRow} /> 
                }
                { 
                    subTab === ALL_DEVICE_SUB_TABS[3] && 
                    <DeviceDisplayTable
                        height={ht} 
                        data={data} 
                        sorted={sorted} 
                        loading={loading}
                        renderCheckAllCell={this.renderCheckAllCell}
                        renderCheckCell={this.renderCheckCell} 
                        renderNameCell={this.renderNameCell}
                        renderMACCell={this.renderMACCell} 
                        onSortedChange={this.onSortedChange}
                        checkBoxRefs={this.checkBox}
                        toggleRow={this.toggleRow} />
                }
                { 
                    subTab === ALL_DEVICE_SUB_TABS[4] && 
                    <DeviceSecurityTable 
                        height={ht} 
                        data={data} 
                        sorted={sorted} 
                        loading={loading}
                        renderCheckAllCell={this.renderCheckAllCell}
                        renderCheckCell={this.renderCheckCell} 
                        renderNameCell={this.renderNameCell}
                        renderMACCell={this.renderMACCell} 
                        onSortedChange={this.onSortedChange}
                        checkBoxRefs={this.checkBox}
                        toggleRow={this.toggleRow} />
                }
                <Pagination totalCount={totalCount} page={page} defaultPageSize={DEVICE_PAGE_SIZE_OPTIONS[0]} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={this.onPageChange} onPageSizeChange={this.onPageSizeChange} divide={'DEVICE'}/>
            </div>
        )
    };

    onChangeSize = size => {
        const data = {...getCache('DEVICE'), size: size};
        updateCache('DEVICE', data);
        this.setState({size});
    };

    onChagneImageSorting = sorting => {
        const {filter} = this.state;
        this.setState({filter: {...filter, sorted: [{id: 'deviceName', desc: sorting === 'desc'}]}});
    };

    onClickSetting = () => {
        const {showSettingPopper} = this.state;
        this.setState({showSettingPopper: !showSettingPopper});
    };

    onChangeShowScreenCapture = e => {
        const data = {...getCache('DEVICE'), showScreenCapture: e.target.checked}
        updateCache('DEVICE', data);
        this.setState({showScreenCapture: e.target.checked});
    };

    onChangeShowInformation = e => {
        let data = {...getCache('DEVICE'), showInformation: e.target.checked}
        this.setState({showInformation: e.target.checked});
        if(this.state.showTagInformation) {
            this.setState({showTagInformation: !e.target.checked});
            data = {...data, showTagInformation: !e.target.checked}
        }
        updateCache('DEVICE', data);
    };

    onChangeShowTagInformation = e => {
        let data = {...getCache('DEVICE'), showTagInformation: e.target.checked}
        this.setState({showTagInformation: e.target.checked});
        if(this.state.showInformation) {
            this.setState({showInformation: !e.target.checked});
            data = {...data, showInformation: !e.target.checked};
        }
        updateCache('DEVICE', data);
    };

    onClickMemo = deviceId => {
        const {addPopup, closePopup, t} = this.props;
        const {data} = this.state;

        addPopup({
            id: commonConstants.DEVICE_MEMO,
            type: commonConstants.DEVICE_MEMO,
            deviceId: deviceId,
            onSave: () => {
                toastr.info(t("MIS_TEXT_SUCCESS_SENT_P"));
                closePopup(commonConstants.DEVICE_MEMO)
            },
            onDelete: (res) => {
                if(res.status.toLowerCase() === 'success') {
                    toastr.info(t("ALERT_SUCCESS_DELETE"));
                }
                this.setState({data: data.map(dev => {
                    if(dev.deviceId === deviceId) {
                        dev.iconMemo = 0;
                    }
                    return dev;
                })});
                closePopup(commonConstants.DEVICE_MEMO);
            },
            onClose: () => closePopup(commonConstants.DEVICE_MEMO),
        });
    };

    renderImageView = () => {
        const {data, height, totalCount, size, filter: {page, pageSize, sorted: [{desc}]}, 
            showScreenCapture, showInformation, showTagInformation, loading} = this.state;
        const {t} = this.props;
        
        return (
            <>
                {
                    data != null && data.length > 0 &&
                    <ImageTableHeader
                        size={size}
                        sorting={desc ? 'desc' : 'asc'}
                        setCheckAllRef={this.checkAll}
                        onChangeCheckAll={this.toggleSelectAll}
                        onChangeSize={this.onChangeSize}
                        onChangeSorting={this.onChagneImageSorting}
                        isCheckAllDefault={data.length === this.selected.length}
                    >
                        <>
                            <Checkbox id='LIST_TITLE_CAPTURE' name={t('LIST_TITLE_CAPTURE')} checked={showScreenCapture}
                                      onChange={this.onChangeShowScreenCapture} style={{padding: '7px'}}/>
                            <Checkbox id='COM_TEXT_INFORMATION_P' name={t('COM_TEXT_INFORMATION_P')}
                                      checked={showInformation} onChange={this.onChangeShowInformation}
                                      style={{padding: '7px'}}/>
                            <Checkbox id='COM_MIP_IDS_TAB_DATALINK_STT_TAG_INFO'
                                      name={t('COM_MIP_IDS_TAB_DATALINK_STT_TAG_INFO')} checked={showTagInformation}
                                      onChange={this.onChangeShowTagInformation} style={{padding: '7px'}}/>
                        </>
                    </ImageTableHeader>
                }
                <div className={"device_image_view_wrap"} style={{height: height - imageViewOffset}}>
                    <DeviceFadeOutTransition transition={!loading} duration='.3s' classes={['device_loading_wrap']}>
                        <Loading/>
                    </DeviceFadeOutTransition>
                    <DeviceFadeInTransition transition={!loading} duration='.3s'>
                        {
                            data != null && data.length > 0 &&
                            <ul className="clearfix">
                            {
                                data.map(
                                    (device, index) => 
                                        <Device 
                                            key={device.deviceId} 
                                            device={device} 
                                            width={size}
                                            index={index}
                                            hasDeviceSecurity={this.hasDeviceSecurity}
                                            showScreenCapture={showScreenCapture}
                                            showInformation={showInformation}
                                            showTagInformation={showTagInformation}
                                            onClick={()=>this.toggleDevice(index)} 
                                            onClickMemo={this.onClickMemo}
                                            selected={this.selected}
                                            setRef={ref => this.deviceCard[index] = ref} />
                                )
                            }
                            </ul>
                        }
                        {
                            (isNil(data) || data.length === 0) &&
                            <div className='device_empty_view' style={{marginTop: height/3}}>
                                <img src={EmptyImg} />
                                <div>DEVICE</div>
                                <div>Add your devices</div>
                            </div>
                        }
                    </DeviceFadeInTransition>
                </div>
                {
                    data != null && data.length > 0 &&
                    <Pagination totalCount={totalCount} page={page} defaultPageSize={DEVICE_PAGE_SIZE_OPTIONS[0]}
                                pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={this.onPageChange}
                                onPageSizeChange={this.onPageSizeChange} divide={'DEVICE'}/>
                }

            </>
        )
    }

    handleCloseSetting = () => {
        this.setState({showSettingPopper: false});
    };

    onSaveSetting = (pollingInterval, pollingNumber) => {
        localStorage.setItem(`devicePollingInterval_${this.userId}`, pollingInterval);
        localStorage.setItem(`devicePollingNumber_${this.userId}`, pollingNumber);
        this.pollingInterval = pollingInterval;
        this.pollingNumber = pollingNumber;
        
        if(this.state.view === 'IMAGE') {
            this.restartPolling();
        }
        this.handleCloseSetting();
    };

    onNetworkMode = () => {
        const {t} = this.props;
        const {data} = this.state;

        if(this.selected.length < 1) {
            return;
        }

        deviceService.requestNetworkMode(this.selected.map(s => data[s].deviceId)).then(res => {
            toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    onExpDate = () => {
        const {addPopup, closePopup} = this.props;
        const {data} = this.state;

        if(this.selected.length < 1) {
            return;
        }

        let expirationDate = '';
        if(this.selected.length === 1) {
            const arr = this.selected.map(s => data[s].expirationDate);
            if( typeof arr[0] !== 'undefined' && arr[0] !== null ) {
                try{
                    expirationDate = new Date(arr[0]);
                }catch{
                    ;
                }
            }
        }

        addPopup({
            id: commonConstants.EXPIRATION_DATE,
            type: commonConstants.EXPIRATION_DATE,
            deviceIds: this.selected.map(s => data[s].deviceId),
            expirationDate : expirationDate,
            onClose: () => closePopup(commonConstants.EXPIRATION_DATE),
            onSave: () => {
                this.fetchData();
                closePopup(commonConstants.EXPIRATION_DATE)
            },
        });
    };

    conversionDisabled = () => {
        const {data, selectedCount} = this.state;
        const {misopt} = this.props;

        return selectedCount === 0 || !this.selected.every(s => data[s].power) || 
            !this.selected.every(s => {
                const option = misopt.misOptionDevice(toUpper(data[s].deviceType), parseInt(data[s].deviceTypeVersion));
                return option && option.deviceSwitchSocLite;
            });
    };

    onConversion = () => {
        const {t, addPopup, closePopup, reloadGroup, updateSubMenuCounter} = this.props;
        const {data} = this.state;

        if(!this.checkDeviceType()) {
            toastr.error(t('MIS_SID_CHOOSE_THE_SAME_TYPE_OF_DEVICE'));
            return;
        };

        const deviceType = data[this.selected[0]].deviceType;
        const deviceIds = this.selected.map(s => data[s].deviceId);
        Promise.all(deviceIds.map(deviceId => deviceService.fetchDeviceById(deviceId))).then(res => {
            const supportWplayer = res.every(r => r.items.supportWplayer);

            addPopup({
                id: commonConstants.DEVICE_TYPE_CONVERSION,
                type: commonConstants.DEVICE_TYPE_CONVERSION,
                conversionType: 'allDevice',
                deviceIds,
                deviceType,
                supportWPlayer: supportWplayer,
                onOk: () => {
                    this.selected = [];
                    this.setState({selectedCount: this.selected.length});
                    this.fetchData();
                    reloadGroup('DEVICE_BY_GROUP', -1);
                    updateSubMenuCounter('DEVICE');
                    closePopup(commonConstants.DEVICE_TYPE_CONVERSION);
                },
                onClose: () => closePopup(commonConstants.DEVICE_TYPE_CONVERSION),
            });
        }).catch(error => console.log(error));
    };

    channelDisabled = () => {
        const {data, selectedCount} = this.state;
        return selectedCount === 0 || !this.selected.every(s => data[s].power) ||
            !this.selected.every(s => data[s].basicSource == 48 || data[s].basicSource == 64);
    };

    onChannel = () => {
        const {addPopup, closePopup, t} = this.props;
        const {data} = this.state;
        const deviceIds = this.selected.map(s => data[s].deviceId);

        addPopup({
            id: commonConstants.TV_CHANNEL,
            type: commonConstants.TV_CHANNEL,
            onSave: channel => {
                deviceService.updateDeviceChannel({
                    deviceIds,
                    chkChannel: `1;${channel.analogDigital ? 1 : 0};${channel.airCable ? 1 : 0};${channel.majorChannel};1;${channel.minorChannel}`
                }).then(res => {
                    toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                    closePopup(commonConstants.TV_CHANNEL);
                }).catch(error => toastr.error(t('TEXT_FAIL_P')));
            },
            onClose: () => closePopup(commonConstants.TV_CHANNEL),
        });
    };

    onAnalyticsStore = type => {
        const {groupId, t} = this.props;

        if(type === 'analyticsStoreEnable') {
            deviceService.sendAnalyticsEnable(groupId).then(res => {
                toastr.success(t('COM_TEXT_SUCCESS_P'));
            }).catch(error => toastr.error(getErrorMessage(error)))
        } else {
            deviceService.sendAnalyticsCancel(groupId).then(res => {
                toastr.success(t('COM_TEXT_SUCCESS_P'));
            }).catch(error => toastr.error(getErrorMessage(error)));
        }
    }

    onBackupPlay = type => {
        const {data} = this.state;
        const {addPopup, closePopup, groupId, t} = this.props;

        if(type === 'backupPlaySetting') {
            const deviceIds = this.selected.map(s => data[s].deviceId);

            if(deviceIds.length === 0) {
                toastr.warning(t('MESSAGE_DEVICE_SELECT_DEVICE_P'));
                return;
            }

            if(!this.checkDeviceType()) {
                toastr.error(t('MIS_SID_CHOOSE_THE_SAME_TYPE_OF_DEVICE'));
                return;
            };

            if(deviceIds.length === data.length) {
               addPopup({
                   id: commonConstants.COMMON_ALERT_POPUP,
                   type: commonConstants.COMMON_ALERT_POPUP,
                   title: t('COM_DID_MAIN_NOTICE'),
                   message: `${t('MESSAGE_DEVICE_SELECT_DEVICE_P')} (${t('MIS_MIX_TEXT_EVENT_SIGN_LT_P').replace('<<A>>', data.length)})`,
                   width: 310,
                   height: 130,
                   onClose: () => closePopup(commonConstants.COMMON_ALERT_POPUP),
               });
               return;
            }

            if(deviceIds.length > 0) {
                addPopup({
                    id: commonConstants.BACKUP_PLAY,
                    type: commonConstants.BACKUP_PLAY,
                    groupId,
                    deviceIds,
                    onSave: () => {
                        closePopup(commonConstants.BACKUP_PLAY);
                        this.fetchData();
                        this.fetchAvailableFunctions();
                        // $('#BackupPlayMngList').html('<div><span></span><ul><li><a id="backupPlayCancel" href="#">'+$.i18n.prop('BUTTON_CANCEL_P')+'</a></li></ul></div>');
				        // $('#backupPlayCancel').unbind('click').on('click', onBackupPlayCancelButton);
                    },
                    onClose: () => closePopup(commonConstants.BACKUP_PLAY),
                });
            }
        } else {
            deviceService.deleteBackupConfig(groupId).then(res => {
                toastr.success(t('MIS_BUTTON_CLEAR_BACKUPPLAYER_P'));
                this.fetchData();
                this.fetchAvailableFunctions();
                // $('#BackupPlayMngList').html('<div><span></span><ul><li><a id="backupPlaySetting" href="#">'+$.i18n.prop('COM_TEXT_SETTING_P')+'</a></li></ul></div>');
				// $('#backupPlaySetting').unbind('click').on('click', onBackupPlaySettingButton);
            }).catch(error => toastr.error(getErrorMessage(error)));
        }
    };

    onBlurSettingPopper = e => {
        this.setState({showSettingPopper: false});
    };

    tagDisabled = () => {
        if(this.selected.length === 0) {
            return false;
        }
        const {misopt: {misOptionDevice}} = this.props;
        const {data} = this.state;

        let hasDisabledDevice = [];

        hasDisabledDevice = data.length > 0 ? this.selected.filter(index => (
            data[index].deviceType === 'RSPLAYER' || data[index].deviceType === 'RSPLAYER4' ||data[index].deviceType === 'RSPLAYER6' ||data[index].deviceType === 'RSPLAYER7' ||
            data[index].deviceType === 'RIPLAYER' ||
            data[index].deviceType === 'FLIP1' || data[index].deviceType === 'FLIP2' ||
            data[index].deviceType === 'RLEDBOX' ||
            data[index].deviceType === 'RSIGNAGE' || data[index].deviceType === 'RSIGNAGE6')
        ) : [];

        if(hasDisabledDevice > 0) {
            return true;
        }

        return data.length > 0 && this.selected.some(el => !(misOptionDevice(toUpper(data[el].deviceType), toInteger(data[el].deviceTypeVersion)) || {}).deviceTagMapping);
    };

    render() {
        const {pollingInterval, pollingNumber} = this;
        const {t, groupId,currContent} = this.props;
        const {data, view, subTab, showSettingPopper, selectedCount, rmServer, showChannelToolTip, availableFunctions: {vwlLayoutMng, backupPlay, analyticsStoreSetting} = {}, totalCount} = this.state;
        const searchPlaceHolder = `${t('TABLE_DEVICE_NAME_P')},MAC,${t('TABLE_IP_ADDR_P')},${t('ADMIN_DEVICEEVENT_ALARMRULE_ASSIGNRULE_CONTENTS_MODEL')},${t('COM_TEXT_FIRMWARE_P')},${t('TABLE_LOCATION_P')}, ${t('TABLE_DEVICE_SERIAL_P')} `;
        return (
            <div className='all_device_wrap' style ={{ display: (currContent === 'ALL_DEVICE'|| currContent === 'DEVICE_BY_GROUP') ? 'block':'none'}}>
                <div className="contents_buttonWrap">
                    <div className="leftButton">
                        {
                            (((view === 'IMAGE' && !isUndefined(data) && data.length > 0)) || view === 'LIST') &&
                                <>
                                    <WhiteButton id={"DEVICE_EDIT"} name={selectedCount <= 1 ? t('TEXT_EDIT_P') : t('MIS_SID_20_EDIT_MULTIPLE')} onClick={this.onEditDevice} disable={selectedCount === 0} />
                                    <WhiteButton id={"DEVICE_DELETE"} name={t('COM_BUTTON_DELETE')} onClick={this.onDeleteDevice} disable={selectedCount === 0} />
                                    <WhiteButton id={"DEVICE_MOVE"} name={t('BUTTON_MOVE_P')} onClick={this.onMoveDevice} disable={selectedCount === 0} />
                                    <WhiteButton id={"DEVICE_QUICK_CONTROL"} name={t('MIS_SID_20_QUICK_CONTROL')} onClick={this.onQuickControl}/>
                                    <SelectBox id={"DEVICE_ASSIGN_TAG"} title={t('MIS_SID_20_ASSIGN_TAG')} type='SUB' selects={tagOptions} multiLang={true} classname='device_menu_select_box' onClick={this.onClickTag} disabled={this.tagDisabled()} />
                                    <WhiteButton id={"DEVICE_EXPORT"} name={t('BUTTON_EXPORT_P')} onClick={this.onExport} style={{borderColor: '#5e5e5e'}} />
                                    {
                                        !isNil(vwlLayoutMng) &&
                                        <SelectBox id={"MIS_TEXT_TITLE_VWLLAYOUT_MANAGE_P"} title={t('MIS_TEXT_TITLE_VWLLAYOUT_MANAGE_P')} type='SUB' selects={this.getVwlLayoutMenu(vwlLayoutMng)} multiLang={true} classname='device_menu_select_box' onClick={this.onVwlLayout} />
                                    }
                                    {
                                        !isNil(backupPlay) && totalCount > 1 &&
                                        <SelectBox id={"MIS_SID_20_BACKUP_PLAY"} title={t('MIS_SID_20_BACKUP_PLAY')} type='SUB' selects={backupPlay.subMenuList ? backupPlay.subMenuList.map(sm => ({value: sm.subKey, title: sm.name})) : []} multiLang={true} classname='device_menu_select_box' onClick={this.onBackupPlay} />
                                    }
                                    {
                                        !isNil(analyticsStoreSetting) &&
                                        <SelectBox id={'Analytics Store'} title={'Analytics Store'} type='SUB' selects={analyticsStoreSetting.subMenuList ? analyticsStoreSetting.subMenuList.map(sm => ({value: sm.subKey, title: sm.name})) : []} multiLang={true} classname='device_menu_select_box' onClick={this.onAnalyticsStore} />
                                    }
                                    {
                                        this.state.view === 'LIST' && subTab === ALL_DEVICE_SUB_TABS[2] &&
                                        <>
                                            <WhiteButton id={"MIS_BUTTON_NETWORK_MODE_P"} name={t('MIS_BUTTON_NETWORK_MODE_P')} onClick={this.onNetworkMode} disable={this.networkModeDisabled()} />
                                            <WhiteButton id={"COM_EXPIRATION_DATE_KR_DATE"} name={t('COM_EXPIRATION_DATE_KR_DATE')} onClick={this.onExpDate} disable={selectedCount === 0} />
                                            <WhiteButton id={"MIS_TEXT_DEVICE_CONVERSION_P"} name={t('MIS_TEXT_DEVICE_CONVERSION_P')} onClick={this.onConversion} disable={this.conversionDisabled()} />
                                        </>
                                    }
                                    {
                                        this.state.view === 'LIST' && subTab === ALL_DEVICE_SUB_TABS[3] &&
                                        <div className='tooltip_wrap'>
                                            {
                                                this.channelDisabled() &&
                                                <div className='tooltip_div' onMouseEnter={() => this.setState({showChannelToolTip: true})} onMouseLeave={() => this.setState({showChannelToolTip: false})}></div>
                                            }
                                            <WhiteButton id={"COM_TEXT_CHANNEL_P"} name={t('COM_TEXT_CHANNEL_P')} onClick={this.onChannel} disable={this.channelDisabled()} style={{marginLeft:10}}/>
                                            {
                                                showChannelToolTip &&
                                                <div className='tooltip'>{t('MESSAGE_DEVICE_CHECK_INPUT_SOURCE_P')}</div>
                                            }
                                        </div>
                                    }
                                </>
                        }
                    </div>
                    <div className="rightButton">
                        <DeviceInfoButton buttonStyle={{marginRight: '8px'}} />
                        <span style={{float: 'left', marginRight: '8px'}}>
                            <Filter type='device' width={1140} onSaveFilter={this.onSaveFilter} cachedFilter={this.state.filter}/>
                        </span>
                        <SwitchListNImage view={this.state.view} onClick={this.updateSwitchListNImage}/>
                        <CircleButton style={{float: 'left', marginRight: '8px'}} onClick={this.onClickSetting}>
                            <span style={{
                                background: `url(${SettingIcon}) no-repeat 5px 5px`,
                                display: 'inline-block',
                                height: 28,
                                width: 28,
                            }}></span>
                        </CircleButton>
                        { showSettingPopper && <DeviceListSettingPopper pollingInterval={pollingInterval} pollingNumber={pollingNumber} onBlur={this.onBlurSettingPopper} onSave={this.onSaveSetting} onCancel={this.handleCloseSetting} /> }
                        <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} toolTip={searchPlaceHolder} onClickSearch={this.onKeywordChange} keyword={this.state.filter.keyword} />
                    </div>
                </div>
                {view === 'LIST' && this.renderListView()}
                {view === 'IMAGE' && this.renderImageView()}
                <form id='vwl-layout-editor-form' target='VwlLayoutEditor' method='post'>
                    <input id='vwl-layout-editor-username' type="hidden" name="username"/>
                    <input id='vwl-layout-editor-password' type="hidden" name="password"/>
                    <input id='vwl-layout-editor-token' type="hidden" name="token"/>
                    <input id='vwl-layout-editor-language' type="hidden" name="language"/>
                    <input id='vwl-layout-editor-device-type' type="hidden" name="deviceType"/>
                    <input id='vwl-layout-editor-device-group' type="hidden" name="deviceGroupId"/>
                    <input id='vwl-layout-editor-type' type="hidden" name="editType"/>
                    <input id='vwl-layout-editor-content-id' type="hidden" name="contentId"/>
                </form>
                {
                    rmServer.show && <RMServerOpener url={rmServer.url} token={rmServer.token} />
                }   
            </div>
        )
    }
}

export default connect(
    state => ({
        refreshIds: state.device.refreshIds,
    }),
    dispatch => ({
        openDetailView : (type)=> dispatch(popupAction.openDetailView(type)),
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id)),
        reloadGroup: (id, groupId) => dispatch(menuAction.reloadGroup(id, groupId)),
        updateSubMenuCounter: submenuId => dispatch(menuAction.updateSubMenuCounter(submenuId))
    })
)(withTranslation()(withMISOpt(AllDevice)));