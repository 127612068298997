import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import TextInput from '../../input/TextInput';
import {popupAction} from "../../../actions";
import {commonConstants, userConstants} from "../../../constants";
import {userService} from '../../../services';
import Select from "../../selectbox/Select";
import {useDispatch} from "react-redux";
import BULLET_2 from "../../../images/icon/bullet_2.png";
import {getMixString} from '../../../language/languageUtils'
import {toastr} from 'helper/toastrIntercept';
import "./UserPopup.css"
import {updateRoleListServerAdmin} from './common/userCommons';

const AddUserDetailedInformation = ({
    userInfo,
    updateUserInfoPartially,
    onChange,
}) => {
    const TEXT_INPUT_WIDTH = 290;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {errors} = userInfo || {};
    const INIT_STATUS_EMPTY = 0;
    const INIT_STATUS_ORGAN_ONLY = 1;
    const INIT_STATUS_ORGRAN_GROUP = 2;

    const groupName = userInfo.dataField.groupName;
    const groupId = userInfo.dataField.groupId;
    const organizationId = userInfo.dataField.organizationId;
    const organizationGroupName = userInfo.dataField.organizationGroupName;
    const roleId = userInfo.dataField.roleId;
    const isServerAdmin = localStorage.getItem('user') === null ? false : JSON.parse(localStorage.getItem('user')).serverAdmin;

    const [ldapInfo, setLdapInfo] = useState({
        isLdapEnabled: false,
        isLdapUserSelected : false,
        ldapOrgName: '',
        searchId: '',
        searchResult: [],
        ldapFullId: '',
        ldapSimpleId: '',
        isNickNameEnabled: false
    });

    const [filter, setFilter] = useState({
        isGroupSelectionDisabled: false,
        isOrgGroupSelectionDisabled: true,
        isErrorTextForAdminDisplay: false,
        isLdapSearchingDisplay: false
    });

    const [data, setData] = useState({
        orgList: [],
        roleList: [],
        orgGroupList: []
    });

    const [displayNotification, setDisplay] = useState(false);

    const {orgList, orgGroupList, roleList} = data;

    const show = () => {setDisplay(!displayNotification);};

    const getInitStatus = () => {
        if (organizationId === -1 && groupId === -1) {
            return INIT_STATUS_EMPTY;
        }

        if (organizationId !== -1 && (organizationId === groupId)) {
            return INIT_STATUS_ORGAN_ONLY;
        }

        if (organizationId !== -1 && groupId !== -1) {
            return INIT_STATUS_ORGRAN_GROUP;
        }
    };

    const onSelectOrganChange = (e, organizationId) => {
        Promise.all([
            userService.fetchUserAssignableRoles()
        ]).then(res => {
            const roleListForAll = res[0].items.map(item => {
                return {value: item.roleId, title: item.roleName, organizationId: item.organizationId};
            });

            const roleList = roleListForAll.filter(role => {
                return role.organizationId === userConstants.ORGAN_ID_ADMINISTRATOR || role.organizationId === organizationId;
            });

            updateRoleListServerAdmin(roleList, organizationId);

            if (organizationId === userConstants.ORGAN_ID_ADMINISTRATOR) {
                updateUserInfoPartially({groupName: t('TEXT_ROOT_GROUP_NAME_P'), groupId: userConstants.ORGAN_ID_ADMINISTRATOR});
            } else {
                updateUserInfoPartially({groupName: '', organizationGroupName: ''});
            }

            let currentRoleId = roleList[0].value;
            updateUserInfoPartially({
                organizationName: orgList.filter(item => item.value === organizationId)[0].title,
                organizationId: organizationId,
                roleName: roleList[0].title,
                roleId: roleList[0].value,
                isNickNameEnabled: false
            });

            setFilter({
                    ...filter,
                isOrganizationSelected: true,
                isErrorTextForAdminDisplay: currentRoleId === userConstants.ROLE_ID_SERVER_ADMINISTRATOR,
                isGroupSelectionDisabled: organizationId === userConstants.ORGAN_ID_ADMINISTRATOR,
                isOrgGroupSelectionDisabled: organizationId !== userConstants.ORGAN_ID_ADMINISTRATOR || currentRoleId === userConstants.ROLE_ID_SERVER_ADMINISTRATOR
            });
            setLdapInfo({
                isLdapUserSelected: false,
                ldapOrgName: '',
                searchId: '',
                searchResult: [],
                ldapFullId: '',
                ldapSimpleId: '',
                isLdapEnabled: orgList.filter(item => item.value === organizationId)[0].ldapStatus,
                isNickNameEnabled: false
            });

            setData({...data, roleList: roleList});
        }).catch(
            error => console.log(error)
        )
    };

    const onSelectRoleChange = (e, selectedValue) => {
        let selectedItem = data.roleList.filter(item => item.value === selectedValue);
        updateUserInfoPartially({roleName: selectedItem[0].title, roleId: selectedValue});

        if (selectedValue === userConstants.ROLE_ID_SERVER_ADMINISTRATOR) {
            updateUserInfoPartially({organizationGroupName: ''});
        }

        setFilter({
            ...filter,
            roleId: selectedValue,
            isOrgGroupSelectionDisabled: organizationId !== userConstants.ORGAN_ID_ADMINISTRATOR || selectedValue === userConstants.ROLE_ID_SERVER_ADMINISTRATOR,
            isErrorTextForAdminDisplay: selectedValue === userConstants.ROLE_ID_SERVER_ADMINISTRATOR
        });
    };

    const onSelectOrganGroupChange = (e, selectedValue) => {
        updateUserInfoPartially({organizationGroupName: selectedValue === t('TEXT_ALL_P') ? '' : selectedValue});
    };

    const toggleLdapSearching = (e) => {
        setFilter({...filter, isLdapSearchingDisplay: !filter.isLdapSearchingDisplay});
    };

    const handleGroupSelection = (param) => {
        if(!param || !param[0]) {
            return null;
        }

        if (param[0].groupId === param[0].organizationId) {
            return null;
        }

        updateUserInfoPartially({groupId: param[0].groupId, groupName: param[0].groupName});
        setFilter({...filter, groupId: param[0].groupId, groupName: param[0].groupName});
        dispatch(popupAction.closePopup('ADD_USER_GROUP_SELECTION'));
    };

    const handleLdapOrgSelection = (param) => {
        if(!param) return null;

        setLdapInfo({...ldapInfo, ldapOrgName: param});
        dispatch(popupAction.closePopup('ADD_USER_LDAP_ORGANIZATION_SELECTION'));
    };

    const onGroupSelectionPopup = (e) => {
        if (organizationId === -1) {
            toastr.error(t('MESSAGE_USER_SEL_ORGANIZATION_P'));
            return;
        }

        dispatch(popupAction.addPopup({
            id: commonConstants.ADD_USER_GROUP_SELECTION,
            type: commonConstants.ADD_USER_GROUP_SELECTION,
            onSave: handleGroupSelection,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.ADD_USER_GROUP_SELECTION)),
            organizationId: organizationId === -1 ? 0 : organizationId
        }));
    };

    const onClickLdapSearchUsers = (e) => {
        if (ldapInfo.searchId === '' || ldapInfo.searchId === null) {
            toastr.error(t('MIS_MESSAGE_ENTER_SEARCH_ID_P'));
            return;
        }

        userService.fetchLDAPUsers({
            remoteLdapOrganizationName: ldapInfo.ldapOrgName,
            organizationName: orgList.filter(item => item.value === organizationId)[0].title,
            searchName: ldapInfo.searchId
        }).then(res => {
            setLdapInfo({...ldapInfo, searchResult: res.items});
        }).catch(
            error => console.log(error)
        )
    };

    const onLdapOrgSelectionPopup = (e) => {
        userService.fetchLdapRootGroupList(organizationId)
            .then(res => {
                let remoteLdapOrgList = res.items.remoteLdapOrganizationList.map(orgName => {return {value: orgName};});
                remoteLdapOrgList.splice(0, 0, {
                    value: 'root'
                });

                dispatch(popupAction.addPopup({
                    id: commonConstants.ADD_USER_LDAP_ORGANIZATION_SELECTION,
                    type: commonConstants.ADD_USER_LDAP_ORGANIZATION_SELECTION,
                    onSave: handleLdapOrgSelection,
                    onClose: () => dispatch(popupAction.closePopup(commonConstants.ADD_USER_LDAP_ORGANIZATION_SELECTION)),
                    remoteLdapOrgList: remoteLdapOrgList
                }));

            }).catch(error => {
                let errorCode = error.errorCode !== undefined ? error.errorCode : error.body.errorCode;
                if (errorCode === '404700') {
                    toastr.error(t('MIS_SID_20_LDAP_ORGANIZATION_NOT_OBTAINED'));
                } else {
                    toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
                }
            })
    };

    const onChangeLdapSearchId =  (e) => {
        setLdapInfo({...ldapInfo, searchId: e.target.value});
    };

    const onChangeSelectionLdapId =  (e) => {
        let ldapIdStr = e.target.selectedOptions[0].text.split(',')[0];
        let ldapIdSplitStr = ldapIdStr.split(/,|=|\\/i);
        let ldapSimpleId = ldapIdSplitStr.length > 1 ? ldapIdSplitStr[1] : ldapIdSplitStr[0];

        setLdapInfo({...ldapInfo, ldapFullId: e.target.value, ldapSimpleId: ldapSimpleId});
    };

    const onClickOKLdapUser =  (e) => {
        if (ldapInfo.ldapFullId === '' || ldapInfo.ldapSimpleId === '') {
            toastr.error(t('MIS_MESSAGE_SELECT_ID_P'));
            return;
        }

        userService.checkUserIdAvailability(ldapInfo.ldapSimpleId, ldapInfo.ldapFullId).then(res => {
            if (res.items.result === 0) {
                toastr.error(t('MESSAGE_USER_ID_NOT_AVAILABLE_P'));
            } else if (res.items.result === 1 || res.items.result === 20) {
                let selectedLdapElm = ldapInfo.searchResult.filter(elm => elm.fullId === ldapInfo.ldapFullId)[0];

                setLdapInfo({...ldapInfo, isLdapUserSelected: true});
                updateUserInfoPartially({
                    userId: ldapInfo.ldapSimpleId,
                    ldapSimpleId: ldapInfo.ldapSimpleId,
                    ldapFullId: ldapInfo.ldapFullId,
                    userName: (selectedLdapElm.userName === null || selectedLdapElm.userName === undefined) ? '' : selectedLdapElm.userName,
                    mobileNum: (selectedLdapElm.mobile === null || selectedLdapElm.mobile === undefined) ? '' : selectedLdapElm.mobile,
                    phoneNum: (selectedLdapElm.phone === null || selectedLdapElm.phone === undefined) ? '' : selectedLdapElm.phone,
                    email: (selectedLdapElm.mail === null || selectedLdapElm.mail === undefined) ? '' : selectedLdapElm.mail,
                    password: '',
                    confirmPassword: ''
                });
            }

            if (res.items.result === 1) {
                toggleLdapSearching();
            }

            if (res.items.result === 20) {
                toastr.error(t('MIS_MESSAGE_ENTER_NICKNAME_P'));
                setLdapInfo({...ldapInfo, isNickNameEnabled: true});
                updateUserInfoPartially({isNickNameEnabled: true});
            }
        });
    };

    const onClickCancelLdapUser =  (e) => {
        setLdapInfo({...ldapInfo, ldapFullId: '', ldapSimpleId: ''});
        toggleLdapSearching();
    };

    useEffect(() => {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));

        let initFunctionList = [
            userService.fetchOrganizations(),
            userService.fetchOrganizationGroups(),
            userService.fetchUserAssignableRoles()
        ];

        switch (getInitStatus()) {
            case INIT_STATUS_EMPTY:
                break;
            case INIT_STATUS_ORGAN_ONLY:
                initFunctionList.push(userService.fetchUserSubGroupById(organizationId));
                break;
            case INIT_STATUS_ORGRAN_GROUP:
                initFunctionList.push(userService.fetchUserGroupsById(groupId));
                break;
            default:
                break;
        }

        Promise.all(initFunctionList).then(values => {
            const orgList = values[0].items.map(org => {
                return {
                    value: org.organizationId,
                    title: org.groupName,
                    ldapStatus: org.ldapStatus
                };
            });

            const orgGroupList = values[1].items.map(org => {
                return {value: org.organizationGroupName, title: org.organizationGroupName};
            });
            orgGroupList.splice(0, 0, {title: t('TEXT_ALL_P'), value: t('TEXT_ALL_P')});

            const roleList = values[2].items.map(org => {
                return {value: org.roleId, title: org.roleName, organizationId: org.organizationId};
            });

            if (roleList.filter(role => role.value === roleId).length === 0) {
                updateUserInfoPartially({roleId: userConstants.ROLE_ID_CONTENT_MANAGER});
            }

            if (getInitStatus() === INIT_STATUS_ORGAN_ONLY) {
                let groupId;
                let groupName;

                if (organizationId === 0) {
                    groupId = userConstants.ORGAN_ID_ADMINISTRATOR;
                } else {
                    let defaultGroupItem =  values[3].items.filter(item => item.groupName === 'default');
                    if (defaultGroupItem.length === 0) {
                        groupId = values[3].items[0].groupId;
                        groupName = values[3].items[0].groupName;
                    } else {
                        groupId = defaultGroupItem[0].groupId;
                        groupName = defaultGroupItem[0].groupName;
                    }
                }

                setFilter({
                    ...filter,
                    groupId: groupId,
                    groupName: organizationId === 0 ? t('TEXT_ROOT_GROUP_NAME_P') : groupName,
                    isGroupSelectionDisabled: organizationId === userConstants.ORGAN_ID_ADMINISTRATOR,
                    isOrgGroupSelectionDisabled: organizationId !== userConstants.ORGAN_ID_ADMINISTRATOR
                });

                updateUserInfoPartially({
                    organizationName: orgList.filter(item => item.value === organizationId)[0].title,
                    groupId: groupId,
                    groupName: organizationId === 0 ? t('TEXT_ROOT_GROUP_NAME_P') : groupName
                });
            }

            if (getInitStatus() === INIT_STATUS_ORGRAN_GROUP) {
                updateUserInfoPartially({
                    organizationName: orgList.filter(item => item.value === organizationId)[0].title,
                    groupName: values[3].items.groupName
                });
                setFilter({...filter, groupName: values[3].items.groupName});
            }

            if (getInitStatus() === INIT_STATUS_ORGAN_ONLY || getInitStatus() === INIT_STATUS_ORGRAN_GROUP) {
                updateRoleListServerAdmin(roleList, organizationId);
            }

            setLdapInfo({...ldapInfo, isLdapEnabled: organizationId === -1 ? false : orgList.filter(item => item.value === organizationId)[0].ldapStatus});
            setData({...data, orgList, orgGroupList, roleList});
        }).catch(
            error => console.log(error)
        ).finally(
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
        );
    }, []);

    return (
        <div className='user_pop_wrap'>
            <div className="user_detail_view user_info">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <h4>{t('TEXT_DEFAULT_INFO_P')}
                                    <span>{t('MESSAGE_COMMON_JOIN_NOTICE_P')}</span>
                                </h4>
                            </th>
                        </tr>

                        {
                            !ldapInfo.isNickNameEnabled &&
                            <tr>
                                <th>{t('COM_DID_ADMIN_USER_USERID')}<img src={BULLET_2}/></th>
                                <td>
                                    <div style={{position: "relative"}}>
                                        <TextInput
                                            width={TEXT_INPUT_WIDTH}
                                            propertyName='userId'
                                            errorWidth={500}
                                            error={errors['userId']}
                                            value={userInfo.dataField.userId}
                                            onChange={onChange}
                                            placeholder={t('COM_DID_ADMIN_USER_USERID')}
                                            maxLength={50}
                                            disabled={ldapInfo.isLdapUserSelected}
                                        />
                                    </div>
                                </td>
                            </tr>
                        }
                        {
                            ldapInfo.isNickNameEnabled &&
                            <>
                            <tr>
                                <th id="userIdth">{t('COM_TV_SID_NICKNAME_KR_WRITE')}<img src={BULLET_2}/></th>
                                <td>
                                    <div style={{position: "relative"}}>
                                        <TextInput
                                            width={TEXT_INPUT_WIDTH}
                                            propertyName='nickname'
                                            errorWidth={500}
                                            error={errors['nickname']}
                                            value={userInfo.dataField.nickname}
                                            onChange={onChange}
                                            placeholder={t('COM_TV_SID_NICKNAME_KR_WRITE')}
                                            maxLength={50}
                                        />
                                    </div>

                                    <div id="userAddPopupUserIdErrorDiv" style={{display: 'none'}}>
                                        <span className="error_txt2" id="userAddPopupUserIdErrorMsg"></span>
                                    </div>
                                </td>
                            </tr>

                            <tr id="descriptionNickname">
                                <th></th>
                                <td style={{padding: '0px'}}>
                                <span style={{fontSize: '12px', color:'#b8b8b8'}}>{t('MIS_MESSAGE_NICKNAME_USED_MAGICINFO_AUTHOR_P')}</span>
                                </td>
                            </tr>
                            </>
                        }
                        {
                            ldapInfo.isLdapEnabled &&
                            <tr id="ldapSearch" style={{display:'table-row'}}>
                                <th colSpan="2">
                                    <button id="ldapToggleBtn" className="detail_btn on mt5" onClick={toggleLdapSearching}><span/>{t('MIS_BUTTON_SEARCH_LDAP_P')}
                                    </button>
                                </th>
                            </tr>
                        }
                        {
                            filter.isLdapSearchingDisplay &&
                            <tr id="ldapRow">
                                <td colSpan="2">
                                    <div className="txt_box_3 mb10 pt15">
                                        <div style={{paddingLeft: '24px', paddingRight: '24px'}}>
                                            <table>
                                                <colgroup>
                                                    <col width="175px"/>
                                                    <col width="427px"/>
                                                </colgroup>
                                                <tbody>
                                                <tr>
                                                    <th>{t('MIS_TEXT_LDAP_ORGAN_P')}</th>
                                                    <td>
                                                        <div className="select_box float_l mr8">
                                                            <button id="ldapLocationSelectBtn" className="base un_radius select" style={{width: '370px'}} onClick={onLdapOrgSelectionPopup}>
                                                                <span>{ldapInfo.ldapOrgName === '' ? t('TEXT_SELECT_P') : ldapInfo.ldapOrgName}</span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t('MIS_TEXT_SEARCH_USER_ID_P')}</th>
                                                    <td><input type="text" id="searchIDInput"
                                                               placeholder={t('MIS_TEXT_SEARCH_USER_ID_P')} value={ldapInfo.searchId} style={{width: '348px'}}
                                                               className="mr8" disabled={ldapInfo.ldapOrgName === ''} onChange={onChangeLdapSearchId}/>
                                                        <button id="searchLDAPUserBtn" className="base"
                                                                style={{width: '102px'}} onClick={onClickLdapSearchUsers}>{t('BUTTON_SEARCH')}</button>
                                                    </td>
                                                </tr>
                                                <tr className="va_t">
                                                    <th>{t('COM_TV_SID_MIX_SEARCH_RESULT')}</th>
                                                    <td>
                                                        <select id="searchResult" name="searchResult"
                                                                style={{width: '480px', height: '76px'}} size="10"
                                                                selects={ldapInfo.searchResult}
                                                                disabled={ldapInfo.searchResult.length === 0} onChange={onChangeSelectionLdapId}>
                                                        {
                                                            ldapInfo.searchResult.map(result => <option name={result.id} value={result.fullId}>{result.id}</option>)
                                                        }
                                                        </select>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{borderTop: '1px solid #e7e7e7', paddingBottom: '12px'}}
                                             className="clearfix mt10">
                                            <div className="float_r mt18 mr24">
                                                <button id="saveLDAPBtn" className="base mr8"
                                                        style={{width: '102px'}} onClick={onClickOKLdapUser}>OK
                                                </button>
                                                <button id="cancelLDAPBtn" className="base"
                                                        style={{width: '102px'}} onClick={onClickCancelLdapUser}>Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }
                        {
                            (!ldapInfo.isLdapUserSelected && !ldapInfo.isNickNameEnabled) &&
                            <tr>
                                <th>{t('TEXT_PASSWORD_P')}<img src={BULLET_2}/></th>
                                <td>
                                    <TextInput
                                        width={TEXT_INPUT_WIDTH}
                                        type={"password"}
                                        propertyName='password'
                                        value={userInfo.dataField.password}
                                        errorWidth={500}
                                        error={errors['password']}
                                        onChange={onChange}
                                        placeholder={t('TEXT_PASSWORD_P')}
                                        maxLength={50}/>
                                </td>
                            </tr>
                        }
                        {
                            (!ldapInfo.isLdapUserSelected && !ldapInfo.isNickNameEnabled) &&
                            <tr>
                                <th>{t('COM_TEXT_RE_PASSWORD_P')}<img src={BULLET_2}/></th>
                                <td>
                                    <TextInput
                                        width={TEXT_INPUT_WIDTH}
                                        type={'password'}
                                        propertyName='confirmPassword'
                                        value={userInfo.dataField.confirmPassword}
                                        errorWidth={500}
                                        error={errors['confirmPassword']}
                                        onChange={onChange}
                                        placeholder={t('COM_TEXT_RE_PASSWORD_P')}
                                        maxLength={50}/>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t('COM_TEXT_USER_NAME_P')}<img src={BULLET_2}/></th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='userName'
                                    value={userInfo.dataField.userName}
                                    errorWidth={500}
                                    error={errors['userName']}
                                    onChange={onChange}
                                    placeholder={t('COM_TEXT_USER_NAME_P')}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_EMAIL_P')}<img src={BULLET_2} /></th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='email'
                                    value={userInfo.dataField.email}
                                    errorWidth={500}
                                    error={errors['email']}
                                    onChange={onChange}
                                    placeholder={'magicinfo@samsung.com'}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_MOBILE_NUM_P')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='mobileNum'
                                    value={userInfo.dataField.mobileNum}
                                    onChange={onChange}
                                    placeholder={t('TEXT_MOBILE_NUM_P')}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_PHONE_NUM_P')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='phoneNum'
                                    value={userInfo.dataField.phoneNum}
                                    onChange={onChange}
                                    placeholder={t('TEXT_PHONE_NUM_P')}
                                    maxLength={50} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="user_detail_view mt15 pt24 mb15 user_info t_line">
                <table>
                    <colgroup>
                        <col width="203px" />
                        <col width="" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th colSpan={2}>
                                <h4>{t('TEXT_ORGANIZATION_INFO_P')}</h4>
                            </th>
                        </tr>
                        <tr>
                            <th>{t('TABLE_ORGANIZATION_P')}<img src={BULLET_2} /></th>
                            <td>
                              <span>
                                  <Select propertyName='organizationName' width={TEXT_INPUT_WIDTH} selects={orgList} value={organizationId} error={errors['organizationName']} defaultTitle={t('TEXT_SEL_ORGANIZATION_P')}
                                          onChange={onSelectOrganChange} useEllipsis={true}/>
                              </span>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('TEXT_GROUP_P')}<img src={BULLET_2} /></th>
                            <td>
                                <button propertyName='groupId' className={classNames('base', 'un_radius', 'button',{ "error" : errors['groupName'] !== undefined})}
                                        style={{overflow: "hidden"}}
                                        value={groupId}
                                        onClick={onGroupSelectionPopup} disabled={filter.isGroupSelectionDisabled}>{groupName === "" ? t('MESSAGE_USER_SEL_GROUP_P') : groupName}</button>
                                {
                                    errors['groupName'] !== undefined &&
                                    <div id="userAddPopupUserIdErrorDiv">
                                            <span className="error_txt2"
                                                  id="userAddPopupUserIdErrorMsg">{t('MESSAGE_USER_SEL_GROUP_P')}</span>
                                    </div>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_TEXT_ROLE_P')}<img src={BULLET_2} /></th>
                            <td>
                                <span>
                                    <Select propertyName='roleName' width={TEXT_INPUT_WIDTH} selects={roleList} value={roleId} error={errors['roleName']} onChange={onSelectRoleChange} useEllipsis={true} direction={'up'}/>
                                </span>
                            </td>
                        </tr>
                        {
                            isServerAdmin &&
                            <tr>
                                <th>{t('MIS_SID_ORGANIZATION_GROUP')}</th>
                                <td>
                                    <div className={classNames('float_l', 'mr8')}>
                                    <span>
                                        <Select propertyName='organizationGroupName' width={TEXT_INPUT_WIDTH}
                                                selects={orgGroupList} value={organizationGroupName}
                                                defaultTitle={organizationId === userConstants.ORGAN_ID_ADMINISTRATOR ? t('TEXT_ALL_P') : t('MIS_SID_SELECT_ORGANIZATION_GROUP')} onChange={onSelectOrganGroupChange}
                                                disabled={filter.isOrgGroupSelectionDisabled} useEllipsis={true}/>
                                    </span>
                                    </div>
                                    <button type='button' id='mngGroupTutorialBtn' className={classNames('float_l', 'ml8')}
                                            onClick={() => show()}/>
                                    {
                                        displayNotification &&
                                        <div className="mngGrouptutorial_wrap" style={{display: 'block'}}>
                                            <div>{getMixString(['MIS_SID_MIX_SELELCT_OPTIONS_MANAGE_MENU', 'MIS_SID_ORGANIZATION_GROUP'])}</div>
                                        </div>
                                    }
                                </td>
                            </tr>
                        }
                        {
                            filter.isErrorTextForAdminDisplay && !displayNotification &&
                            <tr>
                                <th></th>
                                    <td className='notification'>
                                    <span className='error_txt2' >{t('MIS_SID_NOT_SELELCT_ORGANIZATION_MSG')}</span>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t('DID_ADMIN_USER_TEAM')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='team'
                                    value={userInfo.dataField.team}
                                    onChange={onChange}
                                    placeholder={t('DID_ADMIN_USER_TEAM')}
                                    maxLength={50} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('DID_ADMIN_USER_POSITION')}</th>
                            <td>
                                <TextInput
                                    width={TEXT_INPUT_WIDTH}
                                    propertyName='jobPosition'
                                    value={userInfo.dataField.jobPosition}
                                    onChange={onChange}
                                    placeholder={t('DID_ADMIN_USER_POSITION')}
                                    maxLength={50} />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="user_detail_view t_line mt20"/>
            </div>
        </div>
    );
};

AddUserDetailedInformation.defaultProps = {
    userInfo: {
        data: {},
        errors: {},
    },
    onChange: () => {},
};

export default AddUserDetailedInformation;