import React from 'react';
import {commonConstants} from "../constants";

const initialState = {
    popups: [],
    maxZIndex : 1001,
    activePopupId : ''
}

export const common = (state = initialState, action) => {
    const {popups,maxZIndex} = state;

    switch (action.type) {
        case commonConstants.COMMON_ADD_POPUP:
            if(popups.findIndex(popup => popup.id === action.popup.id) >= 0) {
                return state;
            }
            action.popup.zIndex =  maxZIndex;
            return {
                ...state,
                popups: popups.concat(action.popup),
                maxZIndex: maxZIndex+1,
                activePopupId: action.popup.id
            }
        case commonConstants.COMMON_CLOSE_POPUP:
            return {
                ...state,
                popups: popups.filter(popup => popup.id !== action.popupId)
            }
        case commonConstants.COMMON_UPDATE_POPUP:
            return {
                ...state,
                popups: popups.map(
                    popup => popup.id === action.popup.id ? action.popup : popup
                )
            }
        case commonConstants.COMMON_POPUP_TYPE_MODELESS:
            if(popups.findIndex(popup => popup.id === action.popup.id) >= 0) {
                return state;
            }
            action.popup.zIndex =  maxZIndex;
            return {
                ...state,
                popups: popups.concat(action.popup),
                maxZIndex: maxZIndex+1,
                activePopupId: action.popup.id
            }
        case commonConstants.COMMON_POPUP_ACTIVE_POPUP:
            return {
                ...state,
                maxZIndex: action.maxZIndex,
                activePopupId : action.activePopupId
            }
        default :
            return state;

    }
}