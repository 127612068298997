import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import TextInput from '../../../input/TextInput';
import {deviceOptions} from "../../../misopt";
import Checkbox from "../../../checkbox/Checkbox";
import RadioGroup from "../../../radio/RadioGroup";
import Select from "../../../selectbox/Select";
import WhiteButton from "../../../button/WhiteButton";
import {get, isNil} from "lodash";

const EditFlipBasic = ({generalInfo, onChangeGeneralInfo,}) => {
    const {t} = useTranslation();
    const {data: {device_name, mac_address, ip_address, device_model_name, application_version}, changed, errors, responseWarning} = generalInfo || {};
    return (
        <div className='device_edit_panel_wrap'>
            <div className='devicepop mb26'>
                <h3>{t('COM_SID_BASIC_INFORMATION')}</h3>
                <div className='detail_view mini_p mt14 mb15'>
                    <table>
                        <colgroup>
                            <col width='203px'/>
                            <col width=''/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className={classNames({'changed': changed['device_name'] === true,
                                'response_warning': !isNil(get(responseWarning, 'device_name'))})}>{t('TABLE_DEVICE_NAME_P')}</th>
                            <td colSpan='2'>
                                <TextInput width={200} propertyName='device_name' errorWidth={530}
                                           value={device_name || ''} error={errors['device_name']}
                                           onChange={onChangeGeneralInfo} maxLength={50}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('DID_ADMIN_LICENSE_MAC')}</th>
                            <td colSpan='2'>{mac_address}</td>
                        </tr>
                        <tr>
                            <th>{t('TABLE_IP_ADDR_P')}</th>
                            <td colSpan='2'>{ip_address}</td>
                        </tr>
                        <tr>
                            <th>{t('TABLE_DEVICE_MODEL_NAME_P')}</th>
                            <td colSpan='2'>{device_model_name}</td>
                        </tr>
                        <tr>
                            <th>{t('COM_DID_LFD_FIRMWARE_VER')}</th>
                            <td colSpan='2'>{application_version}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const EditFlipGeneral = ({deviceTypeVersion, displayInfo, onChangeDisplayInfo}) => {
    const {t} = useTranslation();
    const {data: {basic_power, cleanup_user_data, cleanup_user_data_interval, auto_save, auto_power_off}, changed, error, responseWarning} = displayInfo || {};

    const onChangeChecked = e => {
        if (e.target.checked) {
            onChangeDisplayInfo(e, 0);
        } else {
            onChangeDisplayInfo(e, 1);
        }
    };

    const autoPowerOffList = [
        {value: 0, 'title': 'BUTTON_OFF'},
        {value: 1, 'title': '4 ' + t('COM_SID_HOURS')},
        {value: 2, 'title': '6 ' + t('COM_SID_HOURS')},
        {value: 3, 'title': '8 ' + t('COM_SID_HOURS')},
        {value: 4, 'title': '16 ' + t('COM_SID_HOURS')}
    ];

    return (
        <div className='device_edit_panel_wrap'>
            <div className='devicepop mb26'>
                <h3>{t('COM_TEXT_GENERAL_P')}</h3>
                <div className='detail_view mini_p mt14 mb15'>
                    <table>
                        <colgroup>
                            <col width='203px'/>
                            <col width=''/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className={classNames({'changed': changed['basic_power'],
                                'response_warning': !isNil(get(responseWarning, 'basic_power'))})}>{t('TABLE_POWER_P')}</th>
                            <td colSpan='2'>
                                <Checkbox id='basic_power' propertyName='basic_power' value={basic_power === 0}
                                          onChange={onChangeChecked} name={t('BUTTON_OFF')}/>
                            </td>
                        </tr>
                        <tr>
                            <th className={classNames({'changed': changed['cleanup_user_data'],
                                'response_warning': !isNil(get(responseWarning, 'cleanup_user_data'))})}>{t('COM_BUTTON_DELETE_ALL_P')}</th>
                            <td colSpan='2'>
                                <Checkbox id='cleanup_user_data' propertyName='cleanup_user_data'
                                          value={cleanup_user_data === 1} onChange={onChangeChecked}
                                          name={t('COM_BUTTON_DELETE')}/>
                            </td>
                        </tr>
                        {
                            deviceTypeVersion === 2 &&
                            <tr>
                                <th className={classNames({'changed': changed['cleanup_user_data_interval'],
                                    'response_warning': !isNil(get(responseWarning, 'cleanup_user_data_interval'))})}>{t('COM_LFD_SID_FLIP_PREIOD_FOR_DELETE_ALL')}</th>
                                <td colSpan='2'>
                                    <Select width={200} propertyName='cleanup_user_data_interval'
                                            selects={deviceOptions.flipDeletePeriod} value={cleanup_user_data_interval}
                                            onChange={onChangeDisplayInfo}/>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th className={classNames({'changed': changed['auto_save'],
                                'response_warning': !isNil(get(responseWarning, 'auto_save'))})}>{t('COM_LFD_SID_PENSEE_AUTO_SAVE')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='auto_save' selects={deviceOptions.osdOptions}
                                            value={auto_save} onChange={onChangeDisplayInfo}/>
                            </td>
                        </tr>
                        <tr>
                            <th className={classNames({'changed': changed['auto_power_off'],
                                'response_warning': !isNil(get(responseWarning, 'auto_power_off'))})}>{t('COM_TV_SID_AUTO_POWER_OFF_MENU')}</th>
                            <td colSpan='2'>
                                <Select width={200} propertyName='auto_power_off' selects={autoPowerOffList}
                                        value={auto_power_off} onChange={onChangeDisplayInfo}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const EditFlipSecurity = ({securityInfo, onChangeSecurityInfo}) => {
    const {t} = useTranslation();
    const {data: {misc_block_network_connection, misc_block_usb_port, capture_lock}, changed, errors, responseWarning} = securityInfo || {};
    const blockUsbList = [
        {value: 0, 'title': 'BUTTON_OFF'},
        {value: 1, 'title': 'TEXT_ON_P'},
        {value: 2, 'title': 'COM_EBD_SID_MEETINGROOM_READ_ONLY'}
    ];

    const onChangedRadio = (e, value) => {
        onChangeSecurityInfo(e, value);
    };

    return (
        <div className='device_edit_panel_wrap'>
            <div className='devicepop mb26'>
                <h3>{t('COM_SID_SECURITY')}</h3>
                <div className='detail_view mini_p mt14 mb15'>
                    <table>
                        <colgroup>
                            <col width='203px'/>
                            <col width=''/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className={classNames({'changed': changed['misc_block_network_connection'],
                                'response_warning': !isNil(get(responseWarning, 'misc_block_network_connection'))})}>{t('MIS_SID_NW_LOCK')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='misc_block_network_connection'
                                            selects={deviceOptions.osdOptions}
                                            value={misc_block_network_connection} onChange={onChangedRadio}/>
                            </td>
                        </tr>
                        <tr>
                            <th className={classNames({'changed': changed['misc_block_usb_port'],
                                'response_warning': !isNil(get(responseWarning, 'misc_block_usb_port'))})}>{t('MIS_SID_USB_LOCK')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='misc_block_usb_port' selects={blockUsbList}
                                            value={misc_block_usb_port} onChange={onChangedRadio}/>
                            </td>
                        </tr>
                        <tr>
                            <th className={classNames({'changed': changed['capture_lock'],
                                'response_warning': !isNil(get(responseWarning, 'capture_lock'))})}>{t('LFD_SID_FLIP_PIP_CAPTURE')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='capture_lock' selects={deviceOptions.osdOptions}
                                            value={capture_lock} onChange={onChangedRadio}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const EditFlipServerNetworkSetting = ({setupInfo, onChangeSetupInfo}) => {
    const {t} = useTranslation();
    const [proxy, setProxyInfo] = useState({use: '0', ip: '', port: '', id: '', password: ''});
    const {data: {proxy_setting}, changed, errors, responseWarning} = setupInfo || {};
    const {use = '0', ip = "", port = "", id = "", password = ""} = proxy;

    useEffect(() => {
        if (setupInfo && proxy_setting) {
            const proxyInfos = proxy_setting.split(';');
            setProxyInfo({
                use: proxyInfos[0],
                ip: proxyInfos[1],
                port: proxyInfos[2],
                id: proxyInfos[3],
                password: proxyInfos[4],
            });
        }
    }, [setupInfo]);

    const onChangedUseProxy = ((e, value) => {
        const proxyInfo = value + ';;;;';
        onChangeSetupInfo(e, proxyInfo);
    });

    const onChangedIPAddress = ((e, value) => {
        const proxyInfo = use + ';' + e.target.value + ';' + port + ';' + id + ';' + password;
        onChangeSetupInfo(e, proxyInfo);
    });

    const onChangedPort = ((e, value) => {
        const proxyInfo = use + ';' + ip + ';' + e.target.value + ';' + id + ';' + password;
        onChangeSetupInfo(e, proxyInfo);
    });

    const onChangedUserID = ((e, value) => {
        const proxyInfo = use + ';' + ip + ';' + port + ';' + e.target.value + ';' + password;
        onChangeSetupInfo(e, proxyInfo);
    });

    const onChangedPassword = ((e, value) => {
        const proxyInfo = use + ';' + ip + ';' + port + ';' + id + ';' + e.target.value;
        onChangeSetupInfo(e, proxyInfo);
    });

    return (
        <div className='device_edit_panel_wrap'>
            <div className='devicepop mb26'>
                <h3>{t('COM_TV_SID_SERVER_NETWORK_SETTING')}</h3>
                <div className='detail_view mini_p mt14 mb15'>
                    <table>
                        <colgroup>
                            <col width='203px'/>
                            <col width=''/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className={classNames({'changed': changed['proxy_setting'],
                                'response_warning': !isNil(get(responseWarning, 'proxy_setting'))})}>{t('COM_TEXT_PROXY_SETTING_P')}</th>
                            <td colSpan='2'>
                                <RadioGroup propertyName='proxy_setting' selects={deviceOptions.proxyUsage}
                                            value={use} onChange={onChangedUseProxy}/>
                            </td>
                        </tr>
                        {proxy.use === '2' &&
                        <tr style={{display: 'table-row'}}>
                            <th></th>
                            <td colSpan='2'>
                                <table>
                                    <colgroup>
                                        <col width='170px'/>
                                        <col width=''/>
                                    </colgroup>
                                    <tbody>
                                    <tr>
                                        <td>{t('COM_MIS_TEXT_ADDRESS_P')}</td>
                                        <td>
                                            <form autoComplete='off'><TextInput width={240} propertyName='proxy_setting'
                                                                                value={ip || ''}
                                                                                error={errors['proxy_setting']}
                                                                                onChange={onChangedIPAddress}/></form>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('TABLE_PORT_P')}</td>
                                        <td>
                                            <form autoComplete='off'><TextInput width={240} propertyName='proxy_setting'
                                                                                value={port || ''}
                                                                                error={errors['proxy_setting']}
                                                                                onChange={onChangedPort}/></form>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('COM_DID_ADMIN_USER_USERID')}</td>
                                        <td>
                                            <form autoComplete='off'><TextInput width={240} propertyName='proxy_setting'
                                                                                value={id || ''}
                                                                                error={errors['proxy_setting']}
                                                                                onChange={onChangedUserID}/></form>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{t('TEXT_PASSWORD_P')}</td>
                                        <td>
                                            <form autoComplete='off'><TextInput width={240} propertyName='proxy_setting'
                                                                                value={password || ''}
                                                                                error={errors['proxy_setting']}
                                                                                onChange={onChangedPassword}/></form>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const EditFlipPinCode = ({setupInfo, onChangeSetupInfo}) => {
    const {t} = useTranslation();
    const {data: {pin_code}, changed, errors, responseWarning} = setupInfo || {};
    const [isShowPinCode, setShowPinCode] = useState(false);

    const onMouseDown = () => {
        setShowPinCode(true);
    };

    const onMouseUp = () => {
        setShowPinCode(false);
    };

    const onChangedPinCode = ((e, value) => {
        if (/^[0-9]+$/.test(e.target.value) || e.target.value === '') {
            onChangeSetupInfo(e, e.target.value);
        }
    });

    return (
        <div className='device_edit_panel_wrap'>
            <div className='devicepop mb26'>
                <h3>{t('MIS_SID_SERVER_PIN_CODE')}</h3>
                <div className='detail_view mini_p mt14 mb15'>
                    <table>
                        <colgroup>
                            <col width='203px'/>
                            <col width=''/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th className={classNames({'changed': changed['pin_code'],
                                'response_warning': !isNil(get(responseWarning, 'pin_code'))})}>{t('MIS_SID_SERVER_PIN_CODE')}</th>
                            <td colSpan='2' style={{display: 'flex'}}>
                                <Fragment>
                                    {isShowPinCode &&
                                    <form autoComplete='off'><TextInput width={120} value={pin_code}/></form>
                                    }
                                    {!isShowPinCode &&
                                    <form autoComplete='off'><TextInput type='password' width={120}
                                                                        propertyName='pin_code' value={pin_code || ''}
                                                                        error={errors['pin_code']}
                                                                        onChange={onChangedPinCode} maxLength={6}/>
                                    </form>
                                    }
                                    <div onMouseLeave={onMouseUp} style={{marginLeft: '4px'}}>
                                        <WhiteButton id={'VIEW'} name={t('BUTTON_VIEW_P')} onMouseDown={onMouseDown}
                                                     onMouseUp={onMouseUp}/>
                                    </div>
                                </Fragment>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const EditFlipInformation = (props) => {
    return (
        <div className='edit_flip_device_wrap mt28'>
            <EditFlipBasic {...props} />
            <EditFlipGeneral {...props} />
            {
                props.deviceTypeVersion === 2 && <EditFlipSecurity {...props} />
            }
            <EditFlipServerNetworkSetting {...props} />
            {
                props.deviceTypeVersion === 2 && <EditFlipPinCode {...props} />
            }
        </div>
    );
};

export default EditFlipInformation;