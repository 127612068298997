import React from 'react'
import {useTranslation} from "react-i18next";
import './AdvertisementListView.css'

const AdvertisementListView = ({view, mode, slots, openADPlaylistPopup}) => {


    const {t} = useTranslation();

    const openPopup = (e, slotId, scheduleId) => {
        e.stopPropagation();

        const slot =slots.find(slot => slot.slotId === slotId);
        const event = slot.events && slot.events.length > 0 && slot.events.find(e => e.scheduleId === scheduleId);
        openADPlaylistPopup(event);
    }

    return (
        <div className={'advertisement_list_view_wrap'}>

            <table>
                <colgroup>
                    <col width="15%"/>
                    <col width="40%"/>
                    <col width="20%"/>
                    <col width=""/>
                </colgroup>
                <thead>
                <tr>
                    <th style={{minWidth:80}}>{t("MIS_SID_20_SLOTS")}</th>
                    <th style={{minWidth:270}}>{t("LIST_TITLE_NAME")}</th>
                    <th style={{minWidth:125}}>{t("COM_SID_START_TIME")}</th>
                    <th>{t("COM_SID_END_TIME")}</th>
                </tr>
                </thead>
            </table>

            <div className="list">
                <table>
                    <colgroup>
                        <col width="15%"/>
                        <col width=""/>
                    </colgroup>
                    <tbody id="scheduleCalendarList" style={{borderBottom: '1px solid #f2f2f2'}}>
                    {
                        slots !== undefined && slots.length > 0 &&
                            slots.map(
                                slot =>
                                    <tr>
                                        <td style={{minWidth:80}}>{t("MIS_SID_20_SLOTS")} {slot.slotIndex}</td>
                                        <td colSpan="3" className="adschList">
                                            <table>
                                                <colgroup>
                                                    <col width="47%"/>
                                                    <col width="24%"/>
                                                    <col width=""/>
                                                </colgroup>
                                                <tbody className="adSchSlotTable">
                                                {
                                                    slot.events !== undefined && slot.events.length > 0 &&
                                                        slot.events.map(
                                                            event =>
                                                                <tr onClick={(e)=>openPopup(e, slot.slotId, event.scheduleId)}>
                                                                    <td style={{border:0,minWidth:'250px'}}>{event.contentName}</td>
                                                                    <td style={{border: 0}}>{event.startDate+' '+event.startTime}</td>
                                                                    <td style={{border: 0}}>{event.stopDate+' '+event.stopTime}</td>
                                                                </tr>
                                                        )
                                                }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>

                            )
                    }

                    </tbody>
                </table>
            </div>



        </div>
    )
}
export default AdvertisementListView;