import React from 'react';
import {withTranslation} from "react-i18next";
import ReactDOM from "react-dom";
import Checkbox from "../checkbox/Checkbox";
import './MultiSelectButton.css';
import _isEqual from "lodash/isEqual";

class PlaylistMultiSelectButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: this.props.data,
            selectAll: true,
            height: '',
        };
    }

    checkSelectAll = () => {
        const _data = this.props.data;
        const check = _data.findIndex(el => el.isChecked === false);

        if (check === -1) {
            this.setState({selectAll: true});
        } else {
            this.setState({selectAll: false});
        }
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        this.checkSelectAll();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {data} = nextProps;
        if (data && (data.length !== prevState.data.length)) {
            return {data: nextProps.data};
        }
        return null;
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }


    handleClickOutside = event => {

        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                show: false
            });
        }
    };

    toggleView = () => {
        const _show = !this.state.show;
        this.setState({
            show: _show
        })
    };


    toggleSelectAll = (e) => {
        const {selectAll} = this.state;
        let _data = this.state.data;

        if (selectAll) {
            _data.forEach(el => el.isChecked = false);
        } else {
            _data.forEach(el => el.isChecked = true);
        }
        this.setState({selectAll: !selectAll, data: _data}, this.onChange);
    };


    getData = (id) => {
        let _data = this.state.data;
        _data.forEach(el => {
            if (el.id == id) {  // diff  type ==> Number , String
                el.isChecked = !el.isChecked;
            }
        });
        return _data;
    }

    setData(_data) {
        const check = _data.findIndex(el => el.isChecked === false);
        if (check === -1) {
            this.setState({data: _data, selectAll: true}, this.onChange);
        } else {
            this.setState({data: _data, selectAll: false}, this.onChange);
        }
    }

    onSelect = (e) => {
        let _data = this.getData(e.target.id);
        this.setData(_data);
    };

    onChange = () => {
        const {data} = this.state;
        let lds = [];
        data.forEach(el => {
            if (el.isChecked) {
                lds.push(el.id);
            }
        });
        this.props.onChange(lds);
    };

    visibleSelectAll = () => {
        const {t} = this.props;
        return (
            <li key={'multiSelectKey'}>
                <Checkbox id={'multiSelect_all'}
                          name={t("COM_SID_ALL")}
                          checked={this.state.selectAll}
                          onChange={this.toggleSelectAll}
                />
            </li>
        );
    };

    render() {
        const {t} = this.props;
        const {title, style, width, height} = this.props;
        const {data} = this.state;

        const multiSelect = data.map((el, i) => {
            return (
                <li key={i} data-id={el.id}>
                    <Checkbox index={i}
                              id={el.id}
                              name={el.name}
                              onChange={this.onSelect}
                              checked={el.isChecked}
                              classname={'playlist_filter_label'}
                    />
                </li>
            )
        });

        return (
            <div className={"multiSelect float_l mr8"} style={{marginRight: 10, width: width? width : 'auto',}}>
                <button className="base select multiSelect_button" id="multiSelectButton" style={style} onClick={this.toggleView}>
                    <span>{title}<span className="arrow"></span></span>
                </button>
                <div className="option_list" id="multiSelectWrap" style={{'display': this.state.show ? '' : 'none'}}>
                    <div>
                        <span></span>
                    </div>
                    <ul style={{ height: height ? height : 'auto', overflow: 'hidden auto', padding: '10px 0px',}}>
                        {
                            this.props.itemAll && this.visibleSelectAll()
                        }
                        {
                            multiSelect
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

PlaylistMultiSelectButton.defaultProps = {
    itemAll: true
}

export default withTranslation()(PlaylistMultiSelectButton);