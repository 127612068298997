import React, {useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../../components/button/WhiteButton";
import "../../../components/table/react-table.css";
import {scheduleService} from '../../../services';
import SearchBar from "../../../components/search/SearchBar";
import Checkbox from "../../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../../components/icon/SupportedDeviceIcon";
import {useTranslation} from 'react-i18next';
import DateToStr from "../../../components/utils/DateToStr";
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from "../../../helper";
import {commonConstants, SCHEDULE_PAGE_SIZE_OPTIONS} from "../../../constants";
import {menuAction, popupAction} from "../../../actions";
import {useDispatch} from "react-redux";
import {toastr} from 'helper/toastrIntercept';
import {snakeCase} from "lodash";
import {getErrorMessage} from "../../../helper/responseHandler";
import MagicInfoTable from "../../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../../helper/tables";
import {updateCache} from "../../../helper/cache/tableCache";

const pageSizeOptions = [5, 30, 50, 100, 200, 500];

const ContentScheduleRecycleBin = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...props.cache.filter
    });

    const [data, setData] = useState({
        loading: false,
        items: props.cache.items !== undefined ? props.cache.items : [],
        totalCount: props.cache.totalCount !== undefined ? props.cache.totalCount : 0,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}], searchText} = filter;
        setData({...data, loading: true});
        scheduleService.fetchContentScheduleFilter({
            startIndex: (page * pageSize) + 1,
            pageSize,
            groupType: 'TRASH',
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC',
            searchText: searchText
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
            updateCache('SCHEDULE', {items: res.items, filter: filter, totalCount: res.totalCount})
        });
    }

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);


    const showGroupPopup = () => {
        const programIds = getSelected();
        if (programIds.length > 0) {
            dispatch(popupAction.addPopup({
                type: commonConstants.COMMON_GROUP_POPUP,
                id: programIds[0],
                mode: 'content_schedule',
                save: (groups)=>restoreContentSchedule(groups),
                close: ()=>dispatch(popupAction.closePopup(programIds[0]))
            }));
        }

    }

    const restoreContentSchedule = (groups) => {
        const programIds = getSelected();
        if (programIds.length > 0 && groups.length > 0) {
            const groupIds = [];
            groups.map(
                group => {
                    groupIds.push(group.groupId);
                }
            )
            scheduleService.restoreContentSchedule(groupIds, programIds)
                .then(
                    res=> {
                        if (res) toastr.success(t('TEXT_SUCCESS_P'));
                    }
                )
                .catch((e)=>{
                    switch(e.errorCode) {
                        case "403001":
                            toastr.error(t('ERROR_NO_ROLE'));
                            break;
                        default:
                            toastr.error(getErrorMessage(e));
                            break;
                    }
                })
                .finally(()=>{
                    dispatch(popupAction.closePopup(programIds[0]));
                    dispatch(menuAction.updateSubMenuCounter('SCHEDULE'));
                    setFilter({...filter, force: !filter.force})
            });
        }
    }

    const removeContentSchedule = () => {
        const programIds = getSelected();
        if (programIds.length > 0) {
            dispatch(popupAction.addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                title: t("TEXT_SCHEDULE_DELETE_P"),
                message: t("MESSAGE_SCHEDULE_DELETE_QUE_P"),
                onClickYes: handleDelete,
                onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
            }));
        }
    }

    const handleDelete = () => {
        const programIds = getSelected();
        scheduleService.deleteContentScheduleByIds(programIds, false)
            .then(
                res=> {
                    if (res) toastr.success(t('TEXT_SUCCESS_P'));
                }
            )
            .catch((e)=>{
                switch(e.errorCode) {
                    case "403001":
                        toastr.error(t('ERROR_NO_ROLE'));
                        break;
                    default:
                        toastr.error(getErrorMessage(e));
                        break;
                }
            })
            .finally(()=>{
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                setFilter({...filter, force: !filter.force});
        });
    }

    const showEmptyRecyclePopup = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("BUTTON_EMPTY_RECYCLE_BIN_P"),
            message: t("MESSAGE_CONTENT_CONFIRM_EMPTY_RECYCLE_BIN_P"),
            onClickYes: emptyRecycleBin,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    const emptyRecycleBin = () => {
        scheduleService.emptyContentSchedule()
            .then(
                res=> {
                    if (res) toastr.success(t('TEXT_SUCCESS_P'));
                }
            )
            .catch(e=>toastr.error(getErrorMessage(e)))
            .finally(()=>{
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                setFilter({...filter, force: !filter.force});
            })
    }

    const onChangeScheduleName = value => {
        setFilter({...filter, searchText: value, page: 0})
    }

    const getSelected = () => {
        return selected.current.map(s => items[s].programId);
    }

    const columns = useMemo(()=>[
        {
            accessor: "programId",
            show: false
        },
        {
            id: "checkbox",
            width : 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllContentScheduleRecycle_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'contentScheduleRecycle_'+items[row.index].programId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                );
            }
        },
        {
            Header: t("TEXT_SCHEDULE_NAME_P"),
            accessor: "programName",
            width: 500
        },
        {
            Header: t("COM_MIS_TEXT_SUPPORTED_DEVICE_TYPE_P"),
            Cell: props => <SupportedDeviceIcon deviceType={props.original.deviceType} deviceTypeVersion={props.original.deviceTypeVersion}/>,
            sortable: false,
            width: 150
        },
        {
            Header: t("TEXT_DEVICE_GROUP_P"),
            accessor: "deviceGroupName",
            sortable: false,
            width: 150
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "modifyDate",
            Cell: ({value}) => <DateToStr date={value} />

        }
    ], [items])

    useEffect(() => {
        setStyle({height: window.innerHeight - 205});
    }, []);

    useEffect(() => {
        if(!props.cache.isLoaded || filter.isFetched) {
            fetchData();
        }
    }, [filter]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{width: '100%', display: props.currContent === 'CONTENT_SCHEDULE_RECYCLE_BIN' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_SCHEDULE_RESTORE"} name={t("BUTTON_RESTORE_P")} disable={selectedCnt < 1} onClick={showGroupPopup}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={removeContentSchedule}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_EMPTY_RECYCLE"} name={t("BUTTON_EMPTY_RECYCLE_BIN_P")} onClick={showEmptyRecyclePopup}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="scheduleSearch" placeholder={t('TEXT_SCHEDULE_NAME_P')} onClickSearch={onChangeScheduleName}  />
                </div>
            </div>
            <MagicInfoTable
                data={items}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                columns={columns}
                style={style}
                usePagination={true}
                getTrGroupProps={getTrGroupPropsType2}
                paginationOptions={{
                    totalCount: totalCount,
                    page: page,
                    pageSize: pageSize,
                    pageSizeOptions: SCHEDULE_PAGE_SIZE_OPTIONS,
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    divide: props.divide
                }}
            />
        </div>
    )

}
export default ContentScheduleRecycleBin;