import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isNil} from 'lodash';
import MISDialog from '../../MISDialog';
import {toastr} from 'helper/toastrIntercept';
import moment from 'moment';
import {popupAction} from "../../../../actions";
import {commonConstants} from '../../../../constants';
import {deviceOptions, useMISOpt} from '../../../misopt'
import RadioGroup from '../../../radio/RadioGroup';
import WhiteButton from '../../../button/WhiteButton';
import DateInput from '../../../input/DateInput';
import TimeSelect from '../../../selectbox/TimeSelect';
import PublishGroup from '../../../device/PublishGroup';
import {dateToString, toMomentDateTimeFormat} from '../../../../helper';
import './PublishCustomizePopup.css';
import {deviceService} from '../../../../services';

const PublishCustomizePopup = ({initSoftware, onPublish, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    
    const now = new Date();
    const [publishInfo, setPublishInfo] = useState({
        publishType: 'NOW',
        appliedType: 'GROUP',
        rsvDate: now,
        rsvTime: moment()
    });

    const onClickGroup = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'device',
            allEnabled: true,
            checkbox: true,
            save: groups => {
                setPublishInfo({...publishInfo, groups});
                dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
            }, 
            close: () => dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP)),
        }));
    };

    const onClickPublish = () => {
        const {rsvDate, rsvTime} = publishInfo;

        if(isNil(rsvDate) || rsvDate === null) {
            toastr.error(t('MESSAGE_SCHEDULE_CHECK_DATE_VALUES_P'));
            return;
        }

        if(isNil(rsvTime) || rsvTime === null) {
            toastr.error(t('MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P'));
            return;
        }
        if(isNil(publishInfo.groups) || publishInfo.groups.length === 0) {
            toastr.error(t('MESSAGE_DEVICE_SELECT_DEVICE_GROUP_P'));
            return;
        }

        const rsvDateTime = moment(rsvDate);
        rsvDateTime.hour(rsvTime.hour());
        rsvDateTime.minute(rsvTime.minute());

        deviceService.publishSoftware({
            softwareId: initSoftware.softwareId,
            appliedType: publishInfo.appliedType,
            deployAppliedVer: publishInfo.deployAppliedVer,
            // groupId: (publishInfo.groups && publishInfo.groups.length > 0) ? publishInfo.groups[0].groupId : null,
            groupIds: publishInfo.groups.map(group => group.groupId),
            rsvDate: publishInfo.publishType === 'NOW' ? 'NOW' : rsvDateTime.format(dateTimeFormat)
        }).then(res => {
            toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
            if(onPublish) {
                onPublish();
            }
        }).catch(error => {
            if(error.errorCode === '500633'){
                toastr.error(t('MIS_SID_CAFEB_FIRMWARE_ALREADY_PUBLISHED'));
            } else if(error.errorCode === '500634'){
                toastr.error(t('MESSAGE_DEVICE_DISCONNECT_DEVICE_P'));
            } else if(error.errorCode === '500635'){
                toastr.error(t('MIS_TEXT_DOES_NOT_SUPPORT_THE_PRODUCT_P'));
            }
            onClose && onClose();
    });

    };

    const onDeleteGroup = groupId => {
        const newGroups = publishInfo.groups.filter(group => group.groupId !== groupId);
        setPublishInfo({...publishInfo, groups: newGroups});
    };

    return (
        <MISDialog 
            dialog={{
                title: initSoftware.softwareType === '03' ? t('MIS_SID_20_PUBLISH_CUSTOM_LOGO') : t('MIS_SID_20_PUBLISH_DEFAULT_CONTENT'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 740,
                height: 370,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'MIS_SID_PUBLISH',
                        title: t('MIS_SID_PUBLISH'),
                        onClick: onClickPublish,
                        // disable: !isEmpty(error)
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop'>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="180px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                {
                                    initSoftware.softwareType === 'Custom Logo' &&
                                    <th>{t('MIS_SID_PREMIUM_LOGO_NAME')}</th>
                                }
                                {
                                    initSoftware.softwareType !== 'Custom Logo' &&
                                    <th>{t('TEXT_CONTENT_NAME_P')}</th>
                                }
                                <td>
                                    {initSoftware.softwareName}
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TEXT_FILE_NAME_P')}</th>
                                <td>
                                    {initSoftware.fileName}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="180px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('MIS_ID_PUBLISH_TIME')}</th>
                                <td>
                                    <RadioGroup selects={deviceOptions.swPublishOptions} value={publishInfo.publishType} onChange={(e, value) => setPublishInfo({...publishInfo, publishType: value})} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('COM_ID_SCHEDULE_SCHEDULE_PUBLISH')}</th>
                                <td>
                                    <span className='mr8'>
                                        <DateInput width={120} minDate={now} date={dateToString(publishInfo.rsvDate)} disabled={publishInfo.publishType !== 'RESERVED'} onChange={value => setPublishInfo({...publishInfo, rsvDate: value})} />
                                    </span>
                                    <span>
                                        <TimeSelect style={{width: 120}} format='hh:mm A' disabled={publishInfo.publishType !== 'RESERVED'} value={publishInfo.rsvTime} showSecond={false} onChange={value => setPublishInfo({...publishInfo, rsvTime: value})} />
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TEXT_SELECT_APPLICABLE_DEVICE_P')}</th>
                                <td>
                                    <RadioGroup selects={deviceOptions.swApplicableDevices.filter(d => d.value === 'GROUP')} value={publishInfo.appliedType} />
                                    <WhiteButton name={t('TEXT_SELECT_P')} onClick={onClickGroup} />
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td>
                                    <div className='group_container'>
                                        {
                                            publishInfo.groups &&
                                            publishInfo.groups.map(group => <PublishGroup key={group.groupId} group={group} onDelete={() => onDeleteGroup(group.groupId)} />)
                                        }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default PublishCustomizePopup;