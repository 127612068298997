import React, {useCallback, useEffect, useRef, useState} from 'react';
import './SubmenuContentsContainer.css';
import Filter from "../../../components/filter/Filter";
import SearchBar from "../../../components/search/SearchBar";
import {Draggable} from "../../../components/fullcalendar/interaction";
import {useTranslation} from "react-i18next";
import SwitchListNImage from "../../../components/switch/SwitchListNImage";
import GroupButton from "../../../components/button/GroupButton";
import ContentThumbnail from "../../../components/image/ContentThumbnail";
import {contentService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {snakeCase} from "lodash";
import PerfectScrollbar from 'perfect-scrollbar';
import {playlistService} from "../../../services/playlist.service";
import {rulesetService} from "../../../services/ruleset.service";
import SelectBox from "../../../components/selectbox/SelectBox";
import {Loading} from "../../../components/loading/Loading";
import {
    commonConstants,
    CONTENT_SCHEDULE_CONFIG,
    contentConstants,
    inputSources,
    playlistConstants,
    scheduleConstants
} from "../../../constants";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../actions";
import CategoryButton from "../../../components/category/CategoryButton";
import {useMISOpt} from "../../../components/misopt";
import Chip from "../../../components/chip/Chip";
import {updateCache} from "../../../helper/cache/tableCache";
import {getErrorMessage} from "../../../helper/responseHandler";

const SubmenuContentsContainer = ({deviceType, deviceTypeVersion = 1, programType, type='content_schedule', cache,submenuId} ) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misOptionSchedule} = useMISOpt();
    const perfectScrollbar = useRef(undefined);

    const [style, setStyle] = useState({
        marginTop: 15,
        overflowY: 'auto',
        height: 900,
    })

    const inputSourceList = useCallback(()=>{
        const scheduleInfo = misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion);
        if (scheduleInfo !== undefined) {
            const {contentSchedule : {contents}} = scheduleInfo;
            if (contents !== undefined && contents.inputSources !== undefined) {
                const rtnInputSources = [];
                contents.inputSources.map(input =>{
                    const source = inputSources.find(i => i.value === input);
                    if (source){
                        rtnInputSources.push(source)
                    }
                });
                return rtnInputSources;
            }
        }
        return inputSources;
    }, [])
    const inputSource = inputSourceList();

    const initialMode = cache.filter.mode ? cache.filter.mode : 'CONTENT'
    const [mode, setMode] = useState(initialMode);
    const initialView = cache.filter.view ? cache.filter.view : 'IMAGE'
    const [view, setView] = useState(initialView);

    const [end, setEnd] = useState(false);

    const [isFetching, setIsFetching] = useState(false);

    const getPlaylistType = (playlistType) => {
        return t(playlistConstants.PLAYLISTTYPES.find(type=> type.id === playlistType).name);
    }

    const getSupportedPlaylistType = (programType, deviceType) => {
        let playlistTypes;
        if(deviceType === "LPLAYER" || deviceType === "APLAYER") {
            playlistTypes = ["0"];
        }
        else {
            playlistTypes = CONTENT_SCHEDULE_CONFIG[programType].supportedPlaylistFilter;
        }

        return playlistTypes;
    };

    const initialContentFilter = (cache.isLoaded && cache.filter.mode=='CONTENT')  ? cache.filter : {
        startIndex: 1,
        pageSize: 50,
        contentTypes: CONTENT_SCHEDULE_CONFIG[programType].supportedContentFilter,
        deviceType: deviceType,
        deviceTypeVersion: deviceTypeVersion,
        groupId: undefined,
        creatorIds: undefined,
        tagIds: undefined,
        fileSizes: undefined,
        searchText: undefined,
        expirationStatus: 'VALID',
        sortColumn: snakeCase('lastModifiedDate').toUpperCase(),
        sortOrder: 'DESC',
        approvalStatus: "APPROVED",
        mode : 'CONTENT'
    }

    const initialPlaylistFilter = (cache.isLoaded && cache.filter.mode=='PLAYLIST')  ? cache.filter : {
        startIndex: 1,
        page: 0,
        pageSize: 50,
        deviceType: deviceType,
        deviceTypeVersion: deviceTypeVersion,
        groupId: undefined,
        playlistTypes: getSupportedPlaylistType(programType, deviceType),
        userId: undefined,
        creatorIds: undefined,
        sortColumn: snakeCase('lastModifiedDate').toUpperCase(),
        sortOrder: 'DESC',
        mode : 'PLAYLIST'
    }

    const [content, setContent] = useState({
        force: true,
        count: 0,
        isFetching: false,
        filter: initialContentFilter,
        list: [],
        //isFetched : false
    })

    const [playlist, setPlaylist] = useState({
        force: true,
        count: 0,
        isFetching: false,
        filter: initialPlaylistFilter,
        list: []
    })

    const [ruleSet, setRuleSet] = useState({
        force: true,
        count: 0,
        isFetching: false,
        filter: {
            startIndex:	1,
            pageSize: 50,
            deviceType: deviceType,
            deviceTypeVersion: deviceTypeVersion,
            groupId: undefined,
            userId: undefined,
            searchText: undefined,
            sortColumn: snakeCase('modifyDate').toUpperCase(),
            sortOrder:	'DESC',
            includeTotalSize: true,
            mode : 'RULE_SET'
        },
        list:[]
    })

    const getThumbnailId = (thumbnailInfo, index) => {
        const images = thumbnailInfo.split("|");
        if (images.length > 1) {
            return images[index]
        } else {
            return ''
        }
    }

    useEffect(()=> {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        const ps = new PerfectScrollbar('#external-events', {
            wheelSpeed: 2,
            wheelPropagation: true,
            minScrollbarLength: 20,
            suppressScrollX: true
        });
        document.querySelector('#external-events').addEventListener('ps-y-reach-end', () => {
            setEnd(true)
        });
        bindDraggable();
        perfectScrollbar.current = ps;
    }, [])

    const fetchContents = () => {
        const {filter} = content;
        setIsFetching(true);
        contentService.fetchContentFilter(filter)
            .then(res => {
                    setContent({
                        ...content,
                        list: res.items !== undefined && res.items.length > 0 ? content.list.concat(res.items) : content.list,
                        isFetching: false,
                        count : content.count +1,
                        totalCount : res.totalCount,
                        approvalStatus: "APPROVED",
                        filter: {
                            ...content.filter,
                            startIndex: content.filter.startIndex + res.items.length
                        }
                    });
                    updateCache(submenuId, {filter: content.filter}, 'CONTENT' );
                    perfectScrollbar.current.update();
                }
            ).catch((e) => {
                if(e.errorCode === 408900) {
                    return;
                }

                toastr.error(getErrorMessage(e));
            }).finally(
                ()=> {
                    setIsFetching(false);
                }
            )
    }

    const fetchPlaylists = () => {
        const {filter} = playlist;
        setIsFetching(true);
        playlistService.fetchPlaylistForSchedule(filter)
            .then(res => {
                    setPlaylist({
                        ...playlist,
                        list: res.items !== undefined && res.items.length > 0 ? playlist.list.concat(res.items) : playlist.list,
                        isFetching: false,
                        count : playlist.count +1,
                        totalCount : res.totalCount,
                        filter: {
                            ...playlist.filter,
                            startIndex: playlist.filter.startIndex + res.items.length
                        }
                    });
                    updateCache(submenuId, {filter: playlist.filter}, 'PLAYLIST');
                    perfectScrollbar.current.update();
                }
            ).catch(res=> toastr.error(getErrorMessage(res)))
            .finally(
                ()=> {
                    setIsFetching(false);
                }
            )
    }

    const fetchRuleSets = () => {
        const {filter} = ruleSet;
        setIsFetching(true);
        rulesetService.fetchRulesetFilter(filter)
            .then(res => {
                    setRuleSet({
                        ...ruleSet,
                        list: res.items !== undefined && res.items.length > 0 ? ruleSet.list.concat(res.items) : ruleSet.list,
                        isFetching: false,
                        count : ruleSet.count +1,
                        totalCount : res.totalCount,
                        filter: {
                            ...ruleSet.filter,
                            startIndex: ruleSet.filter.startIndex + res.items.length
                        }
                    });
                    perfectScrollbar.current.update();
                }
            ).catch((e) => {
                if(e.errorCode === 408900) {
                    return;
                }

                toastr.error(getErrorMessage(e));
            }).finally(
                ()=> {
                    setIsFetching(false);
                }
            )
    }

    const bindDraggable = () => {
        const draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".thumbnail_box",
            eventData: function(eventEl) {
                const title = eventEl.getAttribute("title");
                const id = eventEl.getAttribute("id");
                const type = eventEl.getAttribute("type");
                const contentType = eventEl.getAttribute("data-content-type");
                const thumbnail = eventEl.getAttribute("data-thumbnail");
                let mediaType = "";
                if (eventEl.getAttribute("data-media-type") !== undefined) {
                    mediaType = eventEl.getAttribute("data-media-type");
                }

                return {
                    title: title,
                    contentId: id,
                    type: type,
                    mediaType: mediaType,
                    contentType: contentType,
                    thumbnail: thumbnail,
                    /*duration: {hours: 2},*/
                    duration: '01:59',
                    create: false
                };
            }
        });
    }

    const updateDimensions = () => {
        let height = window.innerHeight - 205;
        setStyle({
            ...style,
            height: height
        })
        if (perfectScrollbar !== undefined && perfectScrollbar.current !== undefined) {
            perfectScrollbar.current.update();
        }
    }

    const onGroupPopup = () => {
        let type, params, allowSelectOrganization;
        switch (mode) {
            case 'CONTENT':
                type = 'content';
                params = {groupType : contentConstants.MY_CONTENT_GROUP};
                break;
            case 'PLAYLIST':
                type = 'playlist';
                break;
            case 'RULE_SET':
                type = 'ruleset';
                allowSelectOrganization = true;
                break;
        }
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: type,
            params: params !== undefined ? params : undefined,
            title : t("MIS_SID_SELECT_FOLDER"),
            allowSelectOrganization : allowSelectOrganization,
            save: (groups)=>filterGroups(groups),
            close: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
        }))
    }

    const filterGroups = (groups) => {
        dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
        if (mode === 'CONTENT') {
            setContent({
                ...content,
                filter: {
                    ...content.filter,
                    startIndex: 1,
                    groupType: groups!== undefined && groups.length > 0 ? 'GROUPED' : undefined,
                    groupId: groups!== undefined && groups.length > 0 ? groups[0].groupId.toString() : undefined,
                    groupName: groups!== undefined && groups.length > 0 ? groups[0].groupName : undefined,
                },
                count: 0,
                list: []
            })
        } else if (mode === 'PLAYLIST') {
            setPlaylist({
                ...playlist,
                filter: {
                    ...playlist.filter,
                    startIndex: 1,
                    groupId: groups!== undefined && groups.length > 0 ? groups[0].groupId.toString() : undefined,
                    groupType: groups!== undefined && groups.length > 0 ? 'GROUPED' : undefined,
                    groupName: groups!== undefined && groups.length > 0 ? groups[0].groupName : undefined,
                },
                count: 0,
                list: []
            })
        } else if (mode === 'RULE_SET') {
            setRuleSet({
                ...ruleSet,
                filter: {
                    ...ruleSet.filter,
                    startIndex: 1,
                    groupId: groups!== undefined && groups.length > 0 ? groups[0].groupId.toString() : undefined,
                    groupName: groups!== undefined && groups.length > 0 ? groups[0].groupName : undefined,
                },
                count: 0,
                list: []
            })
        }
    }

    const changeContent = (mode) => {
        const container = document.querySelector('#external-events');
        container.scrollTop = 0;
        setMode(mode);
        if (mode === 'CONTENT' &&content.count === 0 && content.list.length === 0) {
            setContent({...content, force: !content.force});
        } else if (mode === 'PLAYLIST' && playlist.count === 0 && playlist.list.length === 0) {
            setPlaylist({...playlist, force: !playlist.force});
        } else if (mode === 'RULE_SET' && ruleSet.count === 0 && ruleSet.list.length === 0) {
            setRuleSet({...ruleSet, force: !ruleSet.force});
        }
        if (mode === 'CONTENT') {
            updateCache(submenuId, {filter: {...content.filter, startIndex : 1}}, 'CONTENT')
        }else if (mode === 'PLAYLIST'){
            updateCache(submenuId, {filter: {...playlist.filter, startIndex : 1}}, 'PLAYLIST')
        } else if (mode === 'RULE_SET'){
            updateCache(submenuId, {filter: {...ruleSet.filter, startIndex : 1}}, 'RULE_SET')
        } else if (mode === 'INPUT_SOURCE'){
            updateCache(submenuId, {filter: {mode:'INPUT_SOURCE'}}, 'INPUT_SOURCE')
        }
    }

    const onChangeListNImage = (mode) => {
        const container = document.querySelector('#external-events');
        container.scrollTop = 0;
        setView(view === 'LIST' ? 'IMAGE' : 'LIST');
        if (mode === 'CONTENT') {
            updateCache(submenuId, {filter: {...content.filter,  view: view === 'LIST' ? 'IMAGE' : 'LIST', startIndex : 1}}, 'CONTENT')
        }else if (mode === 'PLAYLIST'){
            updateCache(submenuId, {filter: {...playlist.filter, view : view === 'LIST' ? 'IMAGE' : 'LIST', startIndex : 1}}, 'PLAYLIST')
        }
    }

    const onSearch = (value) => {
        if (mode === 'CONTENT') {
            setContent({
                ...content,
                filter: {
                    ...content.filter,
                    searchText: value,
                    startIndex: 1,
                    approvalStatus: "APPROVED"
                },
                list: [],
                count: 0
            })
        } else if (mode === 'PLAYLIST') {
            setPlaylist({
                ...playlist,
                filter: {
                    ...playlist.filter,
                    searchText: value,
                    startIndex: 1
                },
                list: [],
                count: 0
            })
        } else if (mode === 'RULE_SET') {
            setRuleSet({
                ...ruleSet,
                filter: {
                    ...ruleSet.filter,
                    searchText: value,
                    startIndex: 1
                },
                list: [],
                count: 0
            })
        }
    }

    const onSaveFilter = (filter) => {
        const container = document.querySelector('#external-events');
        container.scrollTop = 0;
        if (mode === 'CONTENT') {
            const {contents, users, mediaTags, fileSizes} = filter;
            setContent({
                ...content,
                filter: {
                    ...content.filter,
                    startIndex: 1,
                    contentTypes: contents !== undefined && contents.length === 1 && contents[0] === 'ALL' ? CONTENT_SCHEDULE_CONFIG[programType].supportedContentFilter : contents,
                    creatorIds: users,
                    tagIds: mediaTags,
                    fileSizes: fileSizes,
                },
                list: [],
                count: 0
            })
        } else if (mode === 'PLAYLIST') {
            const {playlistTypes, users} = filter;
            const types =[];
            playlistTypes.map(
                type => {
                    if (type instanceof Object ){
                        types.push(type.id);
                    } else {
                        types.push(type)
                    }
                }

            )
            setPlaylist({
                ...playlist,
                filter: {
                    ...playlist.filter,
                    startIndex: 1,
                    creatorIds: users,
                    playlistTypes: types,
                },
                list: [],
                count: 0,
            })
        } else if (mode === 'RULE_SET') {

        }

    }


    useEffect(()=>{
        if (mode === 'CONTENT' && content.list !== undefined && content.count < 1) {
            fetchContents();
        }
    }, [content])

    useEffect(()=> {
        if (mode === 'PLAYLIST' && playlist.list !== undefined && playlist.count < 1) {
            fetchPlaylists();
        }
    }, [playlist])

    useEffect(()=> {
        if (mode === 'RULE_SET' && ruleSet.list !== undefined && ruleSet.count < 1) {
            fetchRuleSets();
        }
    }, [ruleSet])

    useEffect(()=> {
        if (end) {
            switch (mode) {
                case 'CONTENT':
                    if (content.totalCount >= content.filter.startIndex) {
                        setContent({
                            ...content,
                            count: 0
                        })
                    }
                    break;
                case 'PLAYLIST':
                    if (playlist.totalCount >= playlist.filter.startIndex) {
                        setPlaylist({
                            ...playlist,
                            count: 0
                        })
                    }
                    break;
                case 'RULE_SET':
                    if (ruleSet.totalCount >= ruleSet.filter.startIndex) {
                        setRuleSet({
                            ...ruleSet,
                            count: 0
                        })
                    }
                    break;
            }
            setEnd(false)
        }
    }, [end])

    let contentButton = [
        {key: 'CONTENT_SCHEDULE_CONTENT_BUTTON', title: "DID_MAIN_CONTENT", value:'CONTENT', popup: false, onClick: ()=>changeContent('CONTENT')},
        {key: 'CONTENT_SCHEDULE_PLAYLIST_BUTTON', title: "TEXT_TITLE_PLAYLIST_P", value: 'PLAYLIST', popup: false, onClick: ()=>changeContent('PLAYLIST')},
        {key: 'CONTENT_SCHEDULE_INPUT_SOURCE_BUTTON', title: "TABLE_INPUT_SOURCE_P", value: 'INPUT_SOURCE', popup: false, onClick: ()=>changeContent('INPUT_SOURCE')},
        {key: 'CONTENT_SCHEDULE_RULE_SET_BUTTON', title: "MIS_SID_RULESET", popup: false, value: 'RULE_SET', onClick: ()=>changeContent('RULE_SET')}
    ];



    useEffect(()=> ()=>{
        window.removeEventListener('resize', updateDimensions, true);
    }, [])

    useEffect(()=> ()=>{
        if (perfectScrollbar !== undefined && perfectScrollbar.current !== undefined) {
            perfectScrollbar.current.update();
        }

    }, [mode])

    const scheduleInfo = misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion);



    if (scheduleInfo === undefined) {
        contentButton.push({key: 'CONTENT_SCHEDULE_CONTENT_BUTTON', title: "DID_MAIN_CONTENT", value:'CONTENT', popup: false, onClick: ()=>changeContent('CONTENT')});
    } else {
        const {contentSchedule: {contents}} = scheduleInfo;
        const {supportedContent, supportedPlaylist, supportedInputSource, supportedRuleSet} = CONTENT_SCHEDULE_CONFIG[programType];

        contentButton = [];
        if (supportedContent && (contents === undefined || contents.content)) {
            contentButton.push({key: 'CONTENT_SCHEDULE_CONTENT_BUTTON', title: "DID_MAIN_CONTENT", value:'CONTENT', popup: false, onClick: ()=>changeContent('CONTENT')});
        }
        if (supportedPlaylist && (contents === undefined || contents.playlist)) {
            contentButton.push({key: 'CONTENT_SCHEDULE_PLAYLIST_BUTTON', title: "TEXT_TITLE_PLAYLIST_P", value: 'PLAYLIST', popup: false, onClick: ()=>changeContent('PLAYLIST')});
        }
        if (supportedInputSource && (contents === undefined || contents.inputSource)) {
            contentButton.push({key: 'CONTENT_SCHEDULE_INPUT_SOURCE_BUTTON', title: "TABLE_INPUT_SOURCE_P", value: 'INPUT_SOURCE', popup: false, onClick: ()=>changeContent('INPUT_SOURCE')});
        }
        if (supportedRuleSet && (contents === undefined || contents.ruleset)) {
            contentButton.push({key: 'CONTENT_SCHEDULE_RULE_SET_BUTTON', title: "MIS_SID_RULESET", popup: false, value: 'RULE_SET', onClick: ()=>changeContent('RULE_SET')});
        }

    }

    const onCategoryCheck = (checkedIds) => {
        if (mode === 'CONTENT') {
            setContent({...content, list:[], count: 0, filter: {...content.filter, startIndex: 1, categoryIds: checkedIds}})
        } else if (mode === 'PLAYLIST') {
            setPlaylist({...playlist, list:[], count: 0, filter: {...playlist.filter, startIndex: 1, categoryIds: checkedIds}})
        }
    };

    const onRemoveGroupFilter = () => {
        if (mode === 'CONTENT') {
            setContent({...content, list:[], count: 0, filter: {...content.filter, startIndex: 1, groupType: undefined, groupId: undefined, groupName: undefined}})
        } else if (mode === 'PLAYLIST') {
            setPlaylist({...playlist, list:[], count: 0, filter: {...playlist.filter, startIndex: 1, groupType: undefined, groupId: undefined, groupName: undefined}})
        } else if (mode === 'RULE_SET') {
            setRuleSet({...ruleSet, list:[], count: 0, filter: {...ruleSet.filter, startIndex: 1, groupId: undefined, groupName: undefined}});
        }
    };

    let submenuContentsDivtopMargin = 0;
    if ((mode === 'CONTENT' && content.filter && content.filter.groupType !== undefined)
        || (mode === 'PLAYLIST' && playlist.filter && playlist.filter.groupType !== undefined)
        || (mode === 'RULE_SET' && ruleSet.filter && ruleSet.filter.groupType !== undefined)) {
        
            submenuContentsDivtopMargin = 65;
    }

    return (
        <div className={"content_schedule_wrap"}>

            <div className="schedule_lnb_title" style={{display: 'flex'}}>
                <SelectBox selects={contentButton} value={mode} whiteButton={true} width={110}/>
                {
                    (mode === 'CONTENT' || mode === 'PLAYLIST') &&
                    <CategoryButton defaultCheckedKeys={mode === 'CONTENT' ? content.filter.categoryIds || [] : playlist.filter.categoryIds || []} onCategoryCheck={onCategoryCheck} whiteButton={true}/>
                }
            </div>
            {
                mode === 'CONTENT'  && content.filter !== undefined && content.filter.groupId !== undefined && content.filter.groupType === 'GROUPED' &&
                    <div style={{padding: 20, paddingBottom:5}}>
                        <Chip title={content.filter.groupName} isWhiteBackground height={25} onDelete={onRemoveGroupFilter}/>
                    </div>
            }
            {
                mode === 'PLAYLIST'  && playlist.filter !== undefined && playlist.filter.groupId !== undefined && playlist.filter.groupType === 'GROUPED' &&
                <div style={{padding: 20, paddingBottom:5}}>
                    <Chip title={playlist.filter.groupName} isWhiteBackground height={25} onDelete={onRemoveGroupFilter}/>
                </div>
            }
            {
                mode === 'RULE_SET'  && ruleSet.filter !== undefined && ruleSet.filter.groupId !== undefined &&
                <div style={{padding: 20, paddingBottom:5}}>
                    <Chip title={ruleSet.filter.groupName} isWhiteBackground height={25} onDelete={onRemoveGroupFilter}/>
                </div>
            }
            {
                (mode !== 'INPUT_SOURCE') &&
                <div style={{height: 70}}>
                    <div className="list_top">
                        <GroupButton type={"contentSchedule"} onClick={()=>onGroupPopup()}/>
                        {
                            mode === 'CONTENT' &&
                            <Filter id={"content_schedule"} type={"content_schedule"} width={765} onSaveFilter={(filter)=>onSaveFilter(filter)} mediaTypes={CONTENT_SCHEDULE_CONFIG[programType].supportedContentFilter} cachedFilter={content.filter}/>
                        }
                        {
                            mode === 'PLAYLIST' &&
                            <Filter type={"content_schedule_playlist"} width={390} onSaveFilter={(filter)=>onSaveFilter(filter)} playlistTypes={getSupportedPlaylistType(programType, deviceType)} cachedFilter={playlist.filter}/>
                        }
                        <SwitchListNImage type={"contentSchedule"} view={view} onClick={()=>onChangeListNImage(mode)}/>
                        {
                            mode === 'CONTENT' && <SearchBar width={85} type={"contentSchedule"} onClickSearch={(value)=>onSearch(value)} keyword={content.filter.searchText !== undefined && content.filter.searchText !== '' ? content.filter.searchText : ''}/>
                        }
                        {
                            mode === 'PLAYLIST' && <SearchBar width={85} type={"contentSchedule"} onClickSearch={(value)=>onSearch(value)} keyword={playlist.filter.searchText !== undefined && playlist.filter.searchText !== '' ? playlist.filter.searchText : ''}/>
                        }
                        {
                            mode === 'RULE_SET' && <SearchBar width={100} type={"contentSchedule"} onClickSearch={(value)=>onSearch(value)} keyword={ruleSet.filter.searchText !== undefined && ruleSet.filter.searchText !== '' ? ruleSet.filter.searchText : ''}/>
                        }
                    </div>
                </div>
            }

            <div id="external-events" style={{display: 'block',position: 'absolute', height: style.height - submenuContentsDivtopMargin, overflow: 'auto', width: '100%'}}>
                {
                    isFetching &&
                    <div className={'loading'}>
                        <Loading/>
                    </div>
                }

                <ul className="schedule_contents scrollbar" id={"submenu_schedule_contents_scroll"}>
                    {
                        // for contents
                        mode === 'CONTENT' && content !== undefined && content.list.length > 0 &&
                        content.list.map(
                            (item, index) =>
                                view === 'IMAGE' ?
                                    <li className="thumbnail" key={index}>
                                        <div className="thumbnail_box" type={scheduleConstants.CONTENT_SCHEDULE_TYPE_CONTENT} title={item.contentName} id={item.contentId} data-media-type={item.contentType} data-content-type={item.mediaType} data-thumbnail={item.thumbFileId} data-expiration-date={item.expirationDate} data-file-size={item.totalSize}>
                                            <ContentThumbnail id={item.thumbFileId} filename={item.thumbFileName} width={100} height={56} icon={true} contentType={item.mediaType}/>
                                        </div>
                                        <p>{item.contentName}</p>
                                    </li> :
                                    <li className={'list'} key={index} style={{display: 'flex'}} >
                                        <div className="thumbnail_box" style={{width:90,height:50}} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_CONTENT} title={item.contentName} id={item.contentId} data-media-type={item.contentType} data-content-type={item.mediaType} data-thumbnail={item.thumbFileId} data-expiration-date={item.expirationDate} data-file-size={item.totalSize}>
                                            <ContentThumbnail id={item.thumbFileId} filename={item.thumbFileName} width={100} height={56} icon={true} contentType={item.mediaType}/>
                                        </div>
                                        <div className="detail">
                                            <div className="contentName">{item.contentName}</div>
                                            <div className="contentType">{item.mediaType}</div>
                                            <div className="duration">{item.playTimeInString}</div>
                                        </div>
                                    </li>
                        )
                    }
                    {
                        // for playlist
                        mode === 'PLAYLIST' && playlist !== undefined && playlist.list.length > 0 &&
                        playlist.list.map(
                            (item, index) =>
                                view === 'IMAGE' ?
                                    <li className="thumbnail" key={index}>
                                        <div className="thumbnail_box" type={scheduleConstants.CONTENT_SCHEDULE_TYPE_PLAYLIST} title={item.playlistName} id={item.playlistId} data-media-type={item.playlistType} data-content-type={'PLAYLIST'} data-thumbnail={item.thumbnailInfo} data-file-size={item.totalSize} >
                                            <ContentThumbnail id={getThumbnailId(item.thumbnailInfo, 0)} filename={getThumbnailId(item.thumbnailInfo, 1)} width={100} height={56}/>
                                        </div>
                                        <p>{item.playlistName}</p>
                                    </li> :
                                    <li className={'list'} key={index} style={{display: 'flex'}}>
                                        <div className="thumbnail_box" style={{width:90,height:50}} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_PLAYLIST} title={item.playlistName} id={item.playlistId} data-media-type={item.playlistType} data-content-type={'PLAYLIST'} data-thumbnail={item.thumbnailInfo} data-file-size={item.totalSize}>
                                            <ContentThumbnail id={getThumbnailId(item.thumbnailInfo, 0)} filename={getThumbnailId(item.thumbnailInfo, 1)} width={100} height={56}/>
                                        </div>
                                        <div className="detail">
                                            <div className="contentName">{item.playlistName}</div>
                                            <div className="contentType">{getPlaylistType(item.playlistType)}</div>
                                            <div className="duration">{item.playTime}</div>
                                        </div>
                                    </li>
                        )
                    }
                    {
                        // for input source
                        mode === 'INPUT_SOURCE' &&
                        inputSource.map(
                            item =>
                                <li className="source" key={item.value}>
                                    <a href={"#"} className={"thumbnail_box"} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_INPUT_SOURCE} id={item.value} title={item.text} data-content-type={'HW_IS'} >
                                        <span className={item.icon}></span>
                                        {item.text}
                                    </a>
                                </li>
                        )
                    }
                    {
                        // for rule set
                        mode === 'RULE_SET' && ruleSet !== undefined && ruleSet.list.length > 0 ?
                            ruleSet.list.map(
                                (item, index) =>
                                    view === 'IMAGE' ?
                                        <li className="thumbnail" key={index}>
                                            <div className="thumbnail_box" type="rulset" type={scheduleConstants.CONTENT_SCHEDULE_TYPE_RULE_SET} title={item.name} id={item.rulesetId} data-content-type={'RULESET'} data-thumbnail={item.thumbFileId} data-file-size={item.totalSize}>
                                                <ContentThumbnail id={item.thumbFileId} filename={item.thumbFileName} width={100} height={56}/>
                                            </div>
                                            <p>{item.name}</p>
                                        </li> :
                                        <li className={'list'} key={index} style={{display: 'flex'}}>
                                            <div className="thumbnail_box" style={{width:90,height:50}} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_RULE_SET} title={item.name} id={item.rulesetId} data-media-type={'RULESET'} data-content-type={'RULESET'} data-thumbnail={item.thumbFileId} data-file-size={item.totalSize} >
                                                <ContentThumbnail id={item.thumbFileId} filename={item.thumbFileName} width={100} height={56}/>
                                            </div>
                                            <div className="detail">
                                                <div className="contentName">{item.name}</div>
                                            </div>
                                        </li>
                            )
                            : ''
                    }
                </ul>

            </div>
        </div>
    )

}
export default SubmenuContentsContainer;