import React from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import DialogTitle from "./DialogTitle";
import DialogBody from "./DialogBody";
import DialogFooter from "./DialogFooter";
import {isString, isNumber} from 'lodash';

class Dialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: props.height,
            width: props.width,
            isMinimized: false,
            isMaximized: false,
            left: 0,
            top: 0
        };

        this.dialogRef = React.createRef();
    }

    componentWillMount() {
        /**
         * This is done in the componentWillMount instead of the componentDidMount
         * because this way, a modal that is a child of another will have register
         * for events after its parent
         */
        //this.eventToken = EventStack.addListenable([["keydown", this.handleGlobalKeydown]]);
    }

    componentWillUnmount = () => {
        //EventStack.removeListenable(this.eventToken);
    }

    componentDidMount = () => {
        this.setState({left: this.dialogRef.current.clientWidth / 2, top: this.dialogRef.current.clientHeight / 2});
    }

    handleGlobalKeydown = (e) => {
       /* if (this.props.closeOnEscape && e.keyCode == 27) {
            e.stopPropagation();
            this.onClose();
        }

        return false;*/
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    onMinimize = () => {
        this.setState({ isMinimized: true, isMaximized: false });
    }

    onMaximize = () => {
        this.setState({ isMinimized: false, isMaximized: true });
    }

    onRestore = () => {
        this.setState({ isMinimized: false, isMaximized: false });
    }

    onResize = (event, { element, size }) => {
        this.setState({ width: size.width, height: size.height });
    }

    getDialogTitle = () => {
        return (
            <DialogTitle
                title={this.props.title}
                hasCloseIcon={this.props.hasCloseIcon}
                allowMinimize={this.props.allowMinimize}
                allowMaximize={this.props.allowMaximize}
                isMinimized={this.state.isMinimized}
                isMaximized={this.state.isMaximized}
                onMinimize={this.onMinimize}
                onMaximize={this.onMaximize}
                onRestore={this.onRestore}
                onClose={this.onClose}
                popupId = {this.props.popupId}
                modal = {this.props.modal}
                useEllipsis={this.props.useEllipsis}
            />
        );
    }

    render() {
        const { height, width, isMinimized, isMaximized, left, top } = this.state;
        const { modal, isDraggable, isResizable, buttons, children, position, zIndex, popupId, modalDraggable } = this.props;
        const {X, Y} = position;
        
        let dialogStyle = {
            height: height, 
            width: width,
            zIndex: zIndex
        };

        if(isString(X) && X.toUpperCase() === 'RIGHT'){
            dialogStyle.right = 0;
        } else if(isString(X) && X.toUpperCase() === 'LEFT'){
            dialogStyle.left = 0;
        } else if(isNumber(X)){
            dialogStyle.left = X;
        } else { // default CENTER
            dialogStyle.left = `calc(50% - ${left}px)`;
        }

        if(isString(Y) && Y.toUpperCase() === 'BOTTOM'){
            dialogStyle.bottom = 0;
        } else if(isString(Y) && Y.toUpperCase() === 'TOP'){
            dialogStyle.top = 0;
        } else if(isNumber(Y)){
            dialogStyle.top = Y;
        } else { // default CENTER
            dialogStyle.top = `calc(50% - ${top}px)`;
        }

        let dialog = (
            <div style={dialogStyle} className={cs("ui-dialog", { "minimized": isMinimized, "maximized": isMaximized })} ref={this.dialogRef}>
                {this.getDialogTitle()}
                {
                    !isMinimized && <DialogBody popupId={popupId} modal={modal}>{children}</DialogBody>
                }
                {
                    !isMinimized && <DialogFooter buttons={buttons} onClose={this.onClose}></DialogFooter>
                }
            </div>
        );

        if (!isMinimized && !isMaximized && isResizable) {
            dialog = (
                <Resizable className="box" height={height} width={width} onResize={this.onResize}>
                    {dialog}
                </Resizable>
            );
        }

        if (!isMaximized && isDraggable !== false) {
            dialog = (
                <Draggable handle=".ui-dialog-titlebar" bounds="body">
                    {dialog}
                </Draggable>
            );
        }

        return (
            <div
                className={cs("ui-dialog-container", { "": modal })}>
                {modal && <div className="ui-dialog-overlay"></div>}
                {modalDraggable && <div className="ui-dialog-overlay-with-draggable"></div>}
                {dialog}

            </div>
        );
    }
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    modal: PropTypes.bool,
    position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
    }),
    hasCloseIcon: PropTypes.bool,
    allowMinimize: PropTypes.bool,
    allowMaximize: PropTypes.bool,
    isResizable: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    closeOnEscape: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]).isRequired,
    buttons: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            text: PropTypes.string,
            onClick: PropTypes.func
        })),
        PropTypes.arrayOf(PropTypes.element)
    ]),
    modalDraggable: PropTypes.bool
};

Dialog.defaultProps = {
    height: 300,
    width: 500,
    modal: false,
    closeOnEscape: true,
    isDraggable: false,
    isResizable: false,
    title: '',
    hasCloseIcon: true,
    allowMinimize: false,
    allowMaximize: false,
    onClose: null,
    buttons: null,
    position: { x: -250, y: -150 },
    zIndex : null,
    modalDraggable: false
};

export default Dialog;