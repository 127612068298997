import React, { useState } from 'react';
import classNames from 'classnames';
import './DeviceNameCell.css';
import DevicePowerIcon from './DevicePowerIcon';
import RefreshIcon from '../../images/icon/refresh_normal.png';
import DeviceIcons from './DeviceIcons';
import { SIGNAGE } from '../../constants';

const ToggleButton = ({device, onChange}) => {
    const [open, setOpen] = useState(false);

    const onClick = () => {
        onChange && onChange(!open, device);
        setOpen(!open);
    };

    return (
        <button className={classNames('tb_arrow', {'on': open})} onClick={onClick}></button>
    );
};

const DeviceNameCell = ({device, showIcon, showPowerIcon, showConvertable, disableLink, enableChild, toggleChild, onClick, onClickMemo}) => {
    return (
        <div className='device_name_wrap'>
            <div>
                {
                    showPowerIcon &&
                    <DevicePowerIcon power={device.power || device.powerOn} />
                }
                <span className={classNames({'device_name_cell': !disableLink})} onClick={() => !disableLink && onClick(device.deviceId)}>{device.deviceName}</span>
            </div>
            {
                showIcon && <DeviceIcons device={device} onClickMemo={onClickMemo} />
            }
            {
                showConvertable && <img src={RefreshIcon} className="mr4" alt="Convert" title="Convert" style={{width: 16, height: 14}} />
            }
            {
                enableChild && device.deviceType === SIGNAGE && device.childCount > 0 &&
                <ToggleButton device={device} onChange={toggleChild} />
            }
        </div>
    );
};

DeviceNameCell.defaultProps = {
    device: {},
    showIcon: true,
    showPowerIcon: true,
    showConvertable: false,
    onClick: () => {},
};

export default React.memo(DeviceNameCell);