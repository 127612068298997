import React from 'react';
import './App.css';
import {Route, Router} from 'react-router-dom';
import {history, PrivateRoute} from './helper';
import {connect} from "react-redux";
import {alertActions, menuAction, userActions} from "./actions";
import LoginPage from './pages/login/LoginPage';
import MainPage from './pages/main/MainPage';
import LandingPage from './pages/login/LandingPage';

class App extends React.Component {

    constructor(props) {
        super(props);
        const {clear} = this.props;
        history.listen((location, action) => {
            clear();
        });
        document.body.style.minHeight = '500px';
    }

    componentDidMount() {
        this._updateDimensions();
        window.addEventListener("resize", this._updateDimensions.bind(this));
        //window.addEventListener('beforeunload', this._closeWindow.bind(this));
    }

    componentWillUnmount() {
        localStorage.removeItem("user");
    }

    _updateDimensions() {
        let height = window.innerHeight;
        document.body.style.minHeight = height+'px';
    }

    _closeWindow(){
        const {logout} = this.props;
        logout();
    }

    render() {
        return (
            <Router history={history} >
                <div>
                    <PrivateRoute exact path="/" component={()=> <MainPage />}/>
                    <Route exact path="/login" component={LoginPage}/>
                    <Route path="/landing" component={LandingPage}/>
                </div>
            </Router>
        )
    }
}

export default connect(
    state => ({
        alert: state.alert
    }),
    dispatch => ({
        addTab: tab => dispatch(menuAction.addTab(tab)),
        clear: () => dispatch(alertActions.clear()),
        logout : () => dispatch(userActions.logout())
    })

)(App);
