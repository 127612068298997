import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import MISDialog from "../MISDialog";
import './SavePlaylistPopup.css';
import TextInput from "../../input/TextInput";
import RadioGroup from "../../radio/RadioGroup";
import {SearchButton} from "../../button/SearchButton";
import Moment from "moment";
import {playlistService} from "../../../services";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import {commonConstants, contentConstants} from "../../../constants";

const onOffShuffleOptions = [
    {value:'N', title:'BUTTON_OFF'},
    {value:'Y', title:'TEXT_ON_P'},
];

const onOffShareOptions = [
    {value:1, title:'TEXT_ON_P'},
    {value:0, title:'BUTTON_OFF'},
];

const onOffEvenlyOptions = [
    {value:1, title:'TEXT_ON_P'},
    {value:0, title:'BUTTON_OFF'}
];

const onOffIgnoreTagOptions = [
    {value:1, title:'TEXT_ON_P'},
    {value:0, title:'BUTTON_OFF'}
];

const SavePlaylistPopup = ({playlistInfo, onSave, onClose, mode, addPopup, closePopup}) => {
    const {t} = useTranslation();
    const user = localStorage.getItem('user');

    const {playlistName = "[New Playlist] " + Moment().format('YYYY-MM-DD HH:mm:ss'), shareFlag, metaData, groupId, groupName, playlistType, shuffleFlag, ignoreTag, evennessPlayback} = playlistInfo;
    const [playlistNameValue, setPlaylistNameValue] = useState(mode === 'SAVE' ? playlistName : "[New Playlist] " + Moment().format('YYYY-MM-DD HH:mm:ss'));
    const [shuffleValue, setShuffleValue] = useState(shuffleFlag);
    const [shareValue, setShareValue] = useState(shareFlag);
    const [descriptionValue, setDescriptionValue] = useState(metaData || "");
    const [groups, setGroups] = useState({
        groupId: groupId,
        groupName: groupName
    });

    const [ignoreTagValue, setIgnoreTagValue] = useState(ignoreTag);
    const [evennessPlaybackValue, setEvennessPlaybackValue] = useState(evennessPlayback);
    const setGroup = (groups) => {
        if (groups !== undefined && groups.length > 0) {
            setGroups({groupId: groups[0].groupId, groupName: groups[0].groupName});
            closePopup(commonConstants.COMMON_GROUP_POPUP);
        }
    };

    useEffect(()=> {
        Promise.all([
            playlistService.fetchPlaylistGroupsByUserId(JSON.parse(user).id),
        ]).then(values => {
            JSON.parse(user).id && isEmpty(groupName) && setGroups({groupId:  values[0].items[0].groupId, groupName:  values[0].items[0].groupName})
        }).catch(
        )
    }, []);

    return (
        <div>
            <MISDialog
                dialog = {{
                    title: mode === 'SAVE' ? t("COM_BUTTON_SAVE") : t("BUTTON_SAVE_AS_P"),
                    closeOnEscape : true,
                    modal: true,
                    width : 430,
                    height : 'auto' ,
                    position: {x: -300, y: -400} ,
                    onClose:()=> onClose()
                }}
                buttons ={{
                    leftButtons: [
                    ],
                    rightButtons: [
                        {
                            id: 'APPROVE_SUBMIT',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: (e) => {
                                playlistNameValue.trim() !== "" && onSave({
                                    ...playlistInfo,
                                    playlistName: playlistNameValue.trim(),
                                    shuffleFlag: shuffleValue,
                                    shareFlag: shareValue,
                                    metaData: descriptionValue,
                                    groupId: groups.groupId,
                                    groupName: groups.groupName,
                                    ignoreTag: ignoreTagValue,
                                    evennessPlayback: evennessPlaybackValue
                                })
                            },
                        },
                        {
                            id: 'APPROVE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: () => onClose(),
                        }
                    ]
                }}
            >
                <div className={"save_playlist_popup_wrap"}>

                    <div className={"detail_view mini_p mt14 mb9"}>
                        <table>
                            <colgroup>
                                <col width="101px" />
                                    <col width="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th><span className="popupTh90">{t("TABLE_PLAYLIST_NAME_P")}</span></th>
                                    <td>
                                        <TextInput width={218} type={"text"} value={playlistNameValue}
                                                   error={!playlistNameValue.trim() ? t('MESSAGE_COMMON_ENTER_NAME_P') : ''}
                                                   errorWidth={160}
                                                   maxLength={60}
                                                   onChange={(e) => setPlaylistNameValue(e.target.value)} /></td>
                                </tr>
                            <tr>
                                <th><span className="popupTh90">{t("TEXT_GROUP_P")}</span></th>
                                <td>
                                    <div className="float_l mr10">
                                        <SearchButton onClick={()=> {
                                            addPopup({
                                                id: commonConstants.COMMON_GROUP_POPUP,
                                                type: commonConstants.COMMON_GROUP_POPUP,
                                                mode: 'playlist',
                                                checkbox: false,
                                                params: {groupType : contentConstants.MY_CONTENT_GROUP},
                                                save: (groups)=> setGroup(groups),
                                                close: ()=>closePopup(commonConstants.COMMON_GROUP_POPUP)
                                            });
                                        }}/>
                                    </div>
                                    <div style={{width: 150,background:'none'}}>
                                        <input type="text" style={{width:'100%',background:'none'}} readOnly value={groups.groupName}/>
                                    </div>
                                </td>
                            </tr>
                                {
                                    playlistType === '0' &&
                                        <tr>
                                            <th><span className="popupTh90">{t("TEXT_SHUFFLE_P")}</span></th>
                                            <td>
                                            <RadioGroup
                                                propertyName="playlistShuffle"
                                                selects={onOffShuffleOptions}
                                                value={shuffleValue}
                                                onChange={(e) => setShuffleValue(e.target.getAttribute('data-value')) }
                                            />
                                            </td>
                                        </tr>
                                }
                            <tr>
                                <th><span className="popupTh90">{t("DID_CMS_SHARE")}</span></th>
                                <td>
                                    <RadioGroup
                                        propertyName="playlistShare"
                                        selects={onOffShareOptions}
                                        value={shareValue}
                                        onChange={(e) => {
                                            setShareValue(parseInt(e.target.getAttribute('data-value')))
                                        }}
                                />
                                </td>
                            </tr>
                                {
                                    playlistType === '5' &&
                                    <tr>
                                        <th><span className="popupTh90">{t("MIS_SID_SERVER_PLAY_EVENTLY")}</span></th>
                                        <td>
                                            <RadioGroup
                                                propertyName="playEvenly"
                                                selects={onOffEvenlyOptions}
                                                value={evennessPlaybackValue}
                                                onChange={(e) => setEvennessPlaybackValue(parseInt(e.target.getAttribute('data-value')))}
                                            />
                                        </td>
                                    </tr>
                                }
                                {
                                    playlistType === '5' &&
                                    <tr>
                                        <th><span className="popupTh90">{t("MIS_SID_SERVER_IGNORE_TAG")}</span></th>
                                        <td>
                                            <RadioGroup
                                                propertyName="ignoreTag"
                                                selects={onOffIgnoreTagOptions}
                                                value={ignoreTagValue}
                                                onChange={(e) => setIgnoreTagValue(parseInt(e.target.getAttribute('data-value')))}
                                            />
                                        </td>
                                    </tr>
                                }
                            <tr className="va_t">
                                <th><span className="popupTh90">{t("TEXT_DESCRIPTION_P")}</span></th>
                                <td>
                                    <textarea
                                        style={{width:218, minHeight:80}}
                                        maxLength={200}
                                        value={descriptionValue}
                                        onChange={(e) => setDescriptionValue(e.target.value)}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </MISDialog>
        </div>
    );
};
export default SavePlaylistPopup;

